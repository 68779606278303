import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { obterPacotePorId } from 'src/api/pacote'
import { obterOrcamentoPorId } from 'src/api/solicitacao-orcamento'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import yup from 'src/components/yupCustom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useListagem from 'src/hooks/useListagem'
import { useQueryParams } from 'src/hooks/useQuery'
import { IOrcamentoDetalhadoResponse, ObjetoDescricaoGenerico } from 'src/types'
import { IPacoteForm } from '../pacote/formPacote'

import { AutocompleteRenderOptionState } from '@material-ui/lab'
import { AiTwotoneThunderbolt } from 'react-icons/ai'
import { BiNetworkChart } from 'react-icons/bi'
import {
  ItemListagemPacoteUnificado,
  ListagemPacoteUnificadoQuery,
  listagemPacotesUnificados,
  obterPacoteInteligentePorId
} from 'src/api/pacoteInteligente'
import { IPacoteInteligenteForm } from 'src/components/pacoteInteligente/FormPacoteInteligente'
import UBox from 'src/components/ui/Ubox'
import { MobileContext } from 'src/contexts/Mobile'
import { SynPerfis } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import { FormOrcamento, StepsCotacao } from './formV2'
import { FormOrcamentoInteligenteV2 } from './inteligente/formV2'
import Button from 'src/components/ui/Button'

const useStyles = makeStyles(theme => ({
  container: {},
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  infoTxt: {
    color: theme.palette.info.light
  },
  noMargin: {
    margin: theme.spacing(0)
  },
  BtnCancel: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.common.white,
    '@media (max-width: 1300px)': {
      marginBotton: '10px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

type Props = {
  fecharForm: () => void
}

export const FormOrcamentoUnificado = (props: Props) => {
  const classes = useStyles()
  const history = useHistory()

  const { autocompleteMobileOpen } = useContext(MobileContext)

  const query = useQueryParams()

  //#region Definicao de listas e loading
  const [loadingOrcamento, setLoadingOrcamento] = useState(false)

  const [listaPacotes, setListaPacotes] = useState<
    ItemListagemPacoteUnificado[]
  >([])

  const [medicoSolicitacaoId, setMedicoSolicitacaoId] = useState<string>(null)
  const [medicoCpf, setMedicoCpf] = useState<string>(null)

  const [
    orcamentoDetalhado,
    setOrcamentoDetalhado
  ] = useState<IOrcamentoDetalhadoResponse>()

  const [loadingPacote, setLoadingPacote] = useState(false)
  const [pacoteSelecionado, setPacoteSelecionado] = useState<IPacoteForm>(null)
  const [
    pacoteInstantaneoSelecionado,
    setPacoteInstantaneoSelecionado
  ] = useState<IPacoteInteligenteForm>(null)
  const [activeStep, setActiveStep] = React.useState(0)

  const [activeStepCotacao, setActiveStepCotacao] = React.useState(
    StepsCotacao.resumo
  )
  const [ehEdicaoHospital, setEhEdicaoHospital] = useState(false)

  const { signOut, user, perfilAtual } = useAuth()

  //#endregion

  //#region Formulário

  const schema = yup.object().shape({
    pacote: yup.object().nullable().required().label('Pacote de procedimento')
  })

  let defaultValues: {
    pacote: null
  }

  // defaultValues = solicitacaoMock

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('pacote')
  }, [register])

  // VINCULAR MUDANÇA DE VALOR
  const handleItemValue = (name: string, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
  }

  //#endregion

  //#region MOUNT
  useEffect(() => {
    verificaSeTemPacoteSelecionado()
    verificaEhEdicao()
    verificaEhSecretaria()
    verificarEhEdicaoHospital()
  }, [])

  function verificaEhSecretaria() {
    const medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    const doc = query.get('doc')
    if (medicoSolicitacaoId) {
      setValue('medicoSolicitacaoId', medicoSolicitacaoId)
      setMedicoSolicitacaoId(medicoSolicitacaoId)
      setMedicoCpf(doc)
      // obterLocaisAtendimentoPorUfMedicoCom(medicoSolicitacaoId)
    } else {
      // carregarHospitais()
    }
  }

  function verificaEhEdicao() {
    const id = query.get('id')
    if (!id) {
      setLoadingOrcamento(false)
      return
    }

    obterOrcamentoPorId(id)
      .then(response => {
        const orcamento = response.data

        setOrcamentoDetalhado(orcamento)
        orcamento.pacote.tipo = 'cotacao'

        setValue('pacote', orcamento.pacote)

        setLoadingOrcamento(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        history.goBack()
      })
  }

  // console.log('command', orcamentoDetalhado)
  function verificaSeTemPacoteSelecionado() {
    const idPacote = query.get('idPacote')
    const descricaoPacote = query.get('descricaoPacote')
    const tipo = query.get('tipo')

    if (!!idPacote && !!descricaoPacote) {
      let pacote: ObjetoDescricaoGenerico = {
        id: idPacote,
        descricao: descricaoPacote,
        tipo: tipo
      }

      setValue('pacote', pacote)
    }
  }

  function verificarEhEdicaoHospital() {
    const verificar = query.get('editarHospital')

    if (verificar === 'true') {
      setEhEdicaoHospital(true)
    }
  }
  // console.log('ehEdicaoHospital', ehEdicaoHospital)
  //#endregion

  //#region Carregar Opções do Sistema
  const { loading, dados } = useListagem(1000, listagem)

  function listagem(
    queryBody: ListagemPacoteUnificadoQuery,
    primeiraBusca?: boolean
  ) {
    const medicoId = query.get('medicoSolicitacaoId')
    const doc = query.get('doc')
    queryBody['perfilAtual'] = perfilAtual
    queryBody['medicoId'] = medicoId
    queryBody['medicoCpf'] = doc
    return listagemPacotesUnificados(user.cpf, queryBody, true, true)
  }

  useEffect(() => {
    const medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    if (medicoSolicitacaoId) {
      const filtrado = dados.filter(w => w.medicoId === medicoSolicitacaoId)
      setListaPacotes(filtrado)
    } else {
      setListaPacotes(dados)
    }
    if (!!orcamentoDetalhado?.pacote) {
      setValue('pacote', orcamentoDetalhado.pacote)
    }
  }, [dados])

  //#endregion

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setPacoteInstantaneoSelecionado(null)
    setPacoteSelecionado(null)

    if (!command.pacote) {
      return
    }

    obterPacote(command.pacote.id)
  }, [command.pacote])

  function obterPacote(id) {
    setLoadingPacote(true)

    if (command.pacote.tipo === 'inteligente') {
      obterPacoteInteligentePorId(id)
        .then(response => {
          let pacoteResp = response.data
          let pacote: IPacoteInteligenteForm = pacoteResp

          setPacoteInstantaneoSelecionado(pacote)
        })
        .catch(e => showNotificationErrorAPI(e))
        .finally(() => {
          setLoadingPacote(false)
        })
    } else {
      obterPacotePorId(id)
        .then(response => {
          let pacoteResp = response.data
          let pacote: IPacoteForm = pacoteResp

          pacote.medicoId = medicoSolicitacaoId
          setPacoteSelecionado(pacote)
        })
        .catch(e => showNotificationErrorAPI(e))
        .finally(() => {
          setLoadingPacote(false)
        })
    }
  }

  const handleNovoPacote = texto => {
    history.replace(
      `${SynRoutes.formPacote}?returnUrl=${SynRoutes.formOrcamentoUnificado}`
    )
  }

  return (
    <>
      {loadingOrcamento && (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      )}

      <Box
        hidden={loadingOrcamento}
        component="main"
        className={`fadeInTransition ${classes.container}`}
      >
        <CssBaseline />
        {/* command: {JSON.stringify(command.valorEquipeAnestesia)}
        command: {JSON.stringify(command.equipeAnestesia)} */}
        {/* pacoteSelecionado: {JSON.stringify(pacoteSelecionado)} */}
        {/* <br /> */}
        {/* pacote: {JSON.stringify(command.pacote)} */}
        {/* <br /> */}
        {/* erros: {JSON.stringify(errors)} */}
        {/* <br /> */}
        {/* comorbidades: {JSON.stringify(listaComorbidades)} */}
        {/* {ehXS && (
          <LinearProgress
          variant="determinate"
          value={normalise(activeStep + 1)}
          />
          )} */}
        <Grid container>
          <UBox
            component={Grid}
            item
            xs={12}
            sm={12}
            hidden={
              activeStep !== 0 || activeStepCotacao !== StepsCotacao.resumo
            }
          >
            {ehEdicaoHospital && (
              <Box mt={2} mb={2} mr={1} className={`${classes.BtnCancel}`}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Voltar
                </Button>
              </Box>
            )}
            <Box mt={ehXS ? 2 : 5} pt={ehXS ? 0 : 2} p={0}>
              <form className={classes.form} noValidate={true}>
                <UBox>
                  <Container
                    maxWidth={'sm'}
                    className={`fadeInTransition ${classes.stepContent}`}
                    style={{
                      //  padding: 0,
                      height: autocompleteMobileOpen && ehXS ? '100vh' : 'auto'
                    }}
                  >
                    <Typography variant="h6" style={{ marginBottom: 16 }}>
                      {ehEdicaoHospital ? (
                        <>
                          {
                            'Ajuste o pacote de procedimento de acordo com as necessidades.'
                          }
                        </>
                      ) : (
                        <>
                          {`${
                            query.has('idPacote') ? 'Revise' : 'Escolha'
                          } o pacote de procedimento que será realizado e ajuste-o de acordo com as necessidades do seu paciente.`}
                        </>
                      )}
                    </Typography>
                    {!ehEdicaoHospital && (
                      <Autocomplete
                        key={command?.pacote?.id}
                        erro={errors}
                        name="pacote"
                        label="Pacote de procedimento"
                        loading={loading}
                        closeOnSelect={false}
                        value={command.pacote}
                        options={listaPacotes}
                        getOptionLabel={option => `${option.descricao}`}
                        renderOption={(
                          option: ItemListagemPacoteUnificado,
                          state: AutocompleteRenderOptionState
                        ) => {
                          return (
                            <UBox
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: ehXS ? 'none' : '1px solid #eee',
                                width: '100%',
                                height: '100%'
                              }}
                            >
                              {option.tipo === 'inteligente' ? (
                                <AiTwotoneThunderbolt
                                  className="icon"
                                  style={{
                                    fontSize: '26px',
                                    padding: '4px 0',
                                    color: '#ebb852'
                                  }}
                                />
                              ) : (
                                <BiNetworkChart
                                  className="icon"
                                  style={{
                                    fontSize: '26px',
                                    padding: '4px 0',
                                    color: '#1cc3a5'
                                  }}
                                />
                              )}
                              <Typography
                                variant="subtitle1"
                                style={{ marginLeft: '5px' }}
                              >
                                {option.descricao.toUpperCase()}
                                {option.tipo === 'inteligente' && (
                                  <div
                                    style={{
                                      textTransform: 'capitalize',
                                      margin: '-12px 0 0 ',
                                      padding: '0'
                                    }}
                                  >
                                    <strong>
                                      <small>{option?.hospital}</small>
                                    </strong>
                                  </div>
                                )}
                              </Typography>
                            </UBox>
                          )
                        }}
                        onChange={(event, newValue) => {
                          handleItemValue(
                            'pacote',
                            newValue as ItemListagemPacoteUnificado
                          )
                        }}
                        showAddButton={perfilAtual !== SynPerfis.SECRETARIA}
                        onClickAddButton={handleNovoPacote}
                        addButtonProps={{
                          text: 'Não encontrou? clique aqui'
                        }}
                      />
                    )}
                  </Container>
                </UBox>
              </form>
            </Box>
          </UBox>

          <UBox render={!!pacoteSelecionado} component={Grid} item xs={12}>
            <FormOrcamento
              fecharForm={() => {}}
              pacoteSelecionado={pacoteSelecionado}
              setPacoteSelecionado={pacote => {
                setPacoteSelecionado(pacote)
              }}
              activeStep={activeStepCotacao}
              setActiveStep={step => {
                setActiveStepCotacao(step)
              }}
              orcamentoDetalhado={orcamentoDetalhado}
              medicoCpf={medicoCpf}
              medicoId={medicoSolicitacaoId}
            />
          </UBox>

          <UBox
            render={!!pacoteInstantaneoSelecionado}
            component={Grid}
            item
            xs={12}
          >
            <FormOrcamentoInteligenteV2
              fecharForm={() => {}}
              pacoteSelecionado={pacoteInstantaneoSelecionado}
              setPacoteSelecionado={pacote => {
                setPacoteInstantaneoSelecionado(pacote)
              }}
              activeStep={activeStep}
              setActiveStep={step => {
                setActiveStep(step)
              }}
              // orcamentoDetalhado={orcamentoDetalhado}
              medicoCpf={medicoCpf}
              medicoId={medicoSolicitacaoId}
            />
          </UBox>
        </Grid>
      </Box>
    </>
  )
}
