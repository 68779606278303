import { yupResolver } from '@hookform/resolvers/yup'
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Button from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import UBox from 'src/components/ui/Ubox'
import estados from 'src/core/consts/estados'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import {
  onlyNumber,
  verificarCNPJ,
  verificarCPF
} from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import usePagamento from 'src/hooks/usePagamento'
import { useQueryParams } from 'src/hooks/useQuery'
import { ObterCep } from 'src/api/cep/cep'
import { Alert } from '@material-ui/lab'

import TextField from '../ui/TextField'
import yup from '../yupCustom'
import InfoResponsabilidade from './infoResponsabilidade'
import { FiArrowDown } from 'react-icons/fi'
import { Height } from '@material-ui/icons'
import ScrollToBottom from './scrollToBottom'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1, 0, 0)
    // minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  }
}))
interface IProps {
  handleNextStep: () => void
}

export class IDadosPessoais {
  cidade: string
  cep: string
  endereco: string
  estado: string

  documento: string
  email: string
  nome: string
  telefone: string
  propostaId?: string
}

const DEFAULT_DADOS: IDadosPessoais = {
  documento: '',
  email: '',
  nome: '',
  telefone: '',

  cidade: '',
  cep: '',
  endereco: '',
  estado: ''
}

const cartaoSchema = yup.object().shape({
  documento: yup
    .string()
    .required()
    .test('documento-teste', 'Documento inválido', value => {
      if (!value) return false

      const documento = onlyNumber(value)

      if (documento.length === 11) {
        return verificarCPF(documento)
      } else if (documento.length === 14) {
        return verificarCNPJ(documento)
      }
      return false
    })
    .label('CPF'),
  email: yup.string().nullable().email().required(),
  telefone: yup.string().required(),
  nome: yup.string().required().label('Nome completo / razão social'),
  cep: yup
    .string()
    .nullable()
    .required()
    .test('documento-teste', 'Cep inválido', value => {
      if (!value) return false

      const cep = onlyNumber(value)

      if (cep.length === 8) {
        return true
      }
      return false
    })
    .label('CEP'),
  estado: yup.string().nullable().required().label('UF'),
  cidade: yup.string().min(3).nullable().required().label('Cidade'),
  endereco: yup.string().min(3).nullable().required().label('Endereço')
})

export default function formDadosPessoais({ handleNextStep }: IProps) {
  const styles = useStyles()
  const theme = useTheme()
  const query = useQueryParams()
  const history = useHistory()

  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  const [loadingCep, setLoadingCep] = useState(false)

  const {
    loading,
    resumo,
    urlPix,
    urlPixFracionado,
    pagamentoPix,
    salvarDadosPessoais,
    dadosPessoais
  } = usePagamento()

  const {
    handleSubmit,
    errors,
    register,
    watch,
    control,
    setValue
  } = useForm<IDadosPessoais>({
    mode: 'all',
    resolver: yupResolver(cartaoSchema),
    defaultValues: dadosPessoais
  })
  const command = watch()
  const [loadingDados, setLoadingDados] = useState(false)

  async function submit(form: IDadosPessoais) {
    try {
      setLoadingDados(true)
      await salvarDadosPessoais(form)
      handleNextStep()
      setLoadingDados(false)
    } catch (e) {
      // Swal.fire('Ops...', 'A mensagem é obrigatória', 'warning')
      setLoadingDados(false)
      showNotificationErrorAPI(e)
    }
  }

  const handleRegister = (name, e: React.ChangeEvent<{ value: unknown }>) => {
    setValue(name, e.target.value, { shouldValidate: true })
  }

  async function buscarCep(cep) {
    try {
      let cepNumeros = onlyNumber(cep)
      if (cepNumeros.length === 8) {
        setLoadingCep(true)
        const { data } = await ObterCep(cepNumeros)
        if (data?.localidade) {
          setValue('cidade', data.localidade, { shouldValidate: false })
        }
        if (data?.logradouro) {
          setValue('endereco', data.logradouro, { shouldValidate: false })
        }
        if (data?.uf) {
          setValue('estado', 'BR-' + data.uf.toUpperCase(), {
            shouldValidate: false
          })
        }
        setLoadingCep(false)
      }
    } catch (error) {
      setLoadingCep(false)
    }
  }

  useEffect(() => {
    register('estado')
    register('cep')
  }, [register])

  useEffect(() => {
    buscarCep(command.cep)
  }, [command.cep])

  if (!resumo) return <LoadingGeral />

  return (
    <>
      <UBox
        component="form"
        onSubmit={handleSubmit(submit)}
        id="boxFormulario"
        // noValidate={true}
      >
        <ScrollToBottom cssSeletor=".btnProximoPasso" />
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <UBox>
                  <Typography variant="subtitle1" color="primary">
                    <b>Dados pessoais do pagador para emissão da nota fiscal</b>
                  </Typography>
                  <Divider />
                  {/* {JSON.stringify(command)} */}
                </UBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  disabled={loadingDados}
                  erro={errors}
                  // variant="filled"
                  // autoFocus
                  value={command.nome}
                  defaultValue={command.nome}
                  // control={control}
                  inputRef={register}
                  inputProps={{ maxLength: 100 }}
                  name="nome"
                  label="Nome completo / Razão social"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  disabled={loadingDados}
                  erro={errors}
                  type="tel"
                  mask={
                    command.documento?.replaceAll(' ', '').length <
                    '999.999.999-999'.length
                      ? '999.999.999-999999'
                      : '99.999.999/9999-99'
                  }
                  control={control}
                  name="documento"
                  label="Seu CPF / CNPJ"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={loadingDados}
                  size="small"
                  erro={errors}
                  inputRef={register}
                  inputProps={{ maxLength: 64 }}
                  name="email"
                  label="Seu melhor e-mail"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={loadingDados}
                  size="small"
                  erro={errors}
                  type="tel"
                  maskTelCel
                  control={control}
                  inputProps={{ maxLength: 100 }}
                  name="telefone"
                  label="Telefone"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <UBox mt={2}>
                  <Typography variant="subtitle1" color="primary">
                    <b>Endereço</b>
                  </Typography>
                  <Divider />
                </UBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={loadingDados}
                  type="tel"
                  size="small"
                  erro={errors}
                  mask="99999-999"
                  control={control}
                  inputProps={{ maxLength: 64 }}
                  name="cep"
                  label="Seu CEP"
                  InputLabelProps={{
                    shrink: true
                  }}
                  loading={loadingCep}
                />
              </Grid>
              {!ehXS && <Grid item xs={12} sm={6}></Grid>}

              <Grid item xs={12} sm={2}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  className={styles.formControl}
                >
                  <Select
                    error={!!errors.estado}
                    displayEmpty
                    className={styles.selectEmpty}
                    labelId="label-uf"
                    defaultValue=""
                    value={command?.estado}
                    placeholder="Seu UF"
                    name="estado"
                    inputRef={register}
                    onChange={(e, v) => {
                      handleRegister('estado', e)
                    }}
                  >
                    <MenuItem value="">
                      <em>UF</em>
                    </MenuItem>
                    {estados.map((estado, i) => (
                      <MenuItem key={i} value={`BR-${estado.Initials}`}>
                        {estado.Initials}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText error={!!errors.estado}>
                    {errors.estado?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  disabled={loadingDados}
                  size="small"
                  erro={errors}
                  inputRef={register}
                  inputProps={{ maxLength: 100 }}
                  name="cidade"
                  label="Sua cidade"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  disabled={loadingDados}
                  size="small"
                  erro={errors}
                  inputRef={register}
                  inputProps={{ maxLength: 100 }}
                  name="endereco"
                  label="Seu endereço"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <UBox mt={2} mb={1}>
          <InfoResponsabilidade />
        </UBox>
        <UBox mt={1} display="flex" justifyContent="end">
          <Button
            loading={loadingDados}
            // size="small"
            fullWidth={ehXS}
            color="primary"
            type="submit"
            className="btnProximoPasso"
          >
            Próximo passo
          </Button>
        </UBox>
      </UBox>
    </>
  )
}
