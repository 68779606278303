import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Radio,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'

import ChipStatusOrcamento from 'src/components/solicitacao/ChipStatusOrcamento'

import { SynPerfis } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import { IParceiroFuncionario } from 'src/types'
import formatarDocumento from 'src/core/formatacoes/formatarDocumento'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import { IAcomodacaoResponse } from 'src/api/acomodacao'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'

interface Props {
  data: IAcomodacaoResponse[]
  handleDesativar: (item: IAcomodacaoResponse) => void
  handleAtivar: (item: IAcomodacaoResponse) => void
  handleEditar: (item: IAcomodacaoResponse) => void
  handleDefinirPadrao: (item: IAcomodacaoResponse) => void
  handleVisualizarDetalhes: (item: IAcomodacaoResponse) => void
}

export default function TabelaAcomodacao({
  data,
  handleDesativar,
  handleAtivar,
  handleDefinirPadrao,
  handleVisualizarDetalhes,
  handleEditar
}: Props) {
  const history = useHistory()
  const { signOut, user, perfilAtual } = useAuth()
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="80px">Ativo</TableCell>
            <TableCell>Descricao</TableCell>
            <TableCell>Valor da diária</TableCell>
            <TableCell>Principal</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(item => {
            return (
              <TableRow key={item.id}>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={item.ativo}
                        onChange={() => {
                          if (item.ativo) handleDesativar(item)
                          else handleAtivar(item)
                        }}
                      />
                    }
                    label=""
                  />
                </TableCell>
                <TableCell>
                  {/* <Link
                    component="button"
                    variant="body1"
                    color="primary"
                    align="left"
                    onClick={() => handleVisualizarDetalhes(item)}
                  > */}
                  {item.descricao}
                  {/* </Link> */}
                </TableCell>
                <TableCell>{formatarDinheiro(item.valor)}</TableCell>
                <TableCell>
                  {/* {item.padrao ? 'Sim' : 'Não'} */}
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={!item.ativo}
                        checked={item.padrao}
                        onChange={() => handleDefinirPadrao(item)}
                        name="checkPadrao"
                        color="primary"
                      />
                    }
                    label=""
                  />
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {popupState => (
                      <React.Fragment>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          aria-label="mais"
                          {...bindTrigger(popupState)}
                        >
                          <MoreVertIcon />
                        </IconButton>

                        <Menu
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          {...bindMenu(popupState)}
                        >
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleEditar(item)
                            }}
                          >
                            Editar
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleDefinirPadrao(item)
                            }}
                          >
                            Marcar como principal
                          </MenuItem>

                          {!item?.ativo ? (
                            <MenuItem
                              onClick={() => {
                                popupState.close()
                                handleAtivar(item)
                              }}
                            >
                              Ativar
                            </MenuItem>
                          ) : (
                            <MenuItem
                              onClick={() => {
                                popupState.close()
                                handleDesativar(item)
                              }}
                              className="danger"
                            >
                              Desativar
                            </MenuItem>
                          )}
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
