import React from 'react'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { MdArrowForward } from 'react-icons/md'
import Lottie from 'react-lottie'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components/ui/Button'
import { SynRoutes } from 'src/core/consts/synRoutes'
import * as animationData from '../../assets/animations/done.json'
import { useQueryParams } from 'src/hooks/useQuery'
import { primeiroNome } from 'src/core/helpers/common'

const IconHeight = '80px'
const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    paddingTop: '100px'
  },
  iconDone: {
    height: IconHeight,
    width: IconHeight,
    alignSelf: 'center',
    margin: '0 auto 20px',
    '& div': {
      cursor: 'default'
    }
  },
  icon: {
    fontSize: '1.1rem',
    margin: '-2px 0 0 18px'
  }
}))

function CadastroSucesso() {
  const history = useHistory()
  let query = useQueryParams()
  const user = query.get('nome')

  const defaultOptions = {
    loop: true,
    autoplay: true,
    speed: 0.1,
    animationData: (animationData as any).default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  const classes = useStyles()

  return (
    <Container
      maxWidth="md"
      className={`fadeInTransition syn-container ${classes.root}`}
    >
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.iconDone}>
            <Lottie
              options={defaultOptions}
              isClickToPauseDisabled={true}
              isStopped={false}
              isPaused={false}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            Estamos quase lá <b>Dr(a). {primeiroNome(user)}</b>
          </Typography>
          <p>
            Clique no link que você receberá dentro de instantes por{' '}
            <b>e-mail</b> e <b>WhatsApp</b>
            . Através desse link iremos concluir o seu cadastro.
            <br />
            Obrigado
          </p>
          {/* <Button
            variant="contained"
            onClick={() => history.push(SynRoutes.signin)}
          >
            Continuar <MdArrowForward className={classes.icon} />
          </Button> */}
        </Grid>
      </Grid>
    </Container>
  )
}

export default CadastroSucesso
