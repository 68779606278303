import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import { useHistory } from 'react-router-dom'
import Boleto from 'src/components/pagamento/boleto'
import Pix from 'src/components/pagamento/pix'
import CartaoCredito from 'src/components/pagamento/cartaoCredito'
import MultiplasFormas from 'src/components/pagamento/multiplasFormas'
import Button from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import UBox from 'src/components/ui/Ubox'
import { T_FORMAS_PAGAMENTO } from 'src/contexts/Pagamento/context'
import { SynRoutes } from 'src/core/consts/synRoutes'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import usePagamento from 'src/hooks/usePagamento'
import { useQueryParams } from 'src/hooks/useQuery'
import { Alert } from '@material-ui/lab'
import Steps from 'src/components/pagamento/steps'
import config from 'src/config'
import InfoResponsabilidade from 'src/components/pagamento/infoResponsabilidade'

const FORMA_PAGAMENTO = {
  PIX: 'pix' as T_FORMAS_PAGAMENTO,
  CARTAO_CREDITO: 'credit_card' as T_FORMAS_PAGAMENTO,
  BOLETO: 'boleto' as T_FORMAS_PAGAMENTO,
  MULTIPLAS: 'multiplas' as T_FORMAS_PAGAMENTO
}

const ARRAY_FORMA_PAGAMENTO = [
  {
    tipo: 'pix',
    icone: <i className="fa fa-brands fa-pix "></i>,
    nome: 'Pix'
  },
  {
    tipo: 'credit_card',
    icone: <i className="fa fa-solid fa-credit-card "></i>,
    nome: 'Cartão de crédito'
  },
  {
    tipo: 'boleto',
    icone: <i className="fa fa-solid fa-barcode "></i>,
    nome: 'Boleto'
  },
  {
    tipo: 'multiplas',
    icone: <i className="fa fa-square-plus"></i>,
    nome: 'Multiplas formas de pagamento'
  }
]

const padrao = {
  background: '#fff',
  borderRadius: '8px'
  // boxShadow: '1px 1px 8px #ddd'
}
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    // margin: '0 auto',
    margin: '0 ',
    padding: 0,
    // gap: '40px',
    '& .card-pagamento': {
      ...padrao
    },
    '& .item-selecionado': {
      ...padrao,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '72px',
      lineHeight: '1.1em'
      // background: '#eee',
      // borderRadius: '8px',
      // borderBottom: '1px solid #ddd',
      // borderRight: '1px solid #ddd',
    }
  },
  boxResumo: {
    ...padrao,
    width: '440px',
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(6)
  },
  boxPagamento: {
    // ...padrao,
    width: 'calc(100% - 480px)'
    // width: '60%'
  },
  boxPagamentoDetalhe: {
    ...padrao,
    // maxWidth: '700px',
    width: '100%',
    '& .qr-code': {
      maxWidth: '210px'
    },
    '& .box-instructions p': {
      width: 'calc(100% - 48px)'
    },
    '& .icon-instructions': {
      background: theme.palette.secondary.main,
      color: '#fff',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 20,
      marginRight: '8px'
    },
    '& .back-green': {
      background: '#1cc3a5'
      // background: '#1cc332'
    },
    '& .text-green': {
      color: '#1cc3a5'
      // color: '#1cc332',
    }
  },
  boxFormaPagamento: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',

    '& .item': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '180px',
      gap: '8px',
      minHeight: '84px',
      // background: '#eee',
      border: '1px solid #999',
      color: '#878787',
      borderRadius: '8px',
      cursor: 'pointer',
      textAlign: 'center',
      lineHeight: '1rem',
      fontSize: '0.8rem',
      // borderBottom: '1px solid #ddd',
      // borderRight: '1px solid #ddd',

      '&:hover': {
        // background: theme.palette.secondary.main,
        // background: theme.palette.secondary.main,
        // color: '#fff',
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      },

      '&:last-child': {
        // borderRight: 'none'
      }
    }
  },
  [theme.breakpoints.down(1401)]: {
    boxFormaPagamento: {
      flexDirection: 'column',
      '& .item': {
        width: '100%'
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    container: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'start',
      flexDirection: 'column',
      gap: '40px',
      margin: 0,
      padding: 0
    },
    boxResumo: {
      ...padrao,
      marginLeft: 0,
      width: '100%',
      // maxWidth: '700px',
      order: 1
    },
    boxPagamento: {
      // ...padrao,
      width: '100%',
      order: 2
    },
    boxFormaPagamento: {
      flexDirection: 'column',
      '& .item': {
        width: '100%'
      }
    }
  }
}))

export default function Pagamento() {
  const styles = useStyles()
  const theme = useTheme()
  const query = useQueryParams()
  const history = useHistory()

  const {
    loadingResumo,
    loadingPagamento,
    loading,
    obterResumoPagamento,
    urlPix,
    boleto,
    formaPagamento,
    selecionarFormaPagamento,
    resumo,
    existePagamentoFracionado,
    reiniciarContext
  } = usePagamento()

  const [primeiroLoading, setPrimeiroLoading] = useState(true)
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  const propostaId = query.get('p')

  async function start() {
    reiniciarContext()
    selecionarFormaPagamento('multiplas')
    obterResumoPagamento(propostaId).then(carregou =>
      setPrimeiroLoading(!carregou)
    )
  }

  var rotaFinanciamento = 'financiamentoStep'
  function financiamento() {
    window.open(`
      ${rotaFinanciamento}?p=${propostaId}`)
  }

  useEffect(() => {
    if (!!resumo?.pago && !primeiroLoading) {
      history.replace(`${SynRoutes.acompanharPagamento}?p=${propostaId}`)
    }
  }, [resumo, primeiroLoading])

  useEffect(() => {
    start()
  }, [])

  if (loading || primeiroLoading) {
    return (
      <UBox
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      </UBox>
    )
  }

  return (
    <UBox>
      <UBox render={!formaPagamento} mx={2}>
        <Alert severity="info">
          <Typography variant="body2" component="span">
            Por favor escolha acima a forma de pagamento que mais lhe atende.
            <br />
            <br />
            Caso deseje pagar o valor total de uma única vez, escolha uma das
            opções: cartão de crédito (parcelamos em até 12x), PIX ou boleto
            bancário.
            <br />
            <br />
            Caso deseje pagar os valores separados utilizando mais de uma forma
            de pagamento, escolha a opção: Múltiplas formas de pagamento.
          </Typography>
        </Alert>
      </UBox>

      <UBox render={!formaPagamento} mx={2}>
        <InfoResponsabilidade />
      </UBox>

      {/* Loading */}
      <UBox
        render={loadingPagamento}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
        <div>Carregando...</div>
      </UBox>

      {/* Inicio Detalhe do pagamento */}
      <UBox
        // p={3}
        mt={0}
        // hidden={loadingPagamento || !formaPagamento}
        className={styles.boxPagamentoDetalhe}
      >
        {/* f:{JSON.stringify(formaPagamento)} <br /> */}
        <MultiplasFormas />
      </UBox>
    </UBox>
  )
}
