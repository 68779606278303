import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { RiArrowLeftLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { obterOrcamentoParceiroPorId } from 'src/api/solicitacao-orcamento'
import HeaderSolicitacao, {
  IPacienteHeader
} from 'src/components/solicitacao/HeaderSolicitacao'
import Ubox from 'src/components/ui/Ubox'
import TipoAnexo from 'src/core/consts/tipoAnexo'
import { TiposAnestesias } from 'src/core/consts/tiposAnestesia'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { useQueryParams } from 'src/hooks/useQuery'
import {
  ComprovanteGenerico,
  ComprovanteHospital
} from 'src/pages/dashboard/operacional/solicitacao/drawerAnexos'
import ResumoOrcamento from 'src/pages/dashboard/parceiro/hospital/components/resumoOrcamento'
import {
  IOrcamentoDetalhadoResponse,
  IPacoteDetalhadoResponse
} from 'src/types'

const useStyles = makeStyles(theme => ({
  body: {},
  content: {
    maxWidth: '700px',
    margin: '10px '
  },
  boxResumo: {
    // backgroundColor: theme.palette.grey[50]
    // background: 'none',
    // boxShadow: 'none'
  },
  divider: {
    margin: '10px 0'
  },
  calculoText: {
    fontSize: '10px'
  },
  cardItem: {
    margin: '9px 0'
  }
}))
let parceiroId = ''
const DadosSolicitacao = () => {
  const styles = useStyles()
  const query = useQueryParams()
  const history = useHistory()
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const ehMD = useMediaQuery(theme.breakpoints.down('md'))

  parceiroId = query.get('pid')
  const [tab, setTab] = useState(0)
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  const [loading, setLoading] = useState(true)
  const [ehPacoteInstantaneo, setEhPacoteIntantaneo] = useState(false)

  const [orcamento, setOrcamento] = useState<IOrcamentoDetalhadoResponse>()
  const [pacote, setPacote] = useState<IPacoteDetalhadoResponse>()
  const [paciente, setPaciente] = useState<IPacienteHeader>()
  const [verificaAnestesia, setVerificaAnestesia] = useState<boolean>(false)
  const [mostrarContatoPaciente, setMostrarContatoPaciente] = useState<boolean>(
    false
  )

  useEffect(() => {
    const id = query.get('id')

    obterOrcamentoParceiroPorId(id)
      .then(response => {
        const dados = response.data

        setOrcamento(dados.solicitacaoOrcamento)
        setPacote(dados.pacoteProcedimento)
        setMostrarContatoPaciente(dados.parceiroVenceuProposta)
        setPaciente({
          nome: dados.solicitacaoOrcamento.pacienteNome,
          dataNascimento: dados.solicitacaoOrcamento.pacienteDataNascimento,
          telefone: dados.solicitacaoOrcamento.pacienteTelefone,
          sexo: dados.solicitacaoOrcamento.pacienteSexo,
          email: dados.solicitacaoOrcamento.pacienteEmail
        })

        if (!!dados?.solicitacaoOrcamento?.pacoteInstantaneo) {
          setEhPacoteIntantaneo(true)
          setTab(1)
        }
        setLoading(false)
      })
      .catch(erro => showNotificationErrorAPI(erro))
  }, [])

  useEffect(() => {
    var ehAnestesiaLocal =
      pacote?.tiposAnestesia?.filter(
        w =>
          w.id === TiposAnestesias.local_realizado_pelo_medico ||
          w.id === TiposAnestesias.local_realizado_pelo_medico_dev
      ).length > 0 && pacote?.tiposAnestesia?.length === 1
    setVerificaAnestesia(ehAnestesiaLocal)
  }, [pacote])

  if (loading) {
    return (
      <Box minHeight="300px" textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  return (
    <Box>
      <Link
        component="button"
        color="primary"
        onClick={() => history.goBack()}
        style={{ marginBottom: '20px' }}
      >
        <RiArrowLeftLine />
        <Typography
          style={{ marginLeft: 10 }}
          variant="subtitle1"
          component="span"
        >
          Voltar
        </Typography>
      </Link>
      <HeaderSolicitacao
        solicitacao
        orcamentoParceiro
        paciente={paciente}
        pacoteProcedimento={pacote}
        medicoNome={orcamento.medicoNome}
        comorbidades={orcamento.comorbidades}
        obsComorbidades={orcamento.observacaoComorbidades}
      />

      <Tabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        // centered
      >
        {/* <Tab value={0} label="Resumo da solicitação" /> */}
        {!ehPacoteInstantaneo && (
          <Tab value={0} label="Detalhes do orçamento" />
        )}
        <Tab value={1} label="Anexos / Comprovantes" />
      </Tabs>
      <Ubox className={styles.content}>
        <Ubox hidden={tab !== 0}>
          <ResumoOrcamento solicitacaoId={orcamento.id} isHospital />
        </Ubox>
        <Ubox hidden={tab !== 1}>
          {!!orcamento?.id && (
            <ComprovanteGenerico
              destinatarioId={parceiroId}
              tipo={TipoAnexo.HOSPITAL.id}
              solicitacaoId={orcamento?.id}
            />
          )}
        </Ubox>
      </Ubox>
    </Box>
  )
}

export default DadosSolicitacao
