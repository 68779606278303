import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache'
import { QueryClientProvider, QueryClient } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false
    }
  }
})
ReactDOM.render(
  // <React.StrictMode>
  <ClearCacheProvider auto>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </ClearCacheProvider>,
  // </React.StrictMode>
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
