import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import LoadingPage from 'src/components/pages/LoadingPage'
import { Button } from 'src/components/ui/Button'
import PasswordField from 'src/components/ui/PasswordField'
import TextField from 'src/components/ui/TextField'
import { SynRoutes } from 'src/core/consts/synRoutes'
import {
  showNotification,
  showNotificationErrorAPI
} from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import { useQueryParams } from 'src/hooks/useQuery'
import api from 'src/services/api'
import { AuthResponse, VerificarAcessoResponse } from 'src/types'
import yup from '../../components/yupCustom'

const useStyles = makeStyles(theme => ({
  container: {},
  logo: {
    width: 120,
    cursor: 'pointer'
  },
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1, 0, 0)
    // minWidth: 120
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  },
  texto: { marginTop: theme.spacing(3), textAlign: 'center' }
}))

const schema = yup.object().shape({
  cpf: yup.string().required().label('CPF'),
  senha: yup.string().min(6).max(50).required().label('Senha'),
  confirmaSenha: yup
    .string()
    .min(6)
    .required()
    .label('Confirmação de senha')
    .test('passwords-match', 'Confirmação está diferente', function (value) {
      return this.parent.senha === value
    })
})

interface ConfigurarAcessoMedicoCommand {
  cpf: string
  senha: string
  confirmaSenha: string
}

export default function ConfigurarAcessoMedico() {
  const classes = useStyles()
  const history = useHistory()
  const { setUserSyn } = useAuth()
  const query = useQueryParams()
  const token = query.get('token')

  const [loading, setLoading] = useState<boolean>(true)
  const [nome, setNome] = useState<string>()

  let defaultValues: ConfigurarAcessoMedicoCommand = {
    cpf: null,
    senha: null,
    confirmaSenha: null
  }

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    handleSubmit,
    formState
  } = useForm<ConfigurarAcessoMedicoCommand>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues
  })
  const command = watch()

  useEffect(() => {
    obterInformacoesAcesso()
  }, [])

  const obterInformacoesAcesso = async () => {
    await api
      .get<VerificarAcessoResponse>(`/medico/verificarAcesso?token=${token}`)
      .then(response => {
        const { acessoConfigurado, nome } = response.data
        //Caso configurado redirecionar para dash
        if (acessoConfigurado) {
          history.replace(SynRoutes.home)
          return
        }
        setLoading(false)
        setNome(nome)
      })
      .catch(error => {
        showNotificationErrorAPI(error)
        history.replace(SynRoutes.home)
      })
  }

  const onSubmit = async data => {
    const body = { ...data, token }
    await api
      .post<AuthResponse>('medico/configurarAcesso', body)
      .then(response => {
        showNotification('Parabéns', 'Acesso configurado.', 'success')
        setUserSyn(response.data)
        history.push(SynRoutes.home)
      })
      .catch(error => showNotificationErrorAPI(error))
      .finally(() => setLoading(false))
  }

  if (loading) return <LoadingPage />
  return (
    <Container
      component="main"
      className={`fadeInTransition syn-container ${classes.container}`}
      maxWidth="sm"
    >
      <CssBaseline />

      <Box textAlign="center">
        <img
          onClick={() => history.replace(SynRoutes.home)}
          className={classes.logo}
          src="/image/logo.png"
          alt="logo syn saúde"
        />
      </Box>
      <Grid container spacing={0} direction="column">
        <Grid item xs={12} sm={12} className={classes.texto}>
          <Typography color="textPrimary" variant="subtitle2" component="span">
            Seja bem-vindo(a),
          </Typography>
          <Typography color="textPrimary" variant="h6" component="h2">
            Dr(a). {nome}
          </Typography>
          <Typography
            style={{ marginTop: 8 }}
            color="textPrimary"
            variant="subtitle2"
            component="p"
          >
            Vamos começar configurando seus dados de acesso em nossa plataforma
            Syn Saúde.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.form}
            noValidate={true}
          >
            <Grid container spacing={1} direction="row">
              <Grid item xs={12}>
                <Typography
                  className={classes.subTitle}
                  color="primary"
                  display="block"
                  variant="caption"
                >
                  Acesso
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required={true}
                  mask={'999.999.999-99'}
                  control={control}
                  erro={errors}
                  name="cpf"
                  label="CPF"
                  defaultValue=""
                />
              </Grid>
              <Grid container spacing={1} className={classes.noMargin}>
                <Grid item xs={12} sm={12}>
                  <PasswordField
                    erro={errors}
                    name="senha"
                    label="Senha"
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <PasswordField
                    erro={errors}
                    name="confirmaSenha"
                    label="Confirmação de senha"
                    inputRef={register}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container justify="flex-end" style={{ marginTop: '24px' }}>
              <Grid item xs={12} sm={12}>
                <Button
                  loading={formState.isSubmitting}
                  disabled={formState.isSubmitting}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  )
}
