import {
  FormControl,
  FormHelperText,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useHistory } from 'react-router-dom'
import usePagamento from '../../hooks/usePagamento'

import LoadingGeral from '../../components/ui/LoadingGeral'
import UBox from '../../components/ui/Ubox'
import { T_FORMAS_PAGAMENTO } from '../../contexts/Pagamento/context'
import { useQueryParams } from '../../hooks/useQuery'
import Boleto from './boleto'
import Pix from './pix'
import CartaoCredito from './cartaoCredito'

import NumberFormat from 'react-number-format'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { Alert } from '@material-ui/lab'
import yup from '../yupCustom'
import Button from '../ui/Button'
import { showNotification } from 'src/core/helpers/notification'
import CurrencyInput from '../ui/CurrencyInput'
import ScrollToBottom from './scrollToBottom'
import ProgressoPagamento from './progressoPagamento'

const FORMA_PAGAMENTO = {
  PIX: 'pix' as T_FORMAS_PAGAMENTO,
  CARTAO_CREDITO: 'credit_card' as T_FORMAS_PAGAMENTO,
  BOLETO: 'boleto' as T_FORMAS_PAGAMENTO,
  MULTIPLAS: 'multiplas' as T_FORMAS_PAGAMENTO
}

const ARRAY_FORMA_PAGAMENTO = [
  {
    tipo: 'pix',
    icone: <i className="fa fa-brands fa-pix "></i>,
    nome: 'Pix'
  },
  {
    tipo: 'credit_card',
    icone: <i className="fa fa-solid fa-credit-card "></i>,
    nome: 'Cartão de crédito'
  },
  {
    tipo: 'boleto',
    icone: <i className="fa fa-solid fa-barcode "></i>,
    nome: 'Boleto'
  }
]

const useStyles = makeStyles(theme => ({
  boxFormaPagamento: {
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    margin: '0 -16px',
    gap: '16px',
    flexWrap: 'nowrap',
    '& .item': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      boxSizing: 'border-box',

      // width: '180px',
      // maxWidth: '30%',
      gap: '8px',
      minHeight: '84px',
      // background: '#eee',

      border: '1px solid #999',
      color: '#878787',

      borderRadius: '8px',
      cursor: 'pointer',
      textAlign: 'center',
      '&:hover': {
        // width: 'calc(100% - 4px)',
        // border: `2px solid ${theme.palette.secondary.main}`,
        // color: theme.palette.secondary.main
        // color: '#fff'
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      },
      '&.active, .active:hover': {
        // width: 'calc(100% - 4px)',
        background: theme.palette.primary.main,
        color: '#fff',
        // border: '0px solid #333'
        // color: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  [theme.breakpoints.down(1401)]: {
    boxFormaPagamento: {
      flexDirection: 'column',
      '& .item': {
        width: '100%'
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    boxPagamento: {
      // ...padrao,
      width: '100%',
      order: 2
    },
    boxFormaPagamento: {
      flexDirection: 'column',
      '& .item': {
        width: '100%'
      }
    }
  }
}))

interface IMultiplasForm {
  valorPagamento: number
}
const valorMinimo = 50000
let saldoD: number = null

export default function MultiplasFormas() {
  const schema = yup.object().shape({
    valorPagamento: yup
      .number()
      .nullable()
      .required()
      .test('valor-pagamento-teste', 'Valor de pagamento inválido', value => {
        if (!value) return false

        return true
      })
      .test('valor-pagamento-teste', obterMsg(), value => {
        var saldoDevedor = resumo.saldoDevedor * 100
        var minimo = saldoDevedor < valorMinimo ? saldoDevedor : valorMinimo
        if (value < minimo) {
          return false
        }
        return true
      })
      .label('Valor de pagamento')
  })

  const styles = useStyles()
  const theme = useTheme()
  const query = useQueryParams()
  const history = useHistory()
  const [formaPagamento, setFormaPagamento] = useState<T_FORMAS_PAGAMENTO>(null)

  const [mostrarFormPagamento, setMostrarFormPagamento] = useState<boolean>(
    false
  )

  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    resumo,
    limparFracionado,
    urlPixFracionado,
    boletoFracionado,
    pagamentoCreditoFracionado
  } = usePagamento()

  function obterMsg() {
    if (!saldoD) {
      return ''
    }

    var saldoDevedor = saldoD * 100
    var minimo = saldoDevedor < valorMinimo ? saldoDevedor : valorMinimo
    return `Valor de pagamento deve ser maior ou igual a ${formatarDinheiro(
      minimo / 100
    )}`
  }

  useEffect(() => {
    if (!!resumo?.saldoDevedor) {
      saldoD = resumo.saldoDevedor
    }
  }, [resumo.saldoDevedor])

  function selecionarFormaPagamento(forma: T_FORMAS_PAGAMENTO) {
    setFormaPagamento(forma)

    if (forma === null) {
      setMostrarFormPagamento(false)
    }
  }

  const {
    handleSubmit,
    errors,
    register,
    watch,
    control,
    setValue
  } = useForm<IMultiplasForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {}
  })
  const command = watch()

  function handleChangeValorPagamento(valor: number) {
    let valorFormatado = valor / 100
    if (!valorFormatado || valorFormatado < 0) {
      valor = 0
    }

    if (valorFormatado > resumo.saldoDevedor) {
      let novoValor = parseInt((resumo.saldoDevedor * 100).toFixed(0))
      setValue('valorPagamento', novoValor, { shouldValidate: true })
    } else {
      // return valorFormatado
      setValue('valorPagamento', valor, { shouldValidate: true })
    }
  }

  function handleProximoPagamento() {
    setValue('valorPagamento', 0, { shouldDirty: false })
    setFormaPagamento(null)
    setMostrarFormPagamento(false)
    limparFracionado()
  }

  function submit(form: IMultiplasForm) {
    if (form.valorPagamento / 100 > resumo?.saldoDevedor) {
      showNotification(
        'Verifique valor de pagamento',
        'Valor de pagamento é maior que o saldo devedor.',
        'warning'
      )
      setValue('valorPagamento', 0, { shouldDirty: false })
      return
    }

    if (!formaPagamento) {
      showNotification(
        'Selecione a forma de pagamento',
        'Forma de pagamento é obrigatório',
        'warning'
      )
      return
    }
    setMostrarFormPagamento(true)
    setTimeout(() => {
      try {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
      } catch (error) {
        window.scrollTo(0, 0)
      }
    }, 50)
  }
  const propostaId = query.get('p')
  useEffect(() => {}, [])
  useEffect(() => {
    register('valorPagamento')
  }, [register])

  var rotaFinanciamento = 'financiamentoStep'
  function financiamento() {
    window.open(`
      ${rotaFinanciamento}?p=${propostaId}`)
  }

  function obterValorFormatado() {
    return command.valorPagamento / 100
  }

  if (!resumo) return <LoadingGeral />

  return (
    <UBox p={0}>
      {!mostrarFormPagamento && <ScrollToBottom cssSeletor="#btnAvancar" />}
      <UBox
        render={!!formaPagamento && mostrarFormPagamento}
        width="100%"
        // maxWidth="700px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="item-selecionado"
      >
        <UBox display="flex" alignItems="center" justifyContent="center">
          <UBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{
              // background: '#1cc3a5',
              background: '#455880',
              width: '32px',
              height: '32px',
              minWidth: '32px',
              borderRadius: '50%',
              color: '#fff',
              fontSize: '1em'
            }}
            mr={2}
          >
            {ARRAY_FORMA_PAGAMENTO.find(f => f.tipo === formaPagamento)?.icone}
          </UBox>
          <Typography
            variant="h6"
            color="primary"
            style={{ lineHeight: '1.6rem' }}
          >
            <b>
              {ARRAY_FORMA_PAGAMENTO.find(f => f.tipo === formaPagamento)?.nome}
            </b>
          </Typography>
        </UBox>

        {!(
          !!pagamentoCreditoFracionado ||
          !!boletoFracionado ||
          !!urlPixFracionado
        ) && (
          <Button
            variant="text"
            color="primary"
            onClick={() => selecionarFormaPagamento(null)}
          >
            Alterar
          </Button>
        )}
      </UBox>

      <UBox
        p={0}
        hidden={mostrarFormPagamento}
        component="form"
        onSubmit={handleSubmit(submit)}
      >
        <UBox>
          <Grid container>
            <Grid item xs={12}>
              <UBox mb={3}>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  style={{ lineHeight: '1.25' }}
                >
                  <b>
                    Informe o valor e a forma de pagamento que deseja utilizar
                  </b>
                </Typography>
                <UBox mt={2}>
                  <Alert severity="info">
                    A proposta só estará disponivel como paga quando os valores
                    dos pagamentos processados for igual ao valor total da
                    proposta.
                  </Alert>
                </UBox>
              </UBox>
            </Grid>
            <Grid item xs={12}>
              <ProgressoPagamento />
            </Grid>
            <Grid item xs={12} sm={6}>
              <UBox
              //  display="flex" justifyContent="flex-end" width="100%"
              >
                <Typography
                  variant="subtitle1"
                  color="primary"
                  style={{ lineHeight: '1.25' }}
                >
                  <b>Informe o valor do pagamento:</b>
                  <br />
                  <small>
                    <b>máximo ({formatarDinheiro(resumo?.saldoDevedor)})</b>
                  </small>
                </Typography>
                <FormControl fullWidth>
                  <CurrencyInput
                    erro={errors}
                    autoFocus
                    // margin="normal"
                    name="valor"
                    // label="Teste"
                    hiddenLabel
                    control={control}
                    // type="tel"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onValueChange={handleChangeValorPagamento}
                    value={command?.valorPagamento}
                    fullWidth
                    // value={command?.valor || 0}
                  />

                  <FormHelperText error={!!errors.valorPagamento}>
                    {errors.valorPagamento?.message}
                  </FormHelperText>
                </FormControl>
              </UBox>
              {/* {JSON.stringify(command)} */}
              {/* {JSON.stringify(errors)} */}
            </Grid>
          </Grid>
        </UBox>
        <UBox mt={2}>
          <Typography variant="subtitle1" color="primary">
            <b>Escolha a forma de pagamento:</b>
          </Typography>
        </UBox>
        <UBox p={2} pt={0} className={styles.boxFormaPagamento}>
          <UBox
            hidden={
              !!formaPagamento &&
              formaPagamento !== FORMA_PAGAMENTO.CARTAO_CREDITO
            }
            className={`item ${
              formaPagamento === FORMA_PAGAMENTO.CARTAO_CREDITO && 'active'
            }`}
            onClick={() =>
              selecionarFormaPagamento(FORMA_PAGAMENTO.CARTAO_CREDITO)
            }
          >
            <i className="fa fa-solid fa-credit-card fa-2x"></i>
            <b>Cartão de crédito</b>
          </UBox>
          <UBox
            className={`item ${
              formaPagamento === FORMA_PAGAMENTO.PIX && 'active'
            }`}
            onClick={() => selecionarFormaPagamento(FORMA_PAGAMENTO.PIX)}
          >
            <i className="fa fa-brands fa-pix fa-2x"></i>
            <b>Pix</b>
          </UBox>
          <UBox
            hidden={
              !!formaPagamento && formaPagamento !== FORMA_PAGAMENTO.BOLETO
            }
            className={`item ${
              formaPagamento === FORMA_PAGAMENTO.BOLETO && 'active'
            }`}
            onClick={() => selecionarFormaPagamento(FORMA_PAGAMENTO.BOLETO)}
          >
            <i className="fa fa-solid fa-barcode fa-2x"></i>

            <b>Boleto</b>
          </UBox>
          <UBox
            hidden={!!formaPagamento}
            className={`item 'active'
          `}
            onClick={() => financiamento()}
          >
            <i className="fa fa-solid fa-wallet fa-2x"></i>
            <b>Solicitar financiamento</b>
          </UBox>
        </UBox>
        <UBox mt={2} display="flex" justifyContent="end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            // size="small"
            disabled={!formaPagamento}
            fullWidth={ehXS}
            id="btnAvancar"
          >
            Avançar
          </Button>
        </UBox>
      </UBox>

      <UBox
        mt={0}
        pt={0}
        hidden={!formaPagamento || !mostrarFormPagamento}
        className={styles.boxPagamentoDetalhe}
      >
        <UBox
          hidden={formaPagamento !== FORMA_PAGAMENTO.PIX}
          maxWidth={600}
          style={{ margin: '0 auto' }}
        >
          {command.valorPagamento && (
            <Pix
              key={command.valorPagamento}
              integral={false}
              valor={obterValorFormatado()}
              handleProximoPagamento={handleProximoPagamento}
            />
          )}
        </UBox>

        <UBox
          render={formaPagamento === FORMA_PAGAMENTO.BOLETO}
          maxWidth={400}
          style={{ margin: '0 auto' }}
        >
          {command.valorPagamento && (
            <Boleto
              key={command.valorPagamento}
              integral={false}
              valor={obterValorFormatado()}
              handleProximoPagamento={handleProximoPagamento}
            />
          )}
        </UBox>

        <UBox render={formaPagamento === FORMA_PAGAMENTO.CARTAO_CREDITO}>
          {command.valorPagamento && (
            <CartaoCredito
              key={command.valorPagamento}
              integral={false}
              valor={obterValorFormatado()}
              handleProximoPagamento={handleProximoPagamento}
            />
          )}
        </UBox>

        <UBox render={formaPagamento === FORMA_PAGAMENTO.MULTIPLAS}>
          <MultiplasFormas />
        </UBox>
      </UBox>
    </UBox>
  )
}
