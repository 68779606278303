const StatusPacoteInteligente = {
  AGUARDANDO_REVISAO: {
    Id: 1,
    Descricao: 'Aguardando revisão'
  },
  REVISAR_OPME: {
    Id: 2,
    Descricao: 'Aguardando revisão de OPME'
  },
  DISPONIVEL_SOLICITACAO: {
    Id: 3,
    Descricao: 'Disponível para solicitar'
  }
}
export default StatusPacoteInteligente
