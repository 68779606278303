import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField as TextFieldMU,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  adicionarComorbidade,
  adicionarLocalAtendimento,
  obterComorbidades,
  obterExames,
  obterLocaisAtendimentoPorUfMedico,
  obterLocaisAtendimentoPorUfMedicoComId,
  obterPacientePorCPF
} from 'src/api/dominio'
import { listagemPacote, obterPacotePorId } from 'src/api/pacote'
import {
  obterOrcamentoPorId,
  obterValoresOrcamentoInteligente,
  salvarSolicitacaoOrcamento,
  salvarSolicitacaoOrcamentoInteligente,
  ValoresInteligentesResponse
} from 'src/api/solicitacao-orcamento'
import { ModalAddGenerico } from 'src/components/modals/ModalAddGenerico'
import {
  StepConnectorSyn,
  StepIconSyn
} from 'src/components/stepper/stepConnector'
import { StepContentSyn } from 'src/components/stepper/stepContentSyn'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { TextField } from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import equipeAnestesias from 'src/core/consts/equipeAnestesia'
import genero from 'src/core/consts/genero'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { formatarIdade } from 'src/core/formatacoes/formatarData'
import {
  onlyNumber,
  primeiraLetraMaiusculo,
  verificarCPF
} from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useListagem from 'src/hooks/useListagem'
import { useQueryParams } from 'src/hooks/useQuery'
import {
  IComboResponse,
  IExame,
  IOrcamentoDetalhadoResponse,
  IPaciente,
  ObjetoDescricaoGenerico,
  SalvarPacoteCommand,
  SolicitarOcamentoCommand,
  SolicitarOcamentoInteligenteCommand
} from 'src/types'
import Swal from 'sweetalert2'

import DateInput from 'src/components/ui/DateInput'
import { NumberFormatCustom } from 'src/components/ui/NumberField'

import estados from 'src/core/consts/estados'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { TiposAnestesias } from 'src/core/consts/tiposAnestesia'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import useAuth from 'src/hooks/useAuth'
import {
  listagemMeusPacotesInteligentes,
  listagemPacoteInteligente,
  ListagemPacoteInteligenteQuery,
  obterPacoteInteligentePorId
} from 'src/api/pacoteInteligente'
import ResumoPacoteInteligente from 'src/components/pacoteInteligente/resumo'
import { IPacoteInteligenteForm } from 'src/components/pacoteInteligente/FormPacoteInteligente'
import DrawerAjustarPacoteInteligente from '../drawerAjustarPacoteInteligente'
import {
  FormConfiguracao,
  mapearPacoteParaRequest,
  Steps
} from '../../pacote/pacoteInteligente/formConfiguracao'
import { ResumoOrcamentoInteligente } from './resumo'
import ResumoPacoteInteligenteOps from 'src/components/pacoteInteligente/resumoOperacional'
import RevisaoPacoteInteligente from 'src/components/pacoteInteligente/revisao'
import { ResumoOrcamento } from '../resumo'
import UBox from 'src/components/ui/Ubox'
import { INTERNACOES_OBJ } from 'src/core/consts/internacoes'
import {
  buscarTodosMembrosEquipe,
  ItemListagemMembroEquipe
} from 'src/api/membroEquipe'
import CurrencyInput from 'src/components/ui/CurrencyInput'
import Button from 'src/components/ui/Button'
import { MdEdit } from 'react-icons/md'

const useStyles = makeStyles(theme => ({
  container: {},
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  infoTxt: {
    color: theme.palette.info.light
  },
  noMargin: {
    margin: theme.spacing(0)
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

export interface IOrcamentoForm {
  id?: string
  codigo?: number
  criado?: string
  dataHoraServidor?: string
  pacienteId?: string
  nomePaciente: string
  cpfPaciente: string
  telefonePaciente: string
  dataNascimentoPaciente: string
  emailPaciente: string
  sexoPaciente: number

  responsavelPacienteId?: string
  cpfResponsavel?: string
  nomeResponsavel?: string

  medicoId?: string
  medicoNome?: string
  medicoEspecialidades?: ObjetoDescricaoGenerico[]

  exames: IExame[]
  avaliacaoPreAnestesica?: boolean
  riscoCirurgico?: boolean
  observacaoExames?: string

  pacote: ObjetoDescricaoGenerico
  comorbidades: ObjetoDescricaoGenerico[]
  reservaSangue: string
  hospitais: ObjetoDescricaoGenerico[]
  observacaoSangue?: string
  observacaoComorbidades?: string

  solicitacaoSemLocalAtendimento: boolean
  temDisponibilidade: boolean

  disponibilidade: string
  anestesia?: string
  observacoes: string
  uf?: string

  combos?: IComboResponse[]
  semComorbidade?: boolean

  valorMinHonorarios?: number
  valorMaxHonorarios?: number
  valorEquipeAnestesia?: number
  equipeAnestesia?: number
  urlLogomarca?: string
  medicoSolicitacaoId?: string
  nomeAssistente?: string

  honorarioEquipeMedica?: number
  membros?: ItemListagemMembroEquipe[]
}

export function mapToForm(item: IOrcamentoDetalhadoResponse) {
  let orcamento: IOrcamentoForm = {
    id: item.id,
    medicoSolicitacaoId: item.medicoSolicitacaoId,
    codigo: item.codigo,
    criado: item.criado,
    dataHoraServidor: item.dataHoraServidor,
    cpfPaciente: item.pacienteCpf,
    nomePaciente: item.pacienteNome,
    emailPaciente: item.pacienteEmail,
    telefonePaciente: item.pacienteTelefone,
    sexoPaciente: item.pacienteSexo,
    pacienteId: item.pacienteId,
    dataNascimentoPaciente: item.pacienteDataNascimento,
    comorbidades: item.comorbidades,
    pacote: item.pacote,
    reservaSangue: item.qtdReservaSange.toString(),
    hospitais: item.locaisAtendimento,
    disponibilidade: item.disponibilidade,
    solicitacaoSemLocalAtendimento: item.solicitacaoSemLocalAtendimento,
    temDisponibilidade: !!item.disponibilidade,
    anestesia: item.anestesia,
    observacoes: item.observacoes,
    medicoId: item.medicoId,
    medicoNome: item.medicoNome,
    medicoEspecialidades: item.medicoEspecialidades,
    riscoCirurgico: item.riscoCirurgico,
    avaliacaoPreAnestesica: item.avaliacaoPreAnestesica,
    observacaoExames: item.observacaoExames,
    cpfResponsavel: item.cpfResponsavel,
    nomeResponsavel: item.nomeResponsavel,
    observacaoSangue: item.observacaoSangue,
    exames: item.exames,
    observacaoComorbidades: item.observacaoComorbidades,
    uf: item.uf,
    semComorbidade: item.semComorbidade,
    valorMaxHonorarios: item.valorMaxHonorarios,
    valorMinHonorarios: item.valorMinHonorarios,
    valorEquipeAnestesia: item.valorEquipeAnestesia,
    nomeAssistente: item.nomeAssistente,
    equipeAnestesia: !!item.valorEquipeAnestesia
      ? equipeAnestesias.medica
      : equipeAnestesias.hospital,
    urlLogomarca: item.urlLogomarca,
    responsavelPacienteId: item.responsavelPacienteId
  }
  return orcamento
}

type Props = {
  fecharForm?: () => void
  pacoteSelecionado?: IPacoteInteligenteForm
  setPacoteSelecionado?: (pacote: IPacoteInteligenteForm) => void
  activeStep?: number
  setActiveStep?: (step: number) => void
  medicoId?: string
  medicoCpf?: string
}

export const FormOrcamentoInteligenteV2 = (props: Props) => {
  const {
    activeStep,
    setActiveStep,
    pacoteSelecionado,
    setPacoteSelecionado,
    medicoCpf,
    medicoId
  } = props
  const classes = useStyles()
  const history = useHistory()

  const query = useQueryParams()

  //#region Definicao de listas e loading
  const [loadingOrcamento, setLoadingOrcamento] = useState(true)

  const [listaPacotes, setListaPacotes] = useState<ObjetoDescricaoGenerico[]>(
    []
  )
  const [loadingMembros, setLoadingMembros] = useState<boolean>(true)
  const [listaMembrosEquipe, setListaMembrosEquipe] = useState<
    ItemListagemMembroEquipe[]
  >([])

  const [listaHospitais, setListaHospitais] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingHospitais, setLoadingHospitais] = useState<boolean>(true)

  // const [
  //   valoresOrcamentos,
  //   setValoresOrcamentos
  // ] = useState<ValoresInteligentesResponse>(null)
  const [valoresOrcamentos, setValoresOrcamentos] = useState<any>(null)
  const [
    loadingValoresOrcamentos,
    setLoadingValoresOrcamentos
  ] = useState<boolean>(true)

  const [estadosHospitais, setEstadosHospitais] = useState<any[]>([])

  const [listaComorbidades, setListaComorbidades] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingComorbidades, setLoadingComorbidades] = useState<boolean>(true)
  const [semComorbidade, setSemComorbidade] = useState<boolean>(false)
  const [verificaAnestesia, setVerificaAnestesia] = useState<boolean>(false)

  const [verificouEquipe, setVerificouEquipe] = useState(false)

  const [
    comorbidadeMensagemErro,
    setComorbidadeMensagemErro
  ] = useState<boolean>(false)

  const [loadingPaciente, setLoadingPaciente] = useState(false)
  const [showFormPaciente, setShowFormPaciente] = useState(false)
  const [pacienteResponsavel, setPacienteResponsavel] = useState(false)

  const [loadingPacote, setLoadingPacote] = useState(false)

  const [ajustarPacote, setAjustarPacote] = useState(false)
  const [etapaAjuste, setEtapaAjuste] = useState<Steps>(undefined)

  const [erroTempoCirurgico, setErroTempoCirurgico] = useState(false)

  const [listaExames, setListaExames] = useState<IExame[]>([])
  const [loadingExames, setLoadingExames] = useState<boolean>(true)
  const [loadingMedicos, setLoadingMedicos] = useState<boolean>(true)

  const [showModalCombo, setShowModalCombo] = useState(false)
  const [ehFocoValor, setFocoValor] = useState(0)
  const { signOut, user, perfilAtual } = useAuth()

  const handleDisponibilidade = e => {
    if (!command.temDisponibilidade) {
      setValue('disponibilidade', '')
    }

    handleItemValue('temDisponibilidade', !command.temDisponibilidade)
  }

  const handleSolicitacaoSemLocalAtendimento = e => {
    setValue('hospitais', [])
    if (estadosHospitais.length !== 1) {
      setValue('uf', '')
    }
    setValue('locaisAtendimento', [])
    setValue('temDisponibilidade', false)
    setValue('disponibilidade', '')

    handleItemValue(
      'solicitacaoSemLocalAtendimento',
      !command.solicitacaoSemLocalAtendimento
    )
  }
  //#endregion

  //#region Modal
  const [textoModal, setTextoModal] = useState('')
  const [showModal, toggleModal] = useState<string>(null)
  const handleShowModal = (texto, modal) => {
    setTextoModal(texto)
    toggleModal(modal)
  }

  const dadosModal = () => {
    if (!showModal) return
    let dados = null
    switch (showModal) {
      case 'hospitais':
        dados = {
          titulo: 'Adicionar local',
          label: 'local de atendimento',
          saveSubmit: adicionarLocalAtendimento,
          handleValue: valor => {
            handleNovoValor('hospitais', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
      case 'comorbidades':
        dados = {
          titulo: 'Adicionar comorbidade',
          label: 'Comorbidade',
          saveSubmit: adicionarComorbidade,
          handleValue: valor => {
            handleNovoValor('comorbidades', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
    }
    return dados
  }
  //#endregion

  //#region Formulário

  const schema = yup.object().shape({
    nomePaciente: yup
      .string()
      .required()
      .test('nome válido', 'Deve informar nome e sobrenome', value => {
        if (!value) return false
        let nomeArray = value.split(' ').filter(texto => texto.trim())
        return nomeArray.length > 1
      })
      .label('Nome completo'),
    nomeResponsavel: yup
      .string()
      .test('nome-responsavel', 'Nome do responsável é obrigatório', value => {
        if (pacienteResponsavel && !value) {
          return false
        }
        return true
      })
      .test('nome válido', 'Deve informar nome e sobrenome', value => {
        if (pacienteResponsavel) {
          let nomeArray = value.split(' ').filter(texto => texto.trim())

          return nomeArray.length > 1
        }

        return true
      })
      .label('Nome completo'),
    cpfPaciente: yup
      .string()
      .nullable()
      .test('cpf-teste', 'CPF inválido', value => {
        if (pacienteResponsavel) return true

        if (!value) return false

        return verificarCPF(onlyNumber(value))
      })
      .label('CPF'),
    cpfResponsavel: yup
      .string()
      .nullable()
      .test(
        'cpf-responsavel',
        'CPF do responsável é obrigatório',
        function (value) {
          if (pacienteResponsavel && !value) {
            return false
          }
          return true
        }
      )
      .test('cpf-teste', 'CPF inválido', value => {
        if (pacienteResponsavel && value) {
          return verificarCPF(onlyNumber(value))
        }

        return true
      })
      .label('CPF'),
    telefonePaciente: yup.string().required().label('Telefone'),
    dataNascimentoPaciente: yup
      .string()
      .required()
      .test('nas', 'Por favor escolha uma data válida', value => {
        return moment(value).isBetween('1922-12-31', moment(), 'day')
      })
      .label('Data de nascimento'),
    emailPaciente: yup.string().email().label('E-mail'),
    sexoPaciente: yup
      .number()
      .required()
      .label('Sexo')
      .test('sexo-teste', 'Informe o sexo', value => {
        if (!value) return false
        return true
      }),
    // exames: yup.array().label('Exames'),
    pacote: yup.object().nullable().required().label('Pacote instantâneo'),
    comorbidades: yup.array().label('Comorbidades'),
    // avaliacaoPreAnestesica: yup.bool(),
    // riscoCirurgico: yup.bool(),
    // solicitacaoSemLocalAtendimento: yup.bool(),
    observacaoExames: yup.string().label('Observações dos exames'),
    observacaoSangue: yup.string().label('Observações'),
    reservaSangue: yup.string().label('Reserva de sangue'),
    // hospitais: yup
    //   .array()
    //   .nullable()
    //   .when('solicitacaoSemLocalAtendimento', {
    //     is: false,
    //     then: yup
    //       .array()
    //       .nullable()
    //       .min(1, 'Escolha ao menos 1 hospital')
    //       .label('Sugestões de hospitais')
    //   }),
    // temDisponibilidade: yup.bool(),
    // disponibilidade: yup.string().when('solicitacaoSemLocalAtendimento', {
    //   is: false,
    //   then: yup.string().when('temDisponibilidade', {
    //     is: true,
    //     then: yup.string().required().label('Disponibilidade')
    //   })
    // }),
    observacoes: yup.string().label('Observações'),
    observacaoComorbidades: yup.string().label('Observação das comorbidades'),
    combos: yup.array().label('Combos')
    // uf: yup.string().nullable().required().label('UF'),
    // valorMinHonorarios: yup
    //   .string()
    //   .required()
    //   .label('Valor mínimo do honorário da equipe')
    //   .test(
    //     'equipeAnestesia',
    //     'O valor minímo não pode ser maior que o honorário',
    //     value => {
    //       if (Number(value) > pacoteSelecionado?.honorarioEquipeMedica) {
    //         return false
    //       }
    //       return true
    //     }
    //   ),
    // valorMaxHonorarios: yup
    //   .string()
    //   .required()
    //   .label('Valor máximo do honorário da equipe')
    // equipeAnestesia: yup
    //   .number()
    //   .required()
    //   .label('Equipe anestesia')
    //   .test('equipeAnestesia', 'Informe a equipe da anestesia', value => {
    //     if (
    //       !value &&
    //       pacoteSelecionado?.tiposAnestesia?.length > 0 &&
    //       !verificaAnestesia
    //     ) {
    //       return false
    //     }
    //     return true
    //   }),
    // valorEquipeAnestesia: yup
    //   .string()
    //   .nullable()
    //   .test(
    //     'valorAnestesia',
    //     'Informe o valor da equipe de anestesia',
    //     (value, context) => {
    //       if (!value && context.parent.equipeAnestesia === 1) return false
    //       return true
    //     }
    //   )
  })

  let defaultValues: IOrcamentoForm = {
    pacienteId: '',
    nomePaciente: '',
    emailPaciente: '',
    telefonePaciente: '',
    cpfPaciente: '',
    dataNascimentoPaciente: '',
    sexoPaciente: 0,

    exames: [],
    avaliacaoPreAnestesica: false,
    riscoCirurgico: false,
    observacaoExames: '',

    pacote: null,
    comorbidades: [],
    hospitais: [],
    observacaoComorbidades: '',

    reservaSangue: '',
    temDisponibilidade: false,
    equipeAnestesia: 0,
    anestesia: '',
    disponibilidade: '',
    observacoes: '',
    solicitacaoSemLocalAtendimento: false,

    combos: [],
    urlLogomarca: undefined
  }

  // defaultValues = solicitacaoMock

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<IOrcamentoForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('medicoSolicitacaoId')
    register('id')
    register('sexoPaciente')
    register('hospitais')
    register('pacote')
    register('telefonePaciente')
    register('comorbidades')
    register('dataNascimentoPaciente')
    register('pacienteId')
    register('combos')
    register('valorMinHonorarios')
    register('valorMaxHonorarios')
    register('equipeAnestesia')
    register('responsavelPacienteId')

    register('honorarioEquipeMedica')
    register('membros')

    // register('temDisponibilidade')
    // register('solicitacaoSemLocalAtendimento')
    // register('riscoCirurgico')
    // register('avaliacaoPreAnestesica')
    // register('exames')
    // register('uf')
    // register('valorEquipeAnestesia')
  }, [register])

  // VINCULAR MUDANÇA DE VALOR
  const handleItemValue = (name: string, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
  }

  const handleNovoValor = (name, item: any) => {
    switch (name) {
      case 'comorbidades':
        carregarComorbidades()
        setValue('comorbidades', [...command.comorbidades, item], {
          shouldValidate: true
        })
        break
      case 'hospitais':
        carregarHospitais()
        setValue('hospitais', [...command.hospitais, item], {
          shouldValidate: true
        })
        break
    }
  }
  //#endregion

  //#region MOUNT
  useEffect(() => {
    // carregarPacotes()
    inicio()
  }, [])

  async function inicio() {
    await carregarMembros()
    carregarComorbidades()
    // carregarExames()
    verificaEhEdicao()
    verificaEhSecretaria()
  }

  function verificaEhSecretaria() {
    const medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    if (medicoSolicitacaoId) {
      setValue('medicoSolicitacaoId', medicoSolicitacaoId)
      obterLocaisAtendimentoPorUfMedicoCom(medicoSolicitacaoId)
    } else {
      carregarHospitais()
    }
  }

  const obterLocaisAtendimentoPorUfMedicoCom = async (medicoSolicitacaoId?) => {
    return obterLocaisAtendimentoPorUfMedicoComId(medicoSolicitacaoId)
      .then(response => {
        setListaHospitais(response.data)
        let listaTodosEstadosHospitais = response.data?.map(item => item.grupo)
        let estadosHospitais = [...new Set(listaTodosEstadosHospitais)]
        if (estadosHospitais.length === 1) {
          setValue('uf', estadosHospitais[0])
        }
        setEstadosHospitais(
          estados.filter(e => estadosHospitais.includes(e.Initials)).sort()
        )
      })
      .finally(() => {
        setLoadingHospitais(false)
      })
  }

  function verificaEhEdicao() {
    const id = query.get('id')
    if (!id) {
      setLoadingOrcamento(false)
      return
    }

    obterOrcamentoPorId(id)
      .then(response => {
        const orcamento = response.data
        const form = mapToForm(orcamento)
        setPacienteResponsavel(!!form.responsavelPacienteId)

        for (const key in form) {
          let value = form[key]
          if (key === 'dataNascimentoPaciente') {
            value = moment(value).format('yyyy-MM-DD')
          }

          if (key === 'semComorbidade') {
            setSemComorbidade(value)
            continue
          }
          setValue(key, value, { shouldValidate: false })
          if (key === 'medicoId') {
            setValue('medicoSolicitacaoId', value)
          }
        }
        setLoadingOrcamento(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        history.goBack()
      })
  }

  function verificaSeTemPacoteSelecionado() {
    const idPacote = query.get('idPacote')
    const descricaoPacote = query.get('descricaoPacote')

    if (!!idPacote && !!descricaoPacote) {
      let pacote: ObjetoDescricaoGenerico = {
        id: idPacote,
        descricao: descricaoPacote
      }

      setValue('pacote', pacote)
    }

    if (!!pacoteSelecionado) {
      let pct: ObjetoDescricaoGenerico = {
        id: pacoteSelecionado?.id,
        descricao: pacoteSelecionado?.descricao
      }

      setValue('pacote', pct)

      let pacote: IPacoteInteligenteForm = pacoteSelecionado
      pacote.honorarioEquipeMedica = pacote.honorarioEquipeMedica * 100
      if (pacote.responsavelAnestesia === 'M') {
        pacote.tiposAnestesia = pacote.tiposAnestesia.map(i => {
          i.valor = i.valor * 100
          return i
        })
      }

      if (!!pacote?.membros) {
        var membros = pacote?.membros.map(i => {
          i.valor = parseInt((i.valor * 100).toFixed(0))
          return i
        })

        membros = membros.filter(m =>
          listaMembrosEquipe.find(lm => lm.id === m.id)
        )

        pacote.membros = membros
      }

      if (pacote?.opmesInclusos?.length > 0) {
        pacote.opmes = [...pacote.opmesInclusos, ...pacote?.opmes]
        pacote.opmesInclusos = []
      }
      // pacote.honorarioEquipeMedica = pacote.honorarioEquipeMedica * 100

      setPacoteSelecionado(pacote)
      handleItemValue('honorarioEquipeMedica', pacote.honorarioEquipeMedica)
    }
  }

  async function carregarMembros() {
    let medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    var cpfOuMedicoId =
      perfilAtual === SynPerfis.MEDICO ? user.cpf : medicoSolicitacaoId

    //TODO AJustar para quando é o próprio médico
    var response = await buscarTodosMembrosEquipe(cpfOuMedicoId)

    var { data } = response
    setListaMembrosEquipe(data)
    setLoadingMembros(false)
  }
  //#endregion

  //#region Carregar Opções do Sistema
  const { loading, dados } = useListagem(1000, listagem)
  function listagem(
    querys: ListagemPacoteInteligenteQuery,
    primeiraBusca?: boolean
  ) {
    querys['perfilAtual'] = perfilAtual

    const doc = query.get('doc')
    if (!!doc) {
      return listagemMeusPacotesInteligentes(doc, querys)
    } else {
      return listagemMeusPacotesInteligentes(user.cpf, querys)
    }
  }

  useEffect(() => {
    if (!loadingMembros) verificaSeTemPacoteSelecionado()
  }, [loadingMembros])

  useEffect(() => {
    // const medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    // if (medicoSolicitacaoId) {
    // const filtrado = dados.filter(w => w.medi === medicoSolicitacaoId)
    // setListaPacotes(filtrado)
    // } else {
    setListaPacotes(dados.filter(i => !!i.configurado))
    // }
  }, [dados])

  const carregarExames = async () => {
    return obterExames()
      .then(response => setListaExames(response.data || []))
      .finally(() => {
        setLoadingExames(false)
      })
    // return setListaExames(mockExames)
  }

  const carregarHospitais = async () => {
    return obterLocaisAtendimentoPorUfMedico()
      .then(response => {
        setListaHospitais(response.data)
        let listaTodosEstadosHospitais = response.data?.map(item => item.grupo)
        let estadosHospitais = [...new Set(listaTodosEstadosHospitais)]
        if (estadosHospitais.length === 1) {
          setValue('uf', estadosHospitais[0])
        }
        setEstadosHospitais(
          estados.filter(e => estadosHospitais.includes(e.Initials)).sort()
        )
      })
      .finally(() => {
        setLoadingHospitais(false)
      })
  }

  const carregarComorbidades = async () => {
    return obterComorbidades()
      .then(response => {
        setListaComorbidades(response.data)
      })
      .finally(() => {
        setLoadingComorbidades(false)
      })
  }

  //#endregion

  async function obterValores() {
    setValoresOrcamentos(null)
    setLoadingValoresOrcamentos(true)

    const { paciente, pacote, request } = prepararRequest()
    return obterValoresOrcamentoInteligente(request)
      .then(response => {
        const valores = response.data

        setValoresOrcamentos(valores)
        setLoadingValoresOrcamentos(false)
      })
      .catch(e => showNotificationErrorAPI(e))
  }

  function prepararRequest() {
    let paciente: IPaciente = {
      id: command.pacienteId,
      comorbidades: command.comorbidades.map(c => c.id),
      cpf: onlyNumber(command.cpfPaciente),
      dataNascimento: moment(command.dataNascimentoPaciente).toJSON(),
      email: command.emailPaciente,
      nome: command.nomePaciente,
      telefone: onlyNumber(command.telefonePaciente),
      sexo: command.sexoPaciente,
      cpfResponsavel: pacienteResponsavel ? command.cpfResponsavel : null,
      nomeResponsavel: command.nomeResponsavel
    }

    let pacote = mapearPacoteParaRequest(
      JSON.parse(JSON.stringify(pacoteSelecionado)),
      pacoteSelecionado?.id
    )
    //pacote.descricao = command.pacote.descricao

    let request: SolicitarOcamentoInteligenteCommand = {
      id: command.id,
      paciente: paciente,
      pacoteInteligente: pacote,
      qtdReservaSangue: !!command.reservaSangue
        ? parseInt(command.reservaSangue)
        : 0,

      observacoes: command.observacoes,
      observacaoExames: command.observacaoExames,
      observacaoSangue: command.observacaoSangue,
      observacaoComorbidades: command.observacaoComorbidades,
      valorMinHonorarios: command.valorMinHonorarios,
      valorMaxHonorarios: command.valorMaxHonorarios,

      responsavelPacienteId: pacienteResponsavel
        ? command.responsavelPacienteId
        : null,
      medicoSolicitacaoId: command.medicoSolicitacaoId,
      perfilAtual: perfilAtual
      // podeFinalDeSemana: false,
      // podeCentroCirurgicoAmbulatorial: false
    }
    return { request, pacote, paciente }
  }

  const onSubmit = async (data: IOrcamentoForm) => {
    const { paciente, pacote, request } = prepararRequest()
    return salvarSolicitacaoOrcamentoInteligente(request)
      .then(response => {
        const id = response.data
        Swal.fire({
          title: 'Parabéns!',
          text: 'Sua solicitação de orçamento foi salva com sucesso.',
          icon: 'success',
          confirmButtonText: 'Continuar',
          showCancelButton: true,
          cancelButtonText: 'Imprimir guia'
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.cancel) {
            history.replace(SynRoutes.listagemOrcamentos + `?print=${id}`)
            return
          }
          if (
            command.solicitacaoSemLocalAtendimento === true &&
            pacote.opmes.length === 0
          ) {
            history.replace(`${SynRoutes.detalhesPropostasMedico}?id=${id}`)
          } else {
            history.replace(SynRoutes.listagemOrcamentos)
          }
        })
      })
      .catch(e => showNotificationErrorAPI(e))
  }

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  //#region stepper
  // const [activeStep, setActiveStep] = React.useState(0)

  // MIN = Minimum expected value
  // MAX = Maximium expected value
  // Function to normalise the values (MIN / MAX could be integrated)
  useEffect(() => {
    let ehAnestesiaLocal =
      pacoteSelecionado?.tiposAnestesia?.filter(
        w =>
          w.id === TiposAnestesias.local_realizado_pelo_medico ||
          w.id === TiposAnestesias.local_realizado_pelo_medico_dev
      ).length > 0 && pacoteSelecionado?.tiposAnestesia?.length === 1
    setVerificaAnestesia(ehAnestesiaLocal)
  }, [pacoteSelecionado])
  const lastStep = !!pacoteSelecionado?.possuiSangue ? 7 : 6
  const normalise = value => ((value - 0) * 100) / (lastStep - 0)

  const formularioCamposDoStep = !!pacoteSelecionado?.possuiSangue
    ? [
        ['pacote'],
        [
          'cpfPaciente',
          'nomePaciente',
          'telefonePaciente',
          'sexoPaciente',
          'dataNascimentoPaciente'
        ],
        ['comorbidades'],
        ['reservaSangue'],
        ['observacoes'],
        ['honorarioEquipeMedica', 'membros']
      ]
    : [
        ['pacote'],
        [
          'cpfPaciente',
          'nomePaciente',
          'telefonePaciente',
          'sexoPaciente',
          'dataNascimentoPaciente'
        ],
        ['comorbidades'],
        ['observacoes'],
        ['honorarioEquipeMedica', 'membros']
      ]

  const handleNext = async () => {
    if (activeStep === 1) {
      const idade = formatarIdade(stringToDate(command.dataNascimentoPaciente))

      if (idade < 18) {
        await setPacienteResponsavel(true)
        const idadeResult = await trigger(['cpfResponsavel', 'nomeResponsavel'])
        if (!idadeResult) return
      }
    }
    var campos = formularioCamposDoStep[activeStep]
    if (campos) {
      const result = await trigger(campos)
      if (!result) {
        return
      }
    }

    setActiveStep(activeStep + 1)
  }

  const debugSteps = false
  const handleBackToStep = stepNumber => {
    if (!stepNumber && stepNumber !== 0) {
      setActiveStep(activeStep - 1)
      return
    }

    if (debugSteps || stepNumber < activeStep) {
      setActiveStep(stepNumber)
    }
  }

  const getSteps = number => {
    const steps = []
    for (let index = 0; index < number; index++) {
      steps.push(
        <Step key={index}>
          <StepLabel
            StepIconComponent={v => StepIconSyn({ ...v, key: index + 1 })}
            onClick={() => handleBackToStep(index)}
          ></StepLabel>
          <StepContent></StepContent>
        </Step>
      )
    }
    return steps
  }

  function handleAjusteEtapa(etapa: Steps) {
    if (etapa === Steps.membrosEquipe) {
      setVerificouEquipe(true)
    }
    setAjustarPacote(true)
    setEtapaAjuste(etapa)
  }

  function handleFecharAjustarPacote() {
    setAjustarPacote(false)
  }

  function handleSalvarAjuste(dados) {
    setPacoteSelecionado(dados)
    setAjustarPacote(false)
    setErroTempoCirurgico(false)
  }

  const handleRadios = e => {
    handleItemValue('sexoPaciente', parseInt(e.target.value))
  }
  const handleRadiosEquipeAnestesia = e => {
    handleItemValue('equipeAnestesia', parseInt(e.target.value))
    if (e.target.value === '2') {
      setValue('valorEquipeAnestesia', 0)
      focarCampoValor(0)
    } else {
      focarCampoValor(1)
    }
  }
  //#endregion

  useEffect(() => {
    if (!command.cpfPaciente && !command.cpfResponsavel) return

    const cpfFormatado = onlyNumber(
      !!pacienteResponsavel ? command.cpfResponsavel : command.cpfPaciente
    )
    if (cpfFormatado.length === 11) {
      setLoadingPaciente(true)
      obterPacientePorCPF(cpfFormatado)
        .then(response => {
          const paciente = response.data

          if (!!paciente && !pacienteResponsavel) {
            setValue('comorbidades', paciente.comorbidades, {
              shouldValidate: true
            })
            setValue('nomePaciente', paciente.nome, { shouldValidate: true })
            if (!!paciente.dataNascimento) {
              let dataFormatada = moment(paciente.dataNascimento).format(
                'yyyy-MM-DD'
              )
              setValue('dataNascimentoPaciente', dataFormatada, {
                shouldValidate: true
              })
            }
            setValue('telefonePaciente', paciente.telefone, {
              shouldValidate: true
            })
            setValue('pacienteId', paciente.id)
            setValue('sexoPaciente', paciente.sexo)
            setValue('emailPaciente', paciente.email, { shouldValidate: true })
            setValue('responsavelPacienteId', null)
          } else if (!!paciente && pacienteResponsavel) {
            setValue('pacienteId', null)
            setValue('cpfPaciente', '')
            setValue('nomeResponsavel', paciente.nome)
            setValue('responsavelPacienteId', paciente.id)
          } else {
            limparFormPac(pacienteResponsavel)
          }
          setShowFormPaciente(true)
        })
        .catch(e => {
          showNotificationErrorAPI(e)
          limparFormPac(pacienteResponsavel)
          setShowFormPaciente(false)
        })
        .finally(() => {
          setTimeout(() => setLoadingPaciente(false), 100)
        })
    }
  }, [command.cpfPaciente, command.cpfResponsavel])

  function limparFormPac(ehResponsavel: boolean) {
    if (ehResponsavel) {
      setValue('nomeResponsavel', '')
      setValue('responsavelPacienteId', null)
      return
    }
    setValue('pacienteId', null)
    setValue('nomePaciente', null)
    setValue('dataNascimentoPaciente', '')
    setValue('telefonePaciente', '')
    setValue('emailPaciente', null)
    setValue('sexoPaciente', 0)
  }

  function toggleComorbidades() {
    setValue('comorbidades', [])
    setSemComorbidade(!semComorbidade)
    setComorbidadeMensagemErro(false)
  }

  function stringToDate(str: string) {
    if (!str) {
      return
    }
    const isoString = str
      .replace('/', '-')
      .replace('/', '-')
      .split('-')
      .reverse()
      .join('-')

    return new Date(isoString)
  }

  async function addCombosSolicitacao(combos: IComboResponse[]) {
    setValue('combos', combos)
    var exames = []

    combos.forEach(combo => {
      combo.exames.forEach(e => {
        if (!exames.find(exame => exame.codigo === e.codigo)) {
          exames.push(e)
        }
      })
    })

    setValue('exames', exames)
  }

  function focarCampoValor(foco) {
    setFocoValor(foco)
  }

  function handleNovoPacote() {
    history.push(SynRoutes.listagemPacotesSugeridos)
  }

  function verificarSeExiste(item: boolean, texto: string) {
    if (!item) return ''
    return texto
  }

  function obterItensContemplados() {
    var itens = []

    let dados: IPacoteInteligenteForm = pacoteSelecionado

    itens.push('valor do hospital')
    itens.push(
      verificarSeExiste(
        !!dados?.tiposAnestesia.find(i => !!i.selecionado),
        'anestesia'
      )
    )

    itens.push(
      verificarSeExiste(
        !!dados?.examesDuranteInternacao.find(i => !!i.selecionado),
        'exames '
      )
    )
    itens.push(
      verificarSeExiste(dados?.opmes?.length !== 0, "OPME's, taxa de OPME")
    )
    itens.push('taxa de serviço')
    itens.push(
      verificarSeExiste(
        !!dados?.reservaSangue,
        'reserva de sangue, bolsa(s) de sangue'
      )
    )

    itens = itens.filter(i => !!i)

    if (itens.length > 0) {
      var texto = itens.join(', ')
      return primeiraLetraMaiusculo(`${texto} e honorários equipe médica.`)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (activeStep === 5) {
      obterValores()
    }
  }, [activeStep])

  function obterValorEquipeMedica(somarHonorarios = false) {
    var honorario = pacoteSelecionado?.honorarioEquipeMedica

    var equipe = pacoteSelecionado?.membros.reduce(
      (partialSum, i) => partialSum + i.valor,
      0
    )
    if (somarHonorarios) {
      return honorario + equipe
    }
    return equipe
  }

  function formatarValor(valor: number, forcaAdaptar = false) {
    let valorFormatado = valor / (forcaAdaptar ? 100 : 1)

    return formatarDinheiro(valorFormatado)
  }

  function retornaPrimeiraEtapa(callback) {
    if (activeStep === 0) return 0

    return callback
  }

  return (
    <>
      {loadingOrcamento && (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      )}

      <Box
        hidden={loadingOrcamento}
        component="main"
        className={`fadeInTransition ${classes.container}`}
        // style={{ wordBreak: 'break-all' }}
      >
        <CssBaseline />
        {/* command: {JSON.stringify(listaMembrosEquipe)} */}
        {/* pacoteSelecionado: {JSON.stringify(pacoteSelecionado)} */}
        {/* <br /> */}
        {/* erros: {JSON.stringify(errors)} */}
        {/* <br /> */}
        {/* comorbidades: {JSON.stringify(listaComorbidades)} */}
        {ehXS && activeStep !== 0 && (
          <LinearProgress
            variant="determinate"
            value={normalise(activeStep + 1)}
          />
        )}
        <Grid container>
          {!ehXS && activeStep !== 0 && (
            <Grid item xs={12} sm={1}>
              <Box mt={5} pt={2} ml={4}>
                <Stepper
                  connector={<StepConnectorSyn />}
                  activeStep={activeStep}
                  style={{ margin: 0, padding: 0, background: 'none' }}
                  orientation="vertical"
                >
                  {getSteps(lastStep)}
                </Stepper>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} sm={activeStep === 0 ? 12 : 10}>
            <Box
              mt={retornaPrimeiraEtapa(ehXS ? 2 : 5)}
              pt={retornaPrimeiraEtapa(ehXS ? 0 : 2)}
              p={retornaPrimeiraEtapa(ehXS ? 2 : 0)}
            >
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.form}
                noValidate={true}
              >
                <StepContentSyn
                  noMarginTop
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 0}
                  hideBack
                  handleBackToStep={handleBackToStep}
                  handleNext={() => {
                    if (!pacoteSelecionado?.mediaTempoCirurgico) {
                      setErroTempoCirurgico(true)
                      return Swal.fire({
                        title: 'Atenção!',
                        icon: 'warning',
                        text:
                          'Média de tempo cirúrgico é um campo obrigatório. Favor ajustar'
                      })
                    }

                    // if (listaMembrosEquipe?.length > 0 && !verificouEquipe) {
                    //   handleAjusteEtapa(Steps.membrosEquipe)
                    //   return
                    // }

                    handleNext()
                  }}
                  textoTitulo=""
                >
                  {/* <Autocomplete
                    erro={errors}
                    name="pacote"
                    label="Pacote instantâneo"
                    loading={loading}
                    closeOnSelect
                    value={command.pacote}
                    options={listaPacotes}
                    renderOption={(option: any) => {
                      return (
                        <UBox width={'100%'}>
                          <Typography variant="body1">
                            {option.descricao?.toUpperCase()}
                            <br />
                            <i>
                              <small>
                                <b>{option.hospital}</b>
                              </small>
                            </i>
                          </Typography>
                          <Divider />
                        </UBox>
                      )
                    }}
                    getOptionLabel={option => `${option.descricao}`}
                    onChange={(event, newValue) => {
                      handleItemValue(
                        'pacote',
                        newValue as ObjetoDescricaoGenerico
                      )
                    }}
                    showAddButton={perfilAtual !== SynPerfis.SECRETARIA}
                    onClickAddButton={handleNovoPacote}
                    addButtonProps={{
                      text: 'Não encontrou? clique aqui'
                    }}
                  /> */}
                  {loadingPacote || loadingMembros ? (
                    <LoadingGenerico />
                  ) : (
                    !!pacoteSelecionado &&
                    !loadingMembros && (
                      <ResumoPacoteInteligenteOps
                        // <RevisaoPacoteInteligente
                        unificarOpmes
                        selecionado
                        medico
                        listaMembros={listaMembrosEquipe}
                        pacote={pacoteSelecionado}
                        esconderIncluso={true}
                        adaptarValor={false}
                        forcarHonorarios
                        exibirAjusteEtapa
                        handleAjusteEtapa={handleAjusteEtapa}
                        exibirDadosMedicos
                        erro={erroTempoCirurgico}
                      />
                    )
                  )}
                </StepContentSyn>

                {/* <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 1}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={''}
                >
                  {!!pacoteSelecionado && (
                    <FormConfiguracao
                      pacote={pacoteSelecionado}
                      etapaAjuste={'membrosEquipe'}
                      handleSalvarAjuste={handleSalvarAjuste}
                    />
                  )}
                </StepContentSyn> */}

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 1}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={'Quem é o seu paciente?'}
                >
                  <Grid container spacing={1}>
                    <Alert severity="info">
                      Caso seja menor de idade ou não possuir CPF, marque a
                      opção abaixo "Paciente necessita de responsável"
                    </Alert>
                    <Box>
                      <Grid
                        container
                        spacing={1}
                        style={{ width: '100%' }}
                        className={classes.noMargin}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Checkbox
                              checked={pacienteResponsavel}
                              onClick={() =>
                                setPacienteResponsavel(!pacienteResponsavel)
                              }
                            />
                            Paciente necessita de responsável
                          </Typography>
                        </Grid>
                        {pacienteResponsavel && (
                          <>
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                              <Typography variant="subtitle2">
                                Responsável
                              </Typography>
                              <Divider style={{ margin: '10px 0' }} />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                erro={errors}
                                loading={loadingPaciente}
                                name="cpfResponsavel"
                                placeholder="Ex: 999.999.999-99"
                                label="CPF do responsável"
                                type="tel"
                                hiddenLabel
                                control={control}
                                mask="999.999.999-99"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                InputProps={{
                                  autoComplete: 'new-password'
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                loading={loadingPaciente}
                                // disabled={
                                //   loadingPaciente ||
                                //   !!command.responsavelPacienteId
                                // }
                                erro={errors}
                                name="nomeResponsavel"
                                label="Nome completo do responsável"
                                inputRef={register}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                inputProps={{ maxLength: 100 }}
                              />
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                              <Typography variant="subtitle2">
                                Paciente
                              </Typography>
                              <Divider style={{ margin: '10px 0' }} />
                            </Grid>
                          </>
                        )}
                        {!pacienteResponsavel && (
                          <Grid item xs={12}>
                            <TextField
                              loading={loadingPaciente}
                              erro={errors}
                              name="cpfPaciente"
                              placeholder="Ex: 999.999.999-99"
                              label="CPF"
                              type="tel"
                              hiddenLabel
                              control={control}
                              mask="999.999.999-99"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                          <TextField
                            erro={errors}
                            name="nomePaciente"
                            label="Nome completo"
                            inputRef={register}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{ maxLength: 100 }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {/* <button
                          onClick={value =>
                            handleItemValue(
                              'dataNascimentoPaciente',
                              '10/10/2000'
                            )
                          }
                          type="button"
                        >
                          mudar valor
                        </button>
                        data:{command.dataNascimentoPaciente} */}

                          <DateInput
                            errors={errors}
                            name="dataNascimentoPaciente"
                            label="Data de nascimento"
                            value={command.dataNascimentoPaciente}
                            control={control}
                            handleChange={value =>
                              handleItemValue('dataNascimentoPaciente', value)
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            erro={errors}
                            name="telefonePaciente"
                            maskTelCel
                            value={command.telefonePaciente}
                            label="Telefone"
                            type="tel"
                            control={control}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            erro={errors}
                            name="emailPaciente"
                            label="E-mail"
                            inputRef={register}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{ maxLength: 100 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <RadioGroup
                            onChange={handleRadios}
                            value={command.sexoPaciente}
                          >
                            <Grid container direction="row">
                              <FormControlLabel
                                control={
                                  <Radio
                                    value={genero.Masculino}
                                    color="primary"
                                    name="pacienteSexo"
                                  />
                                }
                                label="Masculino"
                              />
                              <FormControlLabel
                                control={
                                  <Radio
                                    value={genero.Feminino}
                                    color="primary"
                                    name="pacienteSexo"
                                  />
                                }
                                label="Feminino"
                              />
                            </Grid>
                          </RadioGroup>
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            <FormHelperText error={!!errors.sexoPaciente}>
                              {errors.sexoPaciente?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 2}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={() => {
                    if (!semComorbidade && command.comorbidades.length === 0) {
                      return setComorbidadeMensagemErro(true)
                    }
                    handleNext()
                  }}
                  textoTitulo={
                    'Seu paciente possui comorbidades? Caso não tenha clique em "CONTINUAR"'
                  }
                >
                  <>
                    <Typography style={{ margin: '20px 0 0' }}>
                      <Checkbox
                        checked={semComorbidade}
                        onChange={() => toggleComorbidades()}
                      />{' '}
                      Não possui comorbidade
                    </Typography>
                    {comorbidadeMensagemErro && (
                      <Typography variant="body2" style={{ color: 'red' }}>
                        Marcar caso o paciente não tenha comorbidade
                      </Typography>
                    )}
                    {!semComorbidade && (
                      <Autocomplete
                        multiple
                        erro={errors}
                        name="comorbidades"
                        label="Comorbidades"
                        loading={loadingComorbidades}
                        options={listaComorbidades}
                        value={command.comorbidades}
                        getOptionSelected={option =>
                          !!command.comorbidades.find(l => l.id === option.id)
                        }
                        showCheck
                        showAddButton
                        getOptionLabel={option => option.descricao}
                        onChange={(
                          event,
                          newValue: ObjetoDescricaoGenerico[]
                        ) => {
                          setSemComorbidade(false)
                          setComorbidadeMensagemErro(false)
                          handleItemValue(
                            'comorbidades',
                            newValue as ObjetoDescricaoGenerico[]
                          )
                        }}
                      />
                    )}
                    <Typography variant="body2" style={{ marginTop: '20px' }}>
                      Caso não encontre o item na lista acima, favor descrevê-lo
                      no campo abaixo
                    </Typography>
                    <TextField
                      erro={errors}
                      multiline={true}
                      rows={4}
                      inputRef={register}
                      name="observacaoComorbidades"
                      label="Observações"
                      inputProps={{ maxLength: 500 }}
                    />
                  </>
                </StepContentSyn>

                {/* <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 3}
                hideBack={false}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={
                  'Quais exames seu paciente deve realizar antes do procedimento?'
                }
              >
                <Box>
                  <Alert severity="info" style={{ margin: '24px 0 12px' }}>
                    Crie seus combos de exames para agilizar sua solicitação.
                  </Alert>
                  <Button
                    onClick={() => setShowModalCombo(true)}
                    fullWidth
                    variant="outlined"
                  >
                    Meus combos
                  </Button>
                </Box>

                {showModalCombo && (
                  <ModalComboExames
                    open={showModalCombo}
                    handleCloseModal={() => setShowModalCombo(false)}
                    listaExames={listaExames}
                    addCombosSolicitacao={addCombosSolicitacao}
                    combosSolicitacao={command.combos}
                  />
                )}

                {command.combos.length > 0 && (
                  <div style={{ marginTop: '1em' }}>
                    <Typography variant="subtitle1">
                      Combos selecionados:
                    </Typography>
                  </div>
                )}

                {command.combos?.map(combo => {
                  return (
                    <>
                      <Typography variant="subtitle2" key={combo.id}>
                        {combo.descricao}
                      </Typography>
                    </>
                  )
                })}

                <Divider style={{ margin: '20px 0' }} />

                <Box mt={2}>
                  <Typography variant="h6">Avaliações:</Typography>

                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        <Checkbox
                          checked={command.avaliacaoPreAnestesica}
                          onChange={() =>
                            handleItemValue(
                              'avaliacaoPreAnestesica',
                              !command.avaliacaoPreAnestesica
                            )
                          }
                          color="primary"
                        />
                        Pré-anestésica
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        <Checkbox
                          checked={command.riscoCirurgico}
                          onChange={() =>
                            handleItemValue(
                              'riscoCirurgico',
                              !command.riscoCirurgico
                            )
                          }
                          color="primary"
                        />
                        Risco cirúrgico
                      </Typography>
                    </Grid>
                  </Grid>

                  <Autocomplete
                    multiple
                    erro={errors}
                    name="exames"
                    label="Exames pré-operatórios"
                    loading={loadingExames}
                    options={listaExames.map(item => ({
                      descricao: item.descricao,
                      id: item.id,
                      codigo: item.codigo
                    }))}
                    value={command.exames}
                    getOptionSelected={option =>
                      !!command.exames.find(l => l.id === option.id)
                    }
                    showCheck
                    showAddButton
                    getOptionLabel={(option: IExame) =>
                      `${option.descricao} - ${option.codigo}`
                    }
                    onChange={(event, newValue: IExame[]) => {
                      handleItemValue('exames', newValue as IExame[])
                    }}
                    renderOption={(option: IExame) => {
                      return (
                        <>
                          <Typography
                            variant="subtitle1"
                            style={{ marginRight: '5px' }}
                          >
                            {option.descricao} - {'  '}
                          </Typography>
                          <Typography variant="subtitle2">
                            {option.codigo}
                          </Typography>
                        </>
                      )
                    }}
                  />

                  <TextField
                    erro={errors}
                    multiline={true}
                    rows={4}
                    inputRef={register}
                    name="observacaoExames"
                    label="Observações"
                    inputProps={{ maxLength: 500 }}
                  />
                </Box>
              </StepContentSyn> */}

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={!!pacoteSelecionado?.possuiSangue && activeStep === 3}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Será necessário reserva de sangue? Caso não necessite clique em "Continuar"'
                  }
                >
                  <TextField
                    erro={errors}
                    name="reservaSangue"
                    placeholder="Ex: 2 concentrados de hemácias"
                    label="Qtd. concentrados de hemácias"
                    control={control}
                    type="tel"
                    mask="999"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <Alert severity="info" style={{ marginTop: '20px' }}>
                    Caso necessitar de plasma, concentrados de plaquetas ou
                    outros, favor informar no campo abaixo de observações
                  </Alert>
                  <TextField
                    erro={errors}
                    multiline={true}
                    rows={4}
                    name="observacaoSangue"
                    label="Observações"
                    inputRef={register}
                    inputProps={{ maxLength: 500 }}
                  />
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={
                    activeStep === (!!pacoteSelecionado?.possuiSangue ? 4 : 3)
                  }
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Caso tenha alguma consideração a fazer, sinta-se à vontade para informar abaixo'
                  }
                >
                  <TextField
                    erro={errors}
                    multiline={true}
                    rows={4}
                    inputRef={register}
                    name="observacoes"
                    label="Observações"
                    inputProps={{ maxLength: 500 }}
                  />
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={
                    activeStep === (!!pacoteSelecionado?.possuiSangue ? 5 : 4)
                  }
                  // show={true}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Por favor confirme os honorários do médico principal e de sua equipe'
                  }
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <UBox
                        my={2}
                        style={{
                          border: '1px solid #ccc',
                          borderRadius: '8px',
                          padding: '20px',
                          background: '#fff',
                          fontWeight: 400,
                          maxWidth: '500px',
                          margin: '0 auto',
                          textAlign: 'left'
                        }}
                      >
                        <UBox mb={2}>
                          <Typography
                            variant="body1"
                            color="secondary"
                            align="center"
                          >
                            <b>Honorário médico + Equipe médica</b>
                          </Typography>
                        </UBox>

                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              Honorário médico
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Typography variant="body1">
                              {formatarValor(
                                pacoteSelecionado?.honorarioEquipeMedica,
                                true
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant="body1">
                              Equipe médica
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Typography variant="body1">
                              {formatarValor(obterValorEquipeMedica(), true)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <UBox
                          my={1}
                          pt={1}
                          style={{ borderTop: '2px dashed #ddd' }}
                        >
                          <Typography variant="body1" align="right">
                            <b>
                              {formatarValor(
                                obterValorEquipeMedica(true),
                                true
                              )}
                            </b>
                          </Typography>
                        </UBox>
                      </UBox>
                    </Grid>
                  </Grid>

                  <UBox mt={2}>
                    <UBox display="flex" justifyContent="space-between">
                      <Typography variant="body1" color="primary">
                        <b>Equipe médica</b>
                      </Typography>

                      <Button
                        onClick={() => handleAjusteEtapa(Steps.membrosEquipe)}
                        size="small"
                        variant="outlined"
                      >
                        <MdEdit style={{ marginRight: 4 }} />
                        Ajustar equipe
                      </Button>
                    </UBox>

                    {pacoteSelecionado?.membros?.map((membro, i) => (
                      <Grid key={i} container spacing={0}>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                        >
                          <Typography variant="body1">
                            {membro?.descricao?.toUpperCase()} (
                            {formatarValor(membro?.valor, true)})
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <UBox my={1}>
                            <Divider />
                          </UBox>
                        </Grid>
                      </Grid>
                    ))}
                  </UBox>
                  <UBox mt={3}>
                    <UBox>
                      <Typography variant="body1" color="primary">
                        <b>Honorário do médico principal</b>
                      </Typography>
                    </UBox>
                    <UBox pt={2}>
                      <CurrencyInput
                        fullWidth
                        // hidePrefix
                        // noMoney
                        erro={errors}
                        // autoFocus
                        size="small"
                        variant="outlined"
                        // margin="normal"
                        name="honorario"
                        label="Honorário médico"
                        hiddenLabel
                        control={control}
                        type="tel"
                        InputLabelProps={{
                          shrink: true
                        }}
                        // InputProps={{
                        //   endAdornment: <>%</>
                        // }}
                        // max={999}
                        defaultValue={command?.honorarioEquipeMedica}
                        onValueChange={value => {
                          handleItemValue('honorarioEquipeMedica', value)
                          var novoHonorario = value || 0
                          pacoteSelecionado.honorarioEquipeMedica = novoHonorario

                          setPacoteSelecionado(pacoteSelecionado)
                        }}
                        value={command?.honorarioEquipeMedica || 0}
                      />
                    </UBox>
                  </UBox>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={
                    activeStep === (!!pacoteSelecionado?.possuiSangue ? 6 : 5)
                  }
                  // show={true}
                  hideBack={false}
                  hideNext={true}
                  handleBackToStep={handleBackToStep}
                  textoTitulo={
                    'Ótimo! Agora revise as informações com ATENÇÃO antes de salvar'
                  }
                  showFinish={!loadingValoresOrcamentos}
                  handleFinish={handleSubmit(onSubmit)}
                  textFinish="Enviar para o paciente"
                  loading={formState.isSubmitting}
                >
                  <Box mb={ehXS ? 3 : 0}>
                    <Typography variant="subtitle1">
                      Caso precise alterar alguma informação, clique no botão
                      'Voltar' até chegar na etapa desejada.
                    </Typography>
                    <ResumoOrcamento
                      pacoteSelecionado={pacoteSelecionado as any}
                      pacoteInteligente
                      esconderIncluso={true}
                      forcarHonorarios
                      orcamento={command}
                      isMedico
                    />
                  </Box>
                  <UBox
                    textLoading="Gerando orçamentos..."
                    loading={loadingValoresOrcamentos}
                    mt={2}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <b>
                        Resumo
                        {!!valoresOrcamentos?.valorPropostaEnfermaria &&
                        !!valoresOrcamentos?.valorPropostaApartamento
                          ? ' das propostas:'
                          : ' da proposta:'}
                      </b>
                    </Typography>
                    <Grid container spacing={1}>
                      <UBox
                        render={!!valoresOrcamentos?.valorProposta}
                        component={Grid}
                        item
                        xs={12}
                        sm={6}
                      >
                        <UBox mt={2} mb={2} component={Card}>
                          <Box p={2}>
                            <Grid container direction="row" alignItems="center">
                              <Grid
                                item
                                xs={12}
                                style={{
                                  width: '100%',
                                  overflowX: 'hidden'
                                }}
                              >
                                <UBox
                                  // render={.enfermaria || p.apartamento}
                                  maxWidth="90%"
                                  mb={2}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      whiteSpace: 'nowrap',
                                      textTransform: 'uppercase'
                                    }}
                                    color="primary"
                                  >
                                    <b>
                                      {!!pacoteSelecionado?.pqa &&
                                        INTERNACOES_OBJ.PQA.descricao}
                                      {!!pacoteSelecionado?.dayClinic &&
                                        INTERNACOES_OBJ.DAY_CLINIC.descricao}
                                    </b>
                                  </Typography>
                                </UBox>

                                <Typography
                                  variant="subtitle2"
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  <b>
                                    <small>Valor total:</small>
                                  </b>{' '}
                                  {formatarDinheiro(
                                    valoresOrcamentos?.valorProposta
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </UBox>
                      </UBox>
                      <UBox
                        render={!!valoresOrcamentos?.valorPropostaEnfermaria}
                        component={Grid}
                        item
                        xs={12}
                        sm={6}
                      >
                        <UBox mt={2} mb={2} component={Card}>
                          <Box p={2}>
                            <Grid container direction="row" alignItems="center">
                              <Grid
                                item
                                xs={12}
                                style={{
                                  width: '100%',
                                  overflowX: 'hidden'
                                }}
                              >
                                <UBox
                                  // render={.enfermaria || p.apartamento}
                                  maxWidth="90%"
                                  mb={2}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      whiteSpace: 'nowrap',
                                      textTransform: 'uppercase'
                                    }}
                                    color="primary"
                                  >
                                    <b>
                                      {'Internação de ENFERMARIA'}
                                      {/* {'Internação de APARTAMENTO'} */}
                                    </b>
                                  </Typography>
                                </UBox>

                                <Typography
                                  variant="subtitle2"
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  <b>
                                    <small>Valor total:</small>
                                  </b>{' '}
                                  {formatarDinheiro(
                                    valoresOrcamentos?.valorPropostaEnfermaria
                                  )}
                                </Typography>

                                <Button
                                  onClick={() =>
                                    handleBackToStep(activeStep - 1)
                                  }
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                >
                                  <MdEdit style={{ marginRight: 4 }} />
                                  Alterar honorários
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </UBox>
                      </UBox>
                      <UBox
                        render={!!valoresOrcamentos?.valorPropostaApartamento}
                        component={Grid}
                        item
                        xs={12}
                        sm={6}
                      >
                        <UBox
                          mt={2}
                          mb={2}
                          component={Card}
                          render={valoresOrcamentos?.valorPropostaApartamento}
                        >
                          <Box p={2}>
                            <Grid container direction="row" alignItems="center">
                              <Grid
                                item
                                xs={12}
                                style={{
                                  width: '100%',
                                  overflowX: 'hidden'
                                }}
                              >
                                <UBox
                                  // render={.enfermaria || p.apartamento}
                                  maxWidth="90%"
                                  mb={2}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      whiteSpace: 'nowrap',
                                      textTransform: 'uppercase'
                                    }}
                                    color="primary"
                                  >
                                    <b>
                                      {/* {o.enfermaria && 'Internação de ENFERMARIA'} */}
                                      {'Internação de APARTAMENTO'}
                                    </b>
                                  </Typography>
                                </UBox>

                                <Typography
                                  variant="subtitle2"
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  <b>
                                    <small>Valor total:</small>
                                  </b>{' '}
                                  {formatarDinheiro(
                                    valoresOrcamentos?.valorPropostaApartamento
                                  )}
                                </Typography>

                                <Button
                                  onClick={() =>
                                    handleBackToStep(activeStep - 1)
                                  }
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                >
                                  <MdEdit style={{ marginRight: 4 }} />
                                  Alterar honorários
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </UBox>
                      </UBox>
                      <UBox
                        render={!!valoresOrcamentos}
                        component={Grid}
                        item
                        xs={12}
                        sm={12}
                      >
                        <Alert severity="info">
                          Os valores das propostas apresentadas contemplam:
                          {obterItensContemplados()}
                          {/* todos os custos, honorários da equipe médica, valor do
                          hospital e etc... */}
                        </Alert>

                        {!!valoresOrcamentos?.valorPropostaApartamento &&
                          !!valoresOrcamentos?.valorPropostaEnfermaria && (
                            <UBox mt={2}>
                              <Alert severity="info">
                                Existem duas opções de internação para esse
                                procedimento, as propostas serão enviadas para o
                                paciente escolher a que mais lhe agrada.
                              </Alert>
                            </UBox>
                          )}
                      </UBox>
                    </Grid>
                  </UBox>
                </StepContentSyn>
              </form>
            </Box>
          </Grid>
        </Grid>
        {!!showModal && <ModalAddGenerico open={true} {...dadosModal()} />}
      </Box>
      <DrawerAjustarPacoteInteligente
        open={ajustarPacote}
        etapa={etapaAjuste}
        pacote={pacoteSelecionado}
        handleOnClose={handleFecharAjustarPacote}
        handleSalvarAjuste={handleSalvarAjuste}
      />
    </>
  )
}

export function LoadingGenerico(props) {
  return (
    <Grid
      direction="column"
      item
      xs={12}
      style={{
        padding: 48,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <CircularProgress />
      <Typography variant="subtitle1" component="p">
        Carregando...
      </Typography>
    </Grid>
  )
}
