import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Search } from '@material-ui/icons'
import ClearIcon from '@material-ui/icons/Clear'
import { useContext, useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import {
  alterarStatusProcedimentoRealizado,
  listagemOrcamentoHospital,
  obterStatusSolicitacaoOrcamento
} from 'src/api/solicitacao-orcamento/index'
import ChipStatusOrcamento from 'src/components/solicitacao/ChipStatusOrcamento'
import ButtonUI, { Button } from 'src/components/ui/Button'
import { UltimaSolicitacaoContext } from 'src/contexts/UltimaSolicitacao'
import { SynRoutes } from 'src/core/consts/synRoutes'
import useListagem from 'src/hooks/useListagem'
import { useQueryParams } from 'src/hooks/useQuery'
import useWindowInfiniteScroll from 'src/hooks/useWindowInfiniteScroll'

import { FiFilter } from 'react-icons/fi'
import { GoSearch } from 'react-icons/go'
import DrawerVisualizarOcorrencias from 'src/components/drawers/medico/DrawerVisualizarOcorrencias'
import {
  StatusFiltrosAgendamentoArray,
  statusOrcamento
} from 'src/core/consts/statusSolicitacao'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { formatarData } from 'src/core/formatacoes/formatarData'
import useAuth from 'src/hooks/useAuth'
import {
  IAgedamentoResponse,
  ICancelamentoAgedamentoResponse,
  ItemListagemSolicitacaoOrcamentoResponse
} from 'src/types'
import { ResumoImpressaoOrcamento } from '../../../medico/solicitacao/resumoImpressao'
import CardHospitalListagem from '../components/cardListagem'
import DrawerVisualizarDetalhes from '../components/drawerVisualizarDetalhes'
import DrawerAgendamento from './drawerAgendamento/drawerAgendamento'
import DrawerCancelamento from './drawerCancelamento/drawerCancelamento'

const useStyles = makeStyles(theme => ({
  layoutFiltro: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px',
    margin: '4px 8px',
    padding: '12px 24px'
  }
}))

const data = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}))

function ListagemOrcamento() {
  const { user, perfilAtual } = useAuth()
  //#region  USE STATE
  const history = useHistory()
  const query = useQueryParams()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [detalheVisualizacao, setDetalheVisualizacao] = useState(null)
  const [loadingDetalhe, setLoadingDetalhe] = useState(false)
  const [itemPrint, setItemPrint] = useState(null)
  const [lastSelectedCard, setLastSelectedCard] = useState(null)

  const { setUltimaSolicitacao, ultimaSolicitacaoVista } = useContext(
    UltimaSolicitacaoContext
  )
  const [visualizarOcorrencias, setVisualizarOcorrencias] = useState({
    show: false,
    id: ''
  })
  const [openDrawerAgendamento, setOpenDrawerAgendamento] = useState(false)
  const [openDrawerCancelamento, setOpenDrawerCancelamento] = useState(false)
  const [dataInicio, setDataInicio] = useState('')
  const [dataFim, setDataFim] = useState(null)
  const [showFiltros, setShowFiltros] = useState(false)
  const [dataInicioDirty, setDataInicioDirty] = useState(false)
  const [dataFimDirty, setDataFimDirty] = useState(false)
  const [diasFiltro, setDiasFiltro] = useState('periodo')
  const [filtroStatusAgendamento, setFiltroStatusAgendamento] = useState(' ')
  const [statusSelecionado, setStatusSelecionado] = useState('')
  const [mostrarCotacao, setMostrarCotacao] = useState(true)
  const [mostrarInteligente, setMostrarInteligente] = useState(true)
  const ehPendenteRevisaoValores = false
  //#endregion
  //#region LISTAGEM
  const {
    loading,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    carregarListagem,
    allDados,
    allDadosCarregados,
    qtdAllDados,
    handleProxPagina,
    handleFiltroData,
    atualizarItemListagem,
    listaStatus,
    loadingFiltroStatus,
    filtroStatus,
    handleFiltroStatus,
    handleLimparFiltros,
    handlePesquisarDados,
    handleSetTermoPesquisar,
    loadingPagina
  } = useListagem(9, listagem, obterStatusSolicitacaoOrcamento)

  function listagem(query, primeiraBusca?: boolean) {
    if (!primeiraBusca) setUltimaSolicitacao()
    query['perfilAtual'] = perfilAtual
    query['filtroAgendamento'] = filtroStatusAgendamento
    query['mostrarCotacao'] = mostrarCotacao
    query['mostrarInteligente'] = mostrarInteligente
    return listagemOrcamentoHospital(query, ehPendenteRevisaoValores)
  }

  useEffect(() => {
    carregarListagem({ resetarPaginacao: true })
  }, [mostrarCotacao, mostrarInteligente])

  const [
    solicitacaoItem,
    setSolicitacaoItem
  ] = useState<ItemListagemSolicitacaoOrcamentoResponse>(null)

  useWindowInfiniteScroll({
    data: allDados,
    loadMoreFunc: handleProxPagina,
    stopLoadingData: allDados.length >= qtdAllDados
  })
  //#endregion
  function handleNovoOrcamento() {
    history.push(SynRoutes.medicoAssistente)
  }

  // const handleSelecionarFiltroStatus = (e) => {
  //   setFiltroStatusAgendamento(e.target.value)
  // }

  // useEffect(() => {
  //   carregarListagem({
  //     resetarPaginacao: true
  //   })
  // }, [filtroStatusAgendamento])

  useEffect(() => {
    if (query.has('print')) {
      let print = query.get('print')
      setItemPrint(print)
      history.replace(SynRoutes.listagemOrcamentosHospital)
    }
  }, [])

  const handleFecharDetalhes = () => {
    setDetalheVisualizacao(null)
    setMostrarDetalhe(false)
  }

  // function handleEditar(item: ItemListagemSolicitacaoOrcamentoResponse) {
  //   history.push(
  //     `${SynRoutes.formOrcamentoUnificado}?id=${item.id}&idPacote=${item.id}&descricaoPacote=${item.descricaoPacoteProcedimento}&tipo=cotacao`
  //   )
  // }

  function handleImprimir(item: ItemListagemSolicitacaoOrcamentoResponse) {
    setItemPrint(item.id)
  }

  function handleFecharImprimir() {
    setItemPrint(null)
  }

  const handleVisualizarDetalhes = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setDetalheVisualizacao(item)
    setLoadingDetalhe(true)
    setMostrarDetalhe(true)
  }

  async function handleAlterarStatusProcedimentoRealizado(
    idSolicitacao: string
  ) {
    await alterarStatusProcedimentoRealizado(idSolicitacao, perfilAtual)

    carregarListagem({
      resetarPaginacao: true,
      dataInicio: dataInicio,
      dataFim: dataFim
    })
  }

  // const tratarErroApi = e => {
  //   setMostrarDetalhe(false)
  //   showNotificationErrorAPI(e)
  // }

  function setClassToHighlight(item) {
    if (item?.id === lastSelectedCard?.id) return 'highlight'

    return null
  }
  function renderStatusSolicitacao(
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    return (
      <ChipStatusOrcamento
        statusId={item?.status?.id}
        perfil="parceiro"
        orcamentoAprovado={item?.orcamentoAprovado}
        ehAgendamento={perfilAtual === SynPerfis.AGENDAMENTO}
        preAgendamento={!!item?.agendamento?.dataPreAgendamento}
      />
    )
  }

  function handleVisualizarOcorrencias(solicitacaoId: string) {
    setVisualizarOcorrencias({
      show: true,
      id: solicitacaoId
    })
  }

  function handleAgendar(item: ItemListagemSolicitacaoOrcamentoResponse) {
    setSolicitacaoItem(item)
    setOpenDrawerAgendamento(true)
  }

  function handleCancelarAgendamento(
    itemCancelarAgendamento: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    setSolicitacaoItem(itemCancelarAgendamento)
    setOpenDrawerCancelamento(true)
  }

  function handleFecharAgendamento(
    agendamento: IAgedamentoResponse = null,
    solicitacao?: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    if (solicitacao == null) solicitacao = solicitacaoItem

    if (!!agendamento) {
      var novoItemSolicitacao = solicitacao
      var preAgendamento = [
        statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id,
        statusOrcamento.PAGAMENTO_PARCIAL.Id
      ].includes(solicitacao.status.id)

      //Solicitacao não tinha agendamento ou tinha um pré agendamento e não é pré agendamento
      if (
        (!solicitacao?.agendamento ||
          !!solicitacao?.agendamento?.dataPreAgendamento) &&
        !preAgendamento
      ) {
        novoItemSolicitacao.status = {
          id: statusOrcamento.AGENDADO.Id,
          descricao: statusOrcamento.AGENDADO.Parceiro.Descricao
        }
      }
      if (preAgendamento) {
        agendamento.dataPreAgendamento = agendamento.dataAgendamento
        agendamento.dataAgendamento = null
      }
      novoItemSolicitacao.agendamento = agendamento

      atualizarItemListagem(solicitacao.id, novoItemSolicitacao)
    }
    setOpenDrawerAgendamento(false)
  }

  function handleFecharCancelamento(
    cancelamento: ICancelamentoAgedamentoResponse = null
  ) {
    if (!!cancelamento) {
      var novoItemSolicitacao = solicitacaoItem

      var preAgendamento = [
        statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id,
        statusOrcamento.PAGAMENTO_PARCIAL.Id
      ].includes(solicitacaoItem.status.id)

      if (!preAgendamento) {
        novoItemSolicitacao.status = {
          id: statusOrcamento.EM_AGENDAMENTO.Id,
          descricao: statusOrcamento.EM_AGENDAMENTO.Parceiro.Descricao
        }
      }
      novoItemSolicitacao.agendamento = null

      atualizarItemListagem(solicitacaoItem.id, novoItemSolicitacao)
    }
    setOpenDrawerCancelamento(false)
  }

  function obterListaStatusPorPerfil() {
    var lista = listaStatus

    if (
      perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
      perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL
    ) {
      var statusCotacao = [
        statusOrcamento.EM_ANDAMENTO.Id,
        statusOrcamento.EM_AGENDAMENTO.Id,
        statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id,
        statusOrcamento.REALIZADO.Id,
        statusOrcamento.AGENDADO.Id,
        statusOrcamento.PROCESSO_CANCELADO.Id
      ]
      var novaLista = lista.filter(i => statusCotacao.includes(i.id))
      novaLista.push(
        {
          id: statusOrcamento.SOLICITACAO_RECEBIDA.Id,
          descricao: statusOrcamento.SOLICITACAO_RECEBIDA.Parceiro.Descricao
        },
        {
          id: statusOrcamento.REVISAR_LIBERAR_COTACAO.Id,
          descricao: statusOrcamento.REVISAR_LIBERAR_COTACAO.Parceiro.Descricao
        }
      )
      return novaLista
    }
    return lista
  }

  const handleSetDiaFiltro = e => {
    var valor = e.target.value
    setDiasFiltro(valor)
  }

  useEffect(() => {
    handleFiltroPorData()
  }, [diasFiltro])

  const limparFiltroData = () => {
    setDiasFiltro('periodo')
    setDataInicio('')
    setDataFim('')
    setFiltroStatusAgendamento('')
    setStatusSelecionado('')
    handleLimparFiltros()
  }
  const handleFiltroPorData = () => {
    if (diasFiltro === 'periodo') {
      limparFiltroData()
    } else if (diasFiltro === 'hoje') {
      CarregarDataAtualFiltro()
    } else if (diasFiltro === '3Dias') {
      CarregarDataPodDiaFiltro(3)
    } else if (diasFiltro === '7Dias') {
      CarregarDataPodDiaFiltro(7)
    } else if (diasFiltro === '15Dias') {
      CarregarDataPodDiaFiltro(15)
    } else if (diasFiltro === '30Dias') {
      CarregarDataPodDiaFiltro(30)
    }
  }

  const CarregarDataPodDiaFiltro = dias => {
    var dataFiltro = new Date()
    var day = dataFiltro.getDate().toString().padStart(2, '0')
    var year = dataFiltro.getFullYear()
    var month = (dataFiltro.getMonth() + 1).toString().padStart(2, '0')
    setDataFim(year + '-' + month + '-' + day)

    dataFiltro.setDate(dataFiltro.getDate() - dias)
    year = dataFiltro.getFullYear()
    month = (dataFiltro.getMonth() + 1).toString().padStart(2, '0')
    var dayModificado = dataFiltro.getDate().toString().padStart(2, '0')

    setDataInicio(year + '-' + month + '-' + dayModificado)
  }

  const CarregarDataAtualFiltro = () => {
    var dt = new Date()
    var year = dt.getFullYear()
    var month = (dt.getMonth() + 1).toString().padStart(2, '0')
    var day = dt.getDate().toString().padStart(2, '0')

    setDataInicio(year + '-' + month + '-' + day)
    setDataFim(year + '-' + month + '-' + day)
    setDiasFiltro('hoje')
  }

  function handleKeyDownPesquisar(e) {
    if (e.key === 'Enter' && termoPesquisa) {
      handlePesquisarDados()
    }
  }

  const classes = data()
  const style = useStyles()

  return (
    <Box>
      <Box mb={2}>
        {/* Agendamento */}
        <Typography variant="h5">Solicitações de Orçamentos</Typography>
      </Box>
      {
        //#region Filtro
      }
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              onKeyDown={e => {
                handleKeyDownPesquisar(e)
              }}
              fullWidth
              placeholder="Pesquise pelo nome do pacote, do paciente ou do médico..."
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              value={termoPesquisa}
              onChange={e => {
                if (!e.target.value) {
                  handleSetTermoPesquisar(e as any, true)
                } else {
                  handleSetTermoPesquisar(e as any, false)
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Button
              color="default"
              variant="text"
              onClick={() => setShowFiltros(!showFiltros)}
              startIcon={<FiFilter />}
            >
              {showFiltros ? 'Esconder' : 'Mostrar'} filtros
              {/* <FiFilter style={{ marginLeft: '10px' }} /> */}
              {/* <FilterListIcon style={{ marginLeft: '10px' }} /> */}
            </Button>
          </Grid>
          <>
            {showFiltros && (
              <Grid item xs={12} className={style.layoutFiltro}>
                <Grid container spacing={2}>
                  {SynPerfis.AGENDAMENTO === perfilAtual ? (
                    <Grid item xs={12} sm={3}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={filtroStatusAgendamento || filtroStatus}
                          onChange={e => {
                            setFiltroStatusAgendamento(e.target.value as string)
                          }}
                        >
                          <MenuItem value=" ">Todos os status</MenuItem>
                          {StatusFiltrosAgendamentoArray.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.descricao}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={2}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={statusSelecionado || filtroStatus}
                          onChange={e => {
                            setStatusSelecionado(e.target.value as string)
                          }}
                          disabled={loadingFiltroStatus}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              style={{ marginRight: '16px' }}
                            >
                              {loadingFiltroStatus ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                <></>
                              )}
                            </InputAdornment>
                          }
                        >
                          <MenuItem value=" ">Todos os status</MenuItem>
                          {obterListaStatusPorPerfil().map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.descricao}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        value={diasFiltro}
                        onChange={e => {
                          handleSetDiaFiltro(e)
                        }}
                      >
                        <MenuItem value="periodo">Período</MenuItem>
                        <MenuItem value="hoje">Hoje</MenuItem>
                        <MenuItem key="2" value="3Dias">
                          Últimos 3 dias
                        </MenuItem>
                        <MenuItem key="3" value="7Dias">
                          Últimos 7 dias
                        </MenuItem>
                        <MenuItem key="4" value="15Dias">
                          Últimos 15 dias
                        </MenuItem>
                        <MenuItem key="5" value="30Dias">
                          Últimos 30 dias
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <TextField
                      id="dataInicio"
                      error={dataInicioDirty && !formatarData(dataInicio)}
                      helperText={
                        dataInicioDirty && !formatarData(dataInicio)
                          ? 'Data inválida'
                          : ''
                      }
                      label="De"
                      type="date"
                      defaultValue={dataInicio}
                      value={dataInicio}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onKeyDown={e => {
                        setDataInicioDirty(true)
                      }}
                      onChange={e => {
                        setDataInicio(e.target.value)
                      }}
                    />

                    <TextField
                      error={dataFimDirty && !formatarData(dataFim)}
                      helperText={
                        dataFimDirty && !formatarData(dataFim)
                          ? 'Data inválida'
                          : ''
                      }
                      id="dataFim"
                      label="Até"
                      type="date"
                      defaultValue={dataFim}
                      value={dataFim}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onKeyDown={e => {
                        setDataFimDirty(true)
                      }}
                      onChange={e => {
                        setDataFim(e.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              style={{ display: 'flex', gap: 4, marginTop: 4, marginLeft: 8 }}
            >
              <Grid item xs={3} sm={3}>
                <Button
                  loading={loading}
                  disabled={loading}
                  size="small"
                  fullWidth={true}
                  variant="contained"
                  color="secondary"
                  startIcon={<GoSearch />}
                  onClick={e => {
                    carregarListagem({
                      resetarPaginacao: true,
                      dataInicio: dataInicio,
                      dataFim: dataFim,
                      status: statusSelecionado
                    })
                  }}
                >
                  Pesquisar (Enter)
                </Button>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Button
                  size="small"
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  startIcon={<ClearIcon />}
                  onClick={e => {
                    limparFiltroData()
                  }}
                >
                  Limpar filtros
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControlLabel
                  value={mostrarInteligente}
                  control={
                    <Checkbox
                      checked={mostrarInteligente}
                      onChange={(e, value) => {
                        setMostrarInteligente(value)
                      }}
                    />
                  }
                  label="Mostrar orçamentos instantâneos"
                />
              </FormGroup>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel
                    value={mostrarCotacao}
                    control={
                      <Checkbox
                        checked={mostrarCotacao}
                        onChange={(e, value) => {
                          setMostrarCotacao(value)
                        }}
                      />
                    }
                    label="Mostrar orçamentos cotações"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Box>
      {
        //#endregion
      }

      {
        //#region CARDS LISTAGEM
      }
      {!loading && (
        <Box mt={3} mb={2} textAlign="left" my={3}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                {!!ultimaSolicitacaoVista
                  ? 'Última solicitação vista'
                  : qtdAllDados
                  ? qtdAllDados > 1
                    ? `${qtdAllDados} solicitações`
                    : '1 solicitação'
                  : '0 Solicitações'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {!!ultimaSolicitacaoVista && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setUltimaSolicitacao()}
                >
                  Mostrar todas as solicitações
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      )}

      {nenhumDado && !nenhumResultado && allDados.length === 0 && (
        <>
          <Grid item xs={12} container justify="center">
            <Typography variant="h6" color="primary">
              Nenhuma solicitação de orçamento encontrada.
            </Typography>
          </Grid>
        </>
      )}

      {nenhumResultado && (
        <div style={{ marginTop: 20 }}>
          Nenhum resultado encontrado para sua pesquisa.
        </div>
      )}
      {allDadosCarregados && !loadingPagina && (
        <div className="fadeInTransition">
          <Grid container spacing={2}>
            {ultimaSolicitacaoVista !== undefined && (
              <Grid item xs={12} md={4} key={ultimaSolicitacaoVista.id}>
                <CardHospitalListagem
                  solicitacao={ultimaSolicitacaoVista}
                  className={setClassToHighlight(ultimaSolicitacaoVista)}
                  handleImprimir={handleImprimir}
                  handleVisualizarDetalhes={handleVisualizarDetalhes}
                  item={ultimaSolicitacaoVista}
                  renderStatusSolicitacao={renderStatusSolicitacao}
                  handleVisualizarOcorrencias={handleVisualizarOcorrencias}
                  handleAgendar={handleAgendar}
                  handleCancelarAgendamento={handleCancelarAgendamento}
                  handleAlterarStatusProcedimentoRealizado={
                    handleAlterarStatusProcedimentoRealizado
                  }
                  navigate={history.push}
                  handleOnClose={(agendamento, solicitacao) =>
                    handleFecharAgendamento(agendamento, solicitacao)
                  }
                />
              </Grid>
            )}
            {allDados.map(item => {
              if (ultimaSolicitacaoVista === undefined) {
                return (
                  <Grid item xs={12} md={4} key={item.id}>
                    <CardHospitalListagem
                      solicitacao={item}
                      className={setClassToHighlight(item)}
                      handleImprimir={handleImprimir}
                      handleVisualizarDetalhes={handleVisualizarDetalhes}
                      item={item}
                      renderStatusSolicitacao={renderStatusSolicitacao}
                      handleVisualizarOcorrencias={handleVisualizarOcorrencias}
                      handleAgendar={handleAgendar}
                      handleCancelarAgendamento={handleCancelarAgendamento}
                      handleAlterarStatusProcedimentoRealizado={
                        handleAlterarStatusProcedimentoRealizado
                      }
                      navigate={history.push}
                      handleOnClose={(agendamento, solicitacao) =>
                        handleFecharAgendamento(agendamento, solicitacao)
                      }
                    />
                  </Grid>
                )
              } else {
                return <></>
              }
            })}
          </Grid>
        </div>
      )}

      <Box mt={3} hidden={qtdAllDados === allDados.length} textAlign="center">
        <ButtonUI
          fullWidth
          onClick={handleProxPagina}
          variant="text"
          disabled={loading}
          loading={loading}
          endIcon={<MdKeyboardArrowDown />}
        >
          Carregar mais
        </ButtonUI>
      </Box>

      {
        //#endregion
      }
      {
        //#region Drawer Detalhes
      }
      <DrawerVisualizarDetalhes
        id={detalheVisualizacao?.id}
        exibirValores={true}
        open={mostrarDetalhe}
        handleOnClose={handleFecharDetalhes}
      />

      {
        //#endregion
      }
      {
        //#region Drawer Ocorrencias
      }
      <DrawerVisualizarOcorrencias
        open={visualizarOcorrencias.show}
        handleOnClose={() =>
          setVisualizarOcorrencias({
            show: false,
            id: ''
          })
        }
        solicitacaoId={visualizarOcorrencias.id}
        parceiro={true}
      />
      {
        //#endregion
      }
      {
        //#region IMPRESSÃO
      }
      <ResumoImpressaoOrcamento
        item={itemPrint}
        onClose={handleFecharImprimir}
      />
      {
        //#endregion
      }
      {openDrawerAgendamento && (
        <DrawerAgendamento
          solicitacaoItem={solicitacaoItem}
          open={true}
          handleOnClose={() => setOpenDrawerAgendamento(false)}
          handleFecharAgendamento={agendamento =>
            handleFecharAgendamento(agendamento)
          }
        />
      )}

      {openDrawerCancelamento && (
        <DrawerCancelamento
          solicitacaoItem={solicitacaoItem}
          open={true}
          handleOnClose={() => setOpenDrawerCancelamento(false)}
          handleFecharCancelamento={cancelamento =>
            handleFecharCancelamento(cancelamento)
          }
        />
      )}
    </Box>
  )
}

export default ListagemOrcamento
