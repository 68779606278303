import configProduction from './config.production'

let config = {
  // baseAPI: 'https://localhost:44377',

  // Alterar config
  // baseAPI: 'https://localhost:7131',
  // baseAPI: 'https://localhost:5001',
  baseAPI: 'https://synsaude-dev.azurewebsites.net',
  // baseAPI: 'https://synsaude-demo.azurewebsites.net/',
  // baseAPI: 'https://synsaude-prod.azurewebsites.net',
  // baseAPI: 'https://app.synsaude.com.br',
  // baseAPI: '', //Publicação para utilizar url atual

  //Desenvolvimento
  PublicKeyPagarme: 'pk_test_B7ovX5lFyrUmgbVm'

  //Prod
  // PublicKeyPagarme: 'pk_67YydKijpixPAldP'
}

if (process.env.REACT_APP_ENV === 'production') {
  config = Object.assign(config, configProduction)
}

export default config
