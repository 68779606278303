import {
  Box,
  Card,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import { MdEdit } from 'react-icons/md'
import { Alert } from '@material-ui/lab'
import CircularProgress from '@material-ui/core/CircularProgress'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { parceiroVerProposta } from 'src/api/orcamento'
import {
  formatarData,
  formatarDataHoraLocal,
  formatarDiferencaData,
  formatarIdade
} from 'src/core/formatacoes/formatarData'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { statusOrcamento } from 'src/core/consts/statusSolicitacao'
import {
  IOrcamentoLocal,
  IOrcamentoDetalhadoResponse,
  IPacoteDetalhadoResponse,
  PropostaOPME,
  FornecedorOPME
} from 'src/types'
import formatarCNPJ from 'src/core/formatacoes/formatarCNPJ'
import { obterOrcamentoParceiroPorId } from 'src/api/solicitacao-orcamento'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import HeaderSolicitacao, {
  IPacienteHeader
} from 'src/components/solicitacao/HeaderSolicitacao'
import UBox from 'src/components/ui/Ubox'
import { TiposAnestesias } from 'src/core/consts/tiposAnestesia'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import { SynPerfis } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import ItemResumoOrcamento from './itemResumoOrcamento'
import ResumoPacoteInteligenteOps from 'src/components/pacoteInteligente/resumoOperacional'
import { IPacoteInteligenteDetalhadoResponse } from 'src/api/pacoteInteligente'
import ResumoPacoteInteligenteHospital from './resumoPacoteInteligenteHospital'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import SynTipoParceiro from 'src/core/consts/synTipoParceiro'

const useStylesResumo = makeStyles(theme => ({
  boxResumo: {
    border: '1px solid #eee',
    backgroundColor: '#efefef',
    borderRadius: 4,
    '& .MuiTableCell-root.MuiTableCell-head': {
      padding: theme.spacing(1, 2),
      background: '#f9f9f9',
      color: theme.palette.grey[800]
    },
    '& h3': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  esconderEditar: {
    '& button': {
      display: 'none'
    }
  },
  fontMarca: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '0.8rem',
    color: theme.palette.grey[700]
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  },
  OPMECard: {
    padding: '20px',
    marginBottom: '20px',
    border: '1px solid #efefef',
    boxShadow: 'none',
    background: theme.palette.background.paper
  }
}))

interface Props {
  solicitacaoId: string
  isHospital: boolean
}

const ResumoParceiro = ({ solicitacaoId, isHospital = false }: Props) => {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const styles = useStylesResumo()
  const isDrawer = true

  const { perfilAtual, user } = useAuth()
  let parceiroOpme =
    perfilAtual === SynPerfis.PARCEIRO &&
    user.tipoParceiro === SynTipoParceiro.OPME.id

  const [loading, setLoading] = useState(true)
  const [solicitacao, setSolicitacao] = useState<IOrcamentoDetalhadoResponse>()
  // const [pacote, setPacote] = useState<IPacoteDetalhadoResponse>()
  const [paciente, setPaciente] = useState<IPacienteHeader>()
  const [verificaAnestesia, setVerificaAnestesia] = useState<boolean>(false)

  const [taxaOpmeProposta, setTaxaOpmeProposta] = useState<number>(null)
  const [valorTaxaOpmeProposta, setValorTaxaOpmeProposta] = useState<number>(
    null
  )

  const [mostrarContatoPaciente, setMostrarContatoPaciente] = useState<boolean>(
    false
  )
  const [nomeParceiro, setNomeParceiro] = useState<string>()
  const [dataEnvio, setDataEnvio] = useState<Date>()
  const [listaOPME, setListaOPME] = useState<PropostaOPME[]>()
  const [listaFornecedorOPME, setListaFornecedorOPME] = useState<
    FornecedorOPME[]
  >()
  const [orcamentoLocal, setOrcamentoLocal] = useState<IOrcamentoLocal>()
  const [orcamentoPropostas, setOrcamentoPropostas] = useState<
    IOrcamentoLocal[]
  >()
  const [
    solicitacaoOrcamento,
    setSolicitacaoOrcamento
  ] = useState<IOrcamentoDetalhadoResponse>()
  const [
    pacoteProcedimento,
    setPacoteProcedimento
  ] = useState<IPacoteDetalhadoResponse>()
  const [
    pacoteInstantaneo,
    setPacoteInstantaneo
  ] = useState<IPacoteInteligenteDetalhadoResponse>()

  //#region Use Effect inicial obter solicitação
  useEffect(() => {
    setLoading(true)
    // Promise.all([
    // obterOrcamentoParceiroPorId(solicitacaoId)
    // ])
    parceiroVerProposta(solicitacaoId)
      .then(response => {
        //Parceiro ver proposta
        // var response1 = values[0]
        // var response2 = values[1]
        const dados = response.data
        // const dados2 = response2.data

        setPacoteProcedimento(dados.pacoteProcedimento)
        setPacoteInstantaneo(dados.pacoteInteligente)
        setListaFornecedorOPME(dados.fornecedoresOPME)
        setSolicitacaoOrcamento(dados.solicitacaoOrcamento)
        setNomeParceiro(dados.nomeParceiro)
        setDataEnvio(dados.dataEnvio)
        setListaOPME(dados.listaOPME)
        setOrcamentoLocal(dados.localAtendimento)
        setOrcamentoPropostas(dados.propostas)
        setTaxaOpmeProposta(dados.taxaOpmeProposta)
        setValorTaxaOpmeProposta(dados.valorTaxaOpmeProposta)

        //Obter orçamento
        setMostrarContatoPaciente(dados.parceiroVenceuProposta)
        setSolicitacao(dados.solicitacaoOrcamento)
        // setPacote(dados.pacoteProcedimento)
        setPaciente({
          nome: dados.solicitacaoOrcamento.pacienteNome,
          cpf: dados.solicitacaoOrcamento?.pacienteCpf,
          dataNascimento: dados.solicitacaoOrcamento.pacienteDataNascimento,
          telefone: dados.solicitacaoOrcamento.pacienteTelefone,
          sexo: dados.solicitacaoOrcamento.pacienteSexo,
          email: dados.solicitacaoOrcamento.pacienteEmail,
          cpfResponsavel: dados.solicitacaoOrcamento?.cpfResponsavel,
          nomeResponsavel: dados.solicitacaoOrcamento?.nomeResponsavel
        })
        setLoading(false)
      })
      .catch(erro => showNotificationErrorAPI(erro))
  }, [])

  //#endregion

  useEffect(() => {
    var ehAnestesiaLocal =
      pacoteProcedimento?.tiposAnestesia?.filter(
        w =>
          w.id === TiposAnestesias.local_realizado_pelo_medico ||
          w.id === TiposAnestesias.local_realizado_pelo_medico_dev
      ).length > 0 && pacoteProcedimento?.tiposAnestesia?.length === 1
    setVerificaAnestesia(ehAnestesiaLocal)
  }, [pacoteProcedimento])

  if (loading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  // Filtrar os itens de fornecedoresOPME que possuem idOpme presentes na lista de opmes
  const fornecedoresFiltrados = listaFornecedorOPME?.filter(fornecedor =>
    pacoteProcedimento?.opmes.some(opme => opme.id === fornecedor.idOpme)
  )

  // Mapear a lista de opmes para adicionar o nomeParceiro correspondente
  const opmesComNomeParceiro = pacoteProcedimento?.opmes.map(opme => {
    const fornecedor = fornecedoresFiltrados?.find(
      fornecedor => fornecedor.idOpme === opme.id
    )
    return {
      ...opme,
      nomeParceiro: fornecedor
        ? fornecedor.nomeParceiro
        : 'Parceiro não encontrado'
    }
  })

  return (
    <Box
      mt={2}
      p={2}
      className={clsx({
        [styles.boxResumo]: true
      })}
    >
      <Typography variant="subtitle2">
        {(dataEnvio !== null || undefined) && (
          <>
            <Alert severity="success">
              <strong>Data de envio:</strong> {formatarDataHoraLocal(dataEnvio)}
            </Alert>
            {/* <strong>Data de envio:</strong> {formatarDataHoraLocal(dataEnvio)} */}
          </>
        )}
        {(dataEnvio === null || undefined) && (
          <>
            <Alert severity="warning">Orçamento ainda não foi realizado!</Alert>
          </>
        )}
      </Typography>
      {perfilAtual !== SynPerfis.AGENDAMENTO && (
        <>
          {
            //#region Orçamento cotação do Hospital
          }
          {orcamentoLocal && (
            <ItemResumoOrcamento
              orcamento={orcamentoLocal}
              solicitacao={solicitacao}
              paciente={paciente}
              taxaOpmeProposta={taxaOpmeProposta}
              valorTaxaOpmeProposta={valorTaxaOpmeProposta}
              isHospital={isHospital}
              loading={loading}
              mostrarContatoPaciente={mostrarContatoPaciente}
              pacoteProcedimento={pacoteProcedimento}
              verificaAnestesia={verificaAnestesia}
            />
          )}
        </>
      )}
      {[
        SynPerfis.ADMIN_HOSPITAL,
        SynPerfis.OPERACIONAL_HOSPITAL,
        SynPerfis.COTACAO_HOSPITAL,
        SynPerfis.TESOURARIA_HOSPITAL
      ].includes(perfilAtual) && (
        <>
          {orcamentoPropostas?.map((orcamento, index) => (
            <ItemResumoOrcamento
              key={index}
              orcamento={orcamento}
              solicitacao={solicitacao}
              paciente={paciente}
              // pacote={pacote}
              taxaOpmeProposta={orcamento.taxa}
              valorTaxaOpmeProposta={orcamento?.valorTaxaOpmeProposta}
              isHospital={isHospital}
              loading={loading}
              mostrarContatoPaciente={mostrarContatoPaciente}
              pacoteProcedimento={pacoteProcedimento}
              verificaAnestesia={orcamento.possuiAnestesia}
            />
          ))}
        </>
      )}
      {
        //#endregion
      }

      {
        //#region Lista de OPME
      }
      {listaOPME && listaOPME?.length > 0 && (
        <>
          <UBox mt={2}>
            <Divider />
          </UBox>
          <Box mt={2}>
            <Box>
              <Typography variant="h6" color="secondary">
                <b>Itens OPME's vencedores da proposta</b>
              </Typography>
            </Box>
            {listaOPME.map(o => {
              return (
                <Card className={styles.OPMECard} key={o.id}>
                  <Box mb={1}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6">{o.nomeOPME}</Typography>
                      <Typography variant="body2">
                        <strong>Qtd.</strong> {o.quantidade}
                      </Typography>
                    </Grid>
                  </Box>

                  {o.disponivel ? (
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2">
                          <strong>Fabricante:</strong> {o.fabricante}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2">
                          <strong>Valor un.:</strong>{' '}
                          {formatarDinheiro(o.valorUnitario)}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2">
                          <strong>Cód. ANVISA:</strong>{' '}
                          {o.codAnvisa ? o.codAnvisa : 'Não informado'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2">
                          <strong>Referência:</strong>{' '}
                          {o.referencia ? o.referencia : 'Não informado'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="body2">
                          <strong>Descrição:</strong>{' '}
                          {o.descricao ? o.descricao : 'Não informado'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="body2">
                          <strong>Observação:</strong>{' '}
                          {o.observacao ? o.observacao : 'Não informado'}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography variant="body2">
                      <strong>Não disponível</strong>
                    </Typography>
                  )}
                </Card>
              )
            })}
          </Box>
        </>
      )}
      {
        //#endregion
      }

      {
        //#region Dados do paciente
      }
      {orcamentoPropostas?.length > 0 && mostrarContatoPaciente !== false && (
        <>
          <UBox mt={2}>
            <Divider />
          </UBox>
          <Box>
            <Typography variant="h6" color="secondary">
              <b>Informações da solicitação</b>
            </Typography>
          </Box>
          <UBox render={mostrarContatoPaciente}>
            <Grid container>
              <Grid item xs={12} md={12}>
                {!!solicitacao && (
                  <>
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{
                        // borderBottom: '3px solid #455880',
                        lineHeight: '.9rem',
                        borderRadius: '2px',
                        display: 'inline-block',
                        marginBottom: '8px'
                      }}
                    >
                      <strong>Procedimento</strong>
                    </Typography>

                    <Typography variant="body2">
                      {solicitacao?.pacote?.descricao}
                    </Typography>
                  </>
                )}

                {pacoteProcedimento?.procedimentos.map(i => {
                  return (
                    <Typography variant="body2" key={i.id}>
                      Cód. {`${!!i.detalhe ? i.detalhe : ''} - ${i.descricao}`}
                    </Typography>
                  )
                })}

                <Typography
                  variant="body1"
                  color="primary"
                  style={{
                    // borderBottom: '3px solid #455880',
                    lineHeight: '.9rem',
                    borderRadius: '2px',
                    display: 'inline-block',
                    margin: '12px 0 8px'
                  }}
                >
                  <strong>Médico solicitante </strong>
                </Typography>
                <Typography
                  style={{
                    marginBottom: '8px'
                  }}
                  variant="body2"
                >{`Dr(a). ${solicitacao.medicoNome}`}</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="body1" color="primary">
                  <b>Paciente</b>
                </Typography>
                <Grid container>
                  <Grid item xs={12} md={isDrawer ? 12 : 4}>
                    <Typography variant="body2">
                      <strong style={{ fontWeight: 500 }}>Nome: </strong>{' '}
                      {paciente?.nome}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={isDrawer ? 12 : 4}>
                    <Typography variant="body2">
                      <strong style={{ fontWeight: 500 }}>CPF: </strong>{' '}
                      {formatarCPF(paciente?.cpf)}
                    </Typography>
                  </Grid>
                  {mostrarContatoPaciente && !parceiroOpme && paciente?.email && (
                    <Grid item xs={12} md={isDrawer ? 12 : 4}>
                      <Typography variant="body2">
                        <strong style={{ fontWeight: 500 }}>E-mail: </strong>{' '}
                        {paciente?.email}
                      </Typography>
                    </Grid>
                  )}
                  {mostrarContatoPaciente &&
                    !parceiroOpme &&
                    paciente?.telefone && (
                      <Grid item xs={12} md={isDrawer ? 12 : 4}>
                        <Typography variant="body2">
                          <strong style={{ fontWeight: 500 }}>
                            Telefone:{' '}
                          </strong>{' '}
                          {formatarTelefone(paciente?.telefone)}
                        </Typography>
                      </Grid>
                    )}
                  <Grid item xs={12} md={isDrawer ? 12 : 4}>
                    <Typography variant="body2">
                      <strong style={{ fontWeight: 500 }}>Nascimento: </strong>{' '}
                      {`${formatarData(
                        paciente?.dataNascimento
                      )} - ${formatarIdade(paciente?.dataNascimento)} anos`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={isDrawer ? 12 : 4}>
                    <Typography variant="body2">
                      <strong style={{ fontWeight: 500 }}>Sexo: </strong>{' '}
                      {paciente?.sexo === 1 ? 'Masculino' : 'Feminino'}
                    </Typography>
                  </Grid>
                  {!mostrarContatoPaciente &&
                    perfilAtual !== SynPerfis.PARCEIRO_EXTERNO &&
                    !parceiroOpme && (
                      <Grid item xs={12} md={isDrawer ? 12 : 6}>
                        <Typography variant="body2">
                          <strong style={{ fontWeight: 600 }}>
                            Telefone:{' '}
                          </strong>{' '}
                          {paciente?.telefone
                            ? formatarTelefone(paciente?.telefone)
                            : 'Não informado'}
                        </Typography>
                        <Typography variant="body2">
                          <strong style={{ fontWeight: 600 }}>Email: </strong>{' '}
                          {paciente?.email ? paciente?.email : 'Não informado'}
                        </Typography>
                      </Grid>
                    )}
                  {!parceiroOpme && (
                    <Grid item xs={12} md={12}>
                      <br />
                      <Typography variant="subtitle2" color="primary">
                        Comorbidades
                      </Typography>

                      <Typography variant="body2">
                        {solicitacao.comorbidades?.length !== 0
                          ? solicitacao.comorbidades
                              ?.map(e => e.descricao)
                              .join(', ')
                          : 'Não possui comorbidade'}
                      </Typography>
                      {solicitacao.observacaoComorbidades && (
                        <>
                          <br />
                          <Typography variant="subtitle2" color="primary">
                            Observação das comorbidades
                          </Typography>

                          <Typography variant="body2">
                            {solicitacao.observacaoComorbidades}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {paciente.nomeResponsavel && (
                <Grid item xs={12} sm={isDrawer ? 12 : 6}>
                  <Typography variant="h6">
                    Responsável pelo paciente{' '}
                    {parseInt(formatarDiferencaData(paciente?.dataNascimento)) <
                      18 && '(menor de idade)'}
                  </Typography>
                  <Typography variant="body2">
                    <b style={{ fontWeight: 600 }}>Nome: </b>{' '}
                    {paciente?.nomeResponsavel}
                  </Typography>
                  <Typography variant="body2">
                    <b style={{ fontWeight: 600 }}>CPF: </b>{' '}
                    {paciente?.cpfResponsavel}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </UBox>
        </>
      )}
      {
        //#endregion
      }

      {
        //#region Resumo da solicitação cotação
      }
      {isHospital &&
        !loading &&
        !!solicitacaoOrcamento &&
        !!pacoteProcedimento && (
          <>
            <UBox mt={2}>
              <Divider />
            </UBox>
            <UBox mt={2}>
              <Box>
                <Typography variant="h6" color="secondary">
                  <b>Informações da solicitação</b>
                </Typography>
              </Box>
              <HeaderSolicitacao
                solicitacao
                orcamentoParceiro
                paciente={paciente}
                pacoteProcedimento={pacoteProcedimento}
                medicoNome={solicitacao?.medicoNome}
                comorbidades={solicitacao?.comorbidades}
                obsComorbidades={solicitacao?.observacaoComorbidades}
                isDrawer
              />

              {!parceiroOpme && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="primary"
                      style={{ marginBottom: 6 }}
                    >
                      <strong>
                        {solicitacao?.codigo} - {pacoteProcedimento?.descricao}
                      </strong>{' '}
                    </Typography>
                  </Grid>
                  {!!solicitacaoOrcamento.temPlanoDeSaude && (
                    <Grid item xs={12}>
                      <Typography variant="body2" color="primary">
                        <b>Paciente possui plano de saúde </b>
                      </Typography>
                      <Typography variant="body2">
                        {solicitacaoOrcamento.temPlanoDeSaude ? 'Sim' : ''}
                      </Typography>
                      <br />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="body2" color="primary">
                      <b>Anestesia </b>
                    </Typography>
                    <Typography variant="body2">
                      {pacoteProcedimento.tiposAnestesia?.length === 0 &&
                        'Procedimento sem necessidade de anestesia'}
                      {verificaAnestesia &&
                        'Procedimento terá anestesia Local realizada pelo próprio cirurgião'}
                      {!!pacoteProcedimento.tiposAnestesia?.length &&
                        !verificaAnestesia &&
                        pacoteProcedimento.tiposAnestesia
                          ?.map(e => e.descricao)
                          .join(', ')}
                    </Typography>
                    <UBox
                      render={
                        solicitacao.valorEquipeAnestesia > 0 &&
                        !verificaAnestesia
                      }
                    >
                      <Alert severity="info">
                        Equipe anestésica disponibilizada pelo médico.
                      </Alert>
                    </UBox>
                    <br />
                  </Grid>

                  <Grid item xs={12} className={styles.cardItem}>
                    <Typography variant="body2" color="primary">
                      <b>Equipamentos hospitalares </b>
                    </Typography>
                    <Typography variant="body2">
                      {pacoteProcedimento.equipamentos?.length === 0 &&
                        'Nenhum equipamento'}
                      {!!pacoteProcedimento.equipamentos?.length &&
                        pacoteProcedimento.equipamentos
                          ?.map(e => e.descricao)
                          .join(', ')}
                    </Typography>
                    <br />
                  </Grid>

                  <Grid item xs={12} md={6} className={styles.cardItem}>
                    <Typography variant="body2" color="primary">
                      <b>Materiais específicos </b>
                    </Typography>
                    <Typography variant="body2">
                      {pacoteProcedimento.materiais?.length === 0 &&
                        'Nenhum material'}
                      {!!pacoteProcedimento.materiais?.length &&
                        pacoteProcedimento.materiais
                          ?.map(e => e.descricao)
                          .join(', ')}
                    </Typography>
                    <br />
                  </Grid>

                  <Grid item xs={12} md={12} className={styles.cardItem}>
                    {!!pacoteProcedimento.dayClinic && (
                      <>
                        <Typography variant="body2" color="primary">
                          <b>Day clinic (até 12h de internação)</b>
                        </Typography>
                        <Typography variant="body2">Sim</Typography>
                        <br />
                      </>
                    )}

                    {!!pacoteProcedimento.pqa && (
                      <>
                        <Typography variant="body2" color="primary">
                          <b>PQA - Cirúrgia de pequeno porte</b>
                        </Typography>
                        <Typography variant="body1">Sim</Typography>
                        <Alert severity="info">
                          Cirurgias de pequeno porte em que o paciente tem alta
                          hospitalar do centro cirúrgico até 6h após o
                          procedimento.
                        </Alert>
                        <br />
                      </>
                    )}

                    {parseInt(pacoteProcedimento.diariasUti) > 0 && (
                      <>
                        <Typography variant="body2" color="primary">
                          <b>UTI</b>
                        </Typography>
                        <Typography variant="body2">
                          {`${
                            pacoteProcedimento.diariasUti || '0'
                          }  diária(s) de UTI`}
                        </Typography>
                        <br />
                      </>
                    )}

                    {parseInt(pacoteProcedimento.diariasEnfermaria) > 0 && (
                      <>
                        <Typography variant="body2" color="primary">
                          <b>Enfermaria</b>
                        </Typography>
                        <Typography variant="body2">
                          {`${
                            pacoteProcedimento.diariasEnfermaria || '0'
                          } diária(s) de enfermaria`}
                        </Typography>
                        <br />
                      </>
                    )}

                    {parseInt(pacoteProcedimento.diariasApartamento) > 0 && (
                      <>
                        <Typography variant="body2" color="primary">
                          <b>Apartamento</b>
                        </Typography>
                        <Typography variant="body2">
                          {`${
                            pacoteProcedimento.diariasApartamento || '0'
                          } diária(s) de apartamento`}
                        </Typography>
                        <br />
                      </>
                    )}
                  </Grid>
                  {solicitacao.avaliacaoPreAnestesica && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" color="primary">
                        <b>Avaliação pré-anestésica </b>
                      </Typography>
                      <Typography variant="body2">Sim</Typography>
                      <br />
                    </Grid>
                  )}
                  {solicitacao.riscoCirurgico && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" color="primary">
                        <b>Avaliação de risco cirúrgico </b>
                      </Typography>
                      <Typography variant="body2">Sim</Typography>
                      <br />
                    </Grid>
                  )}
                  {solicitacao.observacaoExames && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" color="primary">
                        <b>Observação dos exames </b>
                      </Typography>
                      <Typography variant="body2">
                        {solicitacao.observacaoExames
                          ? solicitacao.observacaoExames
                          : 'Não informado'}
                      </Typography>
                      <br />
                    </Grid>
                  )}

                  <Grid item xs={12} className={styles.cardItem}>
                    <Typography variant="body2" color="primary">
                      <b>Duração média </b>
                    </Typography>
                    <Typography variant="body2">
                      {pacoteProcedimento.mediaTempoCirurgico
                        ? pacoteProcedimento.mediaTempoCirurgico + 'h'
                        : 'Não informado'}
                    </Typography>
                    <br />
                  </Grid>

                  {!!solicitacao.qtdReservaSange && (
                    <Grid item xs={12} className={styles.cardItem}>
                      <Typography variant="body2" color="primary">
                        <b>Reserva de sangue</b>
                      </Typography>
                      <Typography variant="body2">
                        {solicitacao.qtdReservaSange} concentrados de hemácias
                      </Typography>
                      <br />
                    </Grid>
                  )}

                  {!!solicitacao.observacaoSangue && (
                    <Grid item xs={12} className={styles.cardItem}>
                      <Typography variant="body2" color="primary">
                        <b>Observação reserva de sangue</b>
                      </Typography>
                      <Typography variant="body2">
                        {solicitacao.observacaoSangue}
                      </Typography>
                      <br />
                    </Grid>
                  )}
                  {!!pacoteProcedimento?.examesPosOperatorio && (
                    <>
                      <Grid item xs={12} className={styles.cardItem}>
                        <Typography variant="body2" color="primary">
                          <b>Exames durante internação </b>
                        </Typography>
                        <Typography variant="body2">
                          {pacoteProcedimento?.examesPosOperatorio ||
                            'Não informado'}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {!!solicitacao.disponibilidade && (
                    <Grid item xs={12} className={styles.cardItem}>
                      <Typography variant="body2" color="primary">
                        <b>Disponibilidade para operar em baixo fluxo </b>
                      </Typography>
                      <Typography variant="body2">
                        {solicitacao.disponibilidade || 'Não informado'}
                      </Typography>
                      <br />
                    </Grid>
                  )}
                  {pacoteProcedimento?.observacoes && (
                    <Grid item xs={12} className={styles.cardItem}>
                      <Typography variant="body2" color="primary">
                        <b>Observações gerais do pacote </b>
                      </Typography>
                      <Typography variant="body2">
                        {pacoteProcedimento?.observacoes}
                      </Typography>
                      <br />
                    </Grid>
                  )}
                  {/* {JSON.stringify(pacoteProcedimento.opmes)} */}

                  {[
                    SynPerfis.ADMIN_HOSPITAL,
                    SynPerfis.OPERACIONAL_HOSPITAL,
                    SynPerfis.COTACAO_HOSPITAL,
                    SynPerfis.TESOURARIA_HOSPITAL,
                    SynPerfis.AGENDAMENTO
                  ].includes(perfilAtual) &&
                    opmesComNomeParceiro.length !== 0 && (
                      <Grid item xs={12} className={styles.cardItem}>
                        <Typography variant="body2" color="primary">
                          <b>Lista de OPME </b>
                        </Typography>

                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Item</TableCell>
                                {/* <TableCell>Marca</TableCell> */}
                                <TableCell align="right" width="55px">
                                  Qtd
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {opmesComNomeParceiro?.map((opme, i) => (
                                <TableRow key={i}>
                                  <TableCell component="th" scope="row">
                                    <b style={{ color: '#555' }}>
                                      {opme.descricao}
                                    </b>
                                    <b
                                      style={{
                                        fontSize: '10px',
                                        display: 'block'
                                      }}
                                    >
                                      <i>{opme?.nomeParceiro}</i>
                                    </b>
                                  </TableCell>
                                  {/* <TableCell align="right" width="55px">
                                  <b style={{ fontSize: '10px', display: 'block' }}>
                                    <i>{opme?.marca.length > 0 ? opme?.marca : ' - ' }</i>
                                  </b>
                                </TableCell> */}
                                  <TableCell align="right" width="55px">
                                    <b style={{ marginRight: '4px' }}>
                                      {opme.quantidade || 1}
                                    </b>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                </Grid>
              )}
            </UBox>
          </>
        )}
      {
        //#endregion
      }
      {isHospital &&
        !loading &&
        !!solicitacaoOrcamento &&
        !!pacoteInstantaneo &&
        !parceiroOpme && (
          <ResumoPacoteInteligenteHospital
            adaptarValor={false}
            exibirValor={false}
            pacote={pacoteInstantaneo as any}
            unificarOpmes={false}
            medico={true}
            selecionado
            esconderIncluso={true}
            exibirDadosMedicos
          />
        )}
      {
        //#region Dados Solicitação
      }
      {!!solicitacao.avaliacaoPreAnestesica && (
        <>
          <Typography variant="subtitle2" color="primary">
            Avaliação pré-anestésica
          </Typography>
          <Typography variant="body2">Sim</Typography>
          <br />
        </>
      )}
      {!!solicitacao.riscoCirurgico && (
        <>
          <Typography variant="subtitle2" color="primary">
            Avaliação risco cirúrgico
          </Typography>
          <Typography variant="body2">Sim</Typography>
          <br />
        </>
      )}
      {!!solicitacao.observacaoExames && (
        <>
          <Typography variant="subtitle2" color="primary">
            Observação dos exames
          </Typography>
          <Typography variant="body2">
            {solicitacao.observacaoExames}
          </Typography>
          <br />
        </>
      )}

      {/* {!!solicitacao.qtdReservaSange && (
        <>
          <Typography variant="subtitle2" color="primary">
            Reserva de sangue
          </Typography>
          <Typography variant="body2">
            {solicitacao.qtdReservaSange} concentrados de hemácias
          </Typography>
          <br />
        </>
      )}
      {!!solicitacao.observacaoSangue && (
        <>
          <Typography variant="subtitle2" color="primary">
            Observação reserva de sangue
          </Typography>
          <Typography variant="body2">
            {solicitacao.observacaoSangue}
          </Typography>
          <br />
        </>
      )} */}

      {!!solicitacao.disponibilidade && (
        <>
          <Typography variant="subtitle2" color="primary">
            Disponibilidade para operar em horários de baixo fluxo
          </Typography>
          <Typography variant="body2">{solicitacao.disponibilidade}</Typography>
          <br />
        </>
      )}
      {!!solicitacao.observacoes && (
        <>
          <Typography variant="subtitle2" color="primary">
            Considerações finais
          </Typography>
          <Typography variant="body2">{solicitacao.observacoes}</Typography>
        </>
      )}
      {
        //#endregion
      }
    </Box>
  )
}

export default ResumoParceiro
