import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  CircularProgress,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { obterHistoricoEdicao } from 'src/api/historico-edicao'
import Drawer from 'src/components/ui/Drawer'
import {
  formatarDataLocal,
  formatarHoraLocal
} from 'src/core/formatacoes/formatarData'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'

const useStyles = makeStyles(theme => ({
  accordion: {
    border: '1px solid #efefef',
    boxShadow: 'none',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginBottom: '16px',
    background: theme.palette.grey[50],
    cursor: 'pointer'
  },
  accordionSemOPME: {
    border: '1px solid #efefef',
    boxShadow: 'none',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginBottom: '16px',
    background: theme.palette.grey[20],
    color: '#717171',
    cursor: 'default !important'
  },
  accordionSummary: {
    paddingRight: '10px'
  },
  accordionDetails: {},
  divider: {
    margin: '10px 0'
  },
  ulContent: {
    width: '100%'
  },
  liContent: {
    width: '100%',
    marginLeft: '-5%'
  },
  alert: {
    width: '100%',
    marginTop: '8px',
    marginBottom: '8px'
  }
}))

type Props = {
  open: boolean
  handleOnClose: () => void
  idItemSelecionado: string
}

export default function DrawerHistoricoEditar({
  open,
  idItemSelecionado,
  handleOnClose
}: Props) {
  const styles = useStyles()
  const [itemHistorico, setItemHistorico] = React.useState<any>(null)
  const [expanded, setExpanded] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  function handleOpenAccordion(panel: string) {
    return (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : '')
    }
  }
  function formatarValor(valor: any, modificado: string): any {
    // debugger
    if (modificado.startsWith('pacoteProcedimento')) {
      if (modificado.endsWith('honorarioEquipeMedica')) {
        return formatarDinheiro(valor)
      } else if (
        modificado.endsWith('dayClinic') ||
        modificado.endsWith('pqa') ||
        modificado.endsWith('semHonorarioMedico')
      ) {
        return valor === true ? 'Sim' : 'Não'
      } else if (
        modificado.endsWith('procedimentos') ||
        modificado.endsWith('exames') ||
        modificado.endsWith('membros') ||
        modificado.endsWith('tiposAnestesia') ||
        modificado.endsWith('equipamentos') ||
        modificado.endsWith('materiais') ||
        modificado.endsWith('opmes')
      ) {
        return valor
          ?.map((item: any) => {
            const texto = item?.descricao
            const quantidade =
              item?.quantidade && item?.quantidade > 0
                ? ` (Qtd: ${item?.quantidade})`
                : ''
            const marca =
              item?.marca && item?.marca.length > 0
                ? ` (Marca: ${item?.marca})`
                : ''
            return `${texto}${quantidade}${marca}`
          })
          .join(', ')
      } else {
        return valor
      }
    }
    if (modificado.startsWith('solicitacaoOrcamento')) {
      if (
        modificado.endsWith('valorMaxHonorarios') ||
        modificado.endsWith('valorMinHonorarios')
      ) {
        return formatarDinheiro(valor)
      } else if (
        modificado.endsWith('avaliacaoPreAnestesica') ||
        modificado.endsWith('centroCirurgicoAmbulatorial') ||
        modificado.endsWith('pacoteInstantaneo') ||
        modificado.endsWith('podeDiasUteis') ||
        modificado.endsWith('podeFinalDeSemana') ||
        modificado.endsWith('riscoCirurgico') ||
        modificado.endsWith('semComorbidade') ||
        modificado.endsWith('solicitacaoSemLocalAtendimento') ||
        modificado.endsWith('temPlanoDeSaude')
      ) {
        return valor === true ? 'Sim' : 'Não'
      } else if (modificado.endsWith('pacote')) {
        return valor?.descricao
      } else if (
        modificado.endsWith('comorbidades') ||
        modificado.endsWith('medicoEspecialidades') ||
        modificado.endsWith('locaisAtendimento') ||
        modificado.endsWith('exames')
      ) {
        return valor
          ?.map((item: any) => {
            return item?.descricao
          })
          .join(', ')
      } else {
        return valor
      }
    }
    return valor
  }

  function buscarValorModificado(json, modificado: string): any {
    if (!json || !modificado) return undefined

    const caminho = modificado?.split('.')
    let valor = json

    for (const parte of caminho) {
      if (valor[parte] === undefined) {
        return undefined
      }
      valor = valor[parte]
    }
    return formatarValor(valor, modificado)
  }

  useEffect(() => {
    buscarrHistoricoEdicao(idItemSelecionado)
  }, [idItemSelecionado])

  function buscarrHistoricoEdicao(id: string) {
    setIsLoading(true)
    obterHistoricoEdicao(id)
      .then(response => {
        let dados = response.data
        dados = dados?.map((item: any) => {
          item.jsonAnterior = JSON.parse(item.jsonAnterior)
          item.jsonPosterior = JSON.parse(item.jsonPosterior)
          return item
        })
        setIsLoading(false)
        setItemHistorico(dados)
      })
      .catch(err => {
        console.log('@ERROR: ', err)
      })
  }
  function exibirNomeEditado(modificado: string): string {
    switch (modificado) {
      // Pacote Procedimento
      case 'pacoteProcedimento.descricao':
        return 'Nome da cirurgia/procedimento'
      case 'pacoteProcedimento.procedimentos':
        return 'Procedimentos'
      case 'pacoteProcedimento.mediaTempoCirurgico':
        return 'Tempo do procedimento'
      case 'pacoteProcedimento.honorarioEquipeMedica':
        return 'Honorário médico'
      case 'pacoteProcedimento.tiposAnestesia':
        return 'Tipos de anestesia'
      case 'pacoteProcedimento.opmes':
        return 'Lista de OPME'
      case 'pacoteProcedimento.equipamentos':
        return 'Equipamentos hospitalares'
      case 'pacoteProcedimento.materiais':
        return 'Materiais específicos'
      case 'pacoteProcedimento.observacoesPaciente':
        return 'Considerações para o paciente'
      case 'pacoteProcedimento.observacoes':
        return 'Considerações do pacote'
      case 'pacoteProcedimento.pqa':
        return 'Internação de curta permanência (até 6 horas)'
      case 'pacoteProcedimento.dayClinic':
        return 'Day clinic (até 12h de internação)'
      case 'pacoteProcedimento.membros':
        return 'Equipe'
      case 'pacoteProcedimento.examesPosOperatorio':
        return 'Exames durante internação'
      case 'pacoteProcedimento.diariasUti':
        return 'UTI diarias'
      case 'pacoteProcedimento.diariasApartamento':
        return 'Apartamento diarias'
      // Solicitação de Orçamento
      case 'solicitacaoOrcamento.pacote':
        return 'Nome da cirurgia/procedimento'
      case 'solicitacaoOrcamento.pacienteNome':
        return 'Nome completo'
      case 'solicitacaoOrcamento.observacoes':
        return 'Observações'
      case 'solicitacaoOrcamento.observacaoSangue':
        return 'Observações reserva de sangue'
      case 'solicitacaoOrcamento.qtdReservaSange':
        return 'Qtd. concentrado de hemácias'
      case 'solicitacaoOrcamento.observacaoComorbidades':
        return 'Observações comorbidades'
      case 'solicitacaoOrcamento.cpfResponsavel':
        return 'Cpf do Responsavel'
      case 'solicitacaoOrcamento.nomeResponsavel':
        return 'Nome do Responsavel'
      case 'solicitacaoOrcamento.pacienteCpf':
        return 'Cpf do Paciente'
      case 'solicitacaoOrcamento.pacienteTelefone':
        return 'Telefone do Paciente'
      case 'solicitacaoOrcamento.pacienteEmail':
        return 'Email do Paciente'
      case 'solicitacaoOrcamento.pacienteDataNascimento':
        return 'Data de Nascimento do Paciente'
      case 'solicitacaoOrcamento.pacienteSexo':
        return 'Sexo do Paciente'
      case 'solicitacaoOrcamento.centroCirurgicoAmbulatorial':
        return 'Centro cirúrgico ambulatorial'
      case 'solicitacaoOrcamento.podeDiasUteis':
        return 'Somente em dias úteis'
      case 'solicitacaoOrcamento.podeFinalDeSemana':
        return 'Somente em finais de semana'
      case 'solicitacaoOrcamento.temPlanoDeSaude':
        return 'Pacificiente tem plano de saúde'
      case 'solicitacaoOrcamento.valorEquipeAnestesia':
        return 'Valor da equipe de anestesia'
      case 'solicitacaoOrcamento.valorMaxHonorarios':
        return 'Valor máximo de honorários'
      case 'solicitacaoOrcamento.valorMinHonorarios':
        return 'Valor mínimo de honorários'
      case 'solicitacaoOrcamento.locaisAtendimento':
        return 'Locais de atendimento'
      case 'solicitacaoOrcamento.medicoNome':
        return 'Nome do médico'
      case 'solicitacaoOrcamento.riscoCirurgico':
        return 'Risco cirúrgico'
      case 'solicitacaoOrcamento.semComorbidades':
        return 'Sem comorbidades'
      case 'solicitacaoOrcamento.mediaTempoCirurgico':
        return 'Tempo do procedimento'
      case 'solicitacaoOrcamento.comorbidades':
        return 'Comorbidades'
      case 'solicitacaoOrcamento.pacienteId':
        return 'Identificados do paciente'
    }
  }
  return (
    <Drawer
      open={open}
      anchor="right"
      handleOnClose={handleOnClose}
      titulo="Histórico de Edição"
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
        <Box mt={4} hidden={!isLoading} textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
        <Box hidden={isLoading}>
          {itemHistorico?.length === 0 ? (
            <Alert severity="info">
              <Typography variant="body2">
                Não há histórico de edições para este item.
              </Typography>
            </Alert>
          ) : (
            <>
              {itemHistorico?.map((item: any, index: number) => (
                <Accordion
                  expanded={expanded === index.toString()}
                  onChange={handleOpenAccordion(index.toString())}
                  className={styles.accordion}
                  key={JSON.stringify(item)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Grid
                      className={styles.accordionSummary}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: 6,
                          flexDirection: 'column'
                        }}
                      >
                        <Typography>
                          <strong>Editado por: </strong> {item?.criadoPor}
                        </Typography>
                        <div
                          style={{ display: 'flex', marginBottom: 8, gap: 12 }}
                        >
                          <Typography>
                            <strong>Data: </strong>
                            {formatarDataLocal(item?.criado)}
                          </Typography>
                          <Typography>
                            <strong>Hora:</strong>{' '}
                            {formatarHoraLocal(item?.criado)}
                          </Typography>
                        </div>
                      </div>
                      <Typography>
                        <Chip
                          color="primary"
                          size="small"
                          label={`${
                            item?.modificados?.length > 0
                              ? item?.modificados?.length
                              : 'Sem '
                          } ${
                            item?.modificados?.length === 1
                              ? 'modificação'
                              : 'modificações'
                          }`}
                        />
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      {item?.modificados?.length === 0 ||
                      (item?.modificados.length === 1 &&
                        item?.modificados[0] === '') ? (
                        <Alert severity="info" className={styles.alert}>
                          {' '}
                          Sem modificações{' '}
                        </Alert>
                      ) : (
                        <Typography variant="h6">
                          {item?.modificados?.length > 0
                            ? 'Modificações realizadas:'
                            : 'Sem modificações'}
                        </Typography>
                      )}
                      <ul className={styles.ulContent}>
                        {item?.modificados?.map(
                          (modificado: string, modIndex: number) => {
                            if (!modificado) return null
                            const valorAnterior = buscarValorModificado(
                              item?.jsonAnterior,
                              modificado
                            )
                            const valorPosterior = buscarValorModificado(
                              item?.jsonPosterior,
                              modificado
                            )
                            return (
                              <li key={modIndex} className={styles.liContent}>
                                <Typography>
                                  {/* {JSON.stringify(modificado)} */}
                                  <strong>
                                    {exibirNomeEditado(modificado)}
                                  </strong>
                                  :
                                  <Alert
                                    severity="error"
                                    className={styles.alert}
                                  >
                                    <Typography variant="body2">
                                      {valorAnterior.length <= 0
                                        ? 'Em branco'
                                        : valorAnterior}
                                    </Typography>
                                  </Alert>
                                  <Alert
                                    severity="success"
                                    className={styles.alert}
                                  >
                                    <Typography variant="body2">
                                      {valorPosterior?.length <= 0
                                        ? 'Em branco'
                                        : valorPosterior}
                                    </Typography>
                                  </Alert>
                                </Typography>
                              </li>
                            )
                          }
                        )}
                      </ul>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          )}
        </Box>
      </div>
    </Drawer>
  )
}
