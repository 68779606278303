import React, { useEffect, useState } from 'react'
import UBox from '../ui/Ubox'
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Link
} from '@material-ui/core'
import {
  ContatoParceiro,
  obterInstrucoesNFPorPropostaId
} from 'src/api/pagamento'
import { useQueryParams } from 'src/hooks/useQuery'
import { Alert } from '@material-ui/lab'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import { RiArrowLeftLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'

export default function InstrucoesNotaFiscal() {
  const query = useQueryParams()
  const history = useHistory()
  const [contatos, setContatos] = useState<ContatoParceiro[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [nomeProcedimento, setNomeProcedimento] = useState<string>(
    query.get('nome')
  )

  useEffect(() => {
    start()
  }, [])

  async function start() {
    var propostaId = query.get('p')

    if (!!propostaId) {
      var response = await obterInstrucoesNFPorPropostaId(propostaId)
      const { data } = response
      setContatos(data?.contatoParceiros)
      setLoading(false)
    }
  }

  return (
    <UBox>
      <Link
        component="button"
        color="primary"
        onClick={() => history.goBack()}
        style={{ marginBottom: '20px' }}
      >
        <RiArrowLeftLine />
        <Typography
          style={{ marginLeft: 10 }}
          variant="subtitle1"
          component="span"
        >
          Voltar
        </Typography>
      </Link>
      <UBox mb={2}>
        <Typography variant="h5">Instruções para nota fiscal</Typography>
      </UBox>

      <UBox mb={2} maxWidth={700}>
        <UBox>
          <Alert severity="info">
            As notas fiscais serão emitidas por cada parceiro responsável, e
            após a realização do procedimento.
          </Alert>
        </UBox>
        <UBox mt={2}>
          <Typography variant="subtitle1" color="primary">
            <b>
              Contatos de parceiros para emissão de nota fiscal "
              {nomeProcedimento}"
            </b>
          </Typography>
        </UBox>
        <UBox loading={loading} textLoading="buscando contatos">
          {contatos?.map((c, index) => (
            <Card key={index} style={{ marginBottom: '8px' }}>
              <CardContent>
                <Typography variant="body1">
                  <b>{c.descricao}</b>
                </Typography>
                <Typography variant="body2">
                  <small>
                    <b>Telefone: </b>
                  </small>
                  {!!c.telefone
                    ? formatarTelefone(c.telefone)
                    : 'Não informado'}
                </Typography>
                <Typography variant="body2">
                  {' '}
                  <small>
                    <b>E-mail: </b>
                  </small>{' '}
                  {!!c.email ? c.email : 'Não informado'}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </UBox>
      </UBox>
    </UBox>
  )
}
