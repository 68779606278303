export const SynRoutes = {
  signin: '/sign-in',
  esqueciSenha: '/esqueci-senha',
  definirSenha: '/definir-senha',
  definirSenhaToken: '/definir-senha-token',
  configurarAcesso: '/configurar-acesso-medico',
  cadastroParceiroComercialSyn: '/cadastro/parceiro/:parceiro?',
  cadastro: '/cadastro',
  newCadastro: '/new-cadastro',
  cadastroSucesso: '/cadastro-sucesso',
  home: '/',
  criarSenhaParceiro: '/configurar-acesso-parceiro',
  alterarSenha: '/alterar-senha',
  historicoAtualizacoes: '/historico-atualizacoes',
  termosDeUso: '/termos-de-uso',
  termosDeUsoTexto: '/termos-de-uso-txt',
  politicaDePrivacidade: '/politica-privacidade',
  politicaDePrivacidadeTexto: '/politica-privacidade-txt',
  termos: '/termos',
  selecionarPerfil: '/selecionar-perfil',
  selecionarSetorParceiro: '/selecionar-setor-parceiro',

  //Dashboard Medico
  listagemPacotes: '/pacotes',
  listagemEquipe: '/minha-equipe',
  listagemPacotesSugeridos: '/pacotes-sugeridos',
  pacote: '/pacote',
  formPacote: '/configurar-pacote',
  formConfiguracaoPacoteInteligente: '/configurar-pacote-inteligente',
  listagemOrcamentos: '/orcamentos',
  selecionarTipoOrcamento: '/selecionar-tipo-orcamento',
  formOrcamento: '/solicitar-orcamento',
  formOrcamentoUnificado: '/solicitar-orcamento-unificado',
  formOrcamentoUnificado2: '/solicitar-orcamento-unificado-v2',
  formOrcamentoInteligente: '/solicitar-orcamento-inteligente',
  convidarColega: '/convidar-colega',
  logomarcaMedico: '/logomarca-medico',
  propostasOrcamento: '/propostas-orcamento',
  /* listaPropostas: '/lista-propostas', */
  detalhesPropostasMedico: '/detalhes-propostas-medico',
  assistentesMedico: '/assitentes-medico',
  autorizarHospitais: '/autorizar-hospitais',
  formMedico: '/editar-dados',
  medicoAssistente: '/escolher-medico',

  //Dashboard Operacional
  listagemMedicos: '/medicos',
  listagemFinanciamentos: '/listagem-financiamentos',
  tarefas: '/tarefas',
  listagemSolicitacoesPendencias: '/solicitacoes-pendentes',
  listagemOrcamentosOperacional: '/operacional-orcamentos',
  listagemPacotesOperacional: '/operacional-pacotes',
  listagemParceirosOperacional: '/operacional-parceiros',
  formParceirosOperacional: '/operacional-cadastrar-parceiro',
  formAddUserOperacional: '/operacional-cadastrar-usuario',
  dadosSolicitacaoOperacionalOPME: '/operacional-OPME-dados-solicitacao',
  detalhesPropostas: '/detalhes-propostas-operacional',
  impressaoProposta: '/impressao-proposta',
  resetSenha: '/reset-senha',
  dashboard: '/dashboard',
  formPacoteInteligente: '/cadastro-pacote-inteligente',
  listagemUsuariosSyn: '/gerenciar-usuariosSyn',
  //Dashboard Comercial
  listagemOrcamentosParceiro: '/parceiro-orcamentos',

  // Dashboard Parceiros
  dadosSolicitacaoParceiroOPME: '/parceiro-OPME-dados-solicitacao',
  dadosSolicitacaoParceiroHospital: '/parceiro-hospital-dados-solicitacao',
  dadosTesourariaParceiroHospital: '/parceiro-hospital-dados-tesouraria',

  formOrcamentoHospital: '/solicitar-orcamento-hospital',
  listagemMedicosHospital: '/medicos-hospital',
  listagemPacotesHospital: '/pacotes-hospital',
  formCadastroMedico: '/parceiro-hospital-cadastro-medico',
  listagemOrcamentosHospital: '/parceiro-hospital-listagem-orcamentos',

  //Paciente
  detalhesPropostaPaciente: '/detalhes-proposta-paciente',
  pagamento: '/pagamento',
  financiamento: '/financiamento',
  financiamentoStep: '/financiamentoStep',
  financiamentoSucesso: '/financiamento-sucesso',
  resumoPagamento: '/resumo-pagamento',
  acompanharPagamento: '/acompanhar-pagamento',
  instrucoesNotaFiscal: '/instrucoes-nota-fiscal',

  //Admin
  listagemUsuarios: '/gerenciar-usuarios',
  acomodacoes: '/gerenciar-acomodacoes',

  //Out
  financiamentoB2C: '/financiamento-para-voce'
}
