import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import UBox from 'src/components/ui/Ubox'
import { ItemAcomodacaoInteligente } from '../FormPacoteInteligente'
import { yupResolver } from '@hookform/resolvers/yup'
import yup from 'src/components/yupCustom'
import TextField from 'src/components/ui/TextField'
import CurrencyInput from 'src/components/ui/CurrencyInput'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { ObjetoDescricaoGenerico } from 'src/types'
import { useForm } from 'react-hook-form'
import Button from 'src/components/ui/Button'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { Alert } from '@material-ui/lab'
import { useDebouncedCallback } from 'use-debounce'

const useStyles = makeStyles(theme => ({
  formControl2: {
    top: '16px'
  }
}))

interface IProps {
  incluso?: boolean
  perfilMedico?: boolean
  acomodacoes?: ItemAcomodacaoInteligente[]
  handleChange?: (item: ItemAcomodacaoInteligente[], valido: boolean) => void
}
function TabelaAcomodacao({
  acomodacoes = [],
  handleChange,
  perfilMedico = false,
  incluso = false
}: IProps) {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  const [loading, setLoading] = useState<boolean>(false)
  const [listaAcomodacoes, setListaAcomodacoes] = useState<
    ItemAcomodacaoInteligente[]
  >(acomodacoes)

  // const [listaOpme, setListaOpme] = useState<ItemAcomodacaoInteligente[]>([])
  function addOpme() {
    var novo: ItemAcomodacaoInteligente = {
      descricao: '',
      valor: 0,
      ativo: false,
      padrao: false
    }
    setListaAcomodacoes([...listaAcomodacoes, novo])
  }

  function definirAcomodacaoPadrao(id) {
    let novaLista = listaAcomodacoes.map(a => {
      a.padrao = false
      if (a.id === id) {
        a.padrao = true
      }
      return a
    })
    setListaAcomodacoes(novaLista)
    var formInvalido = novaLista.find(i => !i.valido)

    handleChange(novaLista, !!formInvalido)
  }

  function atualizarPai(lista) {
    var formInvalido = lista.find(i => !i.valido)
    setListaAcomodacoes(lista)

    handleChange(lista, !!formInvalido)
  }

  function atualizarItem(
    key: number,
    value: ItemAcomodacaoInteligente,
    valido: boolean
  ) {
    value['valido'] = valido
    listaAcomodacoes[key] = value

    // if (JSON.stringify(opmes) !== JSON.stringify(listaOpme)) {
    atualizarPai(listaAcomodacoes)
    // }
  }

  return (
    <UBox
      width="100%"
      // style={{ wordBreak: 'break-all' }}
    >
      <UBox render={acomodacoes?.length > 0}>
        <Alert severity="info">
          <b>1.</b> Marque as acomodações disponíveis para esse pacote. Ex:
          <br />
          <Checkbox checked={true} /> Disponível para o paciente.
          <br />
          <Checkbox checked={false} />
          Não disponível para o paciente
          <br />
          <b>2.</b> A seguir defina qual acomodação é a padrão, essa informação
          é utilizada para identificarmos que as diárias inclusas são de
          determinada acomodação.
          <br />
          <Radio checked={true} /> Essa é a acomodação principal seu valor é
          utilizado como base do cálculo.
          <br />
          <b>3.</b> Você também pode configurar valores específicos de cada
          acomodação para esse pacote.
        </Alert>
      </UBox>
      {!loading &&
        listaAcomodacoes.map((acomodacao, i) => (
          <UBox
            key={acomodacao.id + '-' + i + acomodacao.padrao ? 'v' : 'f'}
            // style={{
            //   background: !!opme.valido ? 'none' : '#fee',
            //   borderRadius: '4px'
            // }}
            px={ehXS ? 0 : 2}
          >
            <ItemAcomodacao
              perfilMedico={perfilMedico}
              acomodacao={acomodacao}
              index={i}
              atualizar={atualizarItem}
              definirAcomodacaoPadrao={definirAcomodacaoPadrao}
              key={
                acomodacao.id + acomodacao.padrao.toString()
                // + acomodacao.ativo.toString()
              }
            />
          </UBox>
        ))}
    </UBox>
  )
}

export default TabelaAcomodacao

interface PropItemAcomodacao {
  index: number
  acomodacao: ItemAcomodacaoInteligente

  definirAcomodacaoPadrao: (id: string) => void
  perfilMedico: boolean

  atualizar: (
    key: number,
    value: ItemAcomodacaoInteligente,
    valido: boolean
  ) => void
}

let validando = false

function ItemAcomodacao({
  acomodacao,
  index,
  atualizar,
  definirAcomodacaoPadrao,
  perfilMedico
}: PropItemAcomodacao) {
  const styles = useStyles()
  const [debouncedTermoPesquisa, setDebouncedTermoPesquisa] = useState(
    acomodacao
  )

  const debounced = useDebouncedCallback(() => {
    setDebouncedTermoPesquisa(command)
    return validar()
  }, 0)

  let defaultValues: ItemAcomodacaoInteligente = {
    descricao: '', //M- médico | H - hospital
    valor: 0,
    ativo: true,
    padrao: false
  }

  if (acomodacao !== null) {
    defaultValues = acomodacao
  }

  const schema = yup.object().shape({
    descricao: yup.string().required().label('Descrição'),
    valor: yup.string().required().label('Valor'),
    ativo: yup.bool().label('Ativo'),
    padrao: yup.bool().label('Padrão')
  })

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<ItemAcomodacaoInteligente>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('id')
    register('valor')
    register('descricao')
    register('padrao')
    register('ativo')
  }, [register])

  useEffect(() => {
    debounced.callback()
  }, [
    command.ativo,
    command.descricao,
    command.padrao,
    command.valor,
    command.valido
  ])

  async function validar() {
    var valido = false

    valido = await trigger(['descricao', 'valor', 'ativo', 'padrao'])

    control.updateFormState()

    atualizar(index, command, valido)
  }

  function handleChangeValor(
    key: keyof ItemAcomodacaoInteligente,
    valor: number
  ) {
    let valorFormatado = valor / 100
    if (!valorFormatado || valorFormatado < 0) {
      valor = 0
    }

    setValue(key, valor, { shouldValidate: true })
  }

  const handleItemValue = (name: keyof ItemAcomodacaoInteligente, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
  }

  const verificarForm = () => {
    var vazio = {}
    var resultado = JSON.stringify(errors) === JSON.stringify(vazio)
    return resultado
  }

  function handleDesativar(command: ItemAcomodacaoInteligente) {
    setValue('ativo', false)
  }

  function handleAtivar(command: ItemAcomodacaoInteligente) {
    setValue('ativo', true)
  }
  function handleDefinirPadrao(command: ItemAcomodacaoInteligente) {
    // setValue('padrao', true)
    definirAcomodacaoPadrao(command.id)
  }
  return (
    <UBox
      key={index}
      style={{
        background: !!verificarForm() ? 'none' : '#fee',
        borderRadius: '4px'
      }}
    >
      {/* c:{JSON.stringify(command)} */}
      {/* o:{JSON.stringify(opme)} */}
      {/* {index} */}
      {/* <br />
      <br />
      e:{JSON.stringify(errors)}
      <br />
      f:{JSON.stringify(verificarForm())} */}
      {/* <UBox style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
        c:{JSON.stringify(command)}
        <br />
        c:{JSON.stringify(errors)}
      </UBox> */}
      <Grid container spacing={1} direction="row" alignItems="center">
        <Grid item xs={12} sm={4}>
          <UBox>
            <UBox display="flex" alignItems="center">
              <Tooltip
                title={
                  command.ativo
                    ? 'Desmarque para desativar acomodação.'
                    : 'Marque para ativar acomodação'
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={command.ativo}
                      onChange={() => {
                        if (command.ativo) handleDesativar(command)
                        else handleAtivar(command)
                      }}
                    />
                  }
                  label=""
                />
              </Tooltip>
              <Typography variant="body1" component="span">
                {command.descricao}
              </Typography>
            </UBox>
          </UBox>
        </Grid>

        <UBox component={Grid} item xs={12} sm={4}>
          <UBox display="flex">
            <Tooltip title={'Marque essa acomodação como padrão'}>
              <FormControlLabel
                control={
                  <Radio
                    disabled={!command?.ativo}
                    checked={command.padrao}
                    onChange={() => handleDefinirPadrao(command)}
                    name="checkPadrao"
                    color="primary"
                    value={command.padrao}
                  />
                }
                label=""
              />
            </Tooltip>
            <FormControl
              // className={styles.formControl2}
              fullWidth
              size="medium"
              variant="outlined"
            >
              <CurrencyInput
                fullWidth
                size="small"
                erro={errors}
                disabled={!command?.ativo}
                // autoFocus
                variant="outlined"
                margin="normal"
                name="valor"
                label="Valor de diária extra"
                hiddenLabel
                control={control}
                type="tel"
                InputLabelProps={{
                  shrink: true
                }}
                onValueChange={value => handleChangeValor('valor', value)}
                value={command?.valor || 0}
              />
              <FormHelperText error={!!errors?.valor}>
                {errors?.valor?.message}
              </FormHelperText>
            </FormControl>
          </UBox>
        </UBox>
      </Grid>
      <UBox my={1}>
        <Divider />
      </UBox>
    </UBox>
  )
}
