import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RiArrowLeftLine } from 'react-icons/ri'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'
import {
  hospitalRecusarOrcamento,
  salvarOrcamentoHospital
} from 'src/api/orcamento'
import { obterOrcamentoParceiroPorId } from 'src/api/solicitacao-orcamento'
import ModalSubmitPropostaHospital from 'src/components/hospital/ModalSubmitPropostaHospital'
import HeaderSolicitacao, {
  IPacienteHeader
} from 'src/components/solicitacao/HeaderSolicitacao'
import Button from 'src/components/ui/Button'
import TextFieldSyn from 'src/components/ui/TextField'
import Ubox from 'src/components/ui/Ubox'
import yup from 'src/components/yupCustom'
import { UltimaSolicitacaoContext } from 'src/contexts/UltimaSolicitacao'
import { TiposAnestesias } from 'src/core/consts/tiposAnestesia'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import { useQueryParams } from 'src/hooks/useQuery'
import {
  IOrcamentoDetalhadoResponse,
  IPacoteDetalhadoResponse
} from 'src/types'
import Swal from 'sweetalert2'

const useStyles = makeStyles(theme => ({
  body: {},
  content: {
    maxWidth: '700px',
    margin: '10px'
  },
  boxResumo: {
    backgroundColor: theme.palette.grey[50]
  },
  numberFormat: {
    width: '100%',
    color: '#fff',
    backgroundColor: 'rgba(255,255,255,0.1) ',
    border: 'none',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottom: '2px solid white',
    outline: 'none',
    fontSize: '18px',
    padding: '8px',
    '&::placeholder': {
      color: '#ffffff92'
    }
  },
  inputLabel: {
    marginRight: '20px'
  },
  formGroup: {
    marginBottom: '5px'
  },
  calculoContent: {
    background: 'linear-gradient(to bottom, #455880, #486085)',
    borderRadius: '10px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  calculoContentMD: {
    marginTop: '12px',
    background: 'linear-gradient(to bottom, #455880, #486085)',
    borderRadius: '10px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  divider: {
    margin: '10px 0'
  },
  calculoText: {
    fontSize: '10px'
  },
  submitButton: {
    backgroundColor: '#59F0D5',
    marginTop: '15px',
    color: '#455880',
    height: '50px',
    borderRadius: '10px',
    marginBottom: '10px'
  },
  cardItem: {
    margin: '20px 0'
  },
  orcamentoTitle: {
    marginBottom: '20px'
  },
  cardRecusar: {
    padding: '20px',
    backgroundColor: theme.palette.grey[50],
    border: '1px solid #71717142'
  },
  justificativaRecusar: {
    margin: '20px 0',
    width: '100%'
  },
  recusarButton: {
    backgroundColor: '#e74c3c'
  },
  inputObservacao: {
    margin: '10px 0',
    '& .MuiInputBase-root': {
      color: '#fff'
    },
    '& .MuiFormLabel-root': {
      color: '#fff'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff'
      },
      '&:hover fieldset': {
        borderColor: '#fff'
      }
    }
  }
}))

interface IOrcamentoForm {
  valorAnestesia: number
  valorSala: number
  valorPreAnestesica: number
  valorRiscoCirurgico: number
  valorBolsaSangue: number
  valorReservaSangue: number
  observacao: string
  ehContemplaExame: boolean
  observacaoExames: string
  perfilFuncionarioAtual?: string
}

const orcamentoFormSchema = yup.object().shape({
  valorAnestesia: yup.number().required().min(0).label('Valor anestesia'),
  valorSala: yup.number().required().min(0).label('Valor Sala'),
  valorPreAnestesica: yup
    .number()
    .min(0)
    .label('Valor avaliação pré-anestésica'),
  valorRiscoCirurgico: yup
    .number()
    .min(0)
    .label('Valor avaliação de risco cirúrgico'),
  valorBolsaSangue: yup.number().min(0).label('Valor bolsa de sangue'),
  valorReservaSangue: yup.number().min(0).label('Valor reserva de sangue'),
  observacao: yup.string().label('Observação')
})

const DadosSolicitacao = () => {
  const styles = useStyles()
  const query = useQueryParams()
  const history = useHistory()
  const { user } = useAuth()
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const ehMD = useMediaQuery(theme.breakpoints.down('md'))

  const [loading, setLoading] = useState(true)
  const [enviarLoading, setEnviarLoading] = useState(false)
  const [recusarLoading, setRecusarLoading] = useState(false)
  const [submitModal, setSubmitModal] = useState(false)
  const [orcamento, setOrcamento] = useState<IOrcamentoDetalhadoResponse>()
  const [pacote, setPacote] = useState<IPacoteDetalhadoResponse>()
  const [paciente, setPaciente] = useState<IPacienteHeader>()
  const [verificaAnestesia, setVerificaAnestesia] = useState<boolean>(false)

  const { setUltimaSolicitacaoEnviado } = useContext(UltimaSolicitacaoContext)

  const recusarForm = useForm({
    defaultValues: { justificativa: '' }
  })

  const orcamentoForm = useForm<IOrcamentoForm>({
    resolver: yupResolver(orcamentoFormSchema),
    defaultValues: {
      observacao: '',
      valorAnestesia: 0,
      valorBolsaSangue: 0,
      valorPreAnestesica: 0,
      valorReservaSangue: 0,
      valorRiscoCirurgico: 0,
      valorSala: 0,
      observacaoExames: '',
      ehContemplaExame: false,
      perfilFuncionarioAtual: ''
    }
  })

  const commandOrcar = orcamentoForm.watch()
  const commandRecusar = recusarForm.watch()

  useEffect(() => {
    const id = query.get('id')

    obterOrcamentoParceiroPorId(id)
      .then(response => {
        const dados = response.data
        setOrcamento(dados.solicitacaoOrcamento)
        setPacote(dados.pacoteProcedimento)
        setPaciente({
          nome: dados.solicitacaoOrcamento.pacienteNome,
          dataNascimento: dados.solicitacaoOrcamento.pacienteDataNascimento,
          telefone: dados.solicitacaoOrcamento.pacienteTelefone,
          sexo: dados.solicitacaoOrcamento.pacienteSexo,
          email: dados.solicitacaoOrcamento.pacienteEmail
        })

        setLoading(false)
      })
      .catch(erro => showNotificationErrorAPI(erro))
  }, [])
  useEffect(() => {
    var ehAnestesiaLocal =
      pacote?.tiposAnestesia?.filter(
        w =>
          w.id === TiposAnestesias.local_realizado_pelo_medico ||
          w.id === TiposAnestesias.local_realizado_pelo_medico_dev
      ).length > 0 && pacote?.tiposAnestesia?.length === 1
    setVerificaAnestesia(ehAnestesiaLocal)
  }, [pacote])

  async function recusarOrcamento() {
    const result = await recusarForm.trigger()
    /* eslint-disable no-useless-return */
    if (!result) {
      return
    }

    setRecusarLoading(true)

    try {
      await hospitalRecusarOrcamento({
        solicitacaoOrcamentoId: orcamento.id,
        perfilFuncionarioAtual: user?.setorFuncionario,
        justificativa: commandRecusar.justificativa
      })

      setUltimaSolicitacaoEnviado()

      Swal.fire({
        title: 'Parabéns!',
        text: 'O orçamento foi recusado com sucesso.',
        icon: 'success',
        confirmButtonText: 'Continuar',
        willClose: () => {
          history.push('/')
        }
      })
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setRecusarLoading(false)
  }
  function setContinuar(observacaoExames, ehContemplaExame) {
    setSubmitModal(false)
    if (observacaoExames.length > 0) {
      commandOrcar.observacaoExames = observacaoExames
    }
    commandOrcar.ehContemplaExame = ehContemplaExame
    onSubmit()
  }
  function abrirModal() {
    if (pacote?.examesPosOperatorio?.length > 0) {
      setSubmitModal(true)
    } else {
      commandOrcar.ehContemplaExame = false
      onSubmit()
    }
  }
  async function onSubmit() {
    const result = await orcamentoForm.trigger()

    function renderErrorMessage(errors: any) {
      var message: string[] = []
      for (const key in errors) {
        message.push(errors[key].message)
      }

      return message.join(', ')
    }

    if (!result) {
      return Swal.fire({
        title: 'Atenção!',
        text: renderErrorMessage(orcamentoForm.errors),
        icon: 'warning',
        confirmButtonText: 'Fechar'
      })
    }

    setEnviarLoading(true)

    try {
      await salvarOrcamentoHospital({
        solicitacaoOrcamentoId: orcamento.id,
        perfilFuncionarioAtual: user?.setorFuncionario,
        ...commandOrcar
      })

      setUltimaSolicitacaoEnviado()

      Swal.fire({
        title: 'Parabéns!',
        text: 'O orçamento foi enviado com sucesso.',
        icon: 'success',
        confirmButtonText: 'Continuar',
        willClose: () => {
          history.push('/')
        }
      })

      setEnviarLoading(false)
    } catch (erro) {
      showNotificationErrorAPI(erro)
      setEnviarLoading(false)
    }
  }

  if (loading) {
    return (
      <Box minHeight="300px" textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  return (
    <Box>
      <Link
        component="button"
        color="primary"
        onClick={() => history.goBack()}
        style={{ marginBottom: '20px' }}
      >
        <RiArrowLeftLine />
        <Typography
          style={{ marginLeft: 10 }}
          variant="subtitle1"
          component="span"
        >
          Voltar
        </Typography>
      </Link>
      <HeaderSolicitacao
        solicitacao
        orcamentoParceiro
        paciente={paciente}
        pacoteProcedimento={pacote}
        medicoNome={orcamento.medicoNome}
        comorbidades={orcamento.comorbidades}
        obsComorbidades={orcamento.observacaoComorbidades}
      />

      <Grid container className={styles.body}>
        <Grid item xs={12} sm={7}>
          <Box className={styles.content}>
            <Card>
              <Box px={5} py={5} className={styles.boxResumo}>
                <Box mb={1}>
                  <Typography variant="h6">
                    Informações da solicitação
                  </Typography>
                </Box>
                <Grid container>
                  <Grid item xs={12} className={styles.cardItem}>
                    <Typography variant="h6" color="primary">
                      Anestesia:{' '}
                    </Typography>
                    <Typography>
                      {pacote.tiposAnestesia?.length === 0 &&
                        'Procedimento sem necessidade de anestesia'}
                      {verificaAnestesia &&
                        'Procedimento terá anestesia Local realizada pelo próprio cirurgião'}
                      {!!pacote.tiposAnestesia?.length &&
                        !verificaAnestesia &&
                        pacote.tiposAnestesia?.map(e => e.descricao).join(', ')}
                    </Typography>
                    <Ubox
                      render={
                        orcamento.valorEquipeAnestesia > 0 && !verificaAnestesia
                      }
                    >
                      <Alert severity="info">
                        Equipe anestésica disponibilizada pelo médico.
                      </Alert>
                    </Ubox>
                  </Grid>

                  <Grid item xs={12} className={styles.cardItem}>
                    <Typography variant="h6" color="primary">
                      Equipamentos hospitalares:{' '}
                    </Typography>
                    <Typography>
                      {pacote.equipamentos?.length === 0 &&
                        'Nenhum equipamento'}
                      {!!pacote.equipamentos?.length &&
                        pacote.equipamentos?.map(e => e.descricao).join(', ')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6} className={styles.cardItem}>
                    <Typography variant="h6" color="primary">
                      Materiais específicos:{' '}
                    </Typography>
                    <Typography>
                      {pacote.materiais?.length === 0 && 'Nenhum material'}
                      {!!pacote.materiais?.length &&
                        pacote.materiais?.map(e => e.descricao).join(', ')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12} className={styles.cardItem}>
                    {!!pacote.dayClinic && (
                      <>
                        <Typography variant="h6" color="primary">
                          Day clinic (até 12h de internação)
                        </Typography>
                        <Typography>Sim</Typography>
                        <br />
                      </>
                    )}

                    {!!pacote.pqa && (
                      <>
                        <Typography variant="h6" color="primary">
                          PQA - Cirúrgia de pequeno porte
                        </Typography>
                        <Typography variant="body1">Sim</Typography>
                        <Alert severity="info">
                          Cirurgias de pequeno porte em que o paciente tem alta
                          hospitalar do centro cirúrgico até 6h após o
                          procedimento.
                        </Alert>
                        <br />
                      </>
                    )}

                    {parseInt(pacote.diariasUti) > 0 && (
                      <>
                        <Typography variant="h6" color="primary">
                          UTI
                        </Typography>
                        <Typography>
                          {`${pacote.diariasUti || '0'}  diária(s) de UTI`}
                        </Typography>
                        <br />
                      </>
                    )}

                    {parseInt(pacote.diariasEnfermaria) > 0 && (
                      <>
                        <Typography variant="h6" color="primary">
                          Enfermaria
                        </Typography>
                        <Typography>
                          {`${
                            pacote.diariasEnfermaria || '0'
                          } diária(s) de enfermaria`}
                        </Typography>
                        <br />
                      </>
                    )}

                    {parseInt(pacote.diariasApartamento) > 0 && (
                      <>
                        <Typography variant="h6" color="primary">
                          Apartamento
                        </Typography>
                        <Typography>
                          {`${
                            pacote.diariasApartamento || '0'
                          } diária(s) de apartamento`}
                        </Typography>
                        <br />
                      </>
                    )}
                  </Grid>
                  {orcamento.avaliacaoPreAnestesica && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" color="primary">
                        Avaliação pré-anestésica{' '}
                      </Typography>
                      <Typography>Sim</Typography>
                      <br />
                    </Grid>
                  )}
                  {orcamento.riscoCirurgico && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" color="primary">
                        Avaliação de risco cirúrgico{' '}
                      </Typography>
                      <Typography>Sim</Typography>
                      <br />
                    </Grid>
                  )}
                  {orcamento.observacaoExames && (
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" color="primary">
                        Observação dos exames{' '}
                      </Typography>
                      <Typography>
                        {orcamento.observacaoExames
                          ? orcamento.observacaoExames
                          : 'Não informado'}
                      </Typography>
                      <br />
                    </Grid>
                  )}

                  <Grid item xs={12} md={6} className={styles.cardItem}>
                    <Typography variant="h6" color="primary">
                      Duração média{' '}
                    </Typography>
                    <Typography>
                      {pacote.mediaTempoCirurgico
                        ? pacote.mediaTempoCirurgico + 'h'
                        : 'Não informado'}
                    </Typography>
                  </Grid>

                  {!!orcamento.qtdReservaSange && (
                    <>
                      <Grid item xs={12} md={6} className={styles.cardItem}>
                        <Typography variant="h6" color="primary">
                          Reserva de sangue
                        </Typography>
                        <Typography variant="body2">
                          {orcamento.qtdReservaSange} concentrados de hemácias
                        </Typography>
                        <br />
                      </Grid>
                    </>
                  )}

                  {!!orcamento.observacaoSangue && (
                    <>
                      <Grid item xs={12} md={6} className={styles.cardItem}>
                        <Typography variant="h6" color="primary">
                          Observação reserva de sangue
                        </Typography>
                        <Typography variant="body2">
                          {orcamento.observacaoSangue}
                        </Typography>
                        <br />
                      </Grid>
                    </>
                  )}
                  {!!pacote?.examesPosOperatorio && (
                    <>
                      <Grid item xs={12} md={6} className={styles.cardItem}>
                        <Typography variant="h6" color="primary">
                          Exames durante internação{' '}
                        </Typography>
                        <Typography>
                          {pacote?.examesPosOperatorio || 'Não informado'}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {!!orcamento.disponibilidade && (
                    <Grid item xs={12} className={styles.cardItem}>
                      <Typography variant="h6" color="primary">
                        Disponibilidade para operar em baixo fluxo{' '}
                      </Typography>
                      <Typography>
                        {orcamento.disponibilidade || 'Não informado'}
                      </Typography>
                    </Grid>
                  )}
                  {pacote?.observacoes && (
                    <Grid item xs={12} className={styles.cardItem}>
                      <Typography variant="h6" color="primary">
                        Observações gerais do pacote{' '}
                      </Typography>
                      <Typography>{pacote?.observacoes}</Typography>
                    </Grid>
                  )}
                  {pacote.opmes.length !== 0 && (
                    <Grid item xs={12} className={styles.cardItem}>
                      <Typography variant="h6" color="primary">
                        Lista OPME{' '}
                      </Typography>
                      {pacote.opmes.map(o => (
                        <Typography key={o.id}>
                          - {o.descricao}: {o.quantidade} un.{' '}
                          {o.marca ? `Marca: ${o.marca} ` : ''}
                        </Typography>
                      ))}
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Alert severity="info">
                      A taxa de opme será adicionada após a finalização do
                      orçamento
                    </Alert>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box
            px={ehXS ? 1 : 3}
            py={2}
            className={ehMD ? styles.calculoContent : styles.calculoContentMD}
          >
            <Grid container justify="center">
              <Typography className={styles.orcamentoTitle} variant="h5">
                <strong>Orçamento: </strong>
              </Typography>
            </Grid>
            {!!orcamento.temPlanoDeSaude && (
              <Box mb={2}>
                <Grid item xs={12}>
                  <Alert severity="warning">
                    Paciente possui plano de saúde!
                  </Alert>
                </Grid>
              </Box>
            )}
            <Grid container>
              <Typography variant="body2">
                Valores podem ser: <strong> R$ 0,00 </strong>
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              className={styles.formGroup}
              justify="space-between"
            >
              <Typography variant="body1">Valor do hospital: </Typography>
              <Controller
                render={({ onChange, value }) => (
                  <NumberFormat
                    decimalScale={2}
                    autoFocus
                    fixedDecimalScale
                    allowedDecimalSeparators={[',']}
                    decimalSeparator=","
                    thousandSeparator="."
                    isNumericString
                    prefix="R$ "
                    className={styles.numberFormat}
                    type="tel"
                    value={value}
                    onValueChange={values => onChange(values.floatValue)}
                    placeholder="Valor hospital"
                    onFocus={e => e.target.select()}
                  />
                )}
                name="valorSala"
                control={orcamentoForm.control}
              />
            </Grid>
            <Ubox
              render={
                !orcamento.valorEquipeAnestesia &&
                pacote.tiposAnestesia?.length > 0 &&
                !verificaAnestesia
              }
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                className={styles.formGroup}
              >
                <Typography variant="body1">Anestesia: </Typography>
                <Controller
                  render={({ onChange, value }) => (
                    <NumberFormat
                      decimalScale={2}
                      fixedDecimalScale
                      allowedDecimalSeparators={[',']}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                      prefix="R$ "
                      className={styles.numberFormat}
                      type="tel"
                      value={value}
                      onValueChange={values => onChange(values.floatValue)}
                      placeholder="Valor anestesia"
                      onFocus={e => e.target.select()}
                    />
                  )}
                  name="valorAnestesia"
                  control={orcamentoForm.control}
                />
              </Grid>
            </Ubox>

            {!!orcamento.qtdReservaSange && (
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                className={styles.formGroup}
              >
                <Typography variant="body1">Reserva de sangue: </Typography>
                <Controller
                  render={({ onChange, value }) => (
                    <NumberFormat
                      decimalScale={2}
                      fixedDecimalScale
                      allowedDecimalSeparators={[',']}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                      prefix="R$ "
                      className={styles.numberFormat}
                      type="tel"
                      value={value}
                      onValueChange={values => onChange(values.floatValue)}
                      placeholder="Valor da reserva das bolsas de sangue"
                      onFocus={e => e.target.select()}
                    />
                  )}
                  name="valorReservaSangue"
                  control={orcamentoForm.control}
                />
              </Grid>
            )}

            {!!orcamento.qtdReservaSange && (
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                className={styles.formGroup}
              >
                <Typography variant="body1">Bolsa de sangue: </Typography>
                <Controller
                  render={({ onChange, value }) => (
                    <NumberFormat
                      decimalScale={2}
                      fixedDecimalScale
                      allowedDecimalSeparators={[',']}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                      prefix="R$ "
                      className={styles.numberFormat}
                      type="tel"
                      value={value}
                      onValueChange={values => onChange(values.floatValue)}
                      placeholder="Plasma, concentrados de plaquetas ou outros"
                      onFocus={e => e.target.select()}
                    />
                  )}
                  name="valorBolsaSangue"
                  control={orcamentoForm.control}
                />
              </Grid>
            )}

            {orcamento.avaliacaoPreAnestesica && (
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                className={styles.formGroup}
              >
                <Typography variant="body1">
                  Avaliação pré-anestésica:{' '}
                </Typography>
                <Controller
                  render={({ onChange, value }) => (
                    <NumberFormat
                      decimalScale={2}
                      fixedDecimalScale
                      allowedDecimalSeparators={[',']}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                      prefix="R$ "
                      className={styles.numberFormat}
                      type="tel"
                      value={value}
                      onValueChange={values => onChange(values.floatValue)}
                      onFocus={e => e.target.select()}
                    />
                  )}
                  name="valorPreAnestesica"
                  control={orcamentoForm.control}
                />
              </Grid>
            )}

            {orcamento.riscoCirurgico && (
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                className={styles.formGroup}
              >
                <Typography variant="body1">
                  Avaliação Risco Cirúrgico:{' '}
                </Typography>
                <Controller
                  render={({ onChange, value }) => (
                    <NumberFormat
                      decimalScale={2}
                      fixedDecimalScale
                      allowedDecimalSeparators={[',']}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                      prefix="R$ "
                      className={styles.numberFormat}
                      type="tel"
                      value={value}
                      onValueChange={values => onChange(values.floatValue)}
                      onFocus={e => e.target.select()}
                    />
                  )}
                  name="valorRiscoCirurgico"
                  control={orcamentoForm.control}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextFieldSyn
                className={styles.inputObservacao}
                label="Observação"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  autoComplete: 'new-password'
                }}
                name="observacao"
                inputRef={orcamentoForm.register}
                erro={orcamentoForm.errors}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                className={styles.submitButton}
                onClick={() => abrirModal()}
                loading={enviarLoading}
                disabled={enviarLoading}
                secondaryLoading
              >
                Enviar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" style={{ fontSize: '10px' }}>
                A taxa de opme será adicionada após a finalização do orçamento
              </Typography>
            </Grid>
          </Box>

          <Box py={3}>
            <Card className={styles.cardRecusar}>
              <Typography variant="subtitle2">
                Se o local de atendimento não conseguir atender a solicitação,
                recuse com uma justificativa
              </Typography>

              <TextField
                className={styles.justificativaRecusar}
                variant="outlined"
                label="Justificativa"
                name="justificativa"
                multiline
                {...recusarForm.register('justificativa', {
                  required: 'Justificativa é obrigatória'
                })}
                error={!!recusarForm.errors.justificativa}
                helperText={recusarForm.errors.justificativa?.message}
                onChange={e => {
                  recusarForm.clearErrors()
                  recusarForm.setValue('justificativa', e.target.value)
                }}
                InputProps={{
                  autoComplete: 'new-password'
                }}
              />

              <Button
                fullWidth
                className={styles.recusarButton}
                onClick={() => recusarOrcamento()}
                loading={recusarLoading}
                disabled={recusarLoading}
              >
                Recusar
              </Button>
            </Card>
          </Box>
        </Grid>
      </Grid>
      {submitModal && (
        <ModalSubmitPropostaHospital
          open={submitModal}
          descricaoExame={pacote?.examesPosOperatorio}
          onClose={() => setSubmitModal(false)}
          onFuncao={(observacaoExame, ehContemplaExame) =>
            setContinuar(observacaoExame, ehContemplaExame)
          }
        />
      )}
    </Box>
  )
}

export default DadosSolicitacao
