import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import useAtividade from 'src/hooks/useAtividade'
import {
  ItemListagemSolicitacaoOrcamentoResponse,
  ObjetoDescricaoGenerico,
  OperacionalResponse
} from 'src/types'
import yup from 'src/components/yupCustom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { IAddAtividadeCommand } from 'src/api/atividade'
import {
  showNotification,
  showNotificationErrorAPI
} from 'src/core/helpers/notification'
import { FiPlus } from 'react-icons/fi'
import UBox from 'src/components/ui/Ubox'
import TextField from 'src/components/ui/TextField'
import { IAtividade, IUsuarioAtividade } from 'src/contexts/Atividade/context'
import { isSchema } from 'yup'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { listarUsuarios } from 'src/api/solicitacao-orcamento'
import Alert from '@material-ui/lab/Alert'
import { isTemplateExpression } from 'typescript'
import formaPagamento from 'src/core/consts/formaPagamento'
import meioPagamento, { MEIO_PAGAMENTO } from 'src/core/consts/meioPagamento'
import HourInput from 'src/components/ui/HourInput'
import moment from 'moment'
import CurrencyInput from 'src/components/ui/CurrencyInput'
import usePagamento from 'src/hooks/usePagamento'
import Button from 'src/components/ui/Button'
import { IPagamentoAvulsoRequest } from 'src/contexts/Pagamento/context'
import { Pagamento } from 'src/api/pagamento'
import {
  formatarData,
  formatarHoraLocal
} from 'src/core/formatacoes/formatarData'
import { DropzoneArea } from 'material-ui-dropzone'
import { FaRegFilePdf } from 'react-icons/fa'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDropzoneArea-root': {
      minHeight: '200px'
    },
    '& .MuiDropzoneArea-text': {
      marginBottom: 0
    },
    '& .MuiDropzoneArea-text.MuiTypography-h5': {
      fontSize: '1.2rem',
      padding: '20px 40px 10px'
    },
    '& .MuiDropzoneArea-icon': {
      color: 'rgba(100, 100, 100, 0.87)'
    }
  }
}))

const stylePDF = {
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  border: '1px solid #ddd',
  borderRadius: '8px',
  boxShadow: '3px 3px 3px #eee',
  cursor: 'pointer',
  width: '150px',
  height: '150px',
  display: 'flex'
  // flexDirection: 'column'
}

const styleComp = {
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  border: '1px solid #ddd',
  borderRadius: '8px',
  boxShadow: '3px 3px 3px #eee',
  cursor: 'pointer',
  width: '150px',
  height: '150px'
}

const schema = yup.object().shape({
  formaPagamento: yup
    .object()
    .nullable()
    .required()
    .label('Forma de Pagamento'),
  codigoTransacao: yup
    .string()
    .nullable()
    .when('meioPagamento', {
      is: tipo => tipo?.value === MEIO_PAGAMENTO.MAQUININHA.value,
      then: yup.string().nullable().required().label('Código de transação')
    })
    .label('Código de transação'),
  meioPagamento: yup.object().nullable().required().label('Meio de Pagamento'),
  valor: yup.number().nullable().required().moreThan(0).label('Valor'),
  juros: yup.number().nullable().label('Valor'),
  dataPagamento: yup
    .string()
    .required('Data de pagamento é obrigatória')
    .test('data-valida', 'Por favor escolha uma data válida', function (value) {
      if (!value || value.replace(' ', '').length < 10) return false

      const data = moment(value, 'DD/MM/YYYY')
      if (!data.isValid()) return false
      return true
    })
    .label('Data de agendamento'),
  horaPagamento: yup
    .string()
    .required()
    .test(
      'hora-valida',
      'Por favor escolha um horário válido',
      function (value) {
        const date = this.parent.dataPagamento
        if (!date || date.replace(' ', '').length < 10) return false

        return true
      }
    )
    .label('Horário de agendamento')
})

interface ObjetoGenerico {
  id: number
  value: string
  name: string
}

interface PagamentoAvulsoForm {
  dataPagamento: string
  horaPagamento: string
  formaPagamento: ObjetoGenerico
  meioPagamento: ObjetoGenerico
  codigoTransacao: string
  valor: number
  juros?: number
  urlComprovante?: string

  // formaPagamento: 'cartao de credito' | 'xpto',
}

interface Props {
  pedido?: Pagamento
  propostaId: string
  open: boolean
  handleOnClose: () => void
}

export default function DrawerPagamentoAvulso({
  pedido,
  open,
  propostaId,
  handleOnClose
}: Props) {
  const { pagamentoAvulso, loadingAvulso } = usePagamento()
  const [mostrarAnexo, setMostrarAnexo] = useState(!pedido?.urlComprovante)

  let style = useStyles()
  var pagamentoAvulsoDefault: PagamentoAvulsoForm = {
    dataPagamento: '',
    horaPagamento: '',
    codigoTransacao: '',
    formaPagamento: null,
    meioPagamento: null,
    valor: 0,
    juros: null
  }

  function mapearPedidoParaForm() {
    let dataPagamento = formatarData(pedido.dataPagamento)
    let horaPagamento = formatarHoraLocal(pedido.dataPagamento)
    let forma = formaPagamento.find(f => f.value === pedido.formaPagamento)
    let tipo = meioPagamento.find(f => f.value === pedido.tipoPagamento)
    let form: PagamentoAvulsoForm = {
      ...pedido,
      dataPagamento,
      horaPagamento,
      formaPagamento: forma,
      meioPagamento: tipo,
      codigoTransacao: pedido?.codigoEstabelecimento
    }

    form.valor = parseInt((pedido.valor * 100).toFixed())
    form.juros = parseInt((pedido.juros * 100).toFixed())
    return form
  }

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    handleSubmit
  } = useForm<PagamentoAvulsoForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: !!pedido ? mapearPedidoParaForm() : pagamentoAvulsoDefault
  })

  console.log(
    'default',
    !!pedido ? mapearPedidoParaForm() : pagamentoAvulsoDefault
  )

  var command = watch()

  const [submitting, setSubmitting] = useState(false)
  const [arquivo, setArquivo] = useState<File>(null)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleItemValue = (name: keyof PagamentoAvulsoForm, item) => {
    if (item?.id) {
      setValue(name, item, { shouldValidate: true })
    } else {
      setValue(name, null, { shouldValidate: true })
    }
  }

  const handleValue = (name: keyof PagamentoAvulsoForm, item) => {
    setValue(name, item, { shouldValidate: true })
  }

  function handleChangeValor(key: keyof PagamentoAvulsoForm, valor: number) {
    let valorFormatado = valor / 100
    if (!valorFormatado || valorFormatado < 0) {
      valor = 0
    }

    // return valorFormatado
    setValue(key, valor, { shouldValidate: true })
  }

  function atualizarArquivo(files: File[]) {
    setArquivo(files[0])
  }

  const onSubmit = async (data: PagamentoAvulsoForm) => {
    let juros = !data.juros ? 0 : data.juros / 100
    let valor = data.valor / 100
    let meioPagamento = data.meioPagamento?.value
    let formaPagamento = data.formaPagamento?.value

    let novoPagamento: IPagamentoAvulsoRequest = {
      ...data,
      propostaId,
      meioPagamento,
      formaPagamento,
      juros,
      valor
    }

    if (!!pedido) {
      novoPagamento.pedidoId = pedido.id
    }

    let dataHoraPagamento = moment(
      `${data.dataPagamento} ${data.horaPagamento}`,
      'DD/MM/YYYY HH:mm'
    )
    novoPagamento.dataPagamento = dataHoraPagamento.toISOString()
    if (!!arquivo) {
      novoPagamento.arquivo = arquivo
    }
    try {
      await pagamentoAvulso(novoPagamento)
      showNotification(
        'Sucesso',
        'Novo registro de pagamento adicionado com sucesso.',
        'success',
        true
      )
      handleOnClose()
    } catch (error) {}
  }

  useEffect(() => {
    register('formaPagamento')
    register('meioPagamento')
    register('horaPagamento')
    register('valor')
    register('juros')
  }, [register])

  useEffect(() => {
    register('meioPagamento')
  }, [register])
  return (
    <Drawer
      open={open}
      anchor="right"
      handleOnClose={handleOnClose}
      titulo={`${!pedido ? 'Novo' : 'Editar'} pagamento avulso`}
    >
      <UBox
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        style={{ wordBreak: 'break-all' }}
      >
        {/* {JSON.stringify(command)} */}
        <UBox component={Grid} container xs={12} spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              erro={errors}
              control={control}
              name="dataPagamento"
              label="Data do pagamento"
              placeholder="Ex: 10/04/2025"
              mask="99/99/9999"
              value={command.dataPagamento}
              defaultValue={command.dataPagamento}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <HourInput
              errors={errors}
              name="horaPagamento"
              label="Horário do pagamento"
              value={command.horaPagamento}
              ignorarArrendondamentoMinutos
              control={control}
              handleChange={value => {
                handleValue('horaPagamento', value)
              }}
            />
          </Grid>
        </UBox>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={6}>
            <FormControl fullWidth size="medium" variant="outlined">
              <CurrencyInput
                fullWidth
                hidePrefix
                erro={errors}
                autoFocus
                variant="outlined"
                name="valor"
                label="Valor "
                hiddenLabel
                control={control}
                type="tel"
                InputLabelProps={{
                  shrink: true
                }}
                // InputProps={{
                //   endAdornment: <>%</>
                // }}
                max={1000000000}
                onValueChange={value => handleChangeValor('valor', value)}
                value={command?.valor || 0}
              />
              {/* <FormHelperText error={!!errors?.valor}>
                {errors?.valor?.message}
              </FormHelperText> */}
            </FormControl>
            {/* <TextField
              erro={errors}
              autoFocus
              margin="normal"
              name="valor"
              label="Valor"
              type="number"
              inputRef={register}
              InputLabelProps={{
                shrink: true
              }}
            /> */}
          </Grid>
          {/* </Grid>
        <Grid container spacing={1}> */}
          <Grid item xs={6}>
            <FormControl fullWidth size="medium" variant="outlined">
              <CurrencyInput
                fullWidth
                hidePrefix
                erro={errors}
                autoFocus
                variant="outlined"
                name="juros"
                label="Juros"
                hiddenLabel
                control={control}
                type="tel"
                InputLabelProps={{
                  shrink: true
                }}
                max={1000000000}
                onValueChange={value => handleChangeValor('juros', value)}
                value={command?.juros || 0}
              />
            </FormControl>
            {/* <TextField
              erro={errors}
              autoFocus
              margin="normal"
              name="juros"
              label="Juros"
              type="number"
              inputRef={register}
              InputLabelProps={{
                shrink: true
              }}
            /> */}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              erro={errors}
              name="formaPagamento"
              label="Forma de pagamento"
              placeholder="Informe a forma de pagamento"
              closeOnSelect
              value={command.formaPagamento}
              options={formaPagamento}
              getOptionLabel={option => option.name}
              onChange={(event, newValue) => {
                handleItemValue('formaPagamento', newValue as any)
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              erro={errors}
              name="meioPagamento"
              label="Meio de Pagamento"
              placeholder="Informe o meio de pagamento"
              closeOnSelect
              value={command.meioPagamento}
              options={meioPagamento}
              getOptionLabel={option => option.name}
              onChange={(event, newValue) => {
                handleItemValue('meioPagamento', newValue as any)
              }}
            />
            {/* <TextField
              erro={errors}
              name="tipoPagamento"
              label="Tipo de pagamento"
              closeOnSelect
              value={command.tipoPagamento}
              options={tipoPagamento}
              getOptionLabel={option => option.name}
              onChange={(event, newValue) => {
                handleItemValue('tipoPagamento', newValue as any)
              }}
            /> */}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              erro={errors}
              autoFocus
              margin="normal"
              name="codigoTransacao"
              label="Código de Transação"
              placeholder="Informe o código"
              type="text"
              inputRef={register}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} className={style.root}>
            {mostrarAnexo ? (
              <UBox mb={3}>
                <Box mt={2}>
                  <Typography variant="subtitle2">
                    Insira arquivos do tipo JPG, JPEG, PNG ou PDF
                  </Typography>
                </Box>
                <DropzoneArea
                  onChange={atualizarArquivo}
                  dropzoneText="Clique aqui ou arraste para anexar um comprovante de pagamento."
                  acceptedFiles={[
                    'image/jpeg, image/png, image/jpg, application/pdf,'
                  ]}
                  onAlert={(message, variant) =>
                    console.log(`${variant}: ${message}`)
                  }
                  showPreviews={true}
                  previewText="Comprovante:"
                  showPreviewsInDropzone={false}
                  filesLimit={1}
                  showAlerts={[]}
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
                  }}
                  onDrop={e => {
                    // showNotification('Logomarca!', 'Arquivo adicionado', 'success')
                  }}
                  onDelete={e => {
                    // showNotification('Logomarca!', 'Arquivo removido', 'success')
                  }}
                  onDropRejected={e => {
                    showNotification('Ops...', 'Arquivo inválido', 'warning')
                  }}
                />
              </UBox>
            ) : (
              <UBox mb={1}>
                <Typography variant="subtitle2">Comprovante:</Typography>
                {pedido.urlComprovante.includes('pdf') ? (
                  <div
                    onClick={() => window.open(pedido.urlComprovante, '_blank')}
                    style={{
                      fontSize: '100px',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'red',
                      ...stylePDF
                    }}
                  >
                    <FaRegFilePdf />
                    <p
                      style={{
                        fontSize: '14px',
                        margin: 0,
                        marginTop: 4,
                        padding: 0,
                        paddingTop: 0,
                        fontWeight: 600
                      }}
                    >
                      Baixar PDF
                    </p>
                  </div>
                ) : (
                  <div
                    onClick={() => window.open(pedido.urlComprovante, '_blank')}
                    style={{
                      background: `url(${pedido.urlComprovante})`,
                      ...styleComp
                    }}
                  ></div>
                )}
              </UBox>
            )}
            {!!pedido?.urlComprovante && (
              <UBox mb={2}>
                <Button
                  color="secondary"
                  onClick={() => {
                    setMostrarAnexo(!mostrarAnexo)
                  }}
                >
                  {!mostrarAnexo ? 'Alterar comprovante' : 'Cancelar'}
                </Button>
              </UBox>
            )}
            {/* <TextField
              erro={errors}
              autoFocus
              margin="normal"
              name="comprovante"
              label="Anexar Comprovante"
              type="text"
              inputRef={register}
              InputLabelProps={{
                shrink: true
              }}
            /> */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            loading={loadingAvulso}
          >
            Salvar
          </Button>
        </Grid>
      </UBox>
    </Drawer>
  )
}
