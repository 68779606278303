import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Search } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ButtonCostom from '../../../../components/ui/Button'

import ClearIcon from '@material-ui/icons/Clear'
import Swal from 'sweetalert2'

import { GoSearch } from 'react-icons/go'
import { excluirUsuario } from 'src/api/usuarios'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import useAuth from 'src/hooks/useAuth'

import {
  ativarAcomodacao,
  desativarAcomodacao,
  IAcomodacaoResponse,
  marcarAcomodacaoPadrao,
  obterTodasAcomodacoes
} from 'src/api/acomodacao'
import DrawerVisualizarDetalhes from './componentes/drawerDetalhesAcomodacao'

import { useQuery } from 'react-query'
import DrawerSalvarAcomodacao from './componentes/drawerSalvarAcomodacao'
import TabelaAcomodacao from './componentes/tabelaAcomodacao'
import { removerAcento } from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'

const useStyles = makeStyles(theme => ({
  layoutFiltro: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px',
    margin: '4px 8px',
    padding: '12px 24px'
  }
}))

function listagemAcomodacoes() {
  var style = useStyles()
  const { user, perfilAtual, ehTenantSyn, obterTenantAtual } = useAuth()

  const [itemSelecionado, setItemSelecionado] = useState<IAcomodacaoResponse>(
    undefined
  )

  const history = useHistory()

  const [showFiltros, setShowFiltros] = useState(false)
  const [loadingResetarSenha, setLoadingResetarSenha] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [mostrarDrawerSalvar, setMostrarDrawerSalvar] = useState(false)

  const [termoPesquisa, setTermoPesquisa] = useState<string>('')

  const { data: acomodacoes, isLoading, error, isError, refetch } = useQuery(
    ['acomodacoes', user.id],
    async () => {
      var response = await obterTodasAcomodacoes(true)
      return response.data
    }
  )

  async function carregarListagem() {
    try {
      refetch()
    } catch (error) {}
  }

  const handleFecharDetalhes = () => {
    setMostrarDetalhe(false)
  }

  const handleFecharSalvar = edicao => {
    setMostrarDrawerSalvar(false)
    carregarListagem()
  }

  const handleVisualizarDetalhes = (item: IAcomodacaoResponse) => {
    setItemSelecionado(item)
    setMostrarDetalhe(true)
  }

  async function handleEditar(item: IAcomodacaoResponse) {
    setItemSelecionado(item)
    setMostrarDrawerSalvar(true)
  }

  async function handleDesativar(item: IAcomodacaoResponse) {
    var result = await Swal.fire({
      title: `Deseja desativar a acomodação "${item.descricao}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        // return true
        debugger

        try {
          let response = await desativarAcomodacao(item?.id)
          return response
        } catch (error) {
          showNotificationErrorAPI(error)
          return false
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    })

    if (result.isDismissed) {
      return
    }

    if (result.value) {
      Swal.fire('Acomodação desativada com sucesso!', '', 'success')

      carregarListagem()
    }
  }

  async function handleAtivar(item: IAcomodacaoResponse) {
    var result = await Swal.fire({
      title: `Deseja ativar a acomodação "${item.descricao}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          let response = await ativarAcomodacao(item?.id)
          return response
        } catch (error) {
          showNotificationErrorAPI(error)
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    })

    if (result.isDismissed) {
      return
    }

    if (result.value) {
      Swal.fire('Acomodação ativada com sucesso!', '', 'success')
      carregarListagem()
    }
  }

  async function handleDefinirPadrao(item: IAcomodacaoResponse) {
    var result = await Swal.fire({
      title: `Deseja tornar a acomodação "${item.descricao}" o novo padrão?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          let response = await marcarAcomodacaoPadrao(item?.id)
          return response
        } catch (error) {
          showNotificationErrorAPI(error)
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    })

    if (result.isDismissed) {
      return
    }

    if (result.value) {
      Swal.fire('Acomodação definida como padrão com sucesso!', '', 'success')
      carregarListagem()
    }
  }
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  function handleNovo() {
    setItemSelecionado(null)
    setMostrarDrawerSalvar(true)
  }

  function acomodacoesFiltradas() {
    return acomodacoes?.filter(
      i =>
        !termoPesquisa ||
        removerAcento(i.descricao)
          .toLowerCase()
          ?.includes(removerAcento(termoPesquisa.toLowerCase()))
    )
  }

  return (
    <Box>
      {/* loading: {JSON.stringify(isLoading)} <br />
      error: {JSON.stringify(isError)} <br />
      data: {JSON.stringify(acomodacoes)} <br /> */}
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <Typography variant="h5">Acomodações</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box textAlign="right" mb={2}>
            <Button
              fullWidth={ehXS}
              variant="contained"
              color="primary"
              onClick={handleNovo}
              startIcon={<AddIcon />}
            >
              Nova acomodação
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Pesquise pela descrição"
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: isLoading && (
                  <CircularProgress
                    style={{ fontSize: '20px', width: '20px', height: '20px' }}
                  />
                )
              }}
              value={termoPesquisa}
              onChange={e => {
                setTermoPesquisa(e?.target?.value)
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}></Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <ButtonCostom
              loading={isLoading}
              disabled={isLoading}
              size="small"
              fullWidth={true}
              variant="contained"
              color="secondary"
              startIcon={<GoSearch />}
              onClick={e => {
                carregarListagem()
              }}
            >
              Pesquisar (Enter)
            </ButtonCostom>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ButtonCostom
              size="small"
              fullWidth={true}
              variant="contained"
              color="primary"
              startIcon={<ClearIcon />}
              onClick={e => {
                setTermoPesquisa('')
              }}
            >
              Limpar filtros
            </ButtonCostom>
          </Grid>
        </Grid>
      </Box>
      {!isLoading && (
        <Box mt={3} mb={2} textAlign="left" my={3}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color="primary">
                {acomodacoesFiltradas()?.length
                  ? acomodacoesFiltradas()?.length > 1
                    ? `${acomodacoesFiltradas()?.length} acomodações`
                    : '1 acomodação'
                  : '0 acomodações'}
                {!!termoPesquisa &&
                  ` encontrada(s) com o termo "${termoPesquisa}"`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {!isLoading && !acomodacoesFiltradas()?.length && (
        <div>Nenhum resultado encontrado para sua pesquisa.</div>
      )}
      {!!acomodacoesFiltradas()?.length && !isLoading && (
        <TabelaAcomodacao
          data={acomodacoesFiltradas()}
          handleDesativar={handleDesativar}
          handleAtivar={handleAtivar}
          handleDefinirPadrao={handleDefinirPadrao}
          handleEditar={handleEditar}
          handleVisualizarDetalhes={handleVisualizarDetalhes}
        />
      )}
      {isLoading && <LoadingGeral />}
      <DrawerVisualizarDetalhes
        id={itemSelecionado?.id}
        open={mostrarDetalhe}
        handleOnClose={handleFecharDetalhes}
      />
      {!!mostrarDrawerSalvar && (
        <DrawerSalvarAcomodacao
          item={itemSelecionado}
          open={true}
          handleOnClose={() => handleFecharSalvar(!!itemSelecionado)}
        />
      )}
    </Box>
  )
}

export default listagemAcomodacoes
