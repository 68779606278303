import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  ButtonGroup,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { obterPacotePorId } from 'src/api/pacote'
import { obterOrcamentoPorId } from 'src/api/solicitacao-orcamento'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import yup from 'src/components/yupCustom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useListagem from 'src/hooks/useListagem'
import { useQueryParams } from 'src/hooks/useQuery'
import { IOrcamentoDetalhadoResponse, ObjetoDescricaoGenerico } from 'src/types'
import { IPacoteForm } from '../../pacote/formPacote'

import { AiTwotoneThunderbolt } from 'react-icons/ai'
import { BiNetworkChart } from 'react-icons/bi'
import {
  ItemListagemPacoteUnificado,
  listagemPacotesUnificados,
  ListagemPacoteUnificadoQuery,
  obterPacoteInteligentePorId
} from 'src/api/pacoteInteligente'
import UBox from 'src/components/ui/Ubox'
import useAuth from 'src/hooks/useAuth'
import { FormOrcamento } from '../formV2'

import { AutocompleteRenderOptionState } from '@material-ui/lab'
import { IPacoteInteligenteForm } from 'src/components/pacoteInteligente/FormPacoteInteligente'
import { MobileContext } from 'src/contexts/Mobile'
import { FormOrcamentoInteligenteV3 } from '../inteligente/formConfiguracaoSolicitacao'

import { Search } from '@material-ui/icons'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { desabilitarPacote } from 'src/api/desabilitar'
import Button from 'src/components/ui/Button'
import { removerAcento } from 'src/core/helpers/common'
import Swal from 'sweetalert2'
import { FormConfiguracaoV2 } from '../../pacote/pacoteInteligente/formConfiguracaoV2'
import * as S from './form.styles'

const useStyles = makeStyles(theme => ({
  container: {},
  BtnCancel: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.common.white,
    '@media (max-width: 1300px)': {
      marginBotton: '10px'
    }
  },
  stepContent: {},
  resumoContent: {
    '@media (max-width: 600px)': {
      width: '108%'
    }
  },
  textInfo: {
    maxWidth: '600px',
    margin: '0 auto',
    '@media (max-width: 600px)': {
      marginTop: '-8px',
      paddingLeft: '18px',
      paddingRight: '14px'
    }
  },
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  infoTxt: {
    color: theme.palette.info.light
  },
  noMargin: {
    margin: theme.spacing(0)
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))
export interface Etapa<Generic> {
  step: Generic
  validacao: string[]
  ativo: boolean
}
//#region Cotacao
export enum StepsCotacao {
  resumo = 'resumo',
  paciente = 'paciente',
  comorbidades = 'comorbidades',
  reservaSangue = 'reservaSangue',
  localAtendimento = 'localAtendimento',
  honorarioFlexivel = 'honorarioFlexivel',
  internacao = 'internacao',
  anestesia = 'anestesia',
  observacao = 'observacao',
  revisao = 'revisao'
}

let stepsFormularioCotacao: Etapa<StepsCotacao>[] = [
  { step: StepsCotacao.resumo, validacao: ['pacote'], ativo: true },
  {
    step: StepsCotacao.paciente,
    validacao: [
      'cpfPaciente',
      'nomePaciente',
      'telefonePaciente',
      'sexoPaciente',
      'dataNascimentoPaciente'
    ],
    ativo: true
  },
  { step: StepsCotacao.comorbidades, validacao: ['comorbidades'], ativo: true },
  {
    step: StepsCotacao.reservaSangue,
    validacao: ['reservaSangue'],
    ativo: true
  },
  {
    step: StepsCotacao.localAtendimento,
    validacao: ['disponibilidade', 'uf', 'hospitais'],
    ativo: true
  },
  {
    step: StepsCotacao.honorarioFlexivel,
    validacao: ['valorMinHonorarios', 'valorMaxHonorarios'],
    ativo: true
  },
  {
    step: StepsCotacao.anestesia,
    validacao: ['valorEquipeAnestesia', 'equipeAnestesia'],
    ativo: true
  },
  { step: StepsCotacao.observacao, validacao: ['observacoes'], ativo: true },
  { step: StepsCotacao.revisao, validacao: [], ativo: true }
]
//#endregion

//#region Configuração inteligente
export enum Steps {
  resumo = 'resumo',
  opme = 'opme',
  anestesia = 'anestesia',
  equipamentosMat = 'equipamentosMateriais',
  exames = 'exames',
  internacao = 'internacao',
  honorarioMedico = 'honorarioMedico',
  membrosEquipe = 'membrosEquipe',
  revisao = 'revisao'
}

export let stepsFormulario: Etapa<Steps>[] = [
  { step: Steps.resumo, validacao: [], ativo: true },
  { step: Steps.opme, validacao: [], ativo: true },
  { step: Steps.anestesia, validacao: [], ativo: true },
  { step: Steps.equipamentosMat, validacao: [], ativo: true },
  { step: Steps.exames, validacao: [], ativo: true },
  { step: Steps.internacao, validacao: [], ativo: true },
  {
    step: Steps.honorarioMedico,
    validacao: ['honorarioEquipeMedica', 'mediaTempoCirurgico'],
    ativo: true
  },
  { step: Steps.membrosEquipe, validacao: [], ativo: false },
  { step: Steps.revisao, validacao: [], ativo: false }
]
//#endregion

type Props = {
  fecharForm: () => void
}

const codigoFluxo = 'pctv2'

export const FormOrcamentoUnificadoV2 = (props: Props) => {
  const classes = useStyles()
  const history = useHistory()

  const { autocompleteMobileOpen } = useContext(MobileContext)

  const query = useQueryParams()

  //#region Definicao de listas e loading
  const [loadingOrcamento, setLoadingOrcamento] = useState(false)
  const [urlInicial, setUrlInicial] = useState<string>('')

  const [listaPacotes, setListaPacotes] = useState<
    ItemListagemPacoteUnificado[]
  >([])

  const [medicoSolicitacaoId, setMedicoSolicitacaoId] = useState<string>(null)
  const [medicoCpf, setMedicoCpf] = useState<string>(null)

  const [
    orcamentoDetalhado,
    setOrcamentoDetalhado
  ] = useState<IOrcamentoDetalhadoResponse>()

  const [filtroTexto, setFiltroTexto] = useState<string>('')
  const [configurado, setConfigurado] = useState<boolean>(false)
  const [pacoteConfiguradoId, setPacoteConfiguradoId] = useState<string>(null)

  const [loadingPacote, setLoadingPacote] = useState(false)
  const [mostrarPacotesCotacao, setMostrarPacotesCotacao] = useState(false)
  const [pacoteSelecionado, setPacoteSelecionado] = useState<IPacoteForm>(null)
  const [
    pacoteInstantaneoSelecionado,
    setPacoteInstantaneoSelecionado
  ] = useState<IPacoteInteligenteForm>(null)
  const [pacoteInstantaneoPai, setPacoteInstantaneoPai] = useState<IPacoteForm>(
    null
  )
  const [activeStepConfiguracao, setActiveStepConfiguracao] = React.useState(
    Steps.resumo
  )
  const [activeStepCotacao, setActiveStepCotacao] = React.useState(
    StepsCotacao.resumo
  )
  const [activeStep, setActiveStep] = React.useState(0)
  const [
    listagemPacotesHabilitados,
    setListagemPacotesHabilitados
  ] = useState<boolean>(false)

  const { user, perfilAtual } = useAuth()

  //#endregion

  //#region Formulário

  const schema = yup.object().shape({
    pacote: yup.object().nullable().required().label('Pacote de procedimento')
  })

  let defaultValues: {
    pacote: null
  }

  // defaultValues = solicitacaoMock

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('pacote')
  }, [register])

  // VINCULAR MUDANÇA DE VALOR
  const handleItemValue = (name: string, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
  }

  //#endregion

  //#region MOUNT
  useEffect(() => {
    verificaSeTemPacoteSelecionado()
    verificaEhEdicao()
    verificaEhSecretaria()
    //Montar url inicial para quando usar botão cancelar
    montarUrlInicial()
  }, [])

  function montarUrlInicial() {
    const medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    const doc = query.get('doc')

    var url = `${location.origin}${location.pathname}`
    var queryParams = ''
    if (!!medicoSolicitacaoId || !!doc) {
      queryParams = `?medicoSolicitacaoId=${medicoSolicitacaoId}&doc=${doc}`
    }
    setUrlInicial(`${url}${queryParams}`)
  }

  function verificaEhSecretaria() {
    const medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    const doc = query.get('doc')
    if (medicoSolicitacaoId) {
      setValue('medicoSolicitacaoId', medicoSolicitacaoId)
      setMedicoSolicitacaoId(medicoSolicitacaoId)
      setMedicoCpf(doc)
      // obterLocaisAtendimentoPorUfMedicoCom(medicoSolicitacaoId)
    } else {
      // carregarHospitais()
    }
  }

  function verificaEhEdicao() {
    const id = query.get('id')
    if (!id) {
      setLoadingOrcamento(false)
      return
    }

    obterOrcamentoPorId(id)
      .then(response => {
        const orcamento = response.data

        setOrcamentoDetalhado(orcamento)
        orcamento.pacote.tipo = 'cotacao'

        setValue('pacote', orcamento.pacote)

        setLoadingOrcamento(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        history.goBack()
      })
  }

  function verificaSeTemPacoteSelecionado() {
    const idPacote = query.get('idPacote')
    const descricaoPacote = query.get('descricaoPacote')
    const tipo = query.get('tipo')

    if (!!idPacote && !!descricaoPacote) {
      let pacote: ObjetoDescricaoGenerico = {
        id: idPacote,
        descricao: descricaoPacote,
        tipo: tipo
      }
      setValue('pacote', pacote)
    }
  }
  //#endregion

  //#region Carregar Opções do Sistema
  const { loading, dados, atualizarItemListagem } = useListagem(1000, listagem)

  function listagem(
    queryBody: ListagemPacoteUnificadoQuery,
    primeiraBusca?: boolean
  ) {
    const medicoId = query.get('medicoSolicitacaoId')
    const doc = query.get('doc')
    queryBody['perfilAtual'] = perfilAtual
    queryBody['medicoId'] = medicoId
    queryBody['medicoCpf'] = doc
    return listagemPacotesUnificados(user.cpf, queryBody, true, true)
  }

  useEffect(() => {
    const medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    if (medicoSolicitacaoId) {
      const filtrado = dados.filter(w => w.medicoId === medicoSolicitacaoId)
      setListaPacotes(filtrado)
    } else {
      setListaPacotes(dados)
    }
    if (!!orcamentoDetalhado?.pacote) {
      setValue('pacote', orcamentoDetalhado.pacote)
    }
  }, [dados])

  //#endregion

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setPacoteInstantaneoSelecionado(null)
    setPacoteSelecionado(null)

    if (!command.pacote) {
      return
    }

    obterPacote(command.pacote.id)
  }, [command?.pacote, command?.pacote?.id])

  function obterPacote(id, callback = () => {}) {
    setLoadingPacote(true)

    if (command.pacote.tipo === 'inteligente') {
      obterPacoteInteligentePorId(id)
        .then(response => {
          let pacoteResp = response.data
          let pacote: IPacoteInteligenteForm = pacoteResp
          //Salva o pacote pai se for a primeira vez
          if (!pacoteInstantaneoPai) {
            setPacoteInstantaneoPai(id)
          }
          setPacoteInstantaneoSelecionado(pacote)
          callback()
        })
        .catch(e => showNotificationErrorAPI(e))
        .finally(() => {
          setLoadingPacote(false)
        })
    } else {
      obterPacotePorId(id)
        .then(response => {
          let pacoteResp = response.data
          let pacote: IPacoteForm = pacoteResp

          pacote.medicoId = medicoSolicitacaoId

          const fluxoV2 = query.get('flow')
          if (fluxoV2 === 'pctv2') {
            setActiveStepCotacao(StepsCotacao.paciente)
          }
          setPacoteSelecionado(pacote)
        })
        .catch(e => showNotificationErrorAPI(e))
        .finally(() => {
          setLoadingPacote(false)
        })
    }
  }

  function handleMostrarPacotesCotacao() {
    setMostrarPacotesCotacao(true)
    if (pacotesFiltrados(false, true).length === 0) {
      handleNovoPacote()
    }
  }

  const handleNovoPacote = () => {
    const medicoId = query.get('medicoSolicitacaoId')
    const doc = query.get('doc')

    var url = `${SynRoutes.formPacote}?flow=${codigoFluxo}&returnUrl=${SynRoutes.formOrcamentoUnificado2}`

    if (medicoId) {
      url += '&medicoSolicitacaoId=' + medicoId
    }
    if (doc) {
      url += '&doc=' + doc
    }
    history.replace(url)
  }

  function handleConfiguracao(pacoteId: any, pacote: any) {
    setPacoteConfiguradoId(pacoteId)
    command.pacote.id = pacoteId
    setPacoteInstantaneoSelecionado(null)
    setValue('pacote', command.pacote)
    setActiveStep(1)
    setConfigurado(true)
  }

  function pacotesFiltrados(inteligente = true, cotacao = false) {
    var lista = listaPacotes

    if (!cotacao) {
      lista = lista.filter(
        i =>
          i.tipo !== 'cotacao' && i.desabilitado === listagemPacotesHabilitados
      )
      // if (!!listagemPacotesHabilitados) {
      // } else {
      //   lista = lista.filter(i => i.tipo !== 'cotacao' && i.desabilitado === false)
      // }
      // lista = lista.filter(i => i.tipo !== 'cotacao')
    }

    if (!inteligente) {
      lista = lista.filter(
        i =>
          i.tipo !== 'inteligente' &&
          i.desabilitado === listagemPacotesHabilitados
      )
      // if (!!listagemPacotesHabilitados) {
      // } else {
      //   lista = lista.filter(i => i.tipo !== 'inteligente' && i.desabilitado === false)
      // }
      // lista = lista.filter(i => i.tipo !== 'inteligente')
    }

    if (!filtroTexto) return lista

    return lista.filter(i =>
      removerAcento(i.descricao)
        .toLowerCase()
        .includes(removerAcento(filtroTexto).toLowerCase())
    )
  }

  function handleChangeStep(step: Steps | number) {
    if (typeof step === 'number') {
      if (step === 0) {
        obterPacote(pacoteInstantaneoPai, () => {
          setActiveStepConfiguracao(Steps.resumo)
          setConfigurado(false)
        })
        return
      }
      setActiveStep(step)
    } else {
      if ((step as Steps) === Steps.membrosEquipe) {
        setActiveStep(1)
        setConfigurado(true)
      }
      setActiveStepConfiguracao(step)
    }
  }

  function selecionarPacote(item: ItemListagemPacoteUnificado) {
    setConfigurado(false)
    setActiveStep(0)
    setActiveStepConfiguracao(Steps.resumo)
    handleItemValue('pacote', item as ItemListagemPacoteUnificado)
  }

  async function handleDesabilitar(item: ItemListagemPacoteUnificado) {
    const cpfMedico = query.get('doc')
    let itemDesabilitado: boolean

    if (item.desabilitado === true) {
      itemDesabilitado = false
    } else {
      itemDesabilitado = true
    }

    const payload = {
      pacoteInteligenteId: item.id,
      cpfMedico: cpfMedico || user.cpf,
      desabilitado: itemDesabilitado
    }
    var novoItem = item
    const result = await Swal.fire({
      title: `<p>Tem certeza que deseja ${
        !!item.desabilitado ? 'habilitar' : 'desabilitar'
      } este pacote?</p>`,
      text: `Os pacotes ${
        !!item.desabilitado ? 'habilitados' : 'desabilitados'
      } podem ser ${
        !!item.desabilitado ? 'desabilitados' : 'habilitados'
      } a qualquer momento.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await desabilitarPacote(payload)
          toast.success(
            `Pacote ${
              !!item.desabilitado ? 'habilitado' : 'desabilitado'
            } com sucesso!`
          )
          novoItem.desabilitado = itemDesabilitado
          atualizarItemListagem(item.id, novoItem)
        } catch (error) {
          console.log('@ERROR: ', error)
          toast.error(
            `Ocorreu um erro ao ${
              !!item.desabilitado ? 'habilitar' : 'desabilitar'
            } o pacote. Tente novamente mais tarde.`
          )
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    })

    if (result.isDismissed) {
      return
    }
  }

  return (
    <>
      {loadingOrcamento && (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      )}
      {(!!command?.pacote || mostrarPacotesCotacao) && (
        <Box mt={2} mb={2} mr={1} className={`${classes.BtnCancel}`}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              // handleItemValue('pacote', null)
              // setMostrarPacotesCotacao(false)
              location.href = urlInicial
            }}
          >
            Cancelar
          </Button>
        </Box>
      )}

      <Box
        hidden={loadingOrcamento}
        component="main"
        className={`fadeInTransition ${classes.container}`}
        // style={{ wordBreak: 'break-all' }}
      >
        <CssBaseline />
        {/* command: {JSON.stringify(command.valorEquipeAnestesia)}
        command: {JSON.stringify(command.equipeAnestesia)} */}
        {/* pacoteSelecionado: {JSON.stringify(pacoteInstantaneoSelecionado)} */}
        {/* pacoteSelecionado: {JSON.stringify(pacoteSelecionado)} */}
        {/* <br /> */}
        {/* pacote: {JSON.stringify(command.pacote)} */}
        {/* <br /> */}
        {/* erros: {JSON.stringify(errors)} */}
        {/* <br /> */}
        {/* comorbidades: {JSON.stringify(listaComorbidades)} */}
        <Grid container>
          <UBox
            hidden={!!command?.pacote || mostrarPacotesCotacao}
            width="100%"
            p={2}
          >
            <Grid container spacing={2}>
              <S.HeaderContainer item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  placeholder="Pesquise por nome..."
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={filtroTexto}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  onChange={e => {
                    setFiltroTexto(e.target.value)
                  }}
                />
                <Grid item xs={12} sm={12}>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <ButtonGroup
                      size="large"
                      aria-label="small outlined button group"
                      fullWidth
                    >
                      <Button
                        onClick={() => setListagemPacotesHabilitados(false)}
                        color={
                          !listagemPacotesHabilitados ? 'primary' : 'default'
                        }
                        variant={
                          listagemPacotesHabilitados ? 'outlined' : 'contained'
                        }
                        fullWidth
                        style={{ height: 39 }}
                      >
                        <Typography style={{ fontSize: 13, fontWeight: 600 }}>
                          Habilitados
                        </Typography>
                      </Button>
                      <Button
                        onClick={() => setListagemPacotesHabilitados(true)}
                        color={
                          listagemPacotesHabilitados ? 'primary' : 'default'
                        }
                        variant={
                          !listagemPacotesHabilitados ? 'outlined' : 'contained'
                        }
                        fullWidth
                        style={{ height: 39 }}
                      >
                        <Typography style={{ fontSize: 13, fontWeight: 600 }}>
                          Desabilitados
                        </Typography>
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Grid>
              </S.HeaderContainer>
            </Grid>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} sm={6} md={4}></Grid> */}
              {loading && (
                <Grid item xs={12}>
                  <UBox p={5}>
                    <UBox
                      loading={loading}
                      textLoading={'buscando cirurgias'}
                      width="100%"
                    ></UBox>
                  </UBox>
                </Grid>
              )}
              {!loading && pacotesFiltrados(true).length === 0 && (
                <Grid>
                  <UBox p={2}>
                    <Typography variant="body1">
                      Nenhum resultado encontrado para sua pesquisa.
                    </Typography>
                  </UBox>
                </Grid>
              )}

              {!loading &&
                pacotesFiltrados(true).map(item => {
                  return (
                    <Grid item xs={12} md={4} key={item.codigo}>
                      <S.CardContainer
                        variant="outlined"
                        onClick={() => selecionarPacote(item)}
                      >
                        <S.CardHeaderContainer
                          title={
                            <UBox
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px'
                              }}
                            >
                              <S.BoxContent>
                                <Typography
                                  variant="body1"
                                  className="title"
                                  color="primary"
                                >
                                  {item.descricao.toUpperCase()}
                                </Typography>
                              </S.BoxContent>
                              <div className="msg">
                                <span>clique para acessar</span>
                              </div>
                            </UBox>
                          }
                        />
                        <S.BtnCard>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={e => {
                              e.stopPropagation()
                              handleDesabilitar(item)
                            }}
                          >
                            <div
                              style={{
                                fontSize: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                              }}
                            >
                              {listagemPacotesHabilitados ? (
                                <FaEye size={22} />
                              ) : (
                                <FaEyeSlash size={22} />
                              )}
                              {listagemPacotesHabilitados
                                ? 'Habilitar'
                                : 'Desabilitar'}
                            </div>
                          </Button>
                        </S.BtnCard>
                      </S.CardContainer>
                    </Grid>
                  )
                })}
              {!loading && (
                <Grid item xs={12}>
                  <UBox
                    display={ehXS ? 'block' : 'flex'}
                    alignItems="center"
                    justifyContent="left"
                  >
                    <Typography
                      variant="h6"
                      color="primary"
                      style={{
                        marginRight: ehXS ? 0 : '12px',
                        fontWeight: 500
                      }}
                    >
                      Não encontrou a cirurgia?
                    </Typography>
                    <Button
                      onClick={handleMostrarPacotesCotacao}
                      fullWidth={ehXS}
                    >
                      Solicitar orçamento
                    </Button>
                  </UBox>
                </Grid>
              )}
            </Grid>
          </UBox>
          <UBox
            component={Grid}
            item
            xs={12}
            sm={11}
            render={
              mostrarPacotesCotacao &&
              !command?.pacote &&
              activeStepCotacao === StepsCotacao.resumo
            }
          >
            <Box pt={ehXS ? 0 : 2} p={2}>
              <form className={classes.form} noValidate={true}>
                <UBox>
                  <Container
                    maxWidth={'sm'}
                    className={`fadeInTransition ${classes.stepContent}`}
                    style={{
                      padding: 0,
                      height: autocompleteMobileOpen && ehXS ? '100vh' : 'auto'
                    }}
                  >
                    <Typography variant="h6">
                      {`${
                        query.has('idPacote') ? 'Revise' : 'Escolha'
                      } o pacote de procedimento que será realizado e ajuste-o de acordo com as necessidades do seu paciente.`}
                    </Typography>
                    <Autocomplete
                      key={command?.pacote?.id}
                      erro={errors}
                      name="pacote"
                      label="Pacote de procedimento"
                      loading={loading}
                      closeOnSelect={false}
                      value={command.pacote}
                      options={pacotesFiltrados(false, true)}
                      getOptionLabel={option => `${option.descricao}`}
                      renderOption={(
                        option: ItemListagemPacoteUnificado,
                        state: AutocompleteRenderOptionState
                      ) => {
                        return (
                          <UBox
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              borderBottom: ehXS ? 'none' : '1px solid #eee',
                              width: '100%',
                              height: '100%'
                            }}
                          >
                            {option.tipo === 'inteligente' ? (
                              <AiTwotoneThunderbolt
                                className="icon"
                                style={{
                                  fontSize: '26px',
                                  padding: '4px 0',
                                  color: '#ebb852'
                                }}
                              />
                            ) : (
                              <BiNetworkChart
                                className="icon"
                                style={{
                                  fontSize: '26px',
                                  padding: '4px 0',
                                  color: '#1cc3a5'
                                }}
                              />
                            )}
                            <Typography
                              variant="subtitle1"
                              style={{ marginLeft: '5px' }}
                            >
                              {option.descricao.toUpperCase()}
                              {option.tipo === 'inteligente' && (
                                <div
                                  style={{
                                    textTransform: 'capitalize',
                                    margin: '-12px 0 0 ',
                                    padding: '0'
                                  }}
                                >
                                  <strong>
                                    <small>{option?.hospital}</small>
                                  </strong>
                                </div>
                              )}
                            </Typography>
                          </UBox>
                        )
                      }}
                      onChange={(event, newValue) => {
                        handleItemValue(
                          'pacote',
                          newValue as ItemListagemPacoteUnificado
                        )
                      }}
                      showAddButton={true}
                      onClickAddButton={handleNovoPacote}
                      addButtonProps={{
                        text: 'Não encontrou? clique aqui'
                      }}
                    />
                  </Container>
                </UBox>
              </form>
            </Box>
          </UBox>

          <UBox
            render={!!pacoteSelecionado}
            component={Grid}
            item
            xs={12}
            pt={4}
          >
            <Box className={`fadeInTransition ${classes.resumoContent}`}>
              {activeStepCotacao === StepsCotacao.resumo && (
                <Typography variant="h6" className={`${classes.textInfo}`}>
                  {`${
                    query.has('idPacote') ? 'Revise' : 'Escolha'
                  } o pacote de procedimento que será realizado e ajuste-o de acordo com as necessidades do seu paciente.`}
                </Typography>
              )}
              <FormOrcamento
                isback={() => {
                  location.href = urlInicial
                }}
                fecharForm={() => {}}
                pacoteSelecionado={pacoteSelecionado}
                setPacoteSelecionado={pacote => {
                  setPacoteSelecionado(pacote)
                }}
                activeStep={activeStepCotacao}
                setActiveStep={step => {
                  setActiveStepCotacao(step)
                }}
                orcamentoDetalhado={orcamentoDetalhado}
                medicoCpf={medicoCpf}
                medicoId={medicoSolicitacaoId}
              />
            </Box>
          </UBox>

          <UBox
            render={!!pacoteInstantaneoSelecionado}
            component={Grid}
            item
            xs={12}
          >
            <UBox render={!configurado}>
              <FormConfiguracaoV2
                isback={() => {
                  handleItemValue('pacote', null)
                  setMostrarPacotesCotacao(false)
                }}
                pacote={pacoteInstantaneoSelecionado}
                setPacote={pacote => {
                  setPacoteInstantaneoSelecionado(pacote)
                }}
                activeStep={activeStepConfiguracao}
                setActiveStep={step => {
                  handleChangeStep(step)
                }}
                handleConfiguracao={handleConfiguracao}
                medicoCpf={medicoCpf}
                medicoId={medicoSolicitacaoId}
                stepsFormulario={JSON.parse(JSON.stringify(stepsFormulario))}
              />
            </UBox>
            <UBox render={!!configurado}>
              <FormOrcamentoInteligenteV3
                isback={() => {
                  handleItemValue('pacote', null)
                  setMostrarPacotesCotacao(false)
                }}
                fecharForm={() => {}}
                pacoteSelecionado={pacoteInstantaneoSelecionado}
                setPacoteSelecionado={pacote => {
                  setPacoteInstantaneoSelecionado(pacote)
                }}
                activeStep={activeStep}
                setActiveStep={step => {
                  handleChangeStep(step)
                }}
                // orcamentoDetalhado={orcamentoDetalhado}
                medicoCpf={medicoCpf}
                medicoId={medicoSolicitacaoId}
              />
              {/* )} */}
            </UBox>
          </UBox>
        </Grid>
      </Box>
    </>
  )
}
