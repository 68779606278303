import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { obterOrcamentoPorId } from 'src/api/solicitacao-orcamento'
import { obterPacotePorId, obterPacotePorIdPaciente } from 'src/api/pacote'
import { IParceiroFuncionario, ObjetoDescricaoGenerico } from 'src/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, FormControl, FormHelperText, Grid } from '@material-ui/core'

import { useForm } from 'react-hook-form'
import SynTipoParceiro from 'src/core/consts/synTipoParceiro'

import DrawerVisualizarDetalhes from './drawerDetalhesAcomodacao'
import yup from 'src/components/yupCustom'
import { onlyNumber, verificarCPF } from 'src/core/helpers/common'
import TextField from 'src/components/ui/TextField'
import Button from 'src/components/ui/Button'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { obterSetores } from 'src/api/dominio'
import { salvarUsuario } from 'src/api/usuarios'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  IAcomodacaoResponse,
  ISalvarAcomodacaoCommand,
  salvarAcomodacao
} from 'src/api/acomodacao'
import CurrencyInput from 'src/components/ui/CurrencyInput'

type Props = {
  item: IAcomodacaoResponse
  open: boolean
  handleOnClose: () => void
  dadosSensiveis?: boolean
}

const contactSchema = yup.object().shape({
  id: yup.string().nullable(),
  descricao: yup.string().required().label('Descrição'),
  valor: yup.number().min(0.1).required().label('Valor'),
  padrao: yup.bool().label('Padrão')
})

function DrawerSalvarAcomodacao({ item, open, handleOnClose }: Props) {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false)

  const edicao = !!item

  const defaultValuesAcomodacao: ISalvarAcomodacaoCommand = {
    id: item?.id || null,
    descricao: item?.descricao || '',
    valor: item?.valor * 100 || 0,
    padrao: item?.padrao || false
  }

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    handleSubmit,
    trigger
  } = useForm<ISalvarAcomodacaoCommand>({
    mode: 'onChange',
    resolver: yupResolver(contactSchema),
    defaultValues: defaultValuesAcomodacao
  })

  var command = watch()

  useEffect(() => {
    register('id')
    register('valor')
    register('padrao')
  }, [register])

  const handleRegisterObjeto = (name, objetos: ObjetoDescricaoGenerico[]) => {
    setValue(name, objetos, { shouldValidate: true })
  }

  const onSubmit = async (dataCommand: ISalvarAcomodacaoCommand) => {
    setLoadingSubmitButton(true)

    let acomodacao: ISalvarAcomodacaoCommand = dataCommand
    acomodacao.valor = dataCommand.valor / 100

    salvarAcomodacao(acomodacao)
      .then(() => {
        Swal.fire({
          title: 'Parabéns!',
          text: 'A acomodação foi salva com sucesso.',
          icon: 'success',
          confirmButtonText: 'Continuar'
        }).then(() => {
          handleOnClose()
          setLoadingSubmitButton(false)
        })
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoadingSubmitButton(false)
      })
  }

  function handleChangeValor(
    key: keyof ISalvarAcomodacaoCommand,
    value: number
  ): void {
    setValue(key, value, { shouldValidate: true })
  }

  return (
    <Drawer
      loading={loading}
      anchor="right"
      open={open}
      titulo="Salvar acomodação"
      handleOnClose={handleOnClose}
    >
      {/* c:{JSON.stringify(item)} */}
      {/* <br /> */}
      {/* c:{JSON.stringify(errors)}
      <br /> */}
      {/* c:{JSON.stringify(setores)}
      <br /> */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%' }}
        noValidate={true}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              erro={errors}
              autoFocus
              name="descricao"
              placeholder="Descrição..."
              label="Descrição"
              inputRef={register}
              control={control}
              value={command.descricao}
              InputProps={{
                autoComplete: 'new-password'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              // className={styles.formControl2}
              fullWidth
              size="medium"
              variant="outlined"
            >
              <CurrencyInput
                fullWidth
                erro={errors}
                variant="outlined"
                // size="small"
                // margin="normal"
                name="valor"
                label="Valor da diária"
                hiddenLabel
                control={control}
                type="tel"
                InputLabelProps={{
                  shrink: true
                }}
                onValueChange={value => handleChangeValor('valor', value)}
                value={command?.valor || 0}
              />
              <FormHelperText error={!!errors?.valor}>
                {errors?.valor?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                loading={loadingSubmitButton}
              >
                Salvar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  )
}
export default DrawerSalvarAcomodacao
