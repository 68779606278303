import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { useEffect, useState } from 'react'

import { GrNext, GrPrevious } from 'react-icons/gr'
import { Button } from 'src/components/ui/Button'
import { useQueryParams } from 'src/hooks/useQuery'

const useStyles = makeStyles(theme => ({
  stepContent: {
    padding: theme.spacing(0, 0, 4, 0),
    minHeight: 400
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  noMarginTop: { padding: '0px 24px 112px 24px !important' },
  [theme.breakpoints.down('sm')]: {
    stepContent: {
      padding: theme.spacing(3, 1.5, 14, 1.5),
      minHeight: '100vh'
    },
    noMarginTop: { padding: '0px 16px 112px 16px !important' },
    buttons: {
      position: 'fixed',
      padding: theme.spacing(2, 3),
      bottom: 0,
      margin: 0,
      left: 0,
      width: '100vw',
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: '#fff',
      zIndex: 10
    }
  }
}))

export const StepContentSyn = ({
  noMarginTop,
  children,
  textoTitulo,
  handleBackToStep,
  handleNext,
  hideBack,
  showFinish,
  disabledButton,
  show,
  loading,
  handleFinish,
  textFinish,
  activeStep,
  lastStep,
  large,
  hideNext
}: any) => {
  const classes = useStyles()
  const deveMostrar = !show && {
    style: { display: 'none' }
  }
  const query = useQueryParams()

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [ehEdicaoHospital, setEhEdicaoHospital] = useState(false)
  const [loadingEditar, setLoadingEditar] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [show])
  useEffect(() => {
    verificarEhEdicaoHospital()
  }, [])

  function verificarEhEdicaoHospital() {
    const verificar = query.get('editarHospital')

    if (verificar === 'true') {
      setEhEdicaoHospital(true)
    }
  }
  const handleSalvar = () => {
    setLoadingEditar(true)
    handleFinish()
  }
  return (
    <Container
      maxWidth={large ? 'md' : 'sm'}
      {...deveMostrar}
      className={`fadeInTransition ${classes.stepContent} ${
        !!noMarginTop ? classes.noMarginTop : ''
      }`}
    >
      {!!textoTitulo && (
        <Typography variant="h6" style={{ marginBottom: 16 }}>
          {textoTitulo}
        </Typography>
      )}
      <Grid>
        <Box pb={showFinish ? 6 : 0}>{children}</Box>
      </Grid>

      <Grid
        container
        className={classes.buttons}
        style={{
          marginTop: 16
        }}
      >
        {!!showFinish ? (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                onClick={handleSalvar}
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                loading={ehEdicaoHospital ? loadingEditar : loading}
                disabled={loading || !!disabledButton}
              >
                {!!textFinish ? textFinish : 'Salvar'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              {!hideBack && (
                <Button
                  type="button"
                  fullWidth
                  variant="text"
                  color="default"
                  onClick={() => handleBackToStep()}
                >
                  Voltar
                </Button>
              )}
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={4}>
              {!hideBack && (
                <>
                  {fullScreen ? (
                    <Button
                      type="button"
                      fullWidth
                      variant="text"
                      color="default"
                      onClick={() => handleBackToStep()}
                    >
                      Voltar
                    </Button>
                  ) : (
                    <Box
                      display="flex"
                      className={classes.cursorPointer}
                      alignItems="center"
                      onClick={() => handleBackToStep()}
                    >
                      <Box mr={2} component={GrPrevious} /> VOLTAR
                    </Box>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={3} style={{ display: 'flex' }}></Grid>
            <Grid
              item
              xs={5}
              container
              justify="flex-end"
              style={{ display: 'flex' }}
            >
              {!hideNext && !ehEdicaoHospital && (
                <>
                  {fullScreen ? (
                    <Button
                      variant="contained"
                      type="button"
                      fullWidth
                      color="primary"
                      onClick={handleNext}
                    >
                      Continuar
                    </Button>
                  ) : (
                    <Box
                      display="flex"
                      className={classes.cursorPointer}
                      alignItems="center"
                      onClick={handleNext}
                    >
                      CONTINUAR
                      <Box ml={2} component={GrNext} />
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}
