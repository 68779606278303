import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  adicionarLocalAtendimento,
  obterEspecialidades,
  obterLocaisAtendimento,
  obterParceiroComercialSyn
} from 'src/api/dominio'
import { salvarPreCadastro } from 'src/api/medico'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { Button } from 'src/components/ui/Button'
import PasswordField from 'src/components/ui/PasswordField'
import TextField from 'src/components/ui/TextField'
import estados from 'src/core/consts/estados'
import { onlyNumber, verificarCPF } from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import { ObjetoDescricaoGenerico, PreCadastroCommand } from 'src/types'
import Swal from 'sweetalert2'
import { ModalAddGenerico } from '../../components/modals/ModalAddGenerico'
import yup from '../../components/yupCustom'
// import Ubox from 'src/components/ui/Ubox'
import moment from 'moment'
import UBox from 'src/components/ui/Ubox'
import { SynRoutes } from 'src/core/consts/synRoutes'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { useQueryParams } from 'src/hooks/useQuery'
import CadastroDadosBancarios from '../new-cadastro-medico'
import { IFormsCadastro } from '../new-cadastro-medico/interface'

const useStyles = makeStyles(theme => ({
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1, 0, 0)
    // minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  }
}))

const schema = yup.object().shape({
  nome: yup.string().min(3).max(100).required().label('Nome'),
  crm: yup
    .string()
    .max(12)
    .test('cro-valor', 'CRM ou CRO são obrigatórios', function (value) {
      if (!this.parent.cro && !value) return false

      return true
    })
    .label('CRM'),
  ufCRM: yup
    .string()
    .test('crm-valido', 'UF é obrigatório', function (value) {
      if (!!this.parent.crm && (value === '' || !value)) return false

      return true
    })
    .label('UF CRM'),
  cro: yup
    .string()
    .max(12)
    .test('cr,-valor', 'CRM ou CRO são obrigatórios', function (value) {
      if (!this.parent.crm && !value) return false

      return true
    })
    .label('CRO'),
  ufCRO: yup
    .string()
    .test('cro-valido', 'UF é obrigatório', function (value) {
      if (!!this.parent.cro && (value === '' || !value)) return false

      return true
    })
    .label('UF CRO'),
  telefone: yup
    .string()
    .required()
    .test('valida-telefone', 'O telefone é inválido', value => {
      if (onlyNumber(value).length < 10) return false

      return true
    })
    .label('Telefone'),
  email: yup.string().email().required().label('E-mail'),
  especialidades: yup.array().min(1).label('Especialidades'),
  //locaisAtendimento: yup.array().min(1).label('Locais de atendimento'),
  observacoes: yup.string().max(100).label('Observações'),
  senha: yup.string().required('Senha obrigatória').min(6).max(50),
  confirmarSenha: yup
    .string()
    .oneOf([yup.ref('senha'), null], 'Senhas devem ser iguais'),
  cpf: yup
    .string()
    .test('cpf-teste', 'CPF inválido', value => {
      if (!value) return false

      return verificarCPF(onlyNumber(value))
    })
    .required()
    .label('CPF')

  // nomeBanco: yup.string().max(30).required().label('Titular da conta'),
  // codigoBanco: yup.string().required().label('Código do banco'),
  // agencia: yup.string().max(4).required().label('Agência'),
  // digitoAgencia: yup.string().max(1).label('Dígito da agência'),
  // numeroConta: yup.string().max(13).required().label('Número da conta'),
  // digitoConta: yup.string().max(2).required().label('Dígito da conta'),
  // cpfCnpj: yup
  //   .string()
  //   .test('cnpj-teste', 'Documento inválido', value => {
  //     if (!value) return false
  //     const documento = onlyNumber(value)
  //     if (documento?.length > 11) {
  //       return verificarCNPJ(documento)
  //     } else {
  //       return verificarCPF(documento)
  //     }
  //   })
  //   .required()
  //   .label('Documento'),
  // tipoConta: yup.string().label('Tipo de conta')
})

export default function PreCadastroMedico() {
  // const { parceiro }: { parceiro: string } = useParams();
  const query = useQueryParams()
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const ehSM = useMediaQuery(theme.breakpoints.down('sm'))

  const tenant = query.get('t')
  const parceiro = query.get('parceiro')
  const [especialidades, setEspecialidades] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [parceirosComercialSyn, setParceirosComercialSyn] = useState<
    ObjetoDescricaoGenerico[]
  >([])

  const [loadingEspecialidades, setLoadingEspecialidades] = useState<boolean>(
    true
  )
  const [tab, setTab] = React.useState(0)
  const [dadosBancarios, setDadosBancarios] = useState<IFormsCadastro>(
    {} as IFormsCadastro
  )
  const [isDadosBancariosRecebidos, setIsDadosBancariosRecebidos] = useState(
    false
  )

  const [
    loadingParceirosComercialSyn,
    setloadingParceirosComercialSyn
  ] = useState<boolean>(true)
  const [locais, setLocais] = useState<ObjetoDescricaoGenerico[]>([])
  const [loadingLocais, setLoadingLocais] = useState<boolean>(true)

  const [loading, setLoading] = useState<boolean>(false)

  const { signIn, signOut } = useAuth()

  const [showModal, toggleModal] = useState(false)
  const [textoModal, setTextoModal] = useState('')
  const [textoParceiro, setTextoParceiro] = useState('')
  const [tipoConta, setTipoConta] = useState('checking')
  // const [tipoBanco, setTipoBanco] = useState('');
  const [tipoPessoa, setTipoPessoa] = useState('fisica')
  const [pessoaJuridica, setPessoaJuridica] = useState(false)

  useEffect(() => {
    if (tipoPessoa === 'fisica') {
      setPessoaJuridica(false)
    } else {
      setPessoaJuridica(true)
    }
  }, [tipoPessoa])

  const handleItemValue = (name: string, item) => {
    if (item?.id) {
      setValue(name, item.id)
    }
  }

  // function handleTipoConta(e) {
  //   let valueRadio = e.target.value;
  //   setValue('tipoConta', valueRadio, {
  //     shouldValidate: true
  //   });
  // }

  const handleClose = () => {
    toggleModal(false)
  }
  const handleShowModal = (texto: string) => {
    setTextoModal(texto)
    toggleModal(true)
  }

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    handleSubmit,
    trigger
  } = useForm<PreCadastroCommand>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      codigoBanco: '',
      //locaisAtendimento: [],
      especialidades: [],
      parceiros: undefined
    }
  })
  const command = watch()

  useEffect(() => {
    carregarEspecialidades()
    //carregarLocais();
  }, [])
  useEffect(() => {
    if (parceiro?.length > 0) {
      carregarParceiros()
    } else {
      carregarParceiros()
    }
  }, [parceiro])

  useEffect(() => {
    register('ufCRM')
    register('ufCRO')
    register('especialidades')
    register('parceiros')
    //register('locaisAtendimento');
  }, [register])

  const handleRegister = (name, e: React.ChangeEvent<{ value: unknown }>) => {
    setValue(name, e.target.value, { shouldValidate: true })
  }

  const handleRegisterObjeto = (name, objetos: ObjetoDescricaoGenerico[]) => {
    setValue(name, objetos, { shouldValidate: true })
  }
  const handleChange = event => {
    setTextoParceiro(event.target.value)
    if (event.target.value !== 'parceiro') {
      setValue('parceiros', null, { shouldValidate: true })
    }
  }
  const handlePrceiroRegisterObjeto = (
    name,
    objetos: ObjetoDescricaoGenerico
  ) => {
    setValue(name, objetos, { shouldValidate: true })
  }
  const handleNovoLocal = (local: ObjetoDescricaoGenerico) => {
    // carregarLocais();
    // setValue('locaisAtendimento', [...command.locaisAtendimento, local], {
    //   shouldValidate: true
    // });
  }

  const carregarEspecialidades = async () => {
    return obterEspecialidades()
      .then(response => setEspecialidades(response.data))
      .finally(() => {
        setLoadingEspecialidades(false)
      })
  }

  const carregarParceiros = async () => {
    return obterParceiroComercialSyn()
      .then(response => setParceirosComercialSyn(response.data))
      .finally(() => {
        setloadingParceirosComercialSyn(false)
      })
  }

  const carregarLocais = async () => {
    return obterLocaisAtendimento()
      .then(response => setLocais(response.data))
      .finally(() => {
        setLoadingLocais(false)
      })
  }

  const onSubmit = async (data: PreCadastroCommand) => {
    if (parceiro?.length > 0) {
      data.codigoParceiroSyn = parceiro
    } else if (data?.parceiros) {
      data.codigoParceiroSyn = data.parceiros.detalhe
    }

    if (!!textoParceiro) {
      data.comoConheceu = textoParceiro
    }

    if (!!tenant) {
      data.tenantId = tenant
    }
    var dataNascimento = moment(
      dadosBancarios.birthdate,
      'DD/MM/YYYY'
    ).toISOString()

    const payloadDadosBancarios = {
      nomeBanco: dadosBancarios.nomeBanco,
      agencia: dadosBancarios.agencia,
      digitoAgencia: dadosBancarios.digitoAgencia,
      numeroConta: dadosBancarios.numeroConta,
      digitoConta: dadosBancarios.digitoConta,
      codigoBanco: dadosBancarios.codigoBanco,
      tipoConta: dadosBancarios.tipoConta,
      cpfCnpj: dadosBancarios.cpfCnpj,
      ...(!!dadosBancarios.companyName && {
        companyName: dadosBancarios.companyName,
        tradingName: dadosBancarios.nomeBanco,
        companyEmail: dadosBancarios.companyEmail,
        companyDocument: dadosBancarios.companyDocument,
        annualRevenue: dadosBancarios.annualRevenue,
        companyPhoneNumbers: dadosBancarios.companyPhoneNumbers
      }),
      name: dadosBancarios.nomeBanco,
      email: dadosBancarios.email,
      document: dadosBancarios.document,
      birthdate: dataNascimento,
      monthlyIncome: dadosBancarios.monthlyIncome,
      professionalOccupation: dadosBancarios.professionalOccupation,
      phoneNumbers: dadosBancarios.phoneNumbers,
      selfDeclaredLegalRepresentative:
        dadosBancarios.selfDeclaredLegalRepresentative,
      address: {
        cep: dadosBancarios.cep,
        city: dadosBancarios.city,
        complementary: dadosBancarios.complementary,
        neighborhood: dadosBancarios.neighborhood,
        streetNumber: dadosBancarios.streetNumber,
        referencePoint: dadosBancarios.referencePoint,
        state: dadosBancarios.state,
        street: dadosBancarios.street
      },
      ...(!!dadosBancarios.companyCep && {
        mainAddress: {
          cep: dadosBancarios.companyCep,
          city: dadosBancarios.companyCity,
          complementary: dadosBancarios.companyComplementary,
          neighborhood: dadosBancarios.companyNeighborhood,
          streetNumber: dadosBancarios.companyStreetNumber,
          referencePoint: dadosBancarios.companyReferencePoint,
          state: dadosBancarios.companyState,
          street: dadosBancarios.companyStreet
        }
      })
    }
    const dadosPreCadastro: PreCadastroCommand = {
      nome: data.nome,
      crm: data.crm,
      ufCRM: data.ufCRM,
      cro: data.cro,
      ufCRO: data.ufCRO,
      telefone: data.telefone,
      email: data.email,
      especialidades: data.especialidades,
      observacoes: data.observacoes,
      senha: data.senha,
      confirmarSenha: data.confirmarSenha,
      cpf: data.cpf,
      tenantId: tenant,
      dadosBancarios: payloadDadosBancarios
    }

    const result = await trigger()
    if (!result) return
    setLoading(true)
    try {
      await salvarPreCadastro(dadosPreCadastro)
      await signIn({ cpf: dadosPreCadastro.cpf, senha: dadosPreCadastro.senha })

      await Swal.fire({
        title: 'Parabéns!',
        text: 'Cadastro concluído com sucesso',
        icon: 'success',
        confirmButtonText: 'Continuar'
      })

      history.replace(SynRoutes.home)
    } catch (error) {
      showNotificationErrorAPI(error)
    }

    setLoading(false)
  }

  useEffect(() => {
    register('codigoBanco')
  }, [register])

  const handleDadosBancarios = (data: IFormsCadastro) => {
    setDadosBancarios(data)
    setIsDadosBancariosRecebidos(true)
  }

  const handleNext = async () => {
    // toast.info('Passar para o próximo passo')
    let isValid
    switch (tab) {
      case 0:
        isValid = await trigger([
          'nome',
          'telefone',
          'email',
          'especialidades',
          'observacoes',
          'cpf',
          'senha'
        ])
        if (isValid) {
          setTab(prev => prev + 1)
        }
        break
      case 1:
        setTab(prev => prev + 1)
        break
      default:
        break
    }
  }

  const handleBack = () => {
    setTab(prev => prev - 1)
  }

  const renderResumo = () => {
    const dadosFormulario = watch()
    return (
      <>
        <Box mt={2} mb={1}>
          <Typography variant="h6">
            Ótimo! Agora revise as informações com ATENÇÃO antes de salvar
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1">
            Caso precise alterar alguma informação, clique no botão 'Voltar' até
            chegar na etapa desejada.
          </Typography>
        </Box>
        <Box
          style={{
            backgroundColor: '#f3f1f1',
            padding: '16px',
            borderRadius: '8px'
          }}
        >
          <Typography
            style={{ fontSize: 16, fontWeight: 700, color: '#455880' }}
          >
            Dados Pessoais
          </Typography>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Nome:
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosFormulario.nome}
            </Typography>
          </Box>
          {!!dadosFormulario.crm && (
            <Box style={{ display: 'flex', gap: '6px' }}>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  CRM:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosFormulario.crm}
                </Typography>
              </Box>
              /
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  UF CRM:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosFormulario.ufCRM}
                </Typography>
              </Box>
            </Box>
          )}
          {!!dadosFormulario.cro && (
            <Box style={{ display: 'flex', gap: '6px' }}>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  CRO:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosFormulario.cro}
                </Typography>
              </Box>
              /
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  UF CRO:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosFormulario.ufCRO}
                </Typography>
              </Box>
            </Box>
          )}
          <Box style={{ display: 'flex', gap: '60px' }}>
            <Box style={{ display: 'flex', gap: '6px' }}>
              <Typography
                style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
              >
                Telefone:{' '}
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                {dadosFormulario.telefone}
              </Typography>
            </Box>
            <Box style={{ display: 'flex', gap: '6px' }}>
              <Typography
                style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
              >
                Email:{' '}
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                {dadosFormulario.email}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Especialidades:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosFormulario.especialidades.map(e => e.descricao).join(', ')}
            </Typography>
          </Box>
          {!!dadosFormulario.observacoes && (
            <>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Observações:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosFormulario.observacoes}
                </Typography>
              </Box>
            </>
          )}

          <Divider style={{ margin: '16px 0' }} />
          <Typography
            style={{ fontSize: 16, fontWeight: 700, color: '#455880' }}
          >
            Dados Bancarios
          </Typography>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Nome Banco:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.nomeBanco}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: '12px' }}>
            <Box style={{ display: 'flex', gap: '6px' }}>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Agência:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.agencia}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  -
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.digitoAgencia}
                </Typography>
              </Box>
            </Box>
            <Box style={{ display: 'flex', gap: '6px' }}>
              /
              <Box style={{ display: 'flex', gap: '3px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Número Conta:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.numeroConta}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', gap: '3px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  -
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.digitoConta}
                </Typography>
              </Box>
              /
              <Box style={{ display: 'flex', gap: '3px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Conta:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.tipoConta === 'checking'
                    ? 'Corrente'
                    : 'Poupança'}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              CPF/CNPJ:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.cpfCnpj}
            </Typography>
          </Box>
          {/* <Box style={{ display: 'flex', gap: '6px' }}>
          <Typography style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}>Tipo Conta: </Typography>
          <Typography style={{ fontSize: 14 }}>{dadosBancarios.tipoConta === 'checking' ? 'Corrente' : 'Poupança'}</Typography>
        </Box> */}

          {dadosBancarios.companyName && (
            <>
              <Divider style={{ margin: '16px 0' }} />
              <Typography
                style={{ fontSize: 16, fontWeight: 700, color: '#455880' }}
              >
                Dados da Empresa
              </Typography>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Nome:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.companyName}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', gap: '60px' }}>
                <Box style={{ display: 'flex', gap: '6px' }}>
                  <Typography
                    style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                  >
                    Telefone:{' '}
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>
                    {dadosBancarios.companyPhoneNumbers}
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', gap: '6px' }}>
                  <Typography
                    style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                  >
                    Email:{' '}
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>
                    {dadosBancarios.companyEmail}
                  </Typography>
                </Box>
              </Box>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Cnpj:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.companyDocument}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Razão Social:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.tradingName}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Receita:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {formatarDinheiro(dadosBancarios.annualRevenue)}
                </Typography>
              </Box>

              <Divider style={{ margin: '16px 0' }} />
              <Typography
                style={{ fontSize: 16, fontWeight: 700, color: '#455880' }}
              >
                Endereço da Empresa
              </Typography>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Cep:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.companyCep}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Complemento:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.companyComplementary}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Rua:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.companyStreet}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Numero da rua:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.companyStreetNumber}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Bairro:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.companyNeighborhood}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Cidade:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.companyCity}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Estado:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.companyState}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Typography
                  style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
                >
                  Ponto de referencia:{' '}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  {dadosBancarios.companyReferencePoint}
                </Typography>
              </Box>
            </>
          )}

          <Divider style={{ margin: '16px 0' }} />
          <Typography
            style={{ fontSize: 16, fontWeight: 700, color: '#455880' }}
          >
            {dadosBancarios.companyName
              ? 'Dados Representante'
              : 'Dados Recebedor'}
          </Typography>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Nome:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.name}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: '60px' }}>
            <Box style={{ display: 'flex', gap: '6px' }}>
              <Typography
                style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
              >
                Telefone:{' '}
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                {dadosBancarios.phoneNumbers}
              </Typography>
            </Box>
            <Box style={{ display: 'flex', gap: '6px' }}>
              <Typography
                style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
              >
                Email:{' '}
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                {dadosBancarios.email}
              </Typography>
            </Box>
          </Box>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Documento:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.document}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Aniversário:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.birthdate}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Renda:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {formatarDinheiro(dadosBancarios.monthlyIncome)}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Profissão:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.professionalOccupation}
            </Typography>
          </Box>

          <Divider style={{ margin: '16px 0' }} />
          <Typography
            style={{ fontSize: 16, fontWeight: 700, color: '#455880' }}
          >
            {dadosBancarios.companyName
              ? 'Endereço do Representante'
              : 'Endereço'}
          </Typography>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Cep:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.cep}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Complemento:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.complementary}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Rua:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.street}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Numero da rua:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.streetNumber}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Bairro:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.neighborhood}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Cidade:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.city}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Estado:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.state}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', gap: '6px' }}>
            <Typography
              style={{ fontWeight: 500, color: '#455880', fontSize: 14 }}
            >
              Ponto de referencia:{' '}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              {dadosBancarios.referencePoint}
            </Typography>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <Container
      component="main"
      className="fadeInTransition syn-container-out"
      maxWidth="md"
    >
      <CssBaseline />
      <Box className="syn-box-out" style={{ width: ehSM ? '100%' : '800px' }}>
        {/* <Grid container justify="space-between">
          <Grid item xs={6}>
            <Link
              component="button"
              color="secondary"
              onClick={() => history.goBack()}
            >
              <RiArrowLeftLine />
              <Typography
                style={{ marginLeft: 10 }}
                variant="subtitle1"
                component="span"
              >
                Voltar
              </Typography>
            </Link>
          </Grid>
        </Grid> */}
        <Grid item xs={12}>
          <Box textAlign="center">
            <img src="/image/logo.png" alt="logo syn saúde" width="60" />
            <UBox hidden={tab !== 0}>
              <Typography variant="h6" component="h2">
                Seja bem-vindo(a) à Syn!
              </Typography>
            </UBox>
          </Box>
          <UBox hidden={tab !== 0}>
            <Box mt={2}>
              <Typography variant="body2">
                Olá, Dr(a). Estamos felizes em tê-lo(a) como parceiro(a)!
                <br />
                <br />
                Preencha o cadastro abaixo com seus dados para ter acesso a sua
                plataforma Syn.
                <br />A partir de agora fica fácil e prático elaborar seus
                pacotes de procedimentos, solicitar e acompanhar o orçamento de
                forma simples, através de um canal único.
              </Typography>
            </Box>
          </UBox>
        </Grid>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
          noValidate={true}
        >
          {
            //#region Dados Pessoais
          }
          <UBox hidden={tab !== 0}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12}>
                <Typography
                  className={classes.subTitle}
                  color="primary"
                  display="block"
                  variant="caption"
                >
                  Dados pessoais
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  erro={errors}
                  inputRef={register}
                  name="nome"
                  label="Nome completo *"
                  defaultValue=""
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required={true}
                  maskTelCel
                  control={control}
                  erro={errors}
                  name="telefone"
                  label="Telefone"
                  defaultValue=""
                  type="tel"
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  inputRef={register}
                  erro={errors}
                  name="email"
                  label="E-mail"
                  defaultValue=""
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={classes.subTitle}
                  color="primary"
                  display="block"
                  variant="caption"
                >
                  Dados médicos
                </Typography>
              </Grid>
              <Grid container spacing={1} className={classes.noMargin}>
                <Grid item xs={7} sm={4}>
                  <TextField
                    required={true}
                    mask="999999999999"
                    control={control}
                    erro={errors}
                    name="crm"
                    label="CRM"
                    defaultValue=""
                    InputProps={{
                      autoComplete: 'new-password'
                    }}
                  />
                </Grid>
                <Grid item xs={5} md={2}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    {/* <InputLabel id="label-uf">UF CRM</InputLabel> */}
                    <Select
                      error={!!errors.ufCRM}
                      displayEmpty
                      className={classes.selectEmpty}
                      labelId="label-uf"
                      defaultValue=""
                      placeholder="UF CRM"
                      name="ufCRM"
                      inputRef={register}
                      onChange={(e, v) => {
                        handleRegister('ufCRM', e)
                      }}
                    >
                      <MenuItem value="">
                        <em>UF CRM</em>
                      </MenuItem>
                      {estados.map((estado, i) => (
                        <MenuItem key={i} value={estado.Initials}>
                          {estado.Initials}
                        </MenuItem>
                      ))}
                    </Select>

                    <FormHelperText error={!!errors.ufCRM}>
                      {errors.ufCRM?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={7} sm={4}>
                  <TextField
                    required={true}
                    mask="999999999999"
                    control={control}
                    erro={errors}
                    name="cro"
                    label="CRO"
                    defaultValue=""
                    InputProps={{
                      autoComplete: 'new-password'
                    }}
                  />
                </Grid>
                <Grid item xs={5} md={2}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    {/* <InputLabel id="label-uf">UF CRM</InputLabel> */}
                    <Select
                      error={!!errors.ufCRO}
                      displayEmpty
                      className={classes.selectEmpty}
                      defaultValue=""
                      placeholder="UF CRO"
                      name="ufCRO"
                      inputRef={register}
                      onChange={(e, v) => {
                        handleRegister('ufCRO', e)
                      }}
                    >
                      <MenuItem value="">
                        <em>UF CRO</em>
                      </MenuItem>
                      {estados.map((estado, i) => (
                        <MenuItem key={i} value={estado.Initials}>
                          {estado.Initials}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={!!errors.ufCRO}>
                      {errors.ufCRO?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    erro={errors}
                    name="especialidades"
                    label="Especialidades"
                    value={command.especialidades}
                    options={especialidades}
                    closeOnSelect
                    loading={loadingEspecialidades}
                    showCheck
                    showAddButton
                    getOptionLabel={option => option.descricao}
                    onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                      handleRegisterObjeto(
                        'especialidades',
                        newValue as ObjetoDescricaoGenerico[]
                      )
                    }}
                    InputProps={{
                      autoComplete: 'new-password'
                    }}
                  />
                </Grid>
              </Grid>

              {/* <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              erro={errors}
              name="locaisAtendimento"
              label="Locais de atendimento"
              value={command.locaisAtendimento}
              options={locais}
              loading={loadingLocais}
              showCheck
              showAddButton
              getOptionSelected={option =>
                !!command.locaisAtendimento.find(l => l.id === option.id)
              }
              onClickAddButton={texto => handleShowModal(texto)}
              getOptionLabel={option => option.descricao}
              onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                handleRegisterObjeto(
                  'locaisAtendimento',
                  newValue as ObjetoDescricaoGenerico[]
                );
              }}
            />
          </Grid> */}
              <Grid item xs={12} md={12}>
                <TextField
                  erro={errors}
                  multiline={true}
                  rows={3}
                  inputRef={register}
                  name="observacoes"
                  label="Observações"
                  defaultValue=""
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={classes.subTitle}
                  color="primary"
                  display="block"
                  variant="caption"
                >
                  Dados de acesso
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  erro={errors}
                  name="cpf"
                  placeholder="Ex: 999.999.999-99"
                  label="CPF"
                  hiddenLabel
                  control={control}
                  mask="999.999.999-99"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                />
              </Grid>
              <Grid item md={6} />
              <Grid item xs={12} md={6}>
                <PasswordField
                  inputRef={register}
                  erro={errors}
                  name="senha"
                  label="Senha"
                  defaultValue=""
                  InputProps={{
                    autoComplete: 'off'
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PasswordField
                  inputRef={register}
                  erro={errors}
                  name="confirmarSenha"
                  label="Confirme sua senha"
                  defaultValue=""
                  InputProps={{
                    autoComplete: 'off'
                  }}
                />
              </Grid>

              <Grid container spacing={1} className={classes.noMargin}>
                {!parceiro && !tenant && (
                  <Grid item xs={12}>
                    <Typography
                      className={classes.subTitle}
                      color="primary"
                      display="block"
                      variant="caption"
                    >
                      Como você descobriu sobre a Syn?
                    </Typography>
                  </Grid>
                )}
                {!parceiro && !tenant && (
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select value={textoParceiro} onChange={handleChange}>
                        <MenuItem value="selecione" selected>
                          Selecione
                        </MenuItem>
                        <MenuItem value={'midia'}>Redes sociais</MenuItem>
                        <MenuItem value={'parceiro'}>Parceiros Syn</MenuItem>
                        <MenuItem value={'jornal'}>Jornais</MenuItem>
                        <MenuItem value={'revista'}>Revista</MenuItem>
                        <MenuItem value={'comercial'}>Comercial</MenuItem>
                        {/* <MenuItem value={'I2TS'}>I2TS</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {!parceiro && textoParceiro === 'parceiro' && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Autocomplete
                      fullWidth
                      erro={errors}
                      name="parceiros"
                      label="parceiros"
                      value={command.parceiros}
                      options={parceirosComercialSyn}
                      closeOnSelect
                      loading={loadingParceirosComercialSyn}
                      getOptionLabel={option => option.descricao}
                      onChange={(event, newValue) => {
                        handlePrceiroRegisterObjeto(
                          'parceiros',
                          newValue as ObjetoDescricaoGenerico
                        )
                      }}
                      InputProps={{
                        autoComplete: 'new-password'
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </UBox>
          {
            //#endregion
          }
          {
            //#region Dados Bancarios
          }
          <UBox hidden={tab !== 1}>
            <CadastroDadosBancarios
              dadosBancarios={handleDadosBancarios}
              ignorarSubmit={true}
              handleNext={handleNext}
              handleBack={handleBack}
              activeTab={tab}
              verBotao={true}
            />
          </UBox>
          {
            //#endregion
          }

          {
            //#region Resumo
          }
          <UBox hidden={tab !== 2}>{renderResumo()}</UBox>
          {
            //#endregion
          }
          {/* hidden={tab !== 0} */}
          {/* <UBox >
          </UBox> */}
          <Box style={{ display: 'flex', gap: '12px', marginTop: '16px' }}>
            {tab !== 0 && tab !== 1 && (
              <Grid container justify="flex-end" style={{ marginTop: '24px' }}>
                <Grid item xs={12} sm={tab === 2 ? 12 : 12}>
                  <Button
                    disabled={loading}
                    type="button"
                    fullWidth
                    variant="contained"
                    color="default"
                    onClick={handleBack}
                  >
                    Voltar
                  </Button>
                </Grid>
              </Grid>
            )}

            {tab !== 1 && tab !== 2 && (
              <>
                <Grid
                  container
                  justify="flex-end"
                  style={{ marginTop: '24px' }}
                >
                  <Grid item xs={12} sm={6}>
                    <Button
                      disabled={
                        loading ||
                        (tab === 2 && isDadosBancariosRecebidos === true)
                      }
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Continuar
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
            {tab === 2 && (
              <Grid container justify="flex-end" style={{ marginTop: '24px' }}>
                <Grid item xs={12} sm={tab !== 2 ? 12 : 12}>
                  <Button
                    loading={loading}
                    disabled={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </form>
        {showModal && (
          <ModalAddGenerico
            titulo="Adicionar local de atendimento"
            label="Local de atendimento"
            saveSubmit={adicionarLocalAtendimento}
            handleValue={handleNovoLocal}
            descricao={textoModal}
            open={showModal}
            handleClose={handleClose}
          />
        )}
      </Box>
    </Container>
  )
}
