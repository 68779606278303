import React, { useState, useEffect, useContext } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  Divider,
  Grid,
  TextField as TextFieldMU,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Link
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'
import { TextField } from 'src/components/ui/TextField'
import {
  IOrcamentoDetalhadoResponse,
  IPacoteDetalhadoResponse
} from 'src/types'
import { useQueryParams } from 'src/hooks/useQuery'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { Alert } from '@material-ui/lab'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import Button from 'src/components/ui/Button'
import Sticky from 'react-sticky-el'
import { obterOrcamentoParceiroPorId } from 'src/api/solicitacao-orcamento'

import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { salvarOrcamentoOpme } from 'src/api/orcamento'
import HeaderSolicitacao, {
  IPacienteHeader
} from 'src/components/solicitacao/HeaderSolicitacao'
import { RiArrowLeftLine } from 'react-icons/ri'

import { UltimaSolicitacaoContext } from 'src/contexts/UltimaSolicitacao'

const COR_STATUS_OPME = {
  INDISPONIVEL: '#ea3f3f',
  NAO_PREENCHIDO: '#ffeb3b',
  PREENCHIDO: '#8bc34a'
}
interface IOPME {
  id: string
  nome: string
  qtd: number
  obsMedico?: string
  disponivel?: boolean
  valor?: number
  marca?: string
  fabricante?: string
  referencia?: string
  descricao?: string
  anvisa?: string
  obsFornecedor?: string
  error?: IError
}

const useStyles = makeStyles({
  body: {
    marginTop: '-60px'
  },
  content: {
    width: '100%',
    maxWidth: '700px'
  },
  accordion: {
    borderRadius: '10px',
    marginBottom: '20px',
    boxSizing: 'border-box',
    border: '1px solid #f5f6fa',
    boxShadow: '0px 2px 3px 1px rgba(0,0,0,0.2)'
  },
  accordionSummary: {
    paddingRight: '10px'
  },
  accordionDetails: {},
  disponivelQuestion: {
    marginRight: '20px'
  },
  numberFormat: {
    width: '150px'
  },
  inputLabel: {
    marginRight: '20px'
  },
  formGroup: {
    marginBottom: '5px'
  },
  calculoContent: {
    marginTop: '50px',
    background: 'linear-gradient(to bottom, #455880, #486085)',
    borderRadius: '10px',
    color: '#fff',
    minWidth: '420px',
    maxHeight: 'calc(100vh - 30px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginLeft: '20px'
  },
  calculoContentXS: {
    marginTop: '50px',
    background: 'linear-gradient(to bottom, #455880, #486085)',
    borderRadius: '10px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  divider: {
    margin: '10px 0'
  },
  calculoText: {
    fontSize: '10px'
  },
  submitButton: {
    backgroundColor: '#59F0D5',
    marginTop: '15px',
    color: '#455880',
    height: '50px',
    borderRadius: '10px'
  },
  statusOPME: {
    minHeight: '16px',
    minWidth: '16px',
    maxHeight: '16px',
    maxWidth: '16px',
    margin: '0 8px',
    borderRadius: '50%'
    // border: '1px solid #ddd'
  },
  OPMEList: {
    paddingRight: '5px',
    overflowY: 'auto',
    height: '140px',
    '&::-webkit-scrollbar': {
      width: '3px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c4c4c4'
    }
  }
})

interface IError {
  valorMessage?: string
  fabricanteMessage?: string
}

const DadosSolicitacao = () => {
  const styles = useStyles()
  const query = useQueryParams()
  const history = useHistory()

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  const [loading, setLoading] = useState(true)
  const [enviarLoading, setEnviarLoading] = useState(false)
  const [expanded, setExpanded] = useState<string>('')
  const [OPMEs, setOPMEs] = useState<IOPME[]>([])
  const [orcamento, setOrcamento] = useState<IOrcamentoDetalhadoResponse>()
  const [pacote, setPacote] = useState<IPacoteDetalhadoResponse>()
  const [valorTotal, setValorTotal] = useState<number>()
  const [paciente, setPaciente] = useState<IPacienteHeader>()

  const [errors, setErrors] = useState<boolean>()

  const { setUltimaSolicitacaoEnviado } = useContext(UltimaSolicitacaoContext)

  useEffect(() => {
    const id = query.get('id')

    obterOrcamentoParceiroPorId(id)
      .then(response => {
        const dados = response.data
        setOrcamento(dados.solicitacaoOrcamento)
        setPacote(dados.pacoteProcedimento)
        setPaciente({
          nome: dados.solicitacaoOrcamento.pacienteNome,
          dataNascimento: dados.solicitacaoOrcamento.pacienteDataNascimento,
          telefone: dados.solicitacaoOrcamento.pacienteTelefone,
          sexo: dados.solicitacaoOrcamento.pacienteSexo,
          email: dados.solicitacaoOrcamento.pacienteEmail
        })

        let listaOpme: IOPME[] = dados.pacoteProcedimento.opmes.map(o => {
          let novoOPME = {
            id: o.id,
            nome: o.descricao,
            qtd: o.quantidade,
            obsMedico: o.observacao,
            disponivel: true,
            marca: o.marca
          }
          return novoOPME
        })
        setOPMEs(listaOpme)

        setLoading(false)
      })
      .catch(erro => showNotificationErrorAPI(erro))
  }, [])

  useEffect(() => {
    let valor = 0

    OPMEs.forEach(o => {
      if (o.valor) {
        valor += o.valor * o.qtd
      }
    })

    setValorTotal(valor)
  }, [OPMEs])

  /* --------------- handleChangeOPME --------------------- */

  /*
    OBS: os TextFields devem ser multiline pras funções funcionarem.
    Sem tempo pra arrumar
  */

  function handleOpenAccordion(panel: string) {
    return (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : '')
    }
  }

  function handleCheckDisponivel(item: IOPME) {
    return () => {
      const opmes = OPMEs.map(opme => {
        if (opme.id === item.id) {
          if (opme.disponivel) {
            opme.valor = 0
          }
          opme.disponivel = !opme.disponivel
        }

        return opme
      })

      setOPMEs(opmes)
    }
  }

  function handleSetValue(item: IOPME) {
    return values => {
      const opmes = OPMEs.map(opme => {
        if (opme.id === item.id) {
          opme.valor = values.floatValue
        }

        return opme
      })

      setOPMEs(opmes)
    }
  }

  function handleChangeObsFornecedor(item: IOPME) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const opmes = OPMEs.map(opme => {
        if (opme.id === item.id) {
          opme.obsFornecedor = event.target.value
        }

        return opme
      })

      setOPMEs(opmes)
    }
  }

  function handleChangeFabricante(item: IOPME) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const opmes = OPMEs.map(opme => {
        if (opme.id === item.id) {
          opme.fabricante = event.target.value
        }

        return opme
      })

      setOPMEs(opmes)
    }
  }

  function handleChangeReferencia(item: IOPME) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const opmes = OPMEs.map(opme => {
        if (opme.id === item.id) {
          opme.referencia = event.target.value
        }

        return opme
      })

      setOPMEs(opmes)
    }
  }

  function handleChangeDescricao(item: IOPME) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const opmes = OPMEs.map(opme => {
        if (opme.id === item.id) {
          opme.descricao = event.target.value
        }

        return opme
      })

      setOPMEs(opmes)
    }
  }

  function handleChangeAnvisa(item: IOPME) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const opmes = OPMEs.map(opme => {
        if (opme.id === item.id) {
          opme.anvisa = event.target.value
        }

        return opme
      })

      setOPMEs(opmes)
    }
  }

  function setStatusOPME(item: IOPME) {
    if (!item.disponivel) {
      return (
        <div
          className={styles.statusOPME}
          style={{ backgroundColor: COR_STATUS_OPME.INDISPONIVEL }}
        />
      )
    } else {
      if (!item.valor || !item.fabricante) {
        return (
          <div
            className={styles.statusOPME}
            style={{ backgroundColor: COR_STATUS_OPME.NAO_PREENCHIDO }}
          />
        )
      } else {
        return (
          <div
            className={styles.statusOPME}
            style={{ backgroundColor: COR_STATUS_OPME.PREENCHIDO }}
          />
        )
      }
    }
  }

  /* -------------------------------------------------- */

  async function onSubmit() {
    setEnviarLoading(true)
    const opmeValid = OPMEs.map(o => {
      o.error = {}
      let valid = false
      if (!o.disponivel) {
        valid = true
      } else {
        if (o.valor && o.fabricante) {
          valid = true
        } else {
          if (!o.valor) {
            o.error = { ...o.error, valorMessage: 'Valor é obrigatório' }
          }
          if (!o.fabricante) {
            o.error = {
              ...o.error,
              fabricanteMessage: 'Fabricante é obrigatório'
            }
          }
        }
      }

      setErrors(!errors)

      return valid
    })

    const canSubmit = opmeValid.find(o => !o)

    if (canSubmit === false) {
      setEnviarLoading(false)
      Swal.fire({
        title: 'Atenção!',
        text: 'Preencha os OPMEs corretamente ou marque como não disponível',
        icon: 'error',
        confirmButtonText: 'Fechar'
      })
    } else {
      try {
        await salvarOrcamentoOpme({
          solicitacaoOrcamentoId: orcamento.id,
          listaOrcamentoOPME: OPMEs.map(o => {
            return {
              opmeId: o.id,
              disponivel: o.disponivel,
              valorUnitario: o.valor,
              fabricante: o.fabricante,
              referencia: o.referencia,
              codAnvisa: o.anvisa,
              descricao: o.descricao,
              observacao: o.obsFornecedor
            }
          })
        })

        setUltimaSolicitacaoEnviado()

        Swal.fire({
          title: 'Parabéns!',
          text: 'O orçamento foi enviado com sucesso.',
          icon: 'success',
          confirmButtonText: 'Continuar',
          willClose: () => {
            history.push('/')
          }
        })

        setEnviarLoading(false)
      } catch (erro) {
        showNotificationErrorAPI(erro)
        setEnviarLoading(false)
      }
    }
  }

  if (loading) {
    return (
      <Box minHeight="300px" textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  return (
    <Box>
      <Link
        component="button"
        color="primary"
        onClick={() => history.goBack()}
        style={{ marginBottom: '20px' }}
      >
        <RiArrowLeftLine />
        <Typography
          style={{ marginLeft: 10 }}
          variant="subtitle1"
          component="span"
        >
          Voltar
        </Typography>
      </Link>
      <HeaderSolicitacao
        solicitacao
        orcamentoParceiro
        paciente={paciente}
        pacoteProcedimento={pacote}
        medicoNome={orcamento.medicoNome}
        comorbidades={orcamento.comorbidades}
        obsComorbidades={orcamento.observacaoComorbidades}
      />

      <Grid container spacing={3} xs={12} className={styles.body}>
        {/* ------------------- Accordion ----------------- */}
        <Grid item xs={12} sm={ehXS ? 7 : 6}>
          <Box width="100%" className={styles.content}>
            <Box mb={2} mt="40px">
              <Alert severity="info">
                1- Caso não tenha o produto do fabricante solicitado informe com
                o que você tiver disponível.
                <br />
                2- Caso não tenha a quantidade necessária, marque como não
                disponível.
                <br />
                <br />
                Legenda:
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    className={styles.statusOPME}
                    style={{ backgroundColor: COR_STATUS_OPME.INDISPONIVEL }}
                  />
                  <span>OPME não disponível</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    className={styles.statusOPME}
                    style={{
                      backgroundColor: COR_STATUS_OPME.NAO_PREENCHIDO
                    }}
                  />
                  <span>OPME não preenchido</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    className={styles.statusOPME}
                    style={{
                      backgroundColor: COR_STATUS_OPME.PREENCHIDO
                    }}
                  />
                  <span>OPME orçado corretamente</span>
                </div>
              </Alert>
            </Box>
            <Box mb={1}>
              <Typography variant="h6">Lista de OPME solicitados</Typography>
            </Box>

            {OPMEs.map((item, i) => (
              <Accordion
                expanded={expanded === item.id}
                onChange={handleOpenAccordion(item.id)}
                className={styles.accordion}
                key={item.id}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Grid
                    className={styles.accordionSummary}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {setStatusOPME(item)}
                      <Typography variant="body1"> {item.nome}</Typography>
                    </div>
                    <Typography>Qtd: {item.qtd}</Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                  <Grid container direction="column">
                    {/* {(item.marca || item.obsMedico) && (
                      <Alert severity="info" className={styles.formGroup}>
                        <strong>Marca preferida: </strong>
                        {item.marca ? item.marca : 'Sem preferência'}
                        <br />
                        {item.obsMedico && (
                          <>
                            <strong>Observação do médico: </strong>
                            {item.obsMedico}
                          </>
                        )}
                      </Alert>
                    )} */}
                    <Grid container direction="row" alignItems="center">
                      <Grid container direction="row" alignItems="center">
                        <Typography className={styles.disponivelQuestion}>
                          Produto disponível
                          <Checkbox
                            checked={item.disponivel}
                            onChange={handleCheckDisponivel(item)}
                          />
                        </Typography>

                        {item.disponivel && (
                          <Grid item className={styles.formGroup}>
                            <Grid container direction="row" alignItems="center">
                              <Typography className={styles.inputLabel}>
                                Valor unitário*:{' '}
                              </Typography>
                              <div>
                                <NumberFormat
                                  max="5"
                                  onValueChange={handleSetValue(item)}
                                  decimalScale={2}
                                  fixedDecimalScale
                                  allowedDecimalSeparators={[',']}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  isNumericString
                                  prefix="R$ "
                                  className={styles.numberFormat}
                                  customInput={TextFieldMU}
                                  onFocus={e => e.target.select()}
                                  type="tel"
                                  style={
                                    !item.valor
                                      ? item.error?.valorMessage && {
                                          borderBottom: '1px solid red'
                                        }
                                      : null
                                  }
                                />
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    fontSize: '12px',
                                    color: 'red',
                                    marginTop: '5px'
                                  }}
                                >
                                  {!item.valor
                                    ? item.error?.valorMessage
                                    : null}
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      {item.disponivel && (
                        <>
                          <Grid container className={styles.formGroup}>
                            <TextField
                              erro
                              error={
                                !!item.error?.fabricanteMessage &&
                                !item.fabricante
                              }
                              helperText={
                                !item.fabricante
                                  ? item.error?.fabricanteMessage
                                  : null
                              }
                              name="fabricante"
                              label="Fabricante*"
                              placeholder="Informar fabricante"
                              variant="outlined"
                              multiline
                              InputLabelProps={{ shrink: true }}
                              value={item?.fabricante}
                              onChange={handleChangeFabricante(item)}
                              InputProps={{
                                autoComplete: 'new-password'
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="body2">Opcional</Typography>
                            <Divider className={styles.divider} />
                          </Grid>

                          <Grid
                            container
                            spacing={1}
                            className={styles.formGroup}
                          >
                            <Grid item xs={12} sm={6}>
                              <TextField
                                erro
                                name="referencia"
                                label="Referência"
                                placeholder="Informar referência"
                                variant="outlined"
                                multiline
                                InputLabelProps={{ shrink: true }}
                                value={item.referencia}
                                onChange={handleChangeReferencia(item)}
                                InputProps={{
                                  autoComplete: 'new-password'
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                erro
                                name="anvisa"
                                label="Cód. Anvisa"
                                placeholder="Informe o cód. Anvisa"
                                variant="outlined"
                                multiline
                                InputLabelProps={{ shrink: true }}
                                value={item.anvisa}
                                onChange={handleChangeAnvisa(item)}
                                type="tel"
                                InputProps={{
                                  autoComplete: 'new-password'
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid container className={styles.formGroup}>
                            <TextField
                              erro
                              name="descricao"
                              label="Descrição"
                              placeholder="Informe a descrição do produto"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              value={item.descricao}
                              multiline
                              onChange={handleChangeDescricao(item)}
                              InputProps={{
                                autoComplete: 'new-password'
                              }}
                            />
                          </Grid>

                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            className={styles.formGroup}
                          >
                            <TextField
                              erro
                              name="obsFornecedor"
                              label="Observação"
                              multiline
                              rows={3}
                              placeholder="Adicionar observação"
                              variant="outlined"
                              value={item.obsFornecedor}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ maxLength: 500 }}
                              onChange={handleChangeObsFornecedor(item)}
                              InputProps={{
                                autoComplete: 'new-password'
                              }}
                            />
                          </Grid>
                          <Grid container direction="row" justify="flex-end">
                            <Box
                              display="flex"
                              style={{ cursor: 'pointer' }}
                              alignItems="center"
                              color="primary"
                            >
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  const nextItem = OPMEs[i + 1]
                                  if (nextItem) {
                                    setExpanded(nextItem.id)
                                  } else {
                                    setExpanded(undefined)
                                  }
                                }}
                              >
                                OK
                              </Button>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>

        {/* --------------- Cálculo total --------------  */}
        <Grid item xs={12} sm={5}>
          <Sticky>
            <Box
              px={ehXS ? 1 : 5}
              py={2}
              className={ehXS ? styles.calculoContentXS : styles.calculoContent}
              width="100%"
            >
              <Box minHeight="200px">
                {OPMEs.map(opme => {
                  return (
                    opme.disponivel && (
                      <Box key={opme.id} mb={1}>
                        <Grid
                          container
                          xs={12}
                          direction="row"
                          justify="space-between"
                          alignItems="flex-end"
                        >
                          <Typography
                            variant="body2"
                            className={ehXS && styles.calculoText}
                            style={{ maxWidth: '40%' }}
                          >
                            {opme.nome}:
                          </Typography>
                          <Typography
                            variant="body2"
                            className={ehXS && styles.calculoText}
                          >
                            {opme.valor
                              ? formatarDinheiro(opme.valor)
                              : ' R$ 0,00'}{' '}
                            x {opme.qtd} ={' '}
                            {opme.valor
                              ? formatarDinheiro(opme.qtd * opme.valor)
                              : ' R$ 0,00'}
                          </Typography>
                        </Grid>
                        <Divider className={styles.divider} />
                      </Box>
                    )
                  )
                })}
              </Box>
              <Grid
                container
                xs={12}
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Total:</Typography>
                <Typography variant="h6">
                  {formatarDinheiro(valorTotal) || ' R$ 0,00'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  className={styles.submitButton}
                  onClick={() => onSubmit()}
                  loading={enviarLoading}
                  disabled={enviarLoading}
                  secondaryLoading
                >
                  Enviar
                </Button>
              </Grid>
            </Box>
          </Sticky>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DadosSolicitacao
