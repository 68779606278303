import {
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import {
  BusinessCenter,
  ChromeReaderMode,
  Dashboard,
  DynamicFeed,
  LockOpenOutlined,
  People,
  SdCardOutlined
} from '@material-ui/icons'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import { FaBed, FaTasks } from 'react-icons/fa'
import { GoPackage } from 'react-icons/go'
import { useHistory } from 'react-router-dom'
import UBox from 'src/components/ui/Ubox'
import { SynRoutes } from 'src/core/consts/synRoutes'
import useAtividade from 'src/hooks/useAtividade'
import useAuth from 'src/hooks/useAuth'

function menuLateral(props) {
  const history = useHistory()
  const { user } = useAuth()
  const { obterQtdePendente } = useAtividade()
  return (
    <List>
      {/* <ListItem button>
        <ListItemIcon>
          <Assignment />
        </ListItemIcon>
        <ListItemText primary="Orçamentos" />
      </ListItem> */}

      {process.env.REACT_APP_ENV === 'production'
        ? user.cpf === '04317704170'
        : user.cpf === '03721915178' && (
            <ListItem
              button
              onClick={() => history.push(SynRoutes.listagemUsuariosSyn)}
            >
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <ListItemText primary="Equipe Syn" />
            </ListItem>
          )}

      {!!user.verDashboard && (
        <ListItem button onClick={() => history.push(SynRoutes.dashboard)}>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      )}

      <ListItem button onClick={() => history.push(SynRoutes.acomodacoes)}>
        <ListItemIcon>
          <FaBed />
        </ListItemIcon>
        <ListItemText primary="Acomodações" />
      </ListItem>

      <ListItem button onClick={() => history.push(SynRoutes.listagemMedicos)}>
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary="Médicos" />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemPacotesOperacional)}
      >
        <ListItemIcon style={{ fontSize: '1.4rem' }}>
          <GoPackage />
        </ListItemIcon>
        <ListItemText primary="Pacotes" />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemOrcamentosOperacional)}
      >
        <ListItemIcon>
          <AssignmentOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Solicitações" />
      </ListItem>

      <ListItem button onClick={() => history.push(SynRoutes.tarefas)}>
        <ListItemIcon style={{ fontSize: '1.3rem' }}>
          {/* <FcTodoList /> */}
          <FaTasks />
        </ListItemIcon>
        <ListItemText
          primary={
            <UBox>
              Atividades{' '}
              {obterQtdePendente() > 0 && (
                <Chip
                  variant="default"
                  color="secondary"
                  size="small"
                  label={obterQtdePendente()}
                />
              )}
            </UBox>
          }
        />
      </ListItem>
      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemFinanciamentos)}
      >
        <ListItemIcon>
          <ChromeReaderMode />
        </ListItemIcon>
        <ListItemText primary="Financiamentos" />
      </ListItem>
      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemParceirosOperacional)}
      >
        <ListItemIcon>
          <BusinessCenter />
        </ListItemIcon>
        <ListItemText primary="Parceiros" />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(SynRoutes.historicoAtualizacoes)}
      >
        <ListItemIcon>
          <DynamicFeed />
        </ListItemIcon>
        <ListItemText primary="Atualizações" />
      </ListItem>

      <ListItem button onClick={() => history.push(SynRoutes.resetSenha)}>
        <ListItemIcon>
          <LockOpenOutlined />
        </ListItemIcon>
        <ListItemText primary="Resetar senha" />
      </ListItem>

      <ListItem button onClick={() => history.push(SynRoutes.termos)}>
        <ListItemIcon>
          <SdCardOutlined />
        </ListItemIcon>
        <ListItemText primary="Termos" />
      </ListItem>
      {/* <ListItem
        button
        onClick={() => history.push(SynRoutes.formAddUserOperacional)}
      >
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        <ListItemText primary="Novo usuário" />
      </ListItem> */}

      {/* <ListItem button>
        <ListItemIcon>
          <AirlineSeatFlat />
        </ListItemIcon>
        <ListItemText primary="Pacientes" />
      </ListItem>

      <ListItem button>
        <ListItemIcon>
          <Apartment />
        </ListItemIcon>
        <ListItemText primary="Locais de atendimento" />
      </ListItem> */}
    </List>
  )
}

export default menuLateral
