import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Container,
  CssBaseline,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
  TextField as TextFieldMU,
  Radio
} from '@material-ui/core'
import { NumberFormatCustom } from 'src/components/ui/NumberField'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Button from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import UBox from 'src/components/ui/Ubox'
import estados from 'src/core/consts/estados'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import {
  onlyNumber,
  verificarCNPJ,
  verificarCPF
} from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import usePagamento from 'src/hooks/usePagamento'
import { useQueryParams } from 'src/hooks/useQuery'
import { ObterCep } from 'src/api/cep/cep'
import { Alert } from '@material-ui/lab'

import TextField from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import DateInput from 'src/components/ui/DateInput'
import moment from 'moment'
import { obterResumoFinanciamento } from 'src/api/proposta'
import { cadastrarPreFinanciamento } from 'src/api/financiamento'

const useStyles = makeStyles(theme => ({
  formControl2: {
    top: '16px'
  },
  boxFormaPagamento2: {
    display: 'flex',
    margin: '0 -16px',
    gap: '16px',
    flexWrap: 'nowrap',
    '& .item': {
      border: '2px solid #ecf7ea',
      padding: '15px',
      textAlign: 'center',
      borderRadius: '20px',
      color: '#306025',
      background: '#ecf7ea',
      gap: '8px'
    }
  },
  boxFormaPagamento: {
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    margin: '0 -16px',
    gap: '16px',
    flexWrap: 'nowrap',
    '& .item': {
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      boxSizing: 'border-box',

      // width: '180px',
      // maxWidth: '30%',
      gap: '8px',
      minHeight: '84px',
      // background: '#eee',

      border: '1px solid #999',
      color: '#878787',

      borderRadius: '8px',
      cursor: 'pointer',
      textAlign: 'center',
      '&:hover': {
        // width: 'calc(100% - 4px)',
        // border: `2px solid ${theme.palette.secondary.main}`,
        // color: theme.palette.secondary.main
        // color: '#fff'
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      },
      '&.active, .active:hover': {
        // width: 'calc(100% - 4px)',
        background: theme.palette.primary.main,
        color: '#fff',
        // border: '0px solid #333'
        // color: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  [theme.breakpoints.down(1401)]: {
    boxFormaPagamento: {
      flexDirection: 'column',
      '& .item': {
        width: '100%'
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    boxPagamento: {
      // ...padrao,
      width: '100%',
      order: 2
    },
    boxFormaPagamento: {
      flexDirection: 'column',
      '& .item': {
        width: '100%'
      }
    }
  }
}))

export class ISimulacaoFinanciamento {
  nome: string
  uf: string
  dataNascimento: string
  valorTotalFinanciamento: number
  renda: number
  nomeParceiro: string
  cpf: string
  solicitacaoOrcamentoId?: string
  propostaId: string
  nomeProcedimento: string
  telefone: string
  localAtendimento?: string
  nomeMedico?: string
  idadePaciente?: number
  valorTotal?: number
}

const simulacaoFinanciamento: ISimulacaoFinanciamento = {
  nome: '',
  nomeProcedimento: '',
  uf: '',
  dataNascimento: '',
  valorTotalFinanciamento: 0,
  renda: 0,
  nomeParceiro: '',
  cpf: '',
  solicitacaoOrcamentoId: '',
  propostaId: '',
  telefone: ''
}

const simulacao = yup.object().shape({
  uf: yup.string().nullable().required().label('UF'),
  nome: yup.string().required().label('Nome completo'),
  dataNascimento: yup
    .string()
    .required()
    .test('nas', 'Por favor escolha uma data válida', value => {
      return moment(value).isBetween('1922-12-31', moment(), 'day')
    })
    .label('Data de nascimento'),
  cpf: yup
    .string()
    .required()
    .test('cpf-teste', 'cpf inválido', value => {
      if (!value) return false

      const documento = onlyNumber(value)

      if (documento.length === 11) {
        return verificarCPF(documento)
      } else if (documento.length === 14) {
        return verificarCNPJ(documento)
      }
      return false
    })
    .label('CPF'),
  telefone: yup
    .string()
    .required()
    .test('valida-telefone', 'O telefone é inválido', value => {
      if (onlyNumber(value).length < 10) return false

      return true
    })
    .label('Telefone'),
  valorTotalFinanciamento: yup
    .string()
    .required()
    .label('Valor do honorário financiamento')
    .test(
      'valorTotalFinanciamento',
      'O valor total não pode ser maior que o R$30.000,00',
      value => {
        if (Number(value) > 30000 || Number(value) === 0) {
          return false
        }
        return true
      }
    ),
  renda: yup
    .string()
    .required()
    .label('Valor da renda')
    .test('renda', 'O valor da renda não pode ser R$0,00', value => {
      if (Number(value) === 0) {
        return false
      }
      return true
    })
})

export default function financiamento() {
  const styles = useStyles()
  const theme = useTheme()
  const query = useQueryParams()
  const history = useHistory()
  const ehSM = useMediaQuery(theme.breakpoints.down('sm'))
  const [propostaId, setPropostaId] = useState('')
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [
    resumoFinanciamento,
    setResumoFinanciamento
  ] = useState<ISimulacaoFinanciamento>()

  const {
    handleSubmit,
    errors,
    register,
    watch,
    control,
    setValue
  } = useForm<ISimulacaoFinanciamento>({
    mode: 'all',
    resolver: yupResolver(simulacao),
    defaultValues: simulacaoFinanciamento
  })
  const command = watch()
  const [loadingDados, setLoadingDados] = useState(false)

  const handleRegister = (name, e: React.ChangeEvent<{ value: unknown }>) => {
    setValue(name, e.target.value, { shouldValidate: true })
  }

  useEffect(() => {
    register('nome')
    register('uf')
    register('valorTotalFinanciamento')
    register('renda')
    register('dataNascimento')
    register('nomeParceiro')
    register('cpf')
    register('solicitacaoOrcamentoId')
    register('propostaId')
    register('nomeProcedimento')
    register('telefone')
  }, [register])

  const handleItemValue = (name: any, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
  }
  const id = query.get('p')
  useEffect(() => {
    //Popular valores vindo da tela anterior
    if (id) {
      setLoading(true)
      obterResumoFinanciamento(id)
        .then(response => {
          const form = response.data
          setLoading(false)
          setResumoFinanciamento(response.data)
          for (const key in form) {
            let value = form[key]
            if (key === 'dataNascimentoPaciente') {
              value = moment(value).format('yyyy-MM-DD')
            }
            handleItemValue(key, value)
          }
        })
        .catch(err => {
          setLoading(false)
          showNotificationErrorAPI(err)
          history.goBack()
        })
    }
    setPropostaId(id)
  }, [id])
  async function submit(form: ISimulacaoFinanciamento) {
    try {
      form.propostaId = id
      setLoading(true)
      await cadastrarPreFinanciamento(form)
      setLoading(false)
      history.replace('/financiamento-sucesso')
    } catch (e) {
      // Swal.fire('Ops...', 'A mensagem é obrigatória', 'warning')
      setLoading(false)
      showNotificationErrorAPI(e)
    }
  }

  return (
    <>
      <Container
        component="main"
        className="fadeInTransition syn-container-out"
        maxWidth="md"
      >
        <CssBaseline />

        <UBox
          render={step === 0}
          component="form"
          onSubmit={handleSubmit(submit)}
          // noValidate={true}
        >
          <Box
            className="syn-box-out"
            style={{ width: ehSM ? '100%' : '800px' }}
          >
            <UBox render={loading}>
              <LoadingGeral />
            </UBox>
            {!loading && (
              <UBox>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <img
                      src="/image/logo.png"
                      alt="logo syn saúde"
                      width="60"
                    />
                    <Typography variant="h6" color="primary">
                      Seja bem-vindo(a) ao pedido de financiamento da Syn Saúde
                    </Typography>
                  </Box>
                </Grid>{' '}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <UBox>
                          <Alert severity="info">
                            <Typography variant="body2" component="span">
                              Aqui você pode fazer o pedido de pré análise de
                              financiamento do seu procedimento.
                              <br />
                            </Typography>
                          </Alert>
                          {/* {JSON.stringify(command)} */}
                        </UBox>
                      </Grid>
                      <Grid item xs={12}>
                        <UBox render={resumoFinanciamento?.cpf}>
                          <Typography variant="h6" color="primary">
                            <b>Dados do Procedimento</b>
                          </Typography>
                          <Divider />
                          {/* {JSON.stringify(command)} */}
                          <Box mt={2}>
                            <Typography color="primary">
                              <b>Nome do procedimento:</b>
                              <span style={{ marginLeft: '12px' }}>
                                {resumoFinanciamento?.nomeProcedimento}
                              </span>
                            </Typography>
                          </Box>
                          <Typography color="primary">
                            <b>Local de atendimento:</b>
                            <span style={{ marginLeft: '12px' }}>
                              {resumoFinanciamento?.localAtendimento}
                            </span>
                          </Typography>
                          <Typography color="primary">
                            <b>Paciente:</b>
                            <span style={{ marginLeft: '12px' }}>
                              {resumoFinanciamento?.nome}
                            </span>
                          </Typography>
                          <Typography color="primary">
                            <b>Documento:</b>
                            <span style={{ marginLeft: '12px' }}>
                              {resumoFinanciamento?.cpf}
                            </span>
                          </Typography>
                          <Typography color="primary">
                            <b>Idade:</b>
                            <span style={{ marginLeft: '12px' }}>
                              {resumoFinanciamento?.idadePaciente} anos
                            </span>
                          </Typography>
                          <Typography color="primary">
                            <b>Médico:</b>
                            <span style={{ marginLeft: '12px' }}>
                              {resumoFinanciamento?.nomeMedico}
                            </span>
                          </Typography>
                          <Typography color="primary">
                            <b>Valor total do procedimento:</b>
                            <span style={{ marginLeft: '12px' }}>
                              {formatarDinheiro(
                                resumoFinanciamento?.valorTotal ?? 0
                              )}
                            </span>
                          </Typography>
                        </UBox>
                      </Grid>
                      <Grid item xs={12}>
                        <UBox>
                          <Typography variant="h6" color="primary">
                            <b style={{ marginRight: '12px' }}>
                              Dados do preponente
                            </b>
                            (Financiador)
                          </Typography>
                          <Divider />
                          {/* {JSON.stringify(command)} */}
                        </UBox>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            size="medium"
                            disabled={loadingDados}
                            erro={errors}
                            // autoFocus
                            value={command.nome}
                            defaultValue={command.nome}
                            // control={control}
                            inputRef={register}
                            inputProps={{ maxLength: 100 }}
                            name="nome"
                            label="Nome completo"
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="medium"
                              disabled={loadingDados}
                              erro={errors}
                              type="tel"
                              mask={'999.999.999-99'}
                              control={control}
                              name="cpf"
                              label="Seu CPF"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <DateInput
                              errors={errors}
                              name="dataNascimento"
                              label="Data de nascimento"
                              value={command.dataNascimento}
                              control={control}
                              handleChange={value =>
                                handleItemValue('dataNascimento', value)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              disabled={loadingDados}
                              size="medium"
                              erro={errors}
                              type="tel"
                              maskTelCel
                              control={control}
                              inputProps={{ maxLength: 100 }}
                              name="telefone"
                              label="Telefone"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl
                              fullWidth
                              size="medium"
                              variant="outlined"
                              className={styles.formControl2}
                            >
                              <Select
                                error={!!errors.uf}
                                displayEmpty
                                className={styles.selectEmpty}
                                labelId="label-uf"
                                defaultValue=""
                                value={command?.uf}
                                placeholder="Sua UF"
                                name="uf"
                                inputRef={register}
                                onChange={(e, v) => {
                                  handleRegister('uf', e)
                                }}
                              >
                                <MenuItem value="">
                                  <em>UF</em>
                                </MenuItem>
                                {estados.map((estado, i) => (
                                  <MenuItem
                                    key={i}
                                    value={`${estado.Initials}`}
                                  >
                                    {estado.Initials}
                                  </MenuItem>
                                ))}
                              </Select>

                              <FormHelperText error={!!errors.uf}>
                                {errors.uf?.message}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <UBox mt={2}>
                          <Typography variant="h6" color="primary">
                            <b>Dados do financiamento</b>
                          </Typography>
                          <Divider />
                        </UBox>
                      </Grid>
                      {!ehSM && <Grid item xs={12} sm={6}></Grid>}
                      <Grid item xs={12}>
                        <div>
                          <Typography variant="subtitle1" color="primary">
                            Valor máximo do financiamento: (
                            <b>{formatarDinheiro(30000)}</b>)
                          </Typography>
                        </div>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextFieldMU
                              data-cy="renda"
                              error={!!errors.renda}
                              helperText={errors.renda?.message}
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              label="Sua renda"
                              value={command.renda}
                              onFocus={e => e.target.select()}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = event.target.value
                                handleItemValue('renda', value)
                              }}
                              InputProps={{
                                inputComponent: NumberFormatCustom as any
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextFieldMU
                              data-cy="valorTotalFinanciamento"
                              error={!!errors.valorTotalFinanciamento}
                              helperText={
                                errors.valorTotalFinanciamento?.message
                              }
                              variant="outlined"
                              fullWidth
                              name="valorTotalFinanciamento"
                              margin="normal"
                              label="Valor do financiamento"
                              value={command.valorTotalFinanciamento}
                              onFocus={e => e.target.select()}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = event.target.value
                                handleItemValue(
                                  'valorTotalFinanciamento',
                                  value
                                )
                              }}
                              InputProps={{
                                inputComponent: NumberFormatCustom as any
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <UBox mt={4} justifyContent="start">
                    <Box textAlign="left">
                      <Button
                        fullWidth
                        loading={loadingDados}
                        size="large"
                        color="primary"
                        type="submit"
                      >
                        Enviar pedido de analise
                      </Button>{' '}
                    </Box>
                  </UBox>{' '}
                </Grid>
              </UBox>
            )}
          </Box>
        </UBox>

        <UBox
          render={step === 1}
          // noValidate={true}
        >
          <Box
            className="syn-box-out"
            style={{ width: ehSM ? '100%' : '800px' }}
          >
            <Grid item xs={12}>
              <Box textAlign="center">
                <img src="/image/logo.png" alt="logo syn saúde" width="60" />
                <Typography variant="h6" component="h2">
                  Seja bem-vindo(a) ao financiamento BV!
                </Typography>
              </Box>
            </Grid>{' '}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <UBox>
                  <Box mt={2} mb={3}>
                    <Typography variant="subtitle1" color="primary">
                      <b>Simulação do financiamento</b>
                    </Typography>
                    <Divider />
                    {/* {JSON.stringify(command)} */}
                  </Box>
                </UBox>
                <Grid item xs={12}>
                  <Grid item xs={12} sm={5}>
                    <b>Valor total</b>
                    <Box mb={3}>
                      <TextFieldMU
                        disabled
                        data-cy="valorTotalFinanciamento"
                        error={!!errors.valorTotalFinanciamento}
                        helperText={errors.valorTotalFinanciamento?.message}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={command.valorTotalFinanciamento}
                        onFocus={e => e.target.select()}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const value = event.target.value
                          handleItemValue('valorTotalFinanciamento', value)
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <UBox mb={3}>
                    <Typography variant="subtitle1" color="primary">
                      <b>Periodo de carência</b>
                    </Typography>
                    1º Parcela prevista para 16/02/2023
                    <Typography variant="subtitle1" color="primary">
                      <Radio checked color="primary" /> 30 dias
                    </Typography>
                  </UBox>
                  <UBox mb={3}></UBox>
                  <UBox mb={3}>
                    <Typography variant="subtitle1" color="primary">
                      <b>Opções de parcelamento</b>
                    </Typography>
                    Condições disponíveis para o cliente:
                  </UBox>
                </Grid>

                <Grid container spacing={2}>
                  <UBox p={2} className={styles.boxFormaPagamento2}>
                    <UBox className={'item'}>
                      <b>Em até 24x</b>
                    </UBox>
                    <UBox className={'item'}>
                      <b>Parcelas de até R$ 8.000,00</b>
                    </UBox>
                    <UBox className={'item'}>
                      <b>Valor até R$ 129.424,00 em 24x</b>
                    </UBox>
                  </UBox>
                </Grid>
                <Grid container spacing={2}>
                  <UBox p={2} className={styles.boxFormaPagamento}>
                    <UBox className={'item'}>
                      <b>Em até 24x</b>
                    </UBox>
                    <UBox className={'item'}>
                      <b>Parcelas de até R$ 8.000,00</b>
                    </UBox>
                    <UBox className={'item'}>
                      <b>Valor até R$ 129.424,00 em 24x</b>
                    </UBox>
                  </UBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid></Grid>
          </Box>
        </UBox>
      </Container>
    </>
  )
}
