import { AxiosResponse } from 'axios'
import api from 'src/services/api'

export interface ISalvarAcomodacaoCommand {
  id?: string
  descricao: string
  valor: number
  padrao: boolean
}

export interface IAcomodacaoResponse {
  id?: string
  descricao: string
  valor: number
  padrao: boolean
  ativo: boolean
}

export const obterTodasAcomodacoes: (
  mostrarDesativados?: boolean
) => Promise<AxiosResponse<IAcomodacaoResponse[]>> = (
  mostrarDesativados = false
) => {
  return api.get(`/acomodacoes?mostrarDesativados=${mostrarDesativados}`)
}

export const obterAcomodacaoPorId: (
  id: string
) => Promise<AxiosResponse<IAcomodacaoResponse>> = id => {
  return api.get(`/acomodacoes/${id}`)
}

export const desativarAcomodacao: (
  id: string
) => Promise<AxiosResponse<string>> = id => {
  return api.delete(`/acomodacoes/${id}`)
}

export const ativarAcomodacao: (
  id: string
) => Promise<AxiosResponse<string>> = id => {
  return api.put(`/acomodacoes/${id}/ativar`)
}

export const marcarAcomodacaoPadrao: (
  id: string
) => Promise<AxiosResponse<string>> = id => {
  return api.put(`/acomodacoes/${id}/definir-padrao`)
}

export const salvarAcomodacao: (
  command: ISalvarAcomodacaoCommand
) => Promise<AxiosResponse<string>> = command => {
  return api.post('/acomodacoes', command)
}
