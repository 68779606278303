import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useContext, useEffect, useState } from 'react'
import SynLayout from 'src/components/ui/SynLayout'
import { SynPerfis } from 'src/core/consts/synPerfis'
import {
  obterIniciais,
  primeiroNome
} from 'src/core/helpers/common'
import useAuth from 'src/hooks/useAuth'
import DashboardComercial from './comercial'
import MenuLateralComercialB2B from './comercial/menuLateralB2b'
import MenuLateralAdminHospital from './parceiro/hospital/admin/menuLateral'

import { MdHelpOutline } from 'react-icons/md/index'
import { useHistory } from 'react-router-dom'
import { verificarAmbiente } from 'src/api/ambiente'
import UBox from 'src/components/ui/Ubox'
import { UltimaSolicitacaoContext } from 'src/contexts/UltimaSolicitacao'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import SetoresLocalAtendimento from '../../core/consts/setorLocalAtendimento'
import MenuLateralComercialExpansao from './comercial/menuLateralExpansao'
import MenuLateralComercialMed from './comercial/menuLateralMed'
import MenuLateralExterno from './externo/menuLateral'
import MenuLateralFinanceiro from './financeiro/menuLateral'
import DashboardMedico from './medico'
import MenuLateralMedico from './medico/menuLateral'
import DashboardOperacional from './operacional'
import MenuLateralOperacional from './operacional/menuLateral'
import MenuLateralPaciente from './paciente/menuLateral'
import DashBoardParceiro from './parceiro'
import MenuLateralSetorSolicitacoes from './parceiro/hospital/operacional/menuLateralSetorSolicitacao'
import MenuLateralParceiro from './parceiro/menuLateral'
import MenuLateralSecretaria from './secretaria/menuLateralSecretaria'
// import MenuLateralAgendamento from './agendamento/menuLateral'

const SidebarPage: React.FC = ({ children }) => {
  const history = useHistory()
  const { signOut, user, perfilAtual, hubConectado, toggleTutorial } = useAuth()
  const { setUltimaSolicitacao } = useContext(UltimaSolicitacaoContext)
  const [ambiente, setAmbiente] = useState<string>('')

  function obterInformacoesDashboardPorPerfil() {
    if (!user) {
      return {}
    }

    useEffect(() => {
      verificarAmbiente()
        .then(response => setAmbiente(response.data))
        .catch(err => showNotificationErrorAPI(err))
    }, [])

    if (perfilAtual === SynPerfis.OPERACIONAL) {
      return {
        titulo: 'Painel operacional',
        conteudo: <DashboardOperacional />,
        menuLateral: <MenuLateralOperacional />
      }
    } else if (perfilAtual === SynPerfis.MEDICO) {
      return {
        titulo: 'Painel do médico',
        conteudo: <DashboardMedico />,
        menuLateral: <MenuLateralMedico />
      }
    } else if (perfilAtual === SynPerfis.COMERCIAL_B2B) {
      return {
        titulo: 'Painel do comercial',
        conteudo: <DashboardComercial />,
        menuLateral: <MenuLateralComercialB2B />
      }
    } else if (perfilAtual === SynPerfis.COMERCIAL_EXPANSAO) {
      return {
        titulo: 'Painel do comercial',
        conteudo: <DashboardComercial />,
        menuLateral: <MenuLateralComercialExpansao />
      }
    } else if (perfilAtual === SynPerfis.COMERCIAL_MEDICOS) {
      return {
        titulo: 'Painel do comercial',
        conteudo: <DashboardComercial />,
        menuLateral: <MenuLateralComercialMed />
      }
    } else if (
      perfilAtual === SynPerfis.PARCEIRO ||
      perfilAtual === SynPerfis.COTACAO_HOSPITAL ||
      perfilAtual === SynPerfis.TESOURARIA_HOSPITAL
    ) {
      if (user.setorFuncionario === SetoresLocalAtendimento.SOLICITACOES.Id) {
        return {
          titulo: 'Painel do parceiro',
          menuLateral: <MenuLateralSetorSolicitacoes />
        }
      } else {
        return {
          titulo: 'Painel do parceiro',
          conteudo: <DashBoardParceiro />,
          menuLateral: <MenuLateralParceiro />
        }
      }
    } else if (perfilAtual === SynPerfis.ADMIN_HOSPITAL || perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL) {
      return {
        titulo: 'Painel do parceiro',
        menuLateral: <MenuLateralAdminHospital />
      }
    } else if (perfilAtual === SynPerfis.AGENDAMENTO) {
      return {
        titulo: 'Painel de agendamento',
        menuLateral: <MenuLateralSetorSolicitacoes />
      }
    } else if (perfilAtual === SynPerfis.PACIENTE) {
      return {
        titulo: 'Painel do paciente',
        menuLateral: <MenuLateralPaciente />
      }
    } else if (perfilAtual === SynPerfis.SECRETARIA) {
      return {
        titulo: 'Painel do assistente',
        menuLateral: <MenuLateralSecretaria />
      }
    } else if (perfilAtual === SynPerfis.PARCEIRO_EXTERNO) {
      return {
        titulo: 'Painel do parceiro externo',
        menuLateral: <MenuLateralExterno />
      }
    } else if (perfilAtual === SynPerfis.FINANCEIRO) {
      return {
        titulo: 'Painel do financeiro',
        menuLateral: <MenuLateralFinanceiro />
      }
    }
  }

  const informacoesDashboard = obterInformacoesDashboardPorPerfil()

  function obterNome() {
    if (perfilAtual === SynPerfis.MEDICO) {
      return `Dr(a). ${primeiroNome(
        user.perfisNome?.find(w => w.idPerfil === perfilAtual)?.nome
      )}`
    }

    return primeiroNome(
      user.perfisNome?.find(w => w.idPerfil === perfilAtual)?.nome
    )
  }
  const menuUsuarioAutenticado = (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {popupState => (
        <React.Fragment>
          <Tooltip
            title={`${
              user.perfisNome?.find(w => w.idPerfil === perfilAtual)?.nome
            }`}
          >
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              style={{
                // color: '#616161',

                fontWeight: 700,
                color: 'rgb(69 88 128)',
                border: 0,
                textTransform: 'inherit'
              }}
              // startIcon={<AccountCircle />}
              // endIcon={<ArrowDropDownIcon />}
              {...bindTrigger(popupState)}
            >
              <UBox
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <UBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  style={{
                    border: '1px solid #eee',
                    width: 60,
                    height: 60,
                    borderRadius: '50%',
                    background: '#eee',
                    fontWeight: 600,
                    fontSize: '1.2rem'
                  }}
                >
                  <span
                    style={{
                      right: '-6px',
                      bottom: '-2px',
                      position: 'absolute',
                      marginRight: '8px',
                      width: '18px',
                      height: '18px',
                      borderRadius: '10px',
                      border: '2px solid #fff',
                      background: hubConectado ? '#6ee77e' : '#d85959'
                    }}
                  ></span>
                  <span>
                    {obterIniciais(
                      user.perfisNome?.find(w => w.idPerfil === perfilAtual)
                        ?.nome
                    )}
                  </span>
                </UBox>
                <UBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                  width="100%"
                  mt={1}
                >
                  <span style={{ position: 'relative' }}>{obterNome()}</span>
                  <ArrowDropDownIcon
                    style={{ position: 'absolute', right: 0 }}
                  />
                </UBox>
              </UBox>
            </Button>
          </Tooltip>
          {/* <UBox position="relative" style={{ padding: '4px 32px' }}>
            <Tooltip
              title={
                hubConectado
                  ? 'Conectado para receber notificações'
                  : 'Desconectado para receber notificações'
              }
            >
              <span
                style={{
                  left: '8px',
                  top: '8px',
                  position: 'absolute',
                  width: '20px',
                  height: '20px',
                  borderRadius: '10px',
                  background: hubConectado ? '#6ee77e' : '#d85959'
                }}
              ></span>
            </Tooltip>
            <span>
              {window.location.host.includes('app.synsaude.com.br')
                ? 'PRODUÇÃO'
                : 'DESENVOLVIMENTO'}
            </span>
          </UBox> */}
          <Menu
            transformOrigin={{
              vertical: -50,
              horizontal: 'center'
            }}
            {...bindMenu(popupState)}
          >
            {/* <MenuItem style={{ width: '200px' }}></MenuItem> */}
            {perfilAtual === SynPerfis.MEDICO && (
              <MenuItem onClick={() => history.push(SynRoutes.formMedico)}>
                Meus dados
              </MenuItem>
            )}
            {user.perfis && user.perfis.length > 1 && (
              <MenuItem
                onClick={() => history.push(SynRoutes.selecionarPerfil)}
              >
                Alterar perfil da sessão
              </MenuItem>
            )}
            {perfilAtual === SynPerfis.PARCEIRO &&
              user.lstSetorFuncionario?.length > 1 && (
                <MenuItem
                  onClick={() =>
                    history.push(SynRoutes.selecionarSetorParceiro)
                  }
                >
                  Alterar setor da sessão
                </MenuItem>
              )}
            <MenuItem
              onClick={() => {
                signOut()
                setUltimaSolicitacao()
              }}
              style={{ minWidth: '200px' }}
            >
              Sair
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )

  const cabecalhoPadrao = (
    <div>
      <UBox
        pl={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 16,
          alignItems: 'center',
          boxSizing: 'content-box',
          height: '64px',
          borderImage: 'linear-gradient(to right, #fff,#ebeff8,#fff) 1 1 100%',
          borderStyle: 'solid',
          borderWidth: '2px',
          borderTop: 0
        }}
      >
        <img
          src="/image/logo-2.png"
          alt="logo syn saúde"
          // width="52"
          height="38"
          style={{
            // margin: '12px auto 24px',
            display: 'block',
            imageRendering: 'auto'
          }}
        />
        <UBox
          textAlign="center"
          mt={1}
          mr={2}
          style={{
            background: '#1cc3a5',
            color: '#fff',
            padding: '2px 8px',
            borderRadius: '12px',
            fontSize: '0.7rem',
            fontWeight: 600
          }}
        >
          {process.env.REACT_APP_VERSION}
        </UBox>
      </UBox>

      {/* <Divider
        style={{ margin: '12px 24px 8px', backgroundColor: 'rgba(0,0,0,0.05)' }}
      /> */}
      <UBox mt={2} display="flex" flexDirection="column" alignItems="center">
        {user && menuUsuarioAutenticado}
      </UBox>
      <UBox
        render={ambiente === 'Development'}
        textAlign="center"
        mt={1}
        mr={2}
        style={{
          background: '#FF8C00',
          color: '#fff',
          padding: '2px 8px',
          borderRadius: '12px',
          fontSize: '0.7rem',
          fontWeight: 600,
          width: '80%',
          margin: '0 auto'
        }}
      >
        AMBIENTE DE TESTES
      </UBox>
      <Divider
        style={{ margin: '12px 24px 8px', backgroundColor: 'rgba(0,0,0,0.05)' }}
      />

      {/* <UBox
        render={perfilAtual === SynPerfis.MEDICO}
        textAlign="center"
        mt={2}
        mr={2}
        style={{
          background: '#52c6d4',
          color: '#fff',
          padding: '8px 8px',
          borderRadius: '12px',
          fontSize: '0.7rem',
          fontWeight: 600,
          width: '140px',
          margin: '0 auto',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onClick={() => toggleTutorial(true)}
      >
        <MdHelpOutline style={{ fontSize: '16px', marginRight: '4px' }} />
        VER TUTORIAL
      </UBox> */}
    </div>
  )

  return (
    <SynLayout
      titulo={informacoesDashboard?.titulo}
      menuLateral={informacoesDashboard?.menuLateral}
      menuLateralCabecalho={cabecalhoPadrao}
      //menuTopo={menuUsuarioAutenticado}
    >
      {/* {informacoesDashboard.conteudo} */}
      {children}
    </SynLayout>
  )
}

export default SidebarPage
