import { Route, Switch } from 'react-router-dom'
import NotFound from 'src/components/pages/NotFound'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import useAuth from 'src/hooks/useAuth'
import AlterarSenha from 'src/pages/auth/alterarSenha'
import ConfigurarAcessoMedico from 'src/pages/auth/configurarAcessoMedico'
import CriarSenhaParceiro from 'src/pages/auth/criarSenhaParceiro'
import DefinirSenha from 'src/pages/auth/definirSenha'
import DefinirSenhaToken from 'src/pages/auth/definirSenhaToken'
import EsqueciSenha from 'src/pages/auth/esqueciSenha'
import LoginPage from 'src/pages/auth/login'
import PoliticaDePrivacidade from 'src/pages/auth/politicaDePrivacidade'
import PoliticaDePrivacidadeTexto from 'src/pages/auth/politicaDePrivacidadeTexto'
import SelecionarPerfil from 'src/pages/auth/selecionarPerfil'
import SelecionarSetorParceiro from 'src/pages/auth/selecionarSetorParceiro'
import TermosDeUso from 'src/pages/auth/termosDeUso'
import TermosDeUsoTexto from 'src/pages/auth/termosDeUsoTexto'
import SidebarPage from 'src/pages/dashboard'
import HistoricoAtualizacoes from 'src/pages/dashboard/historicoAtualizacoes'
import AssistentesMedico from 'src/pages/dashboard/medico/assistentes/listagemAssistentes'
import ConvidarColega from 'src/pages/dashboard/medico/convidar-colega'
import ListagemEquipe from 'src/pages/dashboard/medico/equipe/listagemEquipe'
import LogomarcaMedico from 'src/pages/dashboard/medico/logomarca-medico'
import AutorizarHospitais from 'src/pages/dashboard/medico/pacote/autorizarHospitais'
import { FormPacote } from 'src/pages/dashboard/medico/pacote/formPacote'
import ListagemPacote from 'src/pages/dashboard/medico/pacote/listagem'
import ListagemPacotesSugeridos from 'src/pages/dashboard/medico/pacote/listagemPacotesSugeridos'
import { FormConfiguracao } from 'src/pages/dashboard/medico/pacote/pacoteInteligente/formConfiguracao'
import DetalhesPropostasMedico from 'src/pages/dashboard/medico/proposta/detalhesPropostas'
import { FormOrcamentoUnificado } from 'src/pages/dashboard/medico/solicitacao/formUnificado'
import { FormOrcamentoUnificadoV2 } from 'src/pages/dashboard/medico/solicitacao/formUnificadoV2/formUnificadoV2'
import ListagemOrcamento from 'src/pages/dashboard/medico/solicitacao/listagem'
import SelecionarTipoOrcamento from 'src/pages/dashboard/medico/solicitacao/selecionarTipoOrcamento'
import Dashboard from 'src/pages/dashboard/operacional/dashboard'
import ListagemFinanciamento from 'src/pages/dashboard/operacional/financiamento/listagemFinanciamento'
import ListagemMedicosComercial from 'src/pages/dashboard/operacional/medicos/listagemMedicos'
import AdicionarUsuarioOperacional from 'src/pages/dashboard/operacional/novoUsuario/form'
import FormPacoteInteligente from 'src/pages/dashboard/operacional/pacote/formPacote'
import ListagemPacotesOperacional from 'src/pages/dashboard/operacional/pacote/listagem'
import { FormParceiros } from 'src/pages/dashboard/operacional/parceiros/formParceiro'
import ListagemParceirosOperacional from 'src/pages/dashboard/operacional/parceiros/listagemParceiros'
import ResetSenha from 'src/pages/dashboard/operacional/reset/resetSenha'
import DadosSolicitacaoOperacionalOPME from 'src/pages/dashboard/operacional/solicitacao/dadosSolicitacaoParaProposta'
import DetalhesPropostas from 'src/pages/dashboard/operacional/solicitacao/detalhesPropostas'
import ListagemOrcamentosOperacional from 'src/pages/dashboard/operacional/solicitacao/listagemSolicitacoes'
import ListagemUsuariosSyn from 'src/pages/dashboard/operacional/usuarios/listagemUsuariosSyn'
import AcompanharPagamento from 'src/pages/dashboard/paciente/acompanharPagamento'
import HistoricoPropostaPaciente from 'src/pages/dashboard/paciente/detalhesPropostas'
import Financiamento from 'src/pages/dashboard/paciente/financiamento/financiamento'
import FinanciamentoStep from 'src/pages/dashboard/paciente/financiamento/financiamentoSteps'
import FinanciamentoSucesso from 'src/pages/dashboard/paciente/financiamentoSucesso'
import InstrucoesNF from 'src/pages/dashboard/paciente/instrucoesNF'
import Pagamento from 'src/pages/dashboard/paciente/pagePagamento'
import ResumoPagamento from 'src/pages/dashboard/paciente/resumoPagamento'
import DadosSolicitacaoParceiroHospital from 'src/pages/dashboard/parceiro/hospital/financeiro/dadosSolicitacaoHospital'
import CadastroMedicoHospital from 'src/pages/dashboard/parceiro/hospital/operacional/cadastroMedico'
import FormOrcamentoHospital from 'src/pages/dashboard/parceiro/hospital/operacional/formSolicitacao'
import ListagemMedicosHospital from 'src/pages/dashboard/parceiro/hospital/operacional/listagemMedicos'
import ListagemPacotesHospitalSolicitacoes from 'src/pages/dashboard/parceiro/hospital/operacional/listagemPacotes'
import ListagemSolicitacoesPendencias from 'src/pages/dashboard/parceiro/hospital/operacional/listagemPendencias'
import ListagemOrcamentoHospital from 'src/pages/dashboard/parceiro/hospital/operacional/listagemSolicitacoes'
import ResumoSolicitacaoHospital from 'src/pages/dashboard/parceiro/hospital/tesouraria/resumoSolicitacaoHospital'
import ListagemOrcamentosParceiro from 'src/pages/dashboard/parceiro/listagemSolicitacoes'
import DadosSolicitacaoParceiroOPME from 'src/pages/dashboard/parceiro/opme/dadosSolicitacaoOPME'
import ListagemUsuarios from 'src/pages/dashboard/parceiro/usuarios/listagemUsuarios'
import MedicoAssistente from 'src/pages/dashboard/secretaria/listagemMedico'
import Tarefas from 'src/pages/dashboard/tarefas'
import Termos from 'src/pages/dashboard/termos'
import FinanciamentoB2c from 'src/pages/financiamento/financiamentoB2c'
import PreCadastroMedico from 'src/pages/pre-cadastro-medico'
import { FormMedico } from 'src/pages/pre-cadastro-medico/editarDados'
import CadastroSucesso from 'src/pages/pre-cadastro-medico/sucesso'
import SetoresLocalAtendimento from '../core/consts/setorLocalAtendimento'
import ListagemSolicitacoesPaciente from '../pages/dashboard/paciente/listagemSolicitacoes'
import PrivateRoute from './privateRoute'
import PrepararPropostaHospital from 'src/pages/dashboard/operacional/solicitacao/propostaHospital/prepararPropostaHospital'
import ListagemAcomodacoes from 'src/pages/dashboard/parceiro/acomodacoes/listagemAcomodacoes'

const Routes = () => {
  const { user, perfilAtual, ehTenantSyn } = useAuth()
  return (
    <Switch>
      {/*------------------ Auth selecionar perfil ---------------------- */}
      <Route path={SynRoutes.selecionarPerfil} exact>
        <SelecionarPerfil />
      </Route>

      {/*------------------ Auth selecionar perfil ---------------------- */}
      <Route path={SynRoutes.selecionarSetorParceiro} exact>
        <SelecionarSetorParceiro />
      </Route>

      {/*------------------ Tarefas ---------------------- */}
      <PrivateRoute path={SynRoutes.tarefas} exact>
        <SidebarPage>
          <Tarefas />
        </SidebarPage>
      </PrivateRoute>

      {/*------------------ Dashboard Home ---------------------- */}
      <PrivateRoute path={SynRoutes.home} exact>
        <SidebarPage>
          {(perfilAtual === SynPerfis.OPERACIONAL ||
            perfilAtual === SynPerfis.COMERCIAL_B2B ||
            perfilAtual === SynPerfis.FINANCEIRO ||
            perfilAtual === SynPerfis.COMERCIAL_EXPANSAO ||
            perfilAtual === SynPerfis.PARCEIRO_EXTERNO) && (
            <ListagemOrcamentosOperacional />
          )}

          {!ehTenantSyn && perfilAtual === SynPerfis.MEDICO && (
            <FormOrcamentoUnificadoV2 fecharForm={() => {}} />
          )}

          {!ehTenantSyn && perfilAtual === SynPerfis.SECRETARIA && (
            <MedicoAssistente />
          )}

          {!!ehTenantSyn &&
            (perfilAtual === SynPerfis.MEDICO ||
              perfilAtual === SynPerfis.SECRETARIA) && <ListagemOrcamento />}

          {perfilAtual === SynPerfis.AGENDAMENTO && (
            <ListagemOrcamentoHospital />
          )}

          {(perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL ||
            perfilAtual === SynPerfis.ADMIN_HOSPITAL) && (
            <ListagemOrcamentosOperacional />
          )}

          {perfilAtual === SynPerfis.PARCEIRO &&
            user?.setorFuncionario ===
              SetoresLocalAtendimento.SOLICITACOES.Id && (
              <ListagemMedicosComercial />
            )}

          {((perfilAtual === SynPerfis.PARCEIRO &&
            user?.setorFuncionario !==
              SetoresLocalAtendimento.SOLICITACOES.Id) ||
            perfilAtual === SynPerfis.COTACAO_HOSPITAL ||
            perfilAtual === SynPerfis.TESOURARIA_HOSPITAL) && (
            <ListagemOrcamentosParceiro />
          )}
          {perfilAtual === SynPerfis.COMERCIAL_MEDICOS && (
            <ListagemMedicosComercial />
          )}
          {perfilAtual === SynPerfis.PACIENTE && (
            <ListagemSolicitacoesPaciente />
          )}
        </SidebarPage>
      </PrivateRoute>

      {/*------------------ Dashboard Medico ------------------ */}
      <PrivateRoute path={SynRoutes.selecionarTipoOrcamento} exact>
        <SidebarPage>
          <SelecionarTipoOrcamento />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.formPacote} exact>
        <SidebarPage>
          <FormPacote />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.formConfiguracaoPacoteInteligente} exact>
        <SidebarPage>
          <FormConfiguracao />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.formOrcamentoUnificado} exact>
        <SidebarPage>
          <FormOrcamentoUnificado fecharForm={() => {}} />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.formOrcamentoUnificado2} exact>
        <SidebarPage>
          <FormOrcamentoUnificadoV2 fecharForm={() => {}} />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.listagemPacotesSugeridos} exact>
        <SidebarPage>
          <ListagemPacotesSugeridos />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.listagemPacotes} exact>
        <SidebarPage>
          <ListagemPacote />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.listagemEquipe} exact>
        <SidebarPage>
          <ListagemEquipe />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.listagemOrcamentos} exact>
        <SidebarPage>
          <ListagemOrcamento />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.convidarColega} exact>
        <SidebarPage>
          <ConvidarColega />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.logomarcaMedico} exact>
        <SidebarPage>
          <LogomarcaMedico />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.detalhesPropostasMedico} exact>
        <SidebarPage>
          <DetalhesPropostasMedico />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.assistentesMedico} exact>
        <SidebarPage>
          <AssistentesMedico />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.autorizarHospitais} exact>
        <SidebarPage>
          <AutorizarHospitais />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.formMedico} exact>
        <SidebarPage>
          <FormMedico fecharForm={() => {}} />
        </SidebarPage>
      </PrivateRoute>

      {/*------------------  Dashboard Operacional ------------------ */}

      <PrivateRoute path={SynRoutes.dashboard} exact>
        <SidebarPage>
          <Dashboard />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.listagemUsuariosSyn} exact>
        <SidebarPage>
          <ListagemUsuariosSyn />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.listagemMedicos} exact>
        <SidebarPage>
          <ListagemMedicosComercial />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.listagemFinanciamentos} exact>
        <SidebarPage>
          <ListagemFinanciamento />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.resetSenha} exact>
        <SidebarPage>
          <ResetSenha />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.listagemPacotesOperacional} exact>
        <SidebarPage>
          <ListagemPacotesOperacional />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.listagemOrcamentosOperacional} exact>
        <SidebarPage>
          <ListagemOrcamentosOperacional />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.formPacoteInteligente} exact>
        <SidebarPage>
          <FormPacoteInteligente />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.listagemParceirosOperacional} exact>
        <SidebarPage>
          <ListagemParceirosOperacional />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.formParceirosOperacional} exact>
        <SidebarPage>
          <FormParceiros />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.formAddUserOperacional} exact>
        <SidebarPage>
          <AdicionarUsuarioOperacional />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.dadosSolicitacaoOperacionalOPME} exact>
        <SidebarPage>
          {perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL ||
          perfilAtual === SynPerfis.ADMIN_HOSPITAL ? (
            <>
              <PrepararPropostaHospital />
            </>
          ) : (
            <DadosSolicitacaoOperacionalOPME />
          )}
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.detalhesPropostas} exact>
        <SidebarPage>
          <DetalhesPropostas />
        </SidebarPage>
      </PrivateRoute>
      {/* ------------------- Dashboard Parceiro --------------------- */}

      <PrivateRoute path={SynRoutes.listagemUsuarios}>
        <SidebarPage>
          <ListagemUsuarios />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.acomodacoes}>
        <SidebarPage>
          <ListagemAcomodacoes />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.listagemSolicitacoesPendencias}>
        <SidebarPage>
          <ListagemSolicitacoesPendencias />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.dadosSolicitacaoParceiroOPME}>
        <SidebarPage>
          <DadosSolicitacaoParceiroOPME />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.dadosSolicitacaoParceiroHospital}>
        <SidebarPage>
          <DadosSolicitacaoParceiroHospital />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.dadosTesourariaParceiroHospital}>
        <SidebarPage>
          <ResumoSolicitacaoHospital />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.formOrcamentoHospital}>
        <SidebarPage>
          <FormOrcamentoHospital />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.listagemPacotesHospital}>
        <SidebarPage>
          <ListagemPacotesHospitalSolicitacoes />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.listagemMedicosHospital}>
        <SidebarPage>
          <ListagemMedicosHospital />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.formCadastroMedico}>
        <SidebarPage>
          <CadastroMedicoHospital close={() => {}} />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.listagemOrcamentosHospital}>
        <SidebarPage>
          <ListagemOrcamentoHospital />
        </SidebarPage>
      </PrivateRoute>

      {/* DASHBOARD PACIENTE */}

      <PrivateRoute path={SynRoutes.detalhesPropostaPaciente}>
        <SidebarPage>
          <HistoricoPropostaPaciente />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.instrucoesNotaFiscal} exact>
        <SidebarPage>
          <InstrucoesNF />
        </SidebarPage>
      </PrivateRoute>

      <Route path={SynRoutes.pagamento} exact>
        <Pagamento />
        {/* <SidebarPage>
        </SidebarPage> */}
      </Route>

      <PrivateRoute path={SynRoutes.resumoPagamento} exact>
        <SidebarPage>
          <ResumoPagamento />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.acompanharPagamento} exact>
        <SidebarPage>
          <AcompanharPagamento />
        </SidebarPage>
      </PrivateRoute>

      {/*------------------  Outros ------------------ */}

      <PrivateRoute path={SynRoutes.historicoAtualizacoes}>
        <SidebarPage>
          <HistoricoAtualizacoes />
        </SidebarPage>
      </PrivateRoute>

      <PrivateRoute path={SynRoutes.termos}>
        <SidebarPage>
          <Termos />
        </SidebarPage>
      </PrivateRoute>
      <PrivateRoute path={SynRoutes.medicoAssistente}>
        <SidebarPage>
          <MedicoAssistente />
        </SidebarPage>
      </PrivateRoute>
      <Route
        path={SynRoutes.configurarAcesso}
        exact
        component={ConfigurarAcessoMedico}
      />
      <Route
        path={SynRoutes.criarSenhaParceiro}
        exact
        component={CriarSenhaParceiro}
      />
      <Route path={SynRoutes.esqueciSenha} exact component={EsqueciSenha} />
      <Route path={SynRoutes.financiamento} exact component={Financiamento} />
      <Route
        path={SynRoutes.financiamentoStep}
        exact
        component={FinanciamentoStep}
      />

      <Route
        path={SynRoutes.financiamentoB2C}
        exact
        component={FinanciamentoB2c}
      />

      <Route
        path={SynRoutes.financiamentoSucesso}
        exact
        component={FinanciamentoSucesso}
      />
      <Route path={SynRoutes.alterarSenha} exact component={AlterarSenha} />
      <Route path={SynRoutes.definirSenha} exact component={DefinirSenha} />
      <Route path={SynRoutes.termosDeUso} exact component={TermosDeUso} />
      <Route
        path={SynRoutes.termosDeUsoTexto}
        exact
        component={TermosDeUsoTexto}
      />
      <Route
        path={SynRoutes.politicaDePrivacidade}
        exact
        component={PoliticaDePrivacidade}
      />

      <Route
        path={SynRoutes.politicaDePrivacidadeTexto}
        exact
        component={PoliticaDePrivacidadeTexto}
      />
      <Route
        path={SynRoutes.definirSenhaToken}
        exact
        component={DefinirSenhaToken}
      />
      <Route path={SynRoutes.signin} exact component={LoginPage} />
      <Route path={SynRoutes.cadastro} exact component={PreCadastroMedico} />
      {/* <Route
        path={SynRoutes.newCadastro}
        exact
        component={NewPreCadastroMedico}
      /> */}
      <Route
        path={SynRoutes.cadastroParceiroComercialSyn}
        exact
        component={PreCadastroMedico}
      />
      <Route
        path={SynRoutes.cadastroSucesso}
        exact
        component={CadastroSucesso}
      />

      <Route path="*">
        <NotFound />
      </Route>
      {/* </Suspense> */}
    </Switch>
  )
}

export default Routes
