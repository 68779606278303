import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Search } from '@material-ui/icons'
import ClearIcon from '@material-ui/icons/Clear'
import queryString from 'query-string'
import { useContext, useEffect, useState } from 'react'
import { FiFilter } from 'react-icons/fi'
import { GoSearch } from 'react-icons/go'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  alterarStatusSolicitacaoOrcamento,
  excluirOrcamento,
  habilitarPagamento,
  liberarEdicao,
  listagemOrcamento,
  listarOperacionais,
  listarTodasTags,
  obterStatusSolicitacaoOrcamento,
  removerTag
} from 'src/api/solicitacao-orcamento/index'
import DrawerAtividades from 'src/components/atividade/drawerAtividades'
import ModalAddTag from 'src/components/modals/ModalAddTag'
import ModalLiberarAcesso from 'src/components/modals/ModalSenhaMaster'
import CardListagem from 'src/components/solicitacao/CardListagem'
import ButtonUI from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { UltimaSolicitacaoContext } from 'src/contexts/UltimaSolicitacao'
import estados from 'src/core/consts/estados'
import StatusProposta from 'src/core/consts/statusProposta'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { formatarData } from 'src/core/formatacoes/formatarData'
import { copyTextToClipboard, primeiroNome } from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import useListagem from 'src/hooks/useListagem'
import {
  ItemListagemSolicitacaoOrcamentoResponse,
  OperacionalResponse,
  TagResponse
} from 'src/types'
import Swal from 'sweetalert2'
import ButtonCostom from '../../../../../src/components/ui/Button'
import TableSolicitacaoOrcamento from '../../../../components/tables/solicitacaoOrcamento'
import useInfiniteScroll from '../../../../hooks/useWindowInfiniteScroll'
import DrawerAlterarHonorarios from './drawerAlterarHonorarios'
import DrawerAlterarStatus from './drawerAlterarStatus'
import DrawerAnexos from './drawerAnexos'
import DrawerCadastrarCpf from './drawerCadastrarCpf'
import DrawerCadastrarDono from './drawerCadastrarDono'
import DrawerHistoricoEditar from './drawerHistoricoEditar'
import DrawerInstrucoesNF from './drawerInstrucoesNF'
import DrawerLogStatus from './drawerLogStatus'
import DrawerVisualizarDetalhes from './drawerVisualizarDetalhes'
import DrawerVisualizarParceiros from './drawerVisualizarParceiros'
import { E } from 'chart.js/dist/chunks/helpers.core'

const useStyles = makeStyles(theme => ({
  layoutFiltro: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px',
    margin: '4px 8px',
    padding: '12px 24px'
  }
}))

const data = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}))

function listagemSolicitacoes() {
  var style = useStyles()
  const { user, perfilAtual } = useAuth()
  const ehComercial =
    perfilAtual === SynPerfis.COMERCIAL_B2B ||
    perfilAtual === SynPerfis.COMERCIAL_MEDICOS ||
    perfilAtual === SynPerfis.COMERCIAL_EXPANSAO

  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [mostrarAlterarStatus, setMostrarAlterarStatus] = useState(false)
  const [mostrarInstrucoesNF, setMostrarInstrucoesNF] = useState(false)
  const [mostrarAtividades, setMostrarAtividades] = useState(false)
  const [mostrarAlterarHonorarios, setMostrarAlterarHonorarios] = useState(
    false
  )
  const [mostrarLogStatus, setMostrarLogStatus] = useState(false)
  const [mostrarParceiros, setMostrarParceiros] = useState(false)
  const [
    itemSelecionado,
    setItemSelecionado
  ] = useState<ItemListagemSolicitacaoOrcamentoResponse>(undefined)

  const history = useHistory()

  const {
    setUltimaSolicitacao,
    ultimaSolicitacaoVista,
    loading: loadingUltimaSolicitacao,
    atualizarSolicitacao
  } = useContext(UltimaSolicitacaoContext)

  const [mostrarModalTag, setMostrarModalTag] = useState(false)
  const [lstOperacionais, setLstOperacionais] = useState<
    OperacionalResponse[]
  >()
  const [loadingOperacionais, setLoadingOperacionais] = useState(false)
  const [abrirModalCadastroDono, setModalCadastroDono] = useState(false)
  const [todasAsTags, setTodasAsTags] = useState<TagResponse[]>()
  const [loadingTagFiltro, setLoadingTagFiltro] = useState(false)
  const [showFiltros, setShowFiltros] = useState(false)
  const [loadTags, setLoadTags] = useState(false)

  const [showTable, setShowTable] = useState(false)
  const [mostrarCadastrarCpf, setMostrarCadastrarCpf] = useState(false)
  const [mostrarAnexo, setMostrarAnexo] = useState(false)
  const [loadingUF, setLoadingUF] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [dataInicio, setDataInicio] = useState('')
  const [dataFim, setDataFim] = useState(null)
  const [dataInicioDirty, setDataInicioDirty] = useState(false)
  const [dataFimDirty, setDataFimDirty] = useState(false)
  const [diasFiltro, setDiasFiltro] = useState('periodo')
  const [tipoBusca, setTipoBusca] = useState('dataCriacao')
  const [visualizarOcorrencias, setVisualizarOcorrencias] = useState({
    show: false,
    id: ''
  })
  const [solicitacaoId, setSolicitacaoId] = useState(undefined)
  const [statusSelecionado, setStatusSelecionado] = useState('')

  const [loadingMudarStatus, setLoadingMudarStatus] = useState<boolean>(false)

  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    carregarListagem,
    filtroStatus,
    loadingFiltroStatus,
    listaStatus,
    handleFiltroStatus,
    handleFiltroTag,
    handleFiltroResponsavel,
    handleFiltroStatusProposta,
    filtroTag,
    filtroResponsavelId,
    filtroStatusProposta,
    handleProxPagina,
    handleCustomPagina,
    pagina,
    allDados,
    qtdAllDados,
    allDadosCarregados,
    filtroGeral,
    setFiltroGeral,
    handleSetTermoPesquisar,
    handleSetTermoPesquisarCodigo,
    handlePesquisarDados,
    atualizarItemListagem,
    handleFiltroData,
    handleLimparFiltros,
    loadingPagina
  } = useListagem(
    9,
    listagemSolicitacoesOrcamento,
    obterStatusSolicitacaoOrcamento
  )

  function listagemSolicitacoesOrcamento(query, primeiraBusca?: boolean) {
    if (!primeiraBusca) setUltimaSolicitacao()
    query['perfilAtual'] = perfilAtual
    query['tipoBusca'] = tipoBusca
    return listagemOrcamento(query)
  }

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search)
    if (queryParams?.codigo) {
      handleSetTermoPesquisarCodigo(queryParams.codigo)
      handlePesquisarDados()
      if (queryParams?.id) {
        setSolicitacaoId(queryParams.id)
        setMostrarLogStatus(true)
      }
    }
  }, [window.location.search])

  useInfiniteScroll({
    loadMoreFunc: () => {
      if (!!ultimaSolicitacaoVista) return
      handleCustomPagina(pagina + 1)
    },
    data: allDados,
    stopLoadingData: allDados.length >= qtdAllDados
  })

  useEffect(() => {
    handleFiltroPorData()
  }, [diasFiltro])

  useEffect(() => {
    if (
      perfilAtual === SynPerfis.OPERACIONAL ||
      perfilAtual === SynPerfis.COMERCIAL_B2B ||
      perfilAtual === SynPerfis.COMERCIAL_EXPANSAO ||
      perfilAtual === SynPerfis.COMERCIAL_MEDICOS
    ) {
      setLoadingOperacionais(true)

      listarOperacionais()
        .then(response => setLstOperacionais(response.data))
        .catch(err => showNotificationErrorAPI(err))
      setLoadingOperacionais(false)
    }
  }, [])
  useEffect(() => {
    atualizarSolicitacao()
  }, [])

  useEffect(() => {
    setLoadingTagFiltro(true)

    listarTodasTags()
      .then(response => setTodasAsTags(response.data))
      .catch(err => {
        console.log('Erro ao carregar tags', err)
        // showNotificationErrorAPI(err)
      })

    setLoadingTagFiltro(false)
  }, [loadTags])

  const handleSetTipoBusca = e => {
    var valor = e.target.value
    setTipoBusca(valor)
  }

  const handleSetDiaFiltro = e => {
    var valor = e.target.value
    setDiasFiltro(valor)
  }

  const limparFiltroData = () => {
    setDiasFiltro('periodo')
    setDataInicio('')
    setDataFim('')
    handleFiltroData('', '')
  }
  const handleFiltroPorData = () => {
    if (diasFiltro === 'periodo') {
      limparFiltroData()
    } else if (diasFiltro === 'hoje') {
      CarregarDataAtualFiltro()
    } else if (diasFiltro === '3Dias') {
      CarregarDataPodDiaFiltro(3)
    } else if (diasFiltro === '7Dias') {
      CarregarDataPodDiaFiltro(7)
    } else if (diasFiltro === '15Dias') {
      CarregarDataPodDiaFiltro(15)
    } else if (diasFiltro === '30Dias') {
      CarregarDataPodDiaFiltro(30)
    }
  }

  const CarregarDataPodDiaFiltro = dias => {
    var dataFiltro = new Date()
    var day = dataFiltro.getDate().toString().padStart(2, '0')
    var year = dataFiltro.getFullYear()
    var month = (dataFiltro.getMonth() + 1).toString().padStart(2, '0')
    setDataFim(year + '-' + month + '-' + day)

    var dataFim = year + '-' + month + '-' + day

    dataFiltro.setDate(dataFiltro.getDate() - dias)
    year = dataFiltro.getFullYear()
    month = (dataFiltro.getMonth() + 1).toString().padStart(2, '0')
    var dayModificado = dataFiltro.getDate().toString().padStart(2, '0')

    setDataInicio(year + '-' + month + '-' + dayModificado)

    handleFiltroData(year + '-' + month + '-' + dayModificado, dataFim)
  }

  const CarregarDataAtualFiltro = () => {
    var dt = new Date()
    var year = dt.getFullYear()
    var month = (dt.getMonth() + 1).toString().padStart(2, '0')
    var day = dt.getDate().toString().padStart(2, '0')

    setDataInicio(year + '-' + month + '-' + day)
    setDataFim(year + '-' + month + '-' + day)
    setDiasFiltro('hoje')

    handleFiltroData(
      year + '-' + month + '-' + day,
      year + '-' + month + '-' + day
    )
  }

  const handleFecharDetalhes = () => {
    setMostrarDetalhe(false)
  }

  const handleVisualizarDetalhes = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setItemSelecionado(item)
    setMostrarDetalhe(true)
  }

  const handleVisualizarParceiros = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setItemSelecionado(item)
    setMostrarParceiros(true)
  }

  const handleAlterarStatus = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setItemSelecionado(item)
    setMostrarAlterarStatus(true)
  }

  const handleInstrucoesNF = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setItemSelecionado(item)
    setMostrarInstrucoesNF(true)
  }

  const handleAtividades = (item: ItemListagemSolicitacaoOrcamentoResponse) => {
    setItemSelecionado(item)
    setMostrarAtividades(true)
  }

  const handleAlterarHonorarios = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setItemSelecionado(item)
    setMostrarAlterarHonorarios(true)
  }

  const handleFecharAlterarHonorarios = (alterou?: boolean) => {
    setMostrarAlterarHonorarios(false)
  }

  const handleFecharAlterarStatus = (
    alterou?: boolean,
    abrirDrawerComprovante?: boolean
  ) => {
    setMostrarAlterarStatus(false)

    if (abrirDrawerComprovante) {
      handleAnexo(itemSelecionado)
    }
    if (!!alterou) carregarListagem({ resetarPaginacao: true })
  }
  const handleModalCadastroDono = (alterou?: boolean) => {
    setModalCadastroDono(false)

    if (!!alterou) carregarListagem({ resetarPaginacao: true })
  }

  const handleFecharAtividades = (alterou?: boolean) => {
    setMostrarAtividades(false)
  }

  const handleLogStatus = (item: ItemListagemSolicitacaoOrcamentoResponse) => {
    setItemSelecionado(item)
    setMostrarLogStatus(true)
  }

  const handleFecharLogStatus = () => {
    setMostrarLogStatus(false)
  }

  const handleFecharParceiros = () => {
    setMostrarParceiros(false)
    carregarListagem({ resetarPaginacao: true })
  }

  function handleCadastrarCpf(item: ItemListagemSolicitacaoOrcamentoResponse) {
    setItemSelecionado(item)
    setMostrarCadastrarCpf(true)
  }

  function handleAnexo(item: ItemListagemSolicitacaoOrcamentoResponse) {
    setItemSelecionado(item)
    setMostrarAnexo(true)
  }

  async function handleHabilitarPagamento(
    item: ItemListagemSolicitacaoOrcamentoResponse,
    habilitado: boolean
  ) {
    try {
      item.loadingPagamentoHabilitado = true
      atualizarItemListagem(item.id, item)
      await habilitarPagamento(item.id, habilitado)

      let novoItem = item
      novoItem.pagamentoHabilitado = habilitado
      novoItem.loadingPagamentoHabilitado = false
      atualizarItemListagem(item.id, novoItem)
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text:
          'Não foi possível concluir a operação. Tente novamente mais tarde ou contate o suporte.'
      })
    }
  }

  function handleAbrirModalDono(
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    if (item.donoSolicitacaoId) {
      setShowModal(true)
      setItemSelecionado(item)
    } else if (!item.donoSolicitacaoId) {
      setItemSelecionado(item)
      setModalCadastroDono(true)
    }
  }
  function dadosModal() {
    return {
      copy: 'A funcionalidade de editar responsável exige autorização master',
      titulo: 'Acesso Restrito',
      open: showModal,
      handleClose: () => setShowModal(false),
      onConfirm: ehConfirm => setModalCadastroDono(ehConfirm)
    }
  }

  async function handleAbrirModalTag(
    solicitacao: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    try {
      await setItemSelecionado(solicitacao)
    } catch (err) {}

    setMostrarModalTag(true)
  }

  function handleFecharModalTag() {
    setMostrarModalTag(false)
    carregarListagem({ resetarPaginacao: true })
  }

  function loadFiltroStatusProposta() {
    var menuItemList = []
    for (const key in StatusProposta) {
      const component = (
        <MenuItem
          key={StatusProposta[key].Id}
          value={StatusProposta[key].Id}
          style={{ color: StatusProposta[key].Color }}
        >
          {StatusProposta[key].Descricao}
        </MenuItem>
      )

      menuItemList.push(component)
    }

    return menuItemList
  }

  function setClassToHighlight(item) {
    if (item?.id === itemSelecionado?.id) return 'highlight'

    return null
  }

  function handleKeyDownPesquisar(e) {
    // carregarListagem({
    //   resetarPaginacao: true,
    //   dataInicio: dataInicio,
    //   dataFim: dataFim
    // })
    if (e.key === 'Enter' && termoPesquisa) {
      handlePesquisarDados()
    }
  }

  function handlePodeExcluir(id: string) {
    setPodeEcluirItem(id)
    setShowModalExcluir(true)
  }

  async function handleExcluir(ehConfirm: boolean) {
    if (!!ehConfirm) {
      var result = await Swal.fire({
        title: 'Deseja excluir a solicitação de orçamento?',
        text: 'Essa ação não poderá ser revertida.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          return await excluirOrcamento(podeEcluirItem)
        },
        allowOutsideClick: () => !Swal.isLoading()
      })

      if (result.isDismissed) {
        return
      }

      if (result.value) {
        Swal.fire(
          'Solicitação de orçamento excluída com sucesso!',
          '',
          'success'
        )

        carregarListagem({ resetarPaginacao: true })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            'Não foi possível excluir a solicitação de orçamento. Tente novamente mais tarde ou contate o suporte.'
        })
      }
    }
  }

  function handleVisualizarOcorrencias(solicitacaoId: string) {
    setVisualizarOcorrencias({
      show: true,
      id: solicitacaoId
    })
  }

  const classes = data()

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const [showModalEditar, setShowModalEditar] = useState(false)
  const [
    podeEditarItem,
    setPodeEditarItem
  ] = useState<ItemListagemSolicitacaoOrcamentoResponse>()
  const [showModalExcluir, setShowModalExcluir] = useState(false)
  const [podeEcluirItem, setPodeEcluirItem] = useState('')
  const [abrirHistoricoEditar, setAbrirHistoricoEditar] = useState(false)

  function handleEditar(item: ItemListagemSolicitacaoOrcamentoResponse) {
    if (!!item.pacoteInteligenteId) return
    if (perfilAtual === SynPerfis.OPERACIONAL) {
      setPodeEditarItem(item)
      setShowModalEditar(true)
    } else {
      history.push(
        `${SynRoutes.formOrcamentoUnificado}?id=${item.id}&medicoSolicitacaoId=${item.medicoId}&descricaoPacote=${item.descricaoPacoteProcedimento}&tipo=cotacao&editarHospital=true`
      )
    }
  }

  function handleNavitagion(podeEditar: boolean) {
    if (podeEditar) {
      history.push(
        `${SynRoutes.formOrcamentoUnificado}?id=${podeEditarItem.id}&medicoSolicitacaoId=${podeEditarItem.medicoId}&descricaoPacote=${podeEditarItem.descricaoPacoteProcedimento}&tipo=cotacao&editarHospital=true`
      )
    }
  }

  function dadosModalEditar() {
    return {
      copy: 'Para editar é necessário autorização master',
      titulo: 'Editar solicitação',
      open: showModalEditar,
      handleClose: () => setShowModalEditar(false),
      onConfirm: ehConfirm => handleNavitagion(ehConfirm)
    }
  }

  function dadosModalEcluir() {
    return {
      copy: 'Para excluir é necessário autorização master',
      titulo: 'Excluir solicitação',
      open: showModalExcluir,
      handleClose: () => setShowModalExcluir(false),
      onConfirm: ehConfirm => handleExcluir(ehConfirm)
    }
  }

  function handleHistoricoEditar(
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    setAbrirHistoricoEditar(true)
    setItemSelecionado(item)
  }

  function copiarLinkPagamento(itemId: string) {
    const link = `${document.location.origin}${SynRoutes.pagamento}?p=${itemId}`
    copyTextToClipboard(link)
    toast.success(<>Link copiado com sucesso</>, {
      autoClose: 2000,
      position: 'top-center'
    })
  }

  async function handleMudarStatus(
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    let swalResult = await Swal.fire({
      title: 'Atenção',
      text: 'Ao liberar para orçamento, deseja notificar os parceiros?',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Fechar',
      showDenyButton: true,
      denyButtonText: 'Não'
    })

    if (swalResult?.isConfirmed) {
      liberarParaOrcamento(true, item)
    }

    if (swalResult?.isDenied) {
      liberarParaOrcamento(false, item)
    }
  }

  async function liberarParaOrcamento(podeNotificar: boolean, item) {
    const payload = {
      id: item.id,
      statusId: '01178ddd-e05e-4c1a-9824-cfd4d4417823',
      observacao: '',
      medicoVisualizaOcorrencia: false,
      parceiroHospitalVisualizaOcorrencia: false,
      parceiroOpmeVisualizaOcorrencia: false,
      notificarParceiros: podeNotificar,
      motivoSelecionadoId: '00000000-0000-0000-0000-000000000000',
      hospitaisVisualizarOcorrencia: [],
      opmeVisualizarOcorrencia: []
    }

    Swal.fire({
      icon: 'info',
      title: 'Por favor, aguarde.',
      text: 'Estamos liberando seu orçamento!',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })

    try {
      await alterarStatusSolicitacaoOrcamento(payload)
      carregarListagem({ resetarPaginacao: true })
      // Fecha a modal de carregamento
      Swal.close()
    } catch (error) {
      // Tratamento de erro
      showNotificationErrorAPI(error)
    }
  }

  async function handleLiberarEdicao(
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    var swalResult = {
      isConfirmed: false
    }

    swalResult = await Swal.fire({
      title: 'Atenção',
      text: 'Deseja confirmar a liberação para edição?',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não'
    })

    if (swalResult?.isConfirmed) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, aguarde.',
        text: 'Estamos liberando solicitação para edição!',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })

      try {
        await liberarEdicao(item.id, perfilAtual)
        carregarListagem({ resetarPaginacao: true })
        Swal.close()
      } catch (error) {
        // Tratamento de erro
        showNotificationErrorAPI(error)
      }
    }
  }

  return (
    <Box>
      {showModalEditar && (
        <ModalLiberarAcesso
          isDelete={true}
          infoText={
            <>Tem certeza que deseja editar a solicitação de orçamento</>
          }
          cancelButtonText="Cancelar"
          confirmButtonText="Editar"
          {...dadosModalEditar()}
        />
      )}

      {showModalExcluir && (
        <ModalLiberarAcesso
          isDelete={true}
          infoText={
            <>Tem certeza que deseja excluir a solicitação de orçamento</>
          }
          cancelButtonText="Cancelar"
          confirmButtonText="Excluir"
          {...dadosModalEcluir()}
        />
      )}

      <Box mb={2}>
        {/* Operacional Syn  / ADM hospital / Operacional Hospital*/}
        <Typography variant="h5">Solicitações de orçamentos</Typography>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              onKeyDown={e => {
                handleKeyDownPesquisar(e)
              }}
              fullWidth
              placeholder="Pesquise pelo nome do pacote, do paciente ou do médico..."
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: loading && (
                  <CircularProgress
                    style={{ fontSize: '20px', width: '20px', height: '20px' }}
                  />
                )
              }}
              value={termoPesquisa}
              onChange={e => {
                if (!e.target.value) {
                  handleSetTermoPesquisar(e as any, true)
                } else {
                  handleSetTermoPesquisar(e as any, false)
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Button
              color="default"
              variant="text"
              onClick={() => setShowFiltros(!showFiltros)}
              startIcon={<FiFilter />}
            >
              {showFiltros ? 'Esconder' : 'Mostrar'} filtros
              {/* <FiFilter style={{ marginLeft: '10px' }} /> */}
              {/* <FilterListIcon style={{ marginLeft: '10px' }} /> */}
            </Button>
          </Grid>

          {showFiltros && (
            <Grid item xs={12} className={style.layoutFiltro}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      // value={filtroStatus}
                      // // variant="outlined"
                      // onChange={handleFiltroStatus}
                      value={statusSelecionado || filtroStatus}
                      onChange={e => {
                        setStatusSelecionado(e.target.value as string)
                      }}
                      disabled={loadingFiltroStatus}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginRight: '16px' }}
                        >
                          {loadingFiltroStatus ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            <></>
                          )}
                        </InputAdornment>
                      }
                    >
                      <MenuItem value=" ">Todos os status</MenuItem>
                      {listaStatus.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.descricao}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      value={filtroTag}
                      onChange={handleFiltroTag}
                      fullWidth
                      /* endAdornment={
            <InputAdornment
              position="end"
              style={{ marginRight: '16px' }}
            >
              {loadingTagFiltro ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <></>
              )}
            </InputAdornment>
          } */
                    >
                      <MenuItem value=" ">Todas as tags</MenuItem>
                      {todasAsTags?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.descricao}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      value={filtroStatusProposta}
                      onChange={handleFiltroStatusProposta}
                      disabled={loadingTagFiltro}
                      fullWidth
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginRight: '16px' }}
                        >
                          {loadingTagFiltro ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            <></>
                          )}
                        </InputAdornment>
                      }
                    >
                      <MenuItem value=" ">Status da proposta</MenuItem>
                      {loadFiltroStatusProposta().map(item => item)}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={2}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      value={filtroGeral?.uf || ' '}
                      fullWidth
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginRight: '16px' }}
                        >
                          {loadingUF ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            <></>
                          )}
                        </InputAdornment>
                      }
                      onChange={e => {
                        //setUFSelecionada(e.target.value)
                        setFiltroGeral({
                          uf: e.target.value,
                          pagina: 1,
                          qtdPorPagina: 9
                        })
                      }}
                    >
                      <MenuItem value=" ">Ufs</MenuItem>
                      {estados?.map((item, i) => (
                        <MenuItem key={i} value={item.Initials}>
                          {item.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {(perfilAtual === SynPerfis.OPERACIONAL ||
                  perfilAtual === SynPerfis.COMERCIAL_B2B ||
                  perfilAtual === SynPerfis.COMERCIAL_EXPANSAO ||
                  perfilAtual === SynPerfis.COMERCIAL_MEDICOS) && (
                  <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        value={filtroResponsavelId}
                        onChange={handleFiltroResponsavel}
                        fullWidth
                      >
                        <MenuItem value=" ">Responsável</MenuItem>
                        {lstOperacionais?.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {primeiroNome(item.descricao)}
                          </MenuItem>
                        ))}
                        <MenuItem value="responsavel">Sem responsável</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>

              <Grid container spacing={2} style={{ marginTop: '12px' }}>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="buscar-por-label" shrink={true}>
                      Buscar por
                    </InputLabel>
                    <Select
                      labelId="buscar-por-label"
                      label="Buscar por"
                      value={tipoBusca}
                      onChange={e => {
                        handleSetTipoBusca(e)
                      }}
                    >
                      <MenuItem value="dataCriacao">Data de criação</MenuItem>
                      <MenuItem value="primeiroPagamento">
                        Primeiro pagamento
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      value={diasFiltro}
                      onChange={e => {
                        handleSetDiaFiltro(e)
                      }}
                    >
                      <MenuItem value="periodo">Período</MenuItem>
                      <MenuItem value="hoje">Hoje</MenuItem>
                      <MenuItem key="2" value="3Dias">
                        Últimos 3 dias
                      </MenuItem>
                      <MenuItem key="3" value="7Dias">
                        Últimos 7 dias
                      </MenuItem>
                      <MenuItem key="4" value="15Dias">
                        Últimos 15 dias
                      </MenuItem>
                      <MenuItem key="5" value="30Dias">
                        Últimos 30 dias
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <TextField
                    id="dataInicio"
                    error={dataInicioDirty && !formatarData(dataInicio)}
                    helperText={
                      dataInicioDirty && !formatarData(dataInicio)
                        ? 'Data inválida'
                        : ''
                    }
                    label="De"
                    type="date"
                    defaultValue={dataInicio}
                    value={dataInicio}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onKeyDown={e => {
                      setDataInicioDirty(true)
                    }}
                    onChange={e => {
                      setDataInicio(e.target.value)
                    }}
                  />

                  <TextField
                    error={dataFimDirty && !formatarData(dataFim)}
                    helperText={
                      dataFimDirty && !formatarData(dataFim)
                        ? 'Data inválida'
                        : ''
                    }
                    id="dataFim"
                    label="Até"
                    type="date"
                    defaultValue={dataFim}
                    value={dataFim}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onKeyDown={e => {
                      setDataFimDirty(true)
                    }}
                    onChange={e => {
                      setDataFim(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  {diasFiltro === 'periodo' && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleFiltroData(dataInicio, dataFim)}
                    >
                      Filtrar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6} sm={3}>
            <ButtonCostom
              loading={loading}
              disabled={loading}
              size="small"
              fullWidth={true}
              variant="contained"
              color="secondary"
              startIcon={<GoSearch />}
              onClick={e => {
                carregarListagem({
                  resetarPaginacao: true,
                  dataInicio: dataInicio,
                  dataFim: dataFim,
                  status: statusSelecionado
                })
              }}
            >
              Pesquisar (Enter)
            </ButtonCostom>
          </Grid>
          <Grid item xs={6} sm={3}>
            <ButtonCostom
              size="small"
              fullWidth={true}
              variant="contained"
              color="primary"
              startIcon={<ClearIcon />}
              onClick={e => {
                limparFiltroData()
                handleLimparFiltros()
              }}
            >
              Limpar filtros
            </ButtonCostom>
          </Grid>
        </Grid>
      </Box>
      {!loading && (
        <Box mt={3} mb={2} textAlign="left" my={3}>
          <Grid container>
            <Grid item xs={12} sm={!ultimaSolicitacaoVista ? 8 : 4}>
              <Typography variant="h6" color="primary">
                {!!ultimaSolicitacaoVista
                  ? 'Última solicitação vista'
                  : qtdAllDados
                  ? qtdAllDados > 1
                    ? `${qtdAllDados} solicitações`
                    : '1 solicitação'
                  : '0 Solicitações'}
              </Typography>
            </Grid>
            {!!ultimaSolicitacaoVista && (
              <Grid item xs={12} sm={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setUltimaSolicitacao()}
                >
                  Mostrar todas as solicitações
                </Button>
              </Grid>
            )}
            {!ehXS && (
              <Grid item xs={12} sm={4}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <ButtonGroup
                    size="small"
                    aria-label="small outlined button group"
                  >
                    <Button
                      onClick={() => setShowTable(false)}
                      color={!showTable ? 'primary' : 'default'}
                      variant={showTable ? 'outlined' : 'contained'}
                    >
                      Card
                    </Button>
                    <Button
                      onClick={() => setShowTable(true)}
                      color={showTable ? 'primary' : 'default'}
                      variant={!showTable ? 'outlined' : 'contained'}
                    >
                      Lista
                    </Button>
                  </ButtonGroup>
                </Box>
                {/* <Typography>
               <div
                 style={{
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'flex-end'
                 }}
               >
                 <span>CARD</span>
                 <AntSwitch
                   checked={showTable}
                   onChange={() => setShowTable(!showTable)}
                 />
                 <span>LISTA</span>
               </div>
             </Typography> */}
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      {nenhumResultado && (
        <div>Nenhum resultado encontrado para sua pesquisa.</div>
      )}

      {allDadosCarregados && !showTable && !loadingPagina && (
        <div className="fadeInTransition">
          <Grid container spacing={2} style={{ border: '1px solid black' }}>
            {ultimaSolicitacaoVista !== undefined && !loadingUltimaSolicitacao && (
              <Grid item xs={12} md={6} lg={4} key={ultimaSolicitacaoVista.id}>
                <CardListagem
                  solicitacao={ultimaSolicitacaoVista}
                  setUltimaSolicitacao={setUltimaSolicitacao}
                  navigate={route => history.push(route)}
                  perfil={ehComercial ? 'comercial' : 'operacional'}
                  setClassToHighlight={setClassToHighlight}
                  handleVisualizarDetalhes={handleVisualizarDetalhes}
                  handleInstrucoesNF={handleInstrucoesNF}
                  carregarListagem={carregarListagem}
                  handleAtividades={handleAtividades}
                  handleAlterarStatus={handleAlterarStatus}
                  handleAbrirModalTag={handleAbrirModalTag}
                  handleCadastrarCpf={handleCadastrarCpf}
                  handleExcluir={handlePodeExcluir}
                  handleLogStatus={handleLogStatus}
                  handleAlterarHonorarios={handleAlterarHonorarios}
                  handleVisualizarParceiros={handleVisualizarParceiros}
                  removerTag={removerTag}
                  handleOperacional={lstOperacionais}
                  handleAbrirModalDono={handleAbrirModalDono}
                  handleHabilitarPagamento={handleHabilitarPagamento}
                  handleAnexo={handleAnexo}
                  handleEditar={handleEditar}
                  handleHistoricoEditar={handleHistoricoEditar}
                  copiarLinkPagamento={copiarLinkPagamento}
                  handleMudarStatus={handleMudarStatus}
                  handleLiberarEdicao={handleLiberarEdicao}
                />
              </Grid>
            )}
            {/* {JSON.stringify(allDados.map(item => item.codigo))} */}
            {allDados.map(item => {
              if (ultimaSolicitacaoVista === undefined) {
                return (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    key={item.id + allDados?.length}
                  >
                    <CardListagem
                      solicitacao={item}
                      setUltimaSolicitacao={setUltimaSolicitacao}
                      navigate={route => history.push(route)}
                      perfil={ehComercial ? 'comercial' : 'operacional'}
                      setClassToHighlight={setClassToHighlight}
                      handleVisualizarDetalhes={handleVisualizarDetalhes}
                      carregarListagem={carregarListagem}
                      handleAtividades={handleAtividades}
                      handleInstrucoesNF={handleInstrucoesNF}
                      handleAlterarStatus={handleAlterarStatus}
                      handleAbrirModalTag={handleAbrirModalTag}
                      handleCadastrarCpf={handleCadastrarCpf}
                      handleExcluir={handlePodeExcluir}
                      handleLogStatus={handleLogStatus}
                      handleVisualizarParceiros={handleVisualizarParceiros}
                      handleAlterarHonorarios={handleAlterarHonorarios}
                      removerTag={removerTag}
                      handleOperacional={lstOperacionais}
                      handleAbrirModalDono={handleAbrirModalDono}
                      handleHabilitarPagamento={handleHabilitarPagamento}
                      handleAnexo={handleAnexo}
                      handleEditar={handleEditar}
                      handleHistoricoEditar={handleHistoricoEditar}
                      copiarLinkPagamento={copiarLinkPagamento}
                      handleMudarStatus={handleMudarStatus}
                      handleLiberarEdicao={handleLiberarEdicao}
                    />
                  </Grid>
                )
              } else {
                return <></>
              }
            })}
          </Grid>
        </div>
      )}

      {allDadosCarregados && showTable && (
        <TableSolicitacaoOrcamento
          data={allDados}
          ultimaSolicitacaoVista={ultimaSolicitacaoVista}
          setUltimaSolicitacao={setUltimaSolicitacao}
          handleAlterarStatus={handleAlterarStatus}
          handleExcluir={handlePodeExcluir}
          handleLogStatus={handleLogStatus}
          handleVisualizarDetalhes={handleVisualizarDetalhes}
          handleVisualizarParceiros={handleVisualizarParceiros}
          handleAbrirModalTag={handleAbrirModalTag}
        />
      )}

      {loading && <LoadingGeral />}

      <Box
        mt={3}
        hidden={qtdAllDados === allDados.length || loading}
        textAlign="center"
      >
        <ButtonUI
          fullWidth
          onClick={handleProxPagina}
          variant="text"
          disabled={loading}
          loading={loading}
          endIcon={<MdKeyboardArrowDown />}
        >
          Carregar mais
        </ButtonUI>
      </Box>

      <DrawerVisualizarDetalhes
        id={itemSelecionado?.id}
        exibirValores={true}
        open={mostrarDetalhe}
        handleOnClose={handleFecharDetalhes}
      />
      <DrawerAlterarStatus
        motivoCancelamento={itemSelecionado?.motivoCancelamento}
        id={itemSelecionado?.id}
        status={itemSelecionado?.status}
        open={
          mostrarAlterarStatus && perfilAtual !== SynPerfis.PARCEIRO_EXTERNO
        }
        handleOnClose={handleFecharAlterarStatus}
      />
      <DrawerInstrucoesNF
        id={itemSelecionado?.propostaVencedoraId}
        open={mostrarInstrucoesNF && perfilAtual !== SynPerfis.PARCEIRO_EXTERNO}
        handleOnClose={() => setMostrarInstrucoesNF(false)}
      />
      <DrawerCadastrarDono
        id={itemSelecionado?.id}
        lstOperacional={lstOperacionais}
        open={
          abrirModalCadastroDono && perfilAtual !== SynPerfis.PARCEIRO_EXTERNO
        }
        handleOnClose={handleModalCadastroDono}
      />

      <DrawerAtividades
        solicitacao={itemSelecionado}
        open={mostrarAtividades && perfilAtual !== SynPerfis.PARCEIRO_EXTERNO}
        handleOnClose={handleFecharAtividades}
      />

      <DrawerAlterarHonorarios
        id={itemSelecionado?.id}
        open={mostrarAlterarHonorarios}
        handleOnClose={handleFecharAlterarHonorarios}
      />

      <DrawerLogStatus
        id={itemSelecionado?.id ?? solicitacaoId}
        open={mostrarLogStatus}
        handleOnClose={handleFecharLogStatus}
      />
      <DrawerVisualizarParceiros
        id={itemSelecionado?.id}
        open={mostrarParceiros}
        handleOnClose={handleFecharParceiros}
        statusSolicitacao={itemSelecionado?.status?.id}
      />
      <DrawerCadastrarCpf
        id={itemSelecionado?.id}
        open={mostrarCadastrarCpf}
        handleOnClose={() => setMostrarCadastrarCpf(false)}
        carregarListagem={() => carregarListagem({ resetarPaginacao: true })}
      />
      {!!itemSelecionado?.id && mostrarAnexo && (
        <DrawerAnexos
          id={itemSelecionado?.id}
          propostaId={itemSelecionado.propostaVencedoraId}
          open={true}
          handleOnClose={() => setMostrarAnexo(false)}
          carregarListagem={() => carregarListagem({ resetarPaginacao: true })}
        />
      )}
      <ModalLiberarAcesso {...dadosModal()} />
      {mostrarModalTag && (
        <ModalAddTag
          open={mostrarModalTag}
          onClose={handleFecharModalTag}
          solicitacaoId={itemSelecionado?.id}
          loadAllTags={() => setLoadTags(!loadTags)}
        />
      )}
      {abrirHistoricoEditar && (
        <>
          <DrawerHistoricoEditar
            open={abrirHistoricoEditar}
            idItemSelecionado={itemSelecionado.id}
            handleOnClose={() => setAbrirHistoricoEditar(false)}
          />
        </>
      )}
    </Box>
  )
}

export default listagemSolicitacoes
