import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  adicionarComorbidade,
  adicionarLocalAtendimento,
  obterComorbidades,
  obterExames,
  obterPacientePorCPF
} from 'src/api/dominio'
import {
  listarPacotesHospitalPorMedicoId,
  obterPacoteHospitalPorId
} from 'src/api/pacote'
import {
  obterOrcamentoPorId,
  salvarSolicitacaoOrcamentoHospital
} from 'src/api/solicitacao-orcamento'
import { ModalAddGenerico } from 'src/components/modals/ModalAddGenerico'
import {
  StepConnectorSyn,
  StepIconSyn
} from 'src/components/stepper/stepConnector'
import { StepContentSyn } from 'src/components/stepper/stepContentSyn'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { TextField } from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import genero from 'src/core/consts/genero'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { formatarIdade } from 'src/core/formatacoes/formatarData'
import { onlyNumber, verificarCPF } from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { useQueryParams } from 'src/hooks/useQuery'
import {
  IPacoteForm,
  mapearPacoteParaRequest
} from 'src/pages/dashboard/medico/pacote/formPacote'
import { ResumoOrcamento } from 'src/pages/dashboard/medico/solicitacao/resumo'
import {
  IExame,
  IOrcamentoDetalhadoResponse,
  IPaciente,
  IPacoteDetalhadoResponse,
  ItemListagemPacoteProcedimentoResponse,
  ObjetoDescricaoGenerico
} from 'src/types'
import Swal from 'sweetalert2'

import FormPacote from './formPacote'

import DateInput from 'src/components/ui/DateInput'
import StatusMedicoLocalAtendimento from 'src/core/consts/statusMedicoLocalAtendimento'
import useAuth from 'src/hooks/useAuth'
import { listagemMedicoHospital } from '../../../../../api/medico'
import ModalGenerico from '../../../../../components/modals/ModalGenerico'
import CadastroMedico from './cadastroMedico'

const useStyles = makeStyles(theme => ({
  container: {},
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

export interface IOrcamentoForm {
  id?: string

  pacienteId?: string
  nomePaciente: string
  cpfPaciente: string
  telefonePaciente: string
  dataNascimentoPaciente: string
  emailPaciente: string
  sexoPaciente: number
  cpfResponsavel?: string
  nomeResponsavel?: string

  medicoId?: string
  medicoNome?: string
  medicoEspecialidades?: ObjetoDescricaoGenerico[]

  exames: IExame[]
  avaliacaoPreAnestesica?: boolean
  riscoCirurgico?: boolean
  observacaoExames?: string

  pacote: ObjetoDescricaoGenerico
  comorbidades: ObjetoDescricaoGenerico[]
  reservaSangue: string
  hospitais: ObjetoDescricaoGenerico[]
  observacaoSangue?: string
  solicitacaoSemLocalAtendimento: boolean

  temDisponibilidade: boolean
  disponibilidade: string
  observacoes: string
}

export function mapToForm(item: IOrcamentoDetalhadoResponse) {
  let orcamento: IOrcamentoForm = {
    id: item.id,
    cpfPaciente: item.pacienteCpf,
    nomePaciente: item.pacienteNome,
    emailPaciente: item.pacienteEmail,
    telefonePaciente: item.pacienteTelefone,
    sexoPaciente: item.pacienteSexo,
    pacienteId: item.pacienteId,
    dataNascimentoPaciente: item.pacienteDataNascimento,
    comorbidades: item.comorbidades,
    pacote: item.pacote,
    reservaSangue: item.qtdReservaSange.toString(),
    hospitais: item.locaisAtendimento,
    disponibilidade: item.disponibilidade,
    temDisponibilidade: !!item.disponibilidade,
    observacoes: item.observacoes,
    medicoId: item.medicoId,
    medicoNome: item.medicoNome,
    medicoEspecialidades: item.medicoEspecialidades,
    riscoCirurgico: item.riscoCirurgico,
    avaliacaoPreAnestesica: item.avaliacaoPreAnestesica,
    observacaoExames: item.observacaoExames,
    cpfResponsavel: item.cpfResponsavel,
    nomeResponsavel: item.nomeResponsavel,
    observacaoSangue: item.observacaoSangue,
    exames: item.exames,
    solicitacaoSemLocalAtendimento: item.solicitacaoSemLocalAtendimento
  }
  return orcamento
}

export default function FormOrcamento() {
  const classes = useStyles()
  const history = useHistory()
  const { user, perfilAtual } = useAuth()

  const query = useQueryParams()

  //#region Definicao de listas e loading
  const [loadingOrcamento, setLoadingOrcamento] = useState(true)

  const [listaPacotes, setListaPacotes] = useState<any>([])

  const [loadingMedicos, setLoadingMedicos] = useState(false)
  const [listaMedicos, setListaMedicos] = useState<any[]>([])

  const [listaComorbidades, setListaComorbidades] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingComorbidades, setLoadingComorbidades] = useState<boolean>(true)
  const [semComorbidade, setSemComorbidade] = useState<boolean>(false)
  const [
    comorbidadeMensagemErro,
    setComorbidadeMensagemErro
  ] = useState<boolean>(false)

  const [loadingPaciente, setLoadingPaciente] = useState(false)
  const [showFormPaciente, setShowFormPaciente] = useState(false)
  const [pacienteResponsavel, setPacienteResponsavel] = useState(false)

  const [loadingPacote, setLoadingPacote] = useState(false)

  const [listaExames, setListaExames] = useState<IExame[]>([])
  const [loadingExames, setLoadingExames] = useState<boolean>(true)

  const [novoPacote, setNovoPacote] = useState(false)

  const [dadosPacote, setDadosPacote] = useState<IPacoteDetalhadoResponse>()

  //#endregion

  //#region Modal
  const [textoModal, setTextoModal] = useState('')
  const [showModal, toggleModal] = useState<string>(null)

  const [showModalMedico, setShowModalMedico] = useState(false)
  const [showModalPacote, setShowModalPacote] = useState(false)

  const handleShowModal = (texto, modal) => {
    setTextoModal(texto)
    toggleModal(modal)
  }
  const dadosModal = () => {
    if (!showModal) return
    let dados = null
    switch (showModal) {
      case 'hospitais':
        dados = {
          titulo: 'Adicionar local',
          label: 'local de atendimento',
          saveSubmit: adicionarLocalAtendimento,
          handleValue: valor => {
            handleNovoValor('hospitais', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
      case 'comorbidades':
        dados = {
          titulo: 'Adicionar comorbidade',
          label: 'Comorbidade',
          saveSubmit: adicionarComorbidade,
          handleValue: valor => {
            handleNovoValor('comorbidades', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
    }
    return dados
  }
  //#endregion

  //#region Formulário

  const schema = yup.object().shape({
    nomePaciente: yup
      .string()
      .required()
      .test('nome válido', 'Deve informar nome e sobrenome', value => {
        if (!value) return false
        let nomeArray = value.split(' ').filter(texto => texto.trim())
        return nomeArray.length > 1
      })
      .label('Nome completo'),
    nomeResponsavel: yup
      .string()
      .test(
        'nome-responsavel',
        'Nome do responsável é obrigatório',
        function (value) {
          if (pacienteResponsavel && !value) {
            return false
          }
          return true
        }
      )
      .test('nome válido', 'Deve informar nome e sobrenome', value => {
        if (pacienteResponsavel) {
          let nomeArray = value.split(' ').filter(texto => texto.trim())

          return nomeArray.length > 1
        }

        return true
      })
      .label('Nome completo'),
    cpfPaciente: yup
      .string()
      .nullable()
      .test('cpf-teste', 'CPF inválido', value => {
        if (!value) return true

        return verificarCPF(onlyNumber(value))
      })
      .label('CPF'),
    cpfResponsavel: yup
      .string()
      .nullable()
      .test(
        'cpf-responsavel',
        'CPF do responsável é obrigatório',
        function (value) {
          if (pacienteResponsavel && !value) {
            return false
          }
          return true
        }
      )
      .test('cpf-teste', 'CPF inválido', value => {
        if (pacienteResponsavel && value) {
          return verificarCPF(onlyNumber(value))
        }

        return true
      })
      .label('CPF'),
    telefonePaciente: yup.string().required().label('Telefone'),
    dataNascimentoPaciente: yup.string().required().label('Data nascimento'),
    emailPaciente: yup.string().email().label('E-mail'),
    sexoPaciente: yup.number().required().label('Sexo'),
    exames: yup.array().label('Exames'),
    pacote: yup.object().nullable().required().label('Pacote de procedimento'),
    comorbidades: yup.array().label('Comorbidades'),
    avaliacaoPreAnestesica: yup.bool(),
    riscoCirurgico: yup.bool(),
    observacaoExames: yup.string().label('Observações dos exames'),
    observacaoSangue: yup.string().label('Observações'),
    reservaSangue: yup.string().label('Reserva de sangue'),
    disponibilidade: yup.string().when('temDisponibilidade', {
      is: true,
      then: yup.string().required().label('Disponibilidade')
    }),
    observacoes: yup.string().label('Observações'),
    medico: yup
      .object()
      .shape({
        id: yup.string().required().label('Médico')
      })
      .required()
      .label('Médico'),
    medicoNome: yup.string().label('Médico Nome')
  })

  let defaultValues = {
    pacienteId: '',
    nomePaciente: '',
    emailPaciente: '',
    telefonePaciente: '',
    cpfPaciente: '',
    dataNascimentoPaciente: '',
    sexoPaciente: 0,

    exames: [],
    avaliacaoPreAnestesica: false,
    riscoCirurgico: false,
    observacaoExames: '',

    pacote: {},
    comorbidades: [],

    reservaSangue: '',
    observacoes: '',
    medico: {},
    medicoNome: ''
  }

  interface IMedico {
    id: string
    nome: string
  }

  interface ISolicitacaoHospital extends IOrcamentoForm {
    medico: IMedico
  }

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger,
    setError,
    clearErrors
  } = useForm<ISolicitacaoHospital>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('id')
    register('sexoPaciente')
    register('hospitais')
    register('temDisponibilidade')
    register('pacote')
    register('telefonePaciente')
    register('comorbidades')
    register('riscoCirurgico')
    register('avaliacaoPreAnestesica')
    register('dataNascimentoPaciente')
    register('pacienteId')
    register('exames')
    register('medico')
    register('medicoNome')
  }, [register])

  // VINCULAR MUDANÇA DE VALOR
  const handleItemValue = (name: string, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
  }

  const handleNovoValor = (name, item: any) => {
    switch (name) {
      case 'comorbidades':
        carregarComorbidades()
        setValue('comorbidades', [...command.comorbidades, item], {
          shouldValidate: true
        })
        break
    }
  }
  //#endregion

  //#region MOUNT
  useEffect(() => {
    carregarPacotes()
    carregarComorbidades()
    carregarExames()
    verificaEhEdicao()
    carregarMedicos()
  }, [])

  function verificaEhEdicao() {
    const id = query.get('id')
    if (!id) {
      setLoadingOrcamento(false)
      return
    }

    obterOrcamentoPorId(id)
      .then(response => {
        const orcamento = response.data
        const form = mapToForm(orcamento)
        for (const key in form) {
          let value = form[key]
          if (key === 'dataNascimentoPaciente') {
            value = moment(value).format('yyyy-MM-DD')
          }
          setValue(key, value, { shouldValidate: false })
        }
        setLoadingOrcamento(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        history.goBack()
      })
  }

  //#region Carregar Opções do Sistema
  useEffect(() => {
    carregarPacotes()
  }, [command.medico])

  useEffect(() => {
    if (command.pacote.id) {
      obterPacoteHospitalPorId(command.pacote.id)
        .then(res => setDadosPacote(res.data))
        .catch(err => showNotificationErrorAPI(err))
    }
  }, [command.pacote])

  async function carregarPacotes() {
    var dados: ItemListagemPacoteProcedimentoResponse[] = []
    if (!command?.medico?.id) return
    setLoadingPacote(true)
    const response = await listarPacotesHospitalPorMedicoId(command?.medico?.id)

    dados = response.data

    const idPacote = query.get('idPacote')
    if (!!idPacote) {
      const pacote = dados.find(p => p.id === idPacote)

      setValue('pacote', pacote)
    }
    setListaPacotes(dados)

    setLoadingPacote(false)
  }

  const carregarExames = async () => {
    return obterExames()
      .then(response => setListaExames(response.data || []))
      .finally(() => {
        setLoadingExames(false)
      })
  }

  const carregarComorbidades = async () => {
    return obterComorbidades()
      .then(response => {
        setListaComorbidades(response.data)
      })
      .finally(() => {
        setLoadingComorbidades(false)
      })
  }

  async function carregarMedicos() {
    setLoadingMedicos(true)
    const response = await listagemMedicoHospital({
      pagina: 1,
      qtdPorPagina: 1000
    })

    setListaMedicos(
      response.data.items.map(i => {
        return {
          statusLocalAtendimento: i.statusLocalAtendimento,
          ...i.medico
        }
      })
    )

    setLoadingMedicos(false)

    if (query.get('medicoId')) {
      setValue(
        'medico',
        response.data.items.find(m => m.medico.id === query.get('medicoId'))
          .medico
      )

      if (query.get('idPacote')) {
        setActiveStep(2)
      } else {
        setActiveStep(1)
      }
    }
  }

  //#endregion

  const onSubmit = async (data: IOrcamentoForm) => {
    let paciente: IPaciente = {
      id: command.pacienteId,
      comorbidades: command.comorbidades.map(c => c.id),
      cpf: onlyNumber(command.cpfPaciente),
      dataNascimento: moment(command.dataNascimentoPaciente).toJSON(),
      email: command.emailPaciente,
      nome: command.nomePaciente,
      telefone: onlyNumber(command.telefonePaciente),
      sexo: command.sexoPaciente,
      cpfResponsavel: command.cpfResponsavel,
      nomeResponsavel: command.nomeResponsavel
    }

    const newPacote = {
      medicoId: null,
      ...command.pacote
    }

    const pacote = mapearPacoteParaRequest(
      (newPacote as unknown) as IPacoteForm
    )

    let request = {
      id: command.id,
      paciente: paciente,
      pacoteProcedimento: pacote,
      qtdReservaSangue: !!command.reservaSangue
        ? parseInt(command.reservaSangue)
        : 0,
      disponibilidade: command.disponibilidade,
      observacoes: command.observacoes,
      avaliacaoPreAnestesica: command.avaliacaoPreAnestesica,
      riscoCirurgico: command.riscoCirurgico,
      observacaoExames: command.observacaoExames,
      observacaoSangue: command.observacaoSangue,
      exames: command.exames.map(e => e.id),
      medicoId: command.medico.id,
      perfilAtual: perfilAtual
    }

    return salvarSolicitacaoOrcamentoHospital(request)
      .then(response => {
        const id = response.data
        Swal.fire({
          title: 'Parabéns!',
          text: 'Sua solicitação de orçamento foi salva com sucesso.',
          icon: 'success',
          confirmButtonText: 'Continuar',
          showCancelButton: true,
          cancelButtonText: 'Imprimir guia'
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.cancel) {
            history.replace(
              `${SynRoutes.listagemOrcamentosHospital}/?print=${id}`
            )
            return
          }

          history.replace(SynRoutes.listagemOrcamentosHospital)
        })
      })
      .catch(e => {
        showNotificationErrorAPI(e)
      })
  }

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  //#region stepper
  const [activeStep, setActiveStep] = React.useState(0)
  // MIN = Minimum expected value
  // MAX = Maximium expected value
  // Function to normalise the values (MIN / MAX could be integrated)
  const lastStep = 9
  const normalise = value => ((value - 0) * 100) / (lastStep - 0)

  const formularioCamposDoStep = [
    ['medico'],
    ['pacote'],
    [
      'cpfPaciente',
      'nomePaciente',
      'telefonePaciente',
      'sexoPaciente',
      'dataNascimentoPaciente'
    ],
    ['comorbidades'],
    [],
    ['reservaSangue'],
    ['disponibilidade', 'hospitais'],
    ['observacoes']
  ]

  const handleNext = async () => {
    if (activeStep === renderActiveStep(1)) {
      const idade = formatarIdade(stringToDate(command.dataNascimentoPaciente))
      if (idade < 18) {
        await setPacienteResponsavel(true)
        const idadeResult = await trigger(['cpfResponsavel', 'nomeResponsavel'])
        if (!idadeResult) return
      }
    }
    var campos = formularioCamposDoStep[activeStep]
    if (campos) {
      const result = await trigger(campos)
      if (!result) {
        return
      }
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const debugSteps = false
  const handleBackToStep = stepNumber => {
    if (!stepNumber && stepNumber !== 0) {
      setActiveStep(activeStep - 1)
      return
    }

    if (debugSteps || stepNumber < activeStep) {
      setActiveStep(stepNumber)
    }
  }

  const getSteps = number => {
    const steps = []
    for (let index = 0; index < number; index++) {
      steps.push(
        <Step key={index}>
          <StepLabel
            StepIconComponent={v => StepIconSyn({ ...v, key: index + 1 })}
            onClick={() => handleBackToStep(index)}
          ></StepLabel>
          <StepContent></StepContent>
        </Step>
      )
    }
    return steps
  }

  const handleRadios = e => {
    handleItemValue('sexoPaciente', parseInt(e.target.value))
  }
  //#endregion

  useEffect(() => {
    if (!command.cpfPaciente) return

    const value = onlyNumber(command.cpfPaciente)
    if (value.length === 11) {
      setLoadingPaciente(true)
      obterPacientePorCPF(command.cpfPaciente)
        .then(response => {
          const paciente = response.data
          if (!!paciente) {
            setValue('comorbidades', paciente.comorbidades, {
              shouldValidate: true
            })
            setValue('nomePaciente', paciente.nome, { shouldValidate: true })
            if (!!paciente.dataNascimento) {
              let dataFormatada = moment(paciente.dataNascimento).format(
                'yyyy-MM-DD'
              )
              setValue('dataNascimentoPaciente', dataFormatada, {
                shouldValidate: true
              })
            }
            setValue('telefonePaciente', paciente.telefone, {
              shouldValidate: true
            })
            setValue('pacienteId', paciente.id)
            setValue('sexoPaciente', paciente.sexo)
            setValue('emailPaciente', paciente.email, { shouldValidate: true })
          } else {
            limparFormPac()
          }
          setShowFormPaciente(true)
        })
        .catch(e => {
          showNotificationErrorAPI(e)
          limparFormPac()
          setShowFormPaciente(false)
        })
        .finally(() => {
          setLoadingPaciente(false)
        })
    }
  }, [command.cpfPaciente])

  function limparFormPac() {
    setValue('pacienteId', null)
    setValue('nomePaciente', null)
    setValue('dataNascimentoPaciente', null)
    setValue('telefonePaciente', '')
    setValue('emailPaciente', null)
    setValue('sexoPaciente', 0)
  }

  function toggleComorbidades() {
    setValue('comorbidades', [])
    setSemComorbidade(!semComorbidade)
    setComorbidadeMensagemErro(false)
  }

  function stringToDate(str: string) {
    const isoString = str.replace('/', '-').replace('/', '-')

    return new Date(isoString)
  }

  function renderActiveStep(step) {
    return novoPacote ? step + 1 : step
  }

  // function medicoNaLista(medicoId: string) {
  //   debugger
  //   return !!listaMedicos.find(m => m.id === medicoId)
  // }

  function renderGroupedPacotes(pacote: any) {
    return pacote.nomeMedico || pacote.nomeParceiro
  }

  return (
    <>
      {loadingOrcamento && (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      )}

      <Box
        hidden={loadingOrcamento}
        component="main"
        className={`fadeInTransition ${classes.container}`}
      >
        <CssBaseline />
        {/* command: {JSON.stringify(command)} */}
        {/* medicos: {JSON.stringify(listaMedicos)} */}
        {/* pacoteSelecionado: {JSON.stringify(pacoteSelecionado)} */}
        {/* erros: {JSON.stringify(errors)} */}
        {/* <br /> */}
        {/* comorbidades: {JSON.stringify(listaComorbidades)} */}
        {ehXS && (
          <LinearProgress
            variant="determinate"
            value={normalise(activeStep + 1)}
          />
        )}
        <Grid container>
          {!ehXS && (
            <Grid item xs={12} sm={1}>
              <Stepper
                connector={<StepConnectorSyn />}
                activeStep={activeStep}
                style={{ margin: 0, padding: 0, background: 'none' }}
                orientation="vertical"
              >
                {getSteps(lastStep)}
              </Stepper>
            </Grid>
          )}

          <Grid item xs={12} sm={10}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={classes.form}
              noValidate={true}
            >
              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 0}
                hideBack
                handleBackToStep={handleBackToStep}
                handleNext={() => {
                  handleNext()
                }}
                textoTitulo={
                  'Escolha o médico que fará o procedimento. Ou crie um novo médico'
                }
              >
                <Autocomplete
                  erro={errors}
                  name="medico"
                  label="Médico"
                  loading={loadingMedicos}
                  closeOnSelect
                  value={command.medico}
                  options={listaMedicos.sort(
                    (a, b) =>
                      +b.statusLocalAtendimento - +a.statusLocalAtendimento
                  )}
                  getOptionLabel={option => `${option?.nome}`}
                  onChange={(event, newValue) => {
                    setValue('medico', newValue)
                    setValue('medicoNome', newValue?.nome)
                  }}
                  errorMessage={errors?.medico?.id?.message}
                  showAddButton
                  addButtonProps={{ text: 'Não encontrou? Add novo médico' }}
                  onClickAddButton={() => setShowModalMedico(true)}
                  groupBy={option =>
                    StatusMedicoLocalAtendimento.array[
                      option.statusLocalAtendimento
                    ]?.descricao
                  }
                  // getOptionDisabled={option =>
                  //   option.status ===
                  //   StatusMedicoLocalAtendimento.object.PENDENTE.status
                  // }
                  // renderOption={(option) => (
                  //   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  //     <Typography>{option.nome}</Typography>
                  //     {renderChipStatusMedicoLocalAtendimento(option.status)}
                  //   </div>
                  // )}
                />

                <ModalGenerico maxWidth="md" open={showModalMedico}>
                  <CadastroMedico
                    modal
                    close={() => setShowModalMedico(false)}
                    setMedico={medico => {
                      handleItemValue('medico', medico)
                      handleItemValue('medicoNome', medico?.nome)
                    }}
                    // medicoNaLista={medicoNaLista}
                  />
                </ModalGenerico>
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 1}
                handleBackToStep={handleBackToStep}
                handleNext={() => {
                  if (Object.entries(command.pacote).length === 0) {
                    setError('pacote', {
                      message: 'Pacote é obrigatório'
                    })
                    console.log('errors', errors)
                    return
                  }
                  handleNext()
                }}
                textoTitulo={
                  'Escolha o pacote de procedimento que será realizado. Ou crie um pacote específico para esta solicitacao'
                }
              >
                <Autocomplete
                  erro={errors}
                  name="pacote"
                  label="Pacote de procedimento"
                  loading={loadingPacote}
                  closeOnSelect
                  value={command.pacote}
                  options={listaPacotes.sort((a, b) => {
                    if (a.nomeParceiro) {
                      return +1
                    }

                    if (a.nomeMedico) {
                      return -1
                    }
                  })}
                  getOptionLabel={option => {
                    if (option.nomeParceiro) {
                      return `${option.descricao}*`
                    }

                    return option.descricao
                  }}
                  onChange={(event, newValue) => {
                    clearErrors()
                    handleItemValue('pacote', newValue)
                    setNovoPacote(false)
                  }}
                  errorMessage={(errors?.pacote as any)?.message}
                  showAddButton
                  addButtonProps={{ text: 'Não encontrou? Add novo pacote' }}
                  onClickAddButton={() => setShowModalPacote(true)}
                  groupBy={renderGroupedPacotes}
                />

                <ModalGenerico maxWidth="md" fullWidth open={showModalPacote}>
                  <FormPacote
                    close={() => setShowModalPacote(false)}
                    setPacote={pacote => {
                      setValue('pacote', pacote)
                      clearErrors()
                    }}
                  />
                </ModalGenerico>
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 2}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={'Quem é o seu paciente?'}
              >
                <Grid container spacing={1}>
                  <Alert severity="info">
                    Caso seja menor de idade ou não possuir CPF, marque a opção
                    abaixo "Paciente necessita de responsável"
                  </Alert>
                  <Box>
                    <Grid
                      container
                      spacing={1}
                      style={{ width: '100%' }}
                      className={classes.noMargin}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Checkbox
                            checked={pacienteResponsavel}
                            onClick={() =>
                              setPacienteResponsavel(!pacienteResponsavel)
                            }
                          />
                          Paciente necessita de responsável
                        </Typography>
                      </Grid>
                      {pacienteResponsavel && (
                        <>
                          <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="subtitle2">
                              Responsável
                            </Typography>
                            <Divider style={{ margin: '10px 0' }} />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              erro={errors}
                              name="cpfResponsavel"
                              placeholder="Ex: 999.999.999-99"
                              label="CPF do responsável"
                              type="tel"
                              hiddenLabel
                              control={control}
                              mask="999.999.999-99"
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                autoComplete: 'new-password'
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              erro={errors}
                              name="nomeResponsavel"
                              label="Nome completo do responsável"
                              inputRef={register}
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                autoComplete: 'new-password'
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} style={{ marginTop: '20px' }}>
                            <Typography variant="subtitle2">
                              Paciente
                            </Typography>
                            <Divider style={{ margin: '10px 0' }} />
                          </Grid>
                        </>
                      )}
                      {!pacienteResponsavel && (
                        <Grid item xs={12}>
                          <TextField
                            loading={loadingPaciente}
                            erro={errors}
                            name="cpfPaciente"
                            placeholder="Ex: 999.999.999-99"
                            label="CPF"
                            type="tel"
                            hiddenLabel
                            control={control}
                            mask="999.999.999-99"
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              autoComplete: 'new-password'
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          erro={errors}
                          name="nomePaciente"
                          label="Nome completo"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            autoComplete: 'new-password'
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        {/* <button
                          onClick={value =>
                            handleItemValue(
                              'dataNascimentoPaciente',
                              '10/10/2000'
                            )
                          }
                          type="button"
                        >
                          mudar valor
                        </button>
                        data:{command.dataNascimentoPaciente} */}

                        <DateInput
                          errors={errors}
                          name="dataNascimentoPaciente"
                          label="Data de nascimento"
                          value={command.dataNascimentoPaciente}
                          control={control}
                          handleChange={value =>
                            handleItemValue('dataNascimentoPaciente', value)
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          erro={errors}
                          name="telefonePaciente"
                          maskTelCel
                          value={command.telefonePaciente}
                          label="Telefone"
                          type="tel"
                          control={control}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          erro={errors}
                          name="emailPaciente"
                          label="E-mail"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          onChange={handleRadios}
                          value={command.sexoPaciente}
                        >
                          <Grid container direction="row">
                            <FormControlLabel
                              control={
                                <Radio
                                  value={genero.Masculino}
                                  color="primary"
                                  name="pacienteSexo"
                                />
                              }
                              label="Masculino"
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  value={genero.Feminino}
                                  color="primary"
                                  name="pacienteSexo"
                                />
                              }
                              label="Feminino"
                            />
                          </Grid>
                        </RadioGroup>
                        <FormControl fullWidth className={classes.formControl}>
                          <FormHelperText error={!!errors.sexoPaciente}>
                            {errors.sexoPaciente?.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 3}
                hideBack={false}
                handleBackToStep={handleBackToStep}
                handleNext={() => {
                  if (!semComorbidade && command.comorbidades.length === 0) {
                    return setComorbidadeMensagemErro(true)
                  }
                  handleNext()
                }}
                textoTitulo={
                  'Seu paciente possui comorbidades? Caso não tenha clique em "CONTINUAR"'
                }
              >
                <>
                  <Typography style={{ margin: '20px 0 0' }}>
                    <Checkbox
                      checked={semComorbidade}
                      onChange={() => toggleComorbidades()}
                    />{' '}
                    Não possui comorbidade
                  </Typography>
                  {comorbidadeMensagemErro && (
                    <Typography variant="body2" style={{ color: 'red' }}>
                      Marcar caso o paciente não tenha comorbidade
                    </Typography>
                  )}
                  {!semComorbidade && (
                    <Autocomplete
                      multiple
                      erro={errors}
                      name="comorbidades"
                      label="Comorbidades"
                      loading={loadingComorbidades}
                      options={listaComorbidades}
                      value={command.comorbidades}
                      getOptionSelected={option =>
                        !!command.comorbidades.find(l => l.id === option.id)
                      }
                      showCheck
                      onClickAddButton={texto =>
                        handleShowModal(texto, 'comorbidades')
                      }
                      showAddButton
                      getOptionLabel={option => option.descricao}
                      onChange={(
                        event,
                        newValue: ObjetoDescricaoGenerico[]
                      ) => {
                        setSemComorbidade(false)
                        setComorbidadeMensagemErro(false)
                        handleItemValue(
                          'comorbidades',
                          newValue as ObjetoDescricaoGenerico[]
                        )
                      }}
                    />
                  )}
                </>
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 4}
                hideBack={false}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={
                  'Será necessária avaliação pré-anestésica ou de risco cirúrgico? Caso não seja, clique em "CONTINUAR"'
                }
              >
                <Box>
                  <Typography variant="h6">Avaliações:</Typography>

                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        <Checkbox
                          checked={command.avaliacaoPreAnestesica}
                          onChange={() =>
                            handleItemValue(
                              'avaliacaoPreAnestesica',
                              !command.avaliacaoPreAnestesica
                            )
                          }
                          color="primary"
                        />
                        Pré-anestésica
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        <Checkbox
                          checked={command.riscoCirurgico}
                          onChange={() =>
                            handleItemValue(
                              'riscoCirurgico',
                              !command.riscoCirurgico
                            )
                          }
                          color="primary"
                        />
                        Risco cirúrgico
                      </Typography>
                    </Grid>
                  </Grid>

                  <TextField
                    erro={errors}
                    multiline={true}
                    rows={4}
                    inputRef={register}
                    name="observacaoExames"
                    label="Observações"
                  />
                </Box>
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 5}
                hideBack={false}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={
                  'Quais exames seu paciente deve realizar antes do procedimento?'
                }
              >
                <Autocomplete
                  multiple
                  erro={errors}
                  name="exames"
                  label="Exames pré-operatórios"
                  loading={loadingExames}
                  options={listaExames}
                  value={command.exames}
                  getOptionSelected={option =>
                    !!command.exames.find(l => l.id === option.id)
                  }
                  showCheck
                  showAddButton
                  /* onClickAddButton={texto => handleShowModal(texto, 'exame')} */
                  getOptionLabel={(option: IExame) =>
                    `${option.codigo} - ${option.descricao}`
                  }
                  onChange={(event, newValue: IExame[]) => {
                    handleItemValue('exames', newValue as IExame[])
                  }}
                />
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 6}
                hideBack={false}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={
                  'Será necessário reserva de sangue? Caso não necessite clique em "Continuar"'
                }
              >
                <TextField
                  erro={errors}
                  name="reservaSangue"
                  placeholder="Ex: 2 concentrados de hemácias"
                  label="Qtd. concentrados de hemácias"
                  control={control}
                  type="tel"
                  mask="999"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <Alert severity="info" style={{ marginTop: '20px' }}>
                  Caso necessitar de plasma, concentrados de plaquetas ou
                  outros, favor informar no campo abaixo de observações
                </Alert>
                <TextField
                  erro={errors}
                  multiline={true}
                  rows={4}
                  name="observacaoSangue"
                  label="Observações"
                  inputRef={register}
                />
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 7}
                hideBack={false}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={
                  'Caso tenha alguma consideração a fazer, sinta-se à vontade para informar abaixo'
                }
              >
                <TextField
                  erro={errors}
                  multiline={true}
                  rows={4}
                  inputRef={register}
                  name="observacoes"
                  label="Observações"
                />
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 8}
                hideBack={false}
                handleBackToStep={handleBackToStep}
                textoTitulo={
                  'Ótimo! Agora revise as informações com ATENÇÃO antes de salvar'
                }
                showFinish
                handleFinish={handleSubmit(onSubmit)}
                textFinish="Salvar solicitação de orçamento"
                loading={formState.isSubmitting}
              >
                <Box mb={3}>
                  <Typography variant="subtitle1">
                    Caso precise alterar alguma informação, clique no botão
                    'Voltar' até chegar na etapa desejada.
                  </Typography>
                  <ResumoOrcamento
                    orcamento={{
                      ...command,
                      medicoNome: command.medico.nome,
                      urlLogomarca: undefined
                    }}
                    pacoteSelecionado={dadosPacote}
                    isHospital
                  />
                </Box>
              </StepContentSyn>
            </form>
          </Grid>
        </Grid>
        {!!showModal && <ModalAddGenerico open={true} {...dadosModal()} />}
      </Box>
    </>
  )
}

function LoadingGenerico(props) {
  return (
    <Grid
      direction="column"
      item
      xs={12}
      style={{
        padding: 48,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <CircularProgress />
      <Typography variant="subtitle1" component="p">
        Carregando...
      </Typography>
    </Grid>
  )
}
