import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Checkbox,
  Container,
  CssBaseline,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField as TextFieldMU,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Button from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { NumberFormatCustom } from 'src/components/ui/NumberField'
import UBox from 'src/components/ui/Ubox'
import estados from 'src/core/consts/estados'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import {
  onlyNumber,
  removerAcento,
  verificarCNPJ,
  verificarCPF
} from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { useQueryParams } from 'src/hooks/useQuery'

import moment from 'moment'
import { obterResumoFinanciamento } from 'src/api/proposta'
import DateInput from 'src/components/ui/DateInput'
import TextField from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import { StepContentSyn } from 'src/components/stepper/stepContentSyn'
import Swal from 'sweetalert2'
import { format } from 'path'
import genero from 'src/core/consts/genero'
import CurrencyInput from 'src/components/ui/CurrencyInput'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import {
  ESTADO_CIVIL,
  NACIONALIDADE,
  PROFISSOES,
  SITUACAO_IMOVEL,
  TIPOS_PROFISSOES
} from 'src/core/consts/dominioBV'
import { ObterCep } from 'src/api/cep/cep'
import NumberFormat from 'react-number-format'
import { formatarData } from 'src/core/formatacoes/formatarData'
import {
  cadastrarPreFinanciamento,
  IPreAnaliseResponse,
  originarProposta,
  ParcelaBV,
  SimulacaoResponse,
  simularCondicoes
} from 'src/api/financiamento'
import { StatusFinanciamento } from 'src/core/consts/statusFinanciamento'
import {
  StepConnectorSyn,
  StepIconSyn
} from 'src/components/stepper/stepConnector'
import formatarCPF from 'src/core/formatacoes/formatarCPF'

const useStyles = makeStyles(theme => ({
  root: {
    '& .box-financiamento': {
      position: 'relative',
      background: '#fff',
      overflowX: 'hidden',
      width: '100%',
      height: '100%',
      padding: '40px',
      margin: '30px auto ',
      maxWidth: '880px',
      borderRadius: '4px'
    },

    '& .box-resumo small': {
      fontWeight: 600
    },
    [theme.breakpoints.down('sm')]: {
      '& .syn-box-out': {
        overflowX: 'hidden',
        borderRadius: '0',
        width: '100%',
        height: '100%',
        padding: '40px',
        margin: 0
      },
      '& .box-financiamento': {
        padding: '30px 16px',
        margin: '0 auto',
        borderRadius: '0px',
        minHeight: '100vh'
      }
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  formControl2: {
    top: '16px'
  },
  boxFormaPagamento2: {
    display: 'flex',
    margin: '0 -16px',
    gap: '16px',
    flexWrap: 'nowrap',
    '& .item': {
      border: '2px solid #ecf7ea',
      padding: '15px',
      textAlign: 'center',
      borderRadius: '20px',
      color: '#306025',
      background: '#ecf7ea',
      gap: '8px'
    }
  },
  boxFormaPagamento: {
    display: 'flex',
    margin: '0 -16px',
    gap: '16px',
    flexWrap: 'nowrap',
    '& .item': {
      padding: '20px',
      maxWidth: '200px',
      minHeight: '84px',

      border: '1px solid #999',
      color: '#878787',

      borderRadius: '8px',
      cursor: 'pointer',
      textAlign: 'center',
      '&:hover': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      },
      '&.ativo, .ativo:hover': {
        background: theme.palette.primary.main,
        color: '#fff',
        borderColor: theme.palette.primary.main
      }
    }
  },
  [theme.breakpoints.down(1401)]: {
    boxFormaPagamento: {
      flexDirection: 'column',
      '& .item': {
        width: '100%'
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    boxPagamento: {
      // ...padrao,
      width: '100%',
      order: 2
    },
    boxFormaPagamento: {
      flexDirection: 'column',
      '& .item': {
        width: '100%',
        maxWidth: '100%'
      }
    }
  }
}))

export class IResumoFinanciamento {
  nomeProcedimento: string
  localAtendimento?: string
  nome: string
  cpf: string
  nomeMedico?: string
  idadePaciente?: number
  valorTotal?: number
}

export class IFormFinanciamento {
  //Geral
  solicitacaoOrcamentoId?: string
  propostaId: string

  //step1
  nome: string
  cpf: string
  dataNascimento: string
  uf: string
  telefone: string
  renda: number
  valorTotalFinanciamento: number

  //step2
  sexo?: number
  rg?: string
  estadoCivil?: any
  nomeMae?: string
  email?: string
  patrimonio?: number
  nacionalidade?: string
  telefone2?: string

  //Step 3
  estado?: any
  cep?: string
  bairro?: string
  complemento?: string
  numero?: string
  situacaoImovel?: number

  //Step 4
  tipoProfissao?: any
  profissao?: any
  // numeroBeneficio?: string
  anos?: number
  meses?: number
  telefoneComercial?: string

  nomeEmpresa?: string
  cnpjEmpresa?: string
  cepEmpresa?: string
  estadoEmpresa?: any
  bairroEmpresa?: string
  complementoEmpresa?: string
  numeroEmpresa?: string
  situacaoImovelEmpresa?: number
  dataFundacaoEmpresa?: string

  // nomeParceiro: string
}

const financiamentoDefault: IFormFinanciamento = {
  //Geral
  solicitacaoOrcamentoId: '',
  propostaId: '',

  //step1
  nome: '',
  cpf: '',
  dataNascimento: '',
  telefone: '',
  uf: '',
  renda: 0,
  valorTotalFinanciamento: 0,

  sexo: null,

  // nomeParceiro: '',
  // email: '',
  // rg: '',
  // estadoCivil: null,
  // nomeMae: '',
  // estado: 'DF'
  // nomeProcedimento: '',
  tipoProfissao: null,

  anos: null,
  meses: null,
  nomeEmpresa: '',
  cnpjEmpresa: '',
  cepEmpresa: '',
  estadoEmpresa: '',
  bairroEmpresa: '',
  complementoEmpresa: '',
  numeroEmpresa: '',
  dataFundacaoEmpresa: null
}

const schema = yup.object().shape({
  nome: yup.string().required().label('Nome completo'),
  cpf: yup
    .string()
    .required()
    .test('cpf-teste', 'cpf inválido', value => {
      if (!value) return false

      const documento = onlyNumber(value)

      if (documento.length === 11) {
        return verificarCPF(documento)
      } else if (documento.length === 14) {
        return verificarCNPJ(documento)
      }
      return false
    })
    .label('CPF'),
  dataNascimento: yup
    .string()
    .required()
    .test('nas', 'Por favor escolha uma data válida', value => {
      return moment(value).isBetween('1922-12-31', moment(), 'day')
    })
    .label('Data de nascimento'),
  telefone: yup
    .string()
    .required()
    .test('valida-telefone', 'O telefone é inválido', value => {
      if (onlyNumber(value).length < 10) return false

      return true
    })
    .label('Telefone'),
  uf: yup
    .string()
    .test('uf-test', 'Escolha um UF', value => {
      return value !== '-1'
    })
    .nullable()
    .required()
    .label('UF'),
  nacionalidade: yup.string().nullable().required().label('Nacionalidade'),
  valorTotalFinanciamento: yup
    .string()
    .required()
    .label('Valor do honorário financiamento')
    .test(
      'valorTotalFinanciamento',
      'Informe um valor válido.',
      // '' O valor total não pode ser maior que o R$30.000,00',
      value => {
        if (Number(value) > 3000000 || Number(value) === 0) {
          return false
        }
        return true
      }
    ),
  renda: yup
    .string()
    .required()
    .label('Valor da renda')
    .test('renda', 'O valor da renda não pode ser R$0,00', value => {
      if (Number(value) === 0) {
        return false
      }
      return true
    }),
  //Step2
  email: yup.string().required().email().label('Email'),
  rg: yup.string().required().label('Rg ou RNE'),
  estadoCivil: yup.string().nullable().required().label('Estado civil'),
  nomeMae: yup.string().required().label('Nome da mãe'),
  sexo: yup.string().nullable().required().label('Sexo'),
  patrimonio: yup
    .number()
    .nullable()
    .required()
    .test('valor-pagamento-teste', 'Valor de pagamento inválido', value => {
      if (!value) return false

      return true
    })
    .label('Valor de pagamento'),
  telefone2: yup
    .string()
    .required()
    .test('valida-telefone', 'O telefone é inválido', value => {
      if (onlyNumber(value).length < 10) return false

      return true
    })
    .label('Telefone'),

  // step endereço
  cep: yup.string().nullable().required().label('CEP'),
  estado: yup
    .string()
    .test('uf-test', 'Escolha um UF', value => {
      return value !== '-1'
    })
    .nullable()
    .required()
    .label('Estado'),
  cidade: yup.string().nullable().required().label('Cidade'),
  endereco: yup.string().nullable().required().label('Endereço'),
  bairro: yup.string().nullable().required().label('Bairro'),
  complemento: yup.string().nullable().label('Complemento'),
  numero: yup.string().nullable().required().label('número'),
  situacaoImovel: yup
    .number()
    .nullable()
    .required()
    .label('Situação do imóvel'),

  tipoProfissao: yup.object().nullable().required().label('Tipo de trabalho'),

  dataFundacaoEmpresa: yup
    .string()
    .nullable()
    .test('data', 'Por favor escolha uma data válida', function (value) {
      if (!(this.parent?.tipoProfissao?.codigo === 2)) {
        return true
      }
      return moment(value).isBetween('1922-12-31', moment(), 'day')
    })
    // .when('tipoProfissao', {
    //   is: tipo => tipo?.codigo === 2,
    //   then: yup
    //     .string()
    //     .nullable()
    //     .required()
    // })
    .label('Data de fundação da empresa'),

  profissao: yup
    .object()
    .nullable()
    .when('tipoProfissao', {
      is: tipo => {
        return !(tipo?.codigo === 7)
      },
      then: yup.object().nullable().required().label('Atividade')
    }),

  // numeroBeneficio: yup.string().when('tipoProfissao', {
  //   is: tipo => tipo?.codigo === 7,
  //   then: yup.string().nullable().required().label('Número de benefício')
  // }),

  nomeEmpresa: yup.string().when('tipoProfissao', {
    is: tipo => tipo?.codigo === 2,
    then: yup.string().nullable().required().label('Nome da empresa')
  }),

  cnpjEmpresa: yup
    .string()
    .nullable()

    .when('tipoProfissao', {
      is: tipo => tipo?.codigo === 2,
      then: yup
        .string()
        .nullable()
        .required()
        .test('cnpj-teste', 'CNPJ inválido', value => {
          if (!value) return false
          return verificarCNPJ(value)
        })
        .label('CNPJ')
    }),
  cepEmpresa: yup
    .string()
    .nullable()
    .when('tipoProfissao', {
      is: tipo => tipo?.codigo === 2,
      then: yup.string().nullable().required().label('CEP')
    }),
  estadoEmpresa: yup
    .string()
    .nullable()
    .when('tipoProfissao', {
      is: tipo => tipo?.codigo === 2,
      then: yup.string().nullable().required().label('Estado')
    }),
  bairroEmpresa: yup
    .string()
    .nullable()
    .when('tipoProfissao', {
      is: tipo => tipo?.codigo === 2,
      then: yup.string().nullable().required().label('Bairro')
    }),
  complementoEmpresa: yup
    .string()
    .nullable()
    .when('tipoProfissao', {
      is: tipo => tipo?.codigo === 2,
      then: yup.string().nullable().required().label('Complemento')
    }),
  numeroEmpresa: yup
    .string()
    .nullable()
    .when('tipoProfissao', {
      is: tipo => tipo?.codigo === 2,
      then: yup.string().nullable().required().label('Número')
    }),

  anos: yup
    .string()
    .nullable()
    .when('tipoProfissao', {
      is: tipo => !(tipo?.codigo === 2),
      then: yup.string().nullable().required().label('Anos')
    }),
  meses: yup
    .string()
    .nullable()
    .when('tipoProfissao', {
      is: tipo => !(tipo?.codigo === 2),
      then: yup.string().nullable().required().label('Meses')
    }),
  // anos?: number
  // meses?: number
  telefoneComercial: yup
    .string()
    .required()
    .test('valida-telefone', 'O telefone é inválido', value => {
      if (onlyNumber(value).length < 10) return false

      return true
    })
    .label('Telefone')
  // 'cep',
  // 'estado',
  // 'cidade',
  // 'endereco',
  // 'bairro',
  // 'complemento',
  // 'numero',
  // 'situacaoImovel'
})
const valorMax = 30000
export default function financiamentoSteps() {
  const styles = useStyles()
  const theme = useTheme()
  const query = useQueryParams()
  const history = useHistory()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  const [loadingCep, setLoadingCep] = useState(false)
  const [aceitoCET, setAceitoCET] = useState(false)

  const [propostaId, setPropostaId] = useState('')
  const [loading, setLoading] = useState(false)

  const [loadingFinanciamento, setLoadingFinanciamento] = useState(false)

  const [preAnalise, setPreAnalise] = useState<IPreAnaliseResponse>(null)

  const [condicoes, setCondicoes] = useState<SimulacaoResponse>(null)
  const [existeValorParcial, setExisteValorParcial] = useState<boolean>(false)
  const [loadingSimulacao, setLoadingSimulacao] = useState(true)
  const [parcelaSelecionada, setParcelaSelecionada] = useState<ParcelaBV>(null)

  //STEPS
  const [activeStep, setActiveStep] = React.useState(0)
  const lastStep = 6

  const [
    resumoFinanciamento,
    setResumoFinanciamento
  ] = useState<IResumoFinanciamento>()

  const {
    handleSubmit,
    errors,
    register,
    watch,
    trigger,
    control,
    setValue,
    formState
  } = useForm<IFormFinanciamento>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: financiamentoDefault
  })

  const command = watch()

  const [loadingDados, setLoadingDados] = useState(false)

  const handleRegister = (name, e: React.ChangeEvent<{ value: unknown }>) => {
    setValue(name, e.target.value, { shouldValidate: true })
  }

  function handleChangeValor(key: keyof IFormFinanciamento, valor: number) {
    let valorFormatado = valor / 100
    if (!valorFormatado || valorFormatado < 0) {
      valor = 0
    }

    // return valorFormatado
    setValue(key, valor, { shouldValidate: true })
  }

  const handleItemValue = (
    name: keyof IFormFinanciamento,
    item,
    shouldValidate = true
  ) => {
    setValue(name, item, {
      shouldValidate: shouldValidate
    })
  }

  const id = query.get('p')

  function obterValorMax() {
    return resumoFinanciamento?.valorTotal < valorMax
      ? resumoFinanciamento?.valorTotal
      : valorMax
  }

  function obterValorCurrency(valor: number) {
    return valor / 100
  }

  async function buscarCep(cep, ehEmpresa = false) {
    try {
      let cepNumeros = onlyNumber(cep)
      if (cepNumeros.length === 8) {
        setLoadingCep(true)
        const { data } = await ObterCep(cepNumeros)

        if (data?.localidade) {
          setValue(ehEmpresa ? 'cidadeEmpresa' : 'cidade', data.localidade, {
            shouldValidate: true
          })
        }
        if (data?.logradouro) {
          setValue(
            ehEmpresa ? 'enderecoEmpresa' : 'endereco',
            data.logradouro,
            { shouldValidate: true }
          )
        }

        if (data?.uf) {
          setValue(
            ehEmpresa ? 'estadoEmpresa' : 'estado',
            data.uf.toUpperCase(),
            {
              shouldValidate: true
            }
          )
        }
        if (data?.bairro) {
          setValue(
            ehEmpresa ? 'bairroEmpresa' : 'bairro',
            data.bairro.toUpperCase(),
            {
              shouldValidate: true
            }
          )
        }
        setLoadingCep(false)
      }
    } catch (error) {
      setLoadingCep(false)
    }
  }

  useEffect(() => {
    register('solicitacaoOrcamentoId')
    register('propostaId')
    //step 0
    register('nome')
    register('cpf')
    register('dataNascimento')
    register('telefone')
    register('uf')
    register('renda')
    register('valorTotalFinanciamento')

    //Step 1
    register('sexo')
    register('estadoCivil')
    register('patrimonio')
    register('nacionalidade')
    register('telefone2')

    //step endereço
    register('estado')
    register('situacaoImovel')

    //Step profissao
    register('tipoProfissao')
    register('profissao')
    register('estadoEmpresa')
    register('cnpjEmpresa')
    register('dataFundacaoEmpresa')

    register('anos')
    register('meses')
    // register('nomeProcedimento')
    // register('nomeParceiro')
  }, [])

  useEffect(() => {
    //Popular valores vindo da tela anterior
    if (id) {
      setLoading(true)
      setLoadingFinanciamento(true)
      obterResumoFinanciamento(id)
        .then(response => {
          const form = response.data
          setLoading(false)
          setLoadingFinanciamento(false)

          //TODO REMOVER
          // form.valorTotalFinanciamento = 1000000
          // form.valorTotal = 1000000
          setResumoFinanciamento(form)

          for (const key in form) {
            let value = form[key]
            if (key === 'dataNascimentoPaciente') {
              value = moment(value).format('yyyy-MM-DD')
            }
            handleItemValue(key as any, value, false)
          }
        })
        .catch(err => {
          showNotificationErrorAPI(err)
          // history.goBack()
          // setLoading(false)
          // setLoadingFinanciamento(false)
        })
    }
    setPropostaId(id)
  }, [id])

  useEffect(() => {
    if (command.telefone2 && command.telefone2 !== command.telefone) {
      // handleItemValue('telefone', command.telefone2, false)
      setValue('telefone', command.telefone2)
    }
  }, [command.telefone2])

  useEffect(() => {
    buscarCep(command.cep)
  }, [command.cep])

  useEffect(() => {
    var campos = [
      'profissao',
      // 'numeroBeneficio',
      // 'anos',
      // 'meses',
      'telefoneComercial',
      'nomeEmpresa',
      'cnpjEmpresa',
      'cepEmpresa',
      'estadoEmpresa',
      'bairroEmpresa',
      'complementoEmpresa',
      'numeroEmpresa'
    ]
    campos.forEach(campo => {
      setValue(campo, null, { shouldDirty: false, shouldValidate: false })
    })
  }, [command.tipoProfissao])

  useEffect(() => {
    buscarCep(command.cepEmpresa, true)
  }, [command.cepEmpresa])

  const handleRadiosSexo = e => {
    handleItemValue('sexo' as any, parseInt(e.target.value))
  }

  async function simularFinanciamento(valorFinanciamento = 0) {
    try {
      setLoadingSimulacao(true)
      let form = command

      var body = {
        Cpf: form?.cpf,
        DataNascimento: moment(
          form?.dataNascimento,
          'YYYY-MM-DD'
        ).toISOString(),
        Uf: form?.uf,
        ValorSolicitado: obterValorCurrency(form?.valorTotalFinanciamento),
        SolicitacaoFinanciamentoId: preAnalise?.id
      }

      if (valorFinanciamento > 0) {
        body.ValorSolicitado = valorFinanciamento
      }

      let { data } = await simularCondicoes(body)

      var parcelasDisponiveis = data?.listaParcelas?.filter(p => p.elegivel)
      var qtdParcelasDisponiveis = parcelasDisponiveis?.length

      if (qtdParcelasDisponiveis > 0) {
        setParcelaSelecionada(parcelasDisponiveis[qtdParcelasDisponiveis - 1])
      } else {
        setParcelaSelecionada(null)
      }

      setExisteValorParcial(data.existeValorParcial)
      setCondicoes(data)
      setLoadingSimulacao(false)

      if (data.existeValorParcial) {
        propostaParcial(data)
      }
    } catch (error) {
      showNotificationErrorAPI(error)
    }
  }
  function propostaParcial(condicoes) {
    Swal.fire({
      title: 'Valor solicitado indisponível.',
      icon: 'warning',
      html: `Você solicitou o valor de <b>${formatarDinheiro(
        obterValorCurrency(command?.valorTotalFinanciamento)
      )}</b>.
       Infelizmente não encontramos crédito para este valor. Encontramos uma proposta para o valor de <b>${formatarDinheiro(
         condicoes?.valorMaximoFinanciado
       )}</b>.
       <br />
       <br />
       Deseja solicitar financiamento para o valor disponível?`,
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then(v => {
      if (v.isConfirmed) {
        var valorFinancimento = condicoes?.valorMaximoFinanciado * 100
        handleItemValue('valorTotalFinanciamento', valorFinancimento)
        setLoadingSimulacao(true)
        obterPreAnalise(true, valorFinancimento)
      }
    })
  }

  function obterParcelasDisponiveis() {
    var parcelasDisponiveis = condicoes?.listaParcelas?.filter(p => p.elegivel)
    return parcelasDisponiveis
  }

  useEffect(() => {
    if (activeStep === 1) {
      simularFinanciamento()
    }
  }, [activeStep])

  const handleNext = async () => {
    let valido = await validarStep(activeStep)

    if (!valido) {
      return
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const validarStep = async (activeStep: number) => {
    var campos = formularioCamposDoStep[activeStep]

    if (campos) {
      const result = await trigger(campos as any)
      return result
    }
  }

  const formularioCamposDoStep = [
    [
      'nome',
      'cpf',
      'dataNascimento',
      'telefone',
      'uf',
      'renda',
      'valorTotalFinanciamento'
    ],
    [],
    [
      'email',
      'nacionalidade',
      'rg',
      'sexo',
      'nomeMae',
      'telefone',
      'uf',
      'patrimonio',
      'estadoCivil'
    ],
    [
      'cep',
      'estado',
      'cidade',
      'endereco',
      'bairro',
      'complemento',
      'numero',
      'situacaoImovel'
    ],
    [
      'tipoProfissao',
      'profissao',
      // 'numeroBeneficio',
      'anos',
      'meses',
      'telefoneComercial',
      'nomeEmpresa',
      'cnpjEmpresa',
      'cepEmpresa',
      'estadoEmpresa',
      'bairroEmpresa',
      'complementoEmpresa',
      'numeroEmpresa',
      'situacaoImovelEmpresa',
      'dataFundacaoEmpresa'
    ],
    []
  ]

  const debugSteps = false

  const handleBackToStep = stepNumber => {
    if (!stepNumber && stepNumber !== 0) {
      setActiveStep(activeStep - 1)
      return
    }

    if (debugSteps || stepNumber < activeStep) {
      setActiveStep(stepNumber)
    }
  }

  function obterEhAposentado() {
    return command?.tipoProfissao?.codigo === 7
  }

  function obterEhEmpresario() {
    return command?.tipoProfissao?.codigo === 2
  }

  async function obterPreAnalise(
    ehValorParcial = false,
    valorFinanciamento?: number
  ) {
    try {
      let form = command
      form.propostaId = id

      let valido = await validarStep(activeStep)

      if (!valido || !verificarCPF(form.cpf)) {
        return
      }

      setLoadingFinanciamento(true)

      form.renda = obterValorCurrency(form.renda)
      form.valorTotalFinanciamento = obterValorCurrency(
        form.valorTotalFinanciamento
      )
      if (!!valorFinanciamento && ehValorParcial) {
        form.valorTotalFinanciamento = obterValorCurrency(valorFinanciamento)
      }

      let { data } = await cadastrarPreFinanciamento(form)

      setPreAnalise(data)
      if (data?.status === StatusFinanciamento.RECUSADA.Id) {
        Swal.fire(
          'Ops...',
          `Não encontramos crédito para o CPF informado.
          <br/><br/> Para mais informações por favor entre em contato com
          a nossa equipe pelo canal
          <br/>
          <div style="display:flex;align-Items:center; gap:20px;justify-content:center">
          <img class="_afvz" style="width:100px" alt="Página principal do WhatsApp" src="https://static.whatsapp.net/rsrc.php/v3/y7/r/DSxOAUB0raA.png">
          <a href="https://wa.me/5598992293099?text=Ol%C3%A1%2C+estou+querendo+ajuda+para+continuar+minha+solicitação+de+financiamento+pela+Syn+Sa%C3%BAde.">+55 89 99229-3099</a>
          </div>
          .`,
          'error'
        ).then(async result => {
          window.location.href = '/'
        })
        handleItemValue('cpf', '')
        handleItemValue('nome', '')
        handleItemValue('dataNascimento', '')
      } else if (ehValorParcial) {
        await simularFinanciamento(form?.valorTotalFinanciamento)
      } else {
        handleNext()
      }

      setLoadingFinanciamento(false)

      // history.replace('/financiamento-sucesso')
    } catch (e) {
      // Swal.fire('Ops...', 'A mensagem é obrigatória', 'warning')
      setLoadingFinanciamento(false)
      showNotificationErrorAPI(e)
    }
  }

  const getSteps = number => {
    const steps = []
    for (let index = 0; index < number; index++) {
      steps.push(
        <Step key={index}>
          <StepLabel
            StepIconComponent={v => StepIconSyn({ ...v, key: index + 1 })}
            onClick={() => handleBackToStep(index)}
          ></StepLabel>
          <StepContent></StepContent>
        </Step>
      )
    }
    return steps
  }

  const [cleanEmail, setCleanEmail] = useState('')
  useEffect(() => {
    const ajusteEmail = removerAcento(command.email)
    setCleanEmail(ajusteEmail)
  }, [command.email])

  async function submit(form: IFormFinanciamento) {
    // console.log('form', form.email)
    form.email = cleanEmail
    try {
      setLoading(true)
      // setParcelaSelecionada(24)
      var form2
      //  form2 = JSON.parse(
      //   '{"anos":"10","meses":"2","nome":"Izabela Lima Silva","email":"email@email.com","rg":"1231232","nomeMae":"Mae do fulano","cidade":"Brasília","endereco":"QN 7E Conjunto 5","bairro":"RIACHO FUNDO II","complemento":"complemento","numero":"12","numeroBeneficio":"","cidadeEmpresa":"","enderecoEmpresa":"","bairroEmpresa":"","complementoEmpresa":"","numeroEmpresa":"","cpf":"00161074111","telefone":"61984129357","cep":"71880-055","telefoneComercial":"(61) 9999-9911 ","solicitacaoOrcamentoId":"44868965-1353-49f3-9f98-ac80a767d146","propostaId":"","dataNascimento":"1985-09-03T03:00:00","uf":"DF","renda":500000,"valorTotalFinanciamento":1000000,"sexo":1,"estadoCivil":3,"patrimonio":50000000,"nacionalidade":"BRASILEIRO","tipoProfissao":{"codigo":1,"descricao":"ASSALARIADO","obrigaTipoFontePagadora":false,"ativo":true},"profissao":{"codigo":1017,"descricao":"ACUPUNTURISTA","ativo":true},"estado":"DF","situacaoImovel":1,"telefone2":"61984129357"}'
      // )

      // form2 = JSON.parse(
      //   '{"nome":"Izabela Lima Silva","email":"emeil@email.com","rg":"123456","nomeMae":"Mae do fulano","cidade":"Brasília","endereco":"QN 7E Conjunto 5","bairro":"RIACHO FUNDO II","complemento":"teste","numero":"1","numeroBeneficio":"","cidadeEmpresa":"","enderecoEmpresa":"","bairroEmpresa":"","complementoEmpresa":"","numeroEmpresa":"","cpf":"00000120090","telefone":"61984129357","cep":"71880-055","telefoneComercial":"(61) 9959-55555","solicitacaoOrcamentoId":"44868965-1353-49f3-9f98-ac80a767d146","propostaId":"","dataNascimento":"1985-09-03T03:00:00","uf":"DF","renda":1000000,"valorTotalFinanciamento":2500000,"sexo":1,"estadoCivil":5,"patrimonio":7777777,"nacionalidade":"BRASILEIRO","tipoProfissao":{"codigo":1,"descricao":"ASSALARIADO","obrigaTipoFontePagadora":false,"ativo":true},"profissao":{"codigo":189,"descricao":"AEROVIARIO","ativo":true},"estado":"DF","situacaoImovel":1,"anos":"10","meses":"2","telefone2":"61984129357"}'
      // )

      var response = await originarProposta(
        // form2,
        form,
        // 24,
        parcelaSelecionada?.quantidadeParcelas,
        preAnalise?.id
      )

      history.replace('/financiamento-sucesso')
    } catch (error) {
      showNotificationErrorAPI(error)
      setLoading(false)
    }
  }
  const normalise = value => ((value - 0) * 100) / (lastStep - 0)

  return (
    <>
      <Container
        component="main"
        className={`fadeInTransition syn-container-out ${styles.root}`}
        maxWidth="md"
      >
        <CssBaseline />
        {ehXS && (
          <LinearProgress
            variant="determinate"
            value={normalise(activeStep + 1)}
          />
        )}
        {/* <Box
              className="syn-box-out"
              style={{ width: ehXS ? '100%' : '800px' }}
            >
              <UBox render={loading}>
                <LoadingGeral />
              </UBox> */}

        <form
          className="box-financiamento"
          onSubmit={handleSubmit(submit)}
          noValidate={true}
        >
          <UBox mb={ehXS ? 2 : 5}>
            <Box textAlign="center">
              <img
                src="/image/logo.png"
                alt="logo syn saúde"
                width={ehXS ? '46' : '56'}
                style={{
                  marginBottom: ehXS && activeStep === 0 ? '20px' : '0'
                }}
              />

              {activeStep === 0 && (
                <Typography variant="body1" color="primary">
                  <b>
                    Seja bem-vindo(a) ao pedido de financiamento da Syn Saúde
                  </b>
                </Typography>
              )}
            </Box>
            <UBox
              render={activeStep === 0}
              maxWidth={600}
              margin="0 auto"
              textAlign="center"
            >
              {/* <Alert severity="info"> */}
              <Typography variant="body2">
                Aqui você pode fazer o pedido de pré análise de financiamento do
                seu procedimento.
                <br />
              </Typography>
              {/* </Alert> */}
            </UBox>
          </UBox>
          {/* <UBox style={{ wordBreak: 'break-all' }}> */}
          {/* {JSON.stringify(command)}

          <br />
          <br /> */}
          {/* {JSON.stringify(NACIONALIDADE)} */}
          {/* {parcelaSelecionada}x
            <br />
            <br />
            {JSON.stringify(preAnalise)}
            <br />
            <br /> */}
          {/* {JSON.stringify(command)}
            <br />
            <br />
            {JSON.stringify(errors)} */}
          {/* </UBox> */}
          <Grid container>
            {!ehXS && (
              <Grid item xs={12} sm={1}>
                <Box mt={0} pt={1} pr={2} mr={5}>
                  <Stepper
                    connector={<StepConnectorSyn />}
                    activeStep={activeStep}
                    style={{ margin: 0, padding: 0, background: 'none' }}
                    orientation="vertical"
                  >
                    {getSteps(lastStep)}
                  </Stepper>
                </Box>
              </Grid>
            )}
            <Grid item sm={10}>
              <UBox render={loadingFinanciamento && activeStep === 0}>
                <LoadingGeral title="Aguarde um momento estamos consultando seus dados." />
              </UBox>
              <UBox hidden={loadingFinanciamento}>
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 0}
                  // show={false}
                  hideBack
                  handleBackToStep={handleBackToStep}
                  handleNext={() => {
                    obterPreAnalise()
                  }}
                  loading
                  textoTitulo={
                    'Revise os dados do procedimento, e continue a solicitação de financiamento.'
                  }
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <ResumoProposta
                            resumoFinanciamento={resumoFinanciamento}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <UBox>
                            <Typography variant="h6" color="primary">
                              <b style={{ marginRight: '12px' }}>
                                Dados do proponente
                              </b>
                            </Typography>
                            <Divider />
                            {/* {JSON.stringify(command)} */}
                            <UBox mt={2}>
                              <Alert severity="info">
                                <Typography variant="body2">
                                  A solicitação de financiamento deve ser
                                  realizada em nome do próprio paciente ou em
                                  nome de{' '}
                                  <b>
                                    parentes de 1º grau (pai, mãe, filhos ou
                                    cônjuges).
                                  </b>
                                </Typography>
                              </Alert>
                            </UBox>
                          </UBox>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              size="medium"
                              disabled={loadingDados}
                              erro={errors}
                              // autoFocus
                              value={command.nome}
                              defaultValue={command.nome}
                              // control={control}
                              inputRef={register}
                              inputProps={{ maxLength: 100 }}
                              name="nome"
                              label="Nome completo"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                size="medium"
                                disabled={loadingDados}
                                erro={errors}
                                type="tel"
                                mask={'999.999.999-99'}
                                control={control}
                                name="cpf"
                                label="Seu CPF"
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <DateInput
                                errors={errors}
                                name="dataNascimento"
                                label="Data de nascimento"
                                value={command.dataNascimento}
                                control={control}
                                handleChange={value =>
                                  handleItemValue('dataNascimento', value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                disabled={loadingDados}
                                size="medium"
                                erro={errors}
                                type="tel"
                                maskTelCel
                                control={control}
                                inputProps={{ maxLength: 100, minLength: 10 }}
                                name="telefone"
                                label="Telefone"
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                fullWidth
                                size="medium"
                                variant="outlined"
                                className={styles.formControl2}
                              >
                                <InputLabel htmlFor="uf-input" shrink={true}>
                                  Seu UF
                                </InputLabel>

                                <Select
                                  error={!!errors.uf}
                                  displayEmpty
                                  label="Sua UF"
                                  id="uf-input"
                                  className={styles.selectEmpty}
                                  labelId="label-uf"
                                  defaultValue=""
                                  value={command?.uf || '-1'}
                                  placeholder="Sua UF"
                                  name="uf"
                                  onChange={(e, v) => {
                                    handleRegister('uf', e)
                                  }}
                                >
                                  <MenuItem value="-1">
                                    <em>Selecione UF</em>
                                  </MenuItem>
                                  {estados.map((estado, i) => (
                                    <MenuItem key={i} value={estado.Initials}>
                                      {estado.Initials}
                                    </MenuItem>
                                  ))}
                                </Select>

                                <FormHelperText error={!!errors.uf}>
                                  {errors.uf?.message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <UBox mt={2}>
                            <Typography variant="h6" color="primary">
                              <b>Dados do financiamento</b>
                            </Typography>
                            <Divider />
                          </UBox>
                        </Grid>
                        {!ehXS && <Grid item xs={12} sm={6}></Grid>}
                        <Grid item xs={12}>
                          <div>
                            <Typography variant="body2">
                              Valor máximo do financiamento: (
                              <b>{formatarDinheiro(obterValorMax())}</b>)
                            </Typography>
                          </div>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className={styles.formControl2}
                                fullWidth
                                size="medium"
                                variant="outlined"
                              >
                                <CurrencyInput
                                  fullWidth
                                  erro={errors}
                                  autoFocus
                                  variant="outlined"
                                  // margin="normal"
                                  name="valor"
                                  label="Renda"
                                  hiddenLabel
                                  control={control}
                                  type="tel"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  // max={100000}
                                  onValueChange={value =>
                                    handleChangeValor('renda', value)
                                  }
                                  value={command?.renda || 0}
                                />
                                <FormHelperText error={!!errors.renda}>
                                  {errors.renda?.message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                          <TextFieldMU
                            data-cy="renda"
                            error={!!errors.renda}
                            helperText={errors.renda?.message}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            label="Sua renda"
                            value={command.renda}
                            onFocus={e => e.target.select()}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = event.target.value
                              handleItemValue('renda', value, !!value)
                            }}
                            InputProps={{
                              inputComponent: NumberFormatCustom as any
                            }}
                          />
                        </Grid> */}
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className={styles.formControl2}
                                fullWidth
                                size="medium"
                                variant="outlined"
                              >
                                <CurrencyInput
                                  fullWidth
                                  erro={errors}
                                  autoFocus
                                  variant="outlined"
                                  // margin="normal"
                                  name="valor"
                                  label="Valor do financiamento"
                                  hiddenLabel
                                  control={control}
                                  type="tel"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  max={obterValorMax() * 100}
                                  onValueChange={value =>
                                    handleChangeValor(
                                      'valorTotalFinanciamento',
                                      value
                                    )
                                  }
                                  value={command?.valorTotalFinanciamento || 0}
                                />
                                <FormHelperText
                                  error={!!errors.valorTotalFinanciamento}
                                >
                                  {errors.valorTotalFinanciamento?.message}
                                </FormHelperText>
                              </FormControl>
                              {/* <TextFieldMU
                            data-cy="valorTotalFinanciamento"
                            error={!!errors.valorTotalFinanciamento}
                            helperText={errors.valorTotalFinanciamento?.message}
                            variant="outlined"
                            fullWidth
                            name="valorTotalFinanciamento"
                            margin="normal"
                            label="Valor do financiamento"
                            value={command.valorTotalFinanciamento}
                            onFocus={e => e.target.select()}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = event.target.value
                              handleItemValue(
                                'valorTotalFinanciamento',
                                !!value
                              )
                            }}
                            InputProps={{
                              inputComponent: NumberFormatCustom as any
                            }}
                          /> */}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </StepContentSyn>
              </UBox>

              <UBox render={loadingSimulacao && activeStep === 1}>
                <LoadingGeral title="Estamos buscando as melhores condições para seu financiamento." />
              </UBox>
              <UBox hidden={loadingSimulacao}>
                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 1}
                  // show={true}
                  hideNext={loadingSimulacao}
                  handleBackToStep={handleBackToStep}
                  hiden
                  handleNext={() => {
                    if (parcelaSelecionada) {
                      handleNext()
                    } else if (condicoes.existeValorParcial) {
                      propostaParcial(condicoes)
                    } else {
                      Swal.fire(
                        'Sem condições disponíveis.',
                        'Não podemos continuar a solicitação de financiamento pois não existe condições disponíveis no momento.',
                        'warning'
                      )
                    }
                  }}
                  textoTitulo={
                    'Escolha qual proposta de financiamento mais te agrada para simularmos suas parcelas.'
                  }
                >
                  {/* <UBox mt={2} mb={3}>
              <Typography variant="subtitle1" color="primary">
                <b>Simulação do financiamento</b>
              </Typography>
              <Divider />
            </UBox> */}

                  <UBox p={2} className={styles.boxFormaPagamento2}>
                    <UBox className={'item'}>
                      <b>
                        Valor solicitado de{' '}
                        {formatarDinheiro(
                          obterValorCurrency(command.valorTotalFinanciamento)
                        )}
                      </b>
                    </UBox>
                  </UBox>

                  <UBox mb={2}>
                    <Typography variant="subtitle1" color="primary">
                      <b>Condições disponíveis:</b>
                    </Typography>
                  </UBox>
                  <UBox p={2} render={!parcelaSelecionada}>
                    Nenhuma condição disponível. Tente novamente em outro
                    momento.
                  </UBox>
                  <UBox render={!!parcelaSelecionada}>
                    <UBox p={2} className={styles.boxFormaPagamento}>
                      {obterParcelasDisponiveis()?.map((parcela, index) => (
                        <BoxParcela
                          key={index}
                          handleClick={() => setParcelaSelecionada(parcela)}
                          ativo={
                            parcelaSelecionada?.quantidadeParcelas ===
                            parcela?.quantidadeParcelas
                          }
                          qtd={parcela?.quantidadeParcelas}
                          valor={parcela?.valorParcelaSemSeguro}
                          taxa={parcela?.taxaFinanciamentoMensal?.toString()}
                        />
                      ))}

                      {/* <BoxParcela
                  handleClick={() => setParcelaSelecionada(24)}
                  ativo={parcelaSelecionada === 24}
                  qtd={24}
                  valor={condicoes?.listaParcelas[1]?.valorParcelaSemSeguro}
                  taxa={condicoes?.listaParcelas[1]?.taxaFinanciamentoMensal?.toString()}
                /> */}
                    </UBox>

                    {parcelaSelecionada && (
                      <ResumoSimulacao
                        form={command}
                        parcelaSelecionada={parcelaSelecionada}
                        condicoes={condicoes}
                      />
                    )}

                    <UBox my={1}>
                      <Typography variant="subtitle1" color="primary">
                        <b>Periodo de carência: </b>30 dias
                      </Typography>

                      {/* <Typography variant="subtitle1" color="primary">
                30 dias
              </Typography> */}
                      <Alert severity="info">
                        <Typography variant="body2">
                          Caso aprovada a proposta possui carência de 30 dias,
                          com data da 1ª parcela prevista para{' '}
                          <b>{formatarData(moment().add(30, 'days'))}</b>
                          <br />
                        </Typography>
                      </Alert>
                    </UBox>
                  </UBox>
                </StepContentSyn>
              </UBox>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 2}
                // show={true}
                handleBackToStep={handleBackToStep}
                handleNext={() => {
                  handleNext()
                }}
                textoTitulo={
                  'Complete os dados do cliente, para continuar com a solicitação de financiamento.'
                }
              >
                <UBox>
                  {/* <UBox>
                <Typography variant="h6" color="primary">
                  <b style={{ marginRight: '12px' }}>Dados do cliente</b>
                </Typography>
                <Divider />
              </UBox> */}

                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        key={command.nome}
                        size="medium"
                        disabled={true}
                        erro={errors}
                        // autoFocus
                        control={control}
                        value={command.nome}
                        // defaultValue={command.nome}
                        // control={control}
                        // inputRef={register}
                        inputProps={{ maxLength: 100 }}
                        name="nome2"
                        label="Nome completo"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                      {/* {command.nome} */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="medium"
                        disabled={true}
                        erro={errors}
                        type="tel"
                        mask={'999.999.999-99'}
                        value={command.cpf}
                        name="cpf2"
                        label="Seu CPF"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateInput
                        errors={errors}
                        name="dataNascimento2"
                        label="Data de nascimento"
                        disabled={true}
                        value={command.dataNascimento}
                        control={control}
                        handleChange={value => {}}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="medium"
                        disabled={loadingDados}
                        erro={errors}
                        value={command.email}
                        defaultValue={command.email}
                        inputRef={register}
                        inputProps={{ maxLength: 100 }}
                        name="email"
                        label="Email"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}></Grid> */}
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        erro={errors}
                        name="nacionalidade"
                        label="Nacionalidade"
                        closeOnSelect
                        value={command.nacionalidade}
                        options={NACIONALIDADE}
                        getOptionLabel={option => `${option}`}
                        onChange={(event, newValue) => {
                          handleItemValue('nacionalidade', newValue as any)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="medium"
                        erro={errors}
                        // control={control}
                        value={command.rg}
                        inputRef={register}
                        inputProps={{ maxLength: 100 }}
                        name="rg"
                        label="Rg ou RNE"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body1" color="primary">
                        <b>Gênero no documento</b>
                      </Typography>
                      <RadioGroup
                        onChange={handleRadiosSexo}
                        value={command.sexo}
                      >
                        <Grid container direction="row">
                          <FormControlLabel
                            control={
                              <Radio
                                value={genero.Masculino}
                                color="primary"
                                name="sexo"
                              />
                            }
                            label="Masculino"
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                value={genero.Feminino}
                                color="primary"
                                name="sexo"
                              />
                            }
                            label="Feminino"
                          />
                        </Grid>
                      </RadioGroup>
                      {errors.sexo && (
                        <FormControl fullWidth className={styles.formControl}>
                          <FormHelperText error={!!errors.sexo}>
                            {errors.sexo?.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        size="medium"
                        disabled={loadingDados}
                        erro={errors}
                        value={command.nomeMae}
                        defaultValue={command.nomeMae}
                        inputRef={register}
                        inputProps={{ maxLength: 100 }}
                        name="nomeMae"
                        label="Nome da mãe"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        key={command.telefone}
                        disabled={loadingDados}
                        size="medium"
                        erro={errors}
                        type="tel"
                        maskTelCel
                        control={control}
                        defaultValue={command.telefone}
                        autoFocus
                        value={command.telefone2}
                        inputProps={{ maxLength: 100, minLength: 10 }}
                        name="telefone2"
                        label="Telefone"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        size="medium"
                        variant="outlined"
                        className={styles.formControl2}
                      >
                        <InputLabel htmlFor="uf-input2" shrink={true}>
                          Seu UF
                        </InputLabel>
                        <Select
                          error={!!errors.uf}
                          displayEmpty
                          label="Sua UF"
                          id="uf-input"
                          className={styles.selectEmpty}
                          labelId="label-uf"
                          defaultValue=""
                          value={command?.uf || '-1'}
                          placeholder="Sua UF"
                          name="uf"
                          // label="SeuUF"
                          // id="uf-input2"
                          // error={!!errors.uf}
                          // displayEmpty
                          // className={styles.selectEmpty}
                          // labelId="label-uf"
                          // defaultValue=""
                          // value={command?.uf || '-1'}
                          // placeholder="Sua UF"
                          // name="uf"
                          // inputRef={register}
                          onChange={(e, v) => {
                            handleRegister('uf', e)
                          }}
                        >
                          <MenuItem value="-1">
                            <em>Selecione UF</em>
                          </MenuItem>
                          {estados.map((estado, i) => (
                            <MenuItem key={i} value={`${estado.Initials}`}>
                              {estado.Initials}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={!!errors.uf}>
                          {errors.uf?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        className={styles.formControl2}
                        fullWidth
                        size="medium"
                        variant="outlined"
                      >
                        <CurrencyInput
                          fullWidth
                          erro={errors}
                          autoFocus
                          variant="outlined"
                          // margin="normal"
                          name="valor"
                          label="Valor do patrimônio"
                          hiddenLabel
                          control={control}
                          // type="tel"
                          InputLabelProps={{
                            shrink: true
                          }}
                          // max={100000}
                          onValueChange={v =>
                            handleChangeValor('patrimonio', v)
                          }
                          value={command?.patrimonio}
                          // value={command?.valor || 0}
                        />
                        <FormHelperText error={!!errors.patrimonio}>
                          {errors.patrimonio?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        erro={errors}
                        name="estadoCivil"
                        label="Estado civil"
                        closeOnSelect
                        value={
                          command.estadoCivil !== undefined
                            ? ESTADO_CIVIL.find(
                                w => w.codigo === command.estadoCivil
                              )
                            : ESTADO_CIVIL[0]
                        }
                        options={ESTADO_CIVIL}
                        getOptionLabel={option => `${option.descricao}`}
                        onChange={(event, newValue) => {
                          handleItemValue(
                            'estadoCivil',
                            newValue?.codigo as any
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                </UBox>
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 3}
                // show={true}
                handleBackToStep={handleBackToStep}
                handleNext={() => {
                  handleNext()
                }}
                textoTitulo={
                  'Estamos quase lá, agora precisamos que nos informe os dados do seu endereço residencial.'
                }
              >
                <Grid container spacing={1}>
                  {/* <Grid item xs={12}>
                <UBox mt={2}>
                  <Typography variant="subtitle1" color="primary">
                    <b>Endereço</b>
                  </Typography>
                  <Divider />
                </UBox>
              </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loadingCep}
                      type="tel"
                      size="medium"
                      erro={errors}
                      mask="99999-999"
                      control={control}
                      inputProps={{ maxLength: 64 }}
                      name="cep"
                      label="Seu CEP"
                      InputLabelProps={{
                        shrink: true
                      }}
                      loading={loadingCep}
                    />
                  </Grid>
                  {!ehXS && <Grid item xs={12} sm={6}></Grid>}

                  <Grid item xs={12} sm={4}>
                    <FormControl
                      fullWidth
                      key={command?.estado}
                      disabled={loadingCep}
                      size="medium"
                      variant="outlined"
                      className={styles.formControl2}
                    >
                      <InputLabel htmlFor="uf-input" shrink={true}>
                        UF
                      </InputLabel>
                      <Select
                        label="UF"
                        displayEmpty
                        // defaultChecked={command?.estado}
                        error={!!errors.estado}
                        className={styles.selectEmpty}
                        labelId="label-estado"
                        defaultValue={command?.estado || '-1'}
                        value={command?.estado || '-1'}
                        placeholder="Sua UF"
                        name="estado"
                        // inputRef={register}
                        onChange={(e, v) => {
                          handleRegister('estado', e)
                        }}
                      >
                        <MenuItem value="-1">
                          <em>UF</em>
                        </MenuItem>
                        {estados.map((estado, i) => (
                          <MenuItem
                            key={i}
                            selected={estado.Initials === command?.estado}
                            value={estado.Initials}
                          >
                            {estado.Initials}
                          </MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error={!!errors.estado}>
                        {errors.estado?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      disabled={loadingDados}
                      size="medium"
                      erro={errors}
                      inputRef={register}
                      inputProps={{ maxLength: 100 }}
                      name="cidade"
                      label="Sua cidade"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      disabled={loadingDados}
                      size="medium"
                      erro={errors}
                      inputRef={register}
                      inputProps={{ maxLength: 100 }}
                      name="endereco"
                      label="Seu endereço"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loadingDados}
                      size="medium"
                      erro={errors}
                      inputRef={register}
                      inputProps={{ maxLength: 100 }}
                      name="bairro"
                      label="Bairro"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loadingDados}
                      size="medium"
                      erro={errors}
                      inputRef={register}
                      inputProps={{ maxLength: 100 }}
                      name="complemento"
                      label="Complemento"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled={loadingDados}
                      size="medium"
                      erro={errors}
                      inputRef={register}
                      inputProps={{ maxLength: 100 }}
                      name="numero"
                      label="Número"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Autocomplete
                      erro={errors}
                      name="situacaoImovel"
                      label="Situacao do imóvel"
                      closeOnSelect
                      value={
                        command.situacaoImovel !== undefined
                          ? SITUACAO_IMOVEL.find(
                              n => n?.codigo === command.situacaoImovel
                            )
                          : null
                      }
                      options={SITUACAO_IMOVEL}
                      getOptionLabel={option => `${option.descricao}`}
                      onChange={(event, newValue) => {
                        handleItemValue(
                          'situacaoImovel',
                          newValue?.codigo as any
                        )
                      }}
                    />
                  </Grid>
                </Grid>
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 4}
                // show={true}
                handleBackToStep={handleBackToStep}
                handleNext={() => handleNext()}
                textoTitulo={'Informe seus dados profissionais.'}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8}>
                    <Autocomplete
                      erro={errors}
                      name="tipoProfissao"
                      label="Qual o tipo de trabalho?"
                      closeOnSelect
                      value={
                        command.tipoProfissao !== undefined
                          ? TIPOS_PROFISSOES.find(
                              n => n === command.tipoProfissao
                            )
                          : null
                      }
                      options={TIPOS_PROFISSOES}
                      getOptionLabel={option => `${option.descricao}`}
                      onChange={(event, newValue) => {
                        handleItemValue('tipoProfissao', newValue as any)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {/* Mostra campo caso seja aposentado */}
                    <UBox hidden={!obterEhAposentado()}>
                      <TextField
                        disabled={loadingDados}
                        size="medium"
                        erro={errors}
                        type="tel"
                        inputRef={register}
                        inputProps={{ maxLength: 100 }}
                        name="numeroBeneficio"
                        label="Número de benefício"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </UBox>
                    <UBox hidden={obterEhAposentado()}>
                      <Autocomplete
                        erro={errors}
                        name="profissao"
                        label="Atividade que exerce?"
                        closeOnSelect
                        value={
                          command.profissao !== undefined
                            ? PROFISSOES.find(n => n === command.profissao)
                            : null
                        }
                        options={PROFISSOES}
                        getOptionLabel={option => `${option.descricao}`}
                        onChange={(event, newValue) => {
                          handleItemValue('profissao', newValue as any)
                        }}
                      />
                    </UBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldMU
                      data-cy="renda"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      label="Sua renda"
                      value={command.renda / 100}
                      defaultValue={command.renda / 100}
                      disabled={true}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <UBox hidden={obterEhAposentado()}>
                      <TextField
                        disabled={loadingDados}
                        size="medium"
                        erro={errors}
                        type="tel"
                        maskTelCel
                        control={control}
                        inputProps={{ maxLength: 100, minLength: 10 }}
                        name="telefoneComercial"
                        label="Telefone comercial"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </UBox>
                  </Grid>
                  <UBox p={1} hidden={obterEhEmpresario()}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body1" color="primary">
                          {obterEhAposentado() ? (
                            <b>Há quanto tempo está aposentado?</b>
                          ) : (
                            <b>Há quanto tempo trabalha na empresa atual?</b>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <NumberFormat
                          variant="outlined"
                          label="Anos"
                          name="anos"
                          onValueChange={values => {
                            setValue('anos', values.value, {
                              shouldValidate: true
                            })
                          }}
                          customInput={TextFieldMU}
                          type="tel"
                          error={!!errors.anos}
                          helperText={errors?.anos?.message}
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <NumberFormat
                          variant="outlined"
                          label="Meses"
                          name="meses"
                          onValueChange={values => {
                            setValue('meses', values.value, {
                              shouldValidate: true
                            })
                          }}
                          customInput={TextFieldMU}
                          type="tel"
                          error={!!errors.meses}
                          helperText={errors?.meses?.message}
                        />
                      </Grid>
                    </Grid>
                  </UBox>
                  <UBox p={1} hidden={!obterEhEmpresario()}>
                    <UBox>
                      <Typography variant="body1" color="primary">
                        <b>Fale mais sobre a sua empresa</b>
                      </Typography>
                    </UBox>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          size="medium"
                          erro={errors}
                          type="tel"
                          // control={control}
                          inputRef={register}
                          inputProps={{ maxLength: 100 }}
                          name="nomeEmpresa"
                          label="Nome da empresa"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <TextField
                      size="medium"
                      erro={errors}
                      type="tel"
                      control={control}
                      inputProps={{ maxLength: 100 }}
                      name="cnpjEmpresa"
                      label="CNPJ da empresa"
                      InputLabelProps={{
                        shrink: true
                      }}
                    /> */}
                        <TextField
                          erro={errors}
                          type="tel"
                          name="cnpjEmpresa"
                          value={command.cnpjEmpresa}
                          placeholder="Digite o cnpj..."
                          label="CNPJ da empresa"
                          mask="99.999.999/9999-99"
                          control={control}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DateInput
                          errors={errors}
                          name="dataFundacaoEmpresa"
                          label="Data de fundação da empresa"
                          value={command.dataFundacaoEmpresa}
                          control={control}
                          handleChange={value =>
                            handleItemValue('dataFundacaoEmpresa', value)
                          }
                        />
                      </Grid>
                      {/* {!ehXS && <Grid item xs={12} sm={6}></Grid>} */}
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body1" color="primary">
                          <b>Qual o endereço da sua empresa?</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled={loadingCep}
                          type="tel"
                          size="medium"
                          erro={errors}
                          mask="99999-999"
                          control={control}
                          inputProps={{ maxLength: 64 }}
                          name="cepEmpresa"
                          label="Seu CEP"
                          InputLabelProps={{
                            shrink: true
                          }}
                          loading={loadingCep}
                        />
                      </Grid>
                      {!ehXS && <Grid item xs={12} sm={6}></Grid>}

                      <Grid item xs={12} sm={4}>
                        <FormControl
                          fullWidth
                          key={command?.estadoEmpresa}
                          disabled={loadingCep}
                          size="medium"
                          variant="outlined"
                          className={styles.formControl2}
                        >
                          <InputLabel htmlFor="uf-input" shrink={true}>
                            Seu UF
                          </InputLabel>

                          <Select
                            displayEmpty
                            label="Seu UF"
                            error={!!errors.estadoEmpresa}
                            className={styles.selectEmpty}
                            labelId="label-estadoEmpresa"
                            defaultValue={command?.estadoEmpresa || '-1'}
                            value={command?.estadoEmpresa || '-1'}
                            placeholder="Sua UF"
                            name="estadoEmpresa"
                            // inputRef={register}
                            onChange={(e, v) => {
                              handleRegister('estadoEmpresa', e)
                            }}
                          >
                            <MenuItem value="-1">
                              <em>Selecione UF</em>
                            </MenuItem>
                            {estados.map((estado, i) => (
                              <MenuItem
                                key={i}
                                selected={
                                  estado.Initials === command?.estadoEmpresa
                                }
                                value={estado.Initials}
                              >
                                {estado.Initials}
                              </MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error={!!errors.estadoEmpresa}>
                            {errors.estadoEmpresa?.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                          disabled={loadingDados}
                          size="medium"
                          erro={errors}
                          inputRef={register}
                          inputProps={{ maxLength: 100 }}
                          name="cidadeEmpresa"
                          label="Sua cidade"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          disabled={loadingDados}
                          size="medium"
                          erro={errors}
                          inputRef={register}
                          inputProps={{ maxLength: 100 }}
                          name="enderecoEmpresa"
                          label="Seu endereço"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled={loadingDados}
                          size="medium"
                          erro={errors}
                          inputRef={register}
                          inputProps={{ maxLength: 100 }}
                          name="bairroEmpresa"
                          label="Bairro"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled={loadingDados}
                          size="medium"
                          erro={errors}
                          inputRef={register}
                          inputProps={{ maxLength: 100 }}
                          name="complementoEmpresa"
                          label="Complemento"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextField
                          disabled={loadingDados}
                          size="medium"
                          erro={errors}
                          inputRef={register}
                          inputProps={{ maxLength: 100 }}
                          name="numeroEmpresa"
                          label="Número"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                    </Grid>
                  </UBox>
                </Grid>
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 5}
                // show={true}
                loading={loading}
                handleBackToStep={handleBackToStep}
                textoTitulo={'Revise o resumo da solicitação de financiamento.'}
                showFinish
                disabledButton={!aceitoCET}
                textFinish="Solicitar financiamento"
                handleFinish={
                  // () => {
                  // if (!aceitoCET) {
                  //   Swal.fire(
                  //     'Confirme que está ciente das taxas.',
                  //     'Precisamos da sua confirmação para continuar com o financiamento.',
                  //     'warning'
                  //   )
                  // } else {
                  handleSubmit(submit)
                  // }
                  // }
                }
              >
                <ResumoSimulacao
                  condicoes={condicoes}
                  parcelaSelecionada={parcelaSelecionada}
                  form={command}
                />

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={formState.isSubmitting}
                        checked={aceitoCET}
                        onChange={(e, value) => setAceitoCET(value)}
                        value={aceitoCET}
                      />
                    }
                    label="Confirmo que estou ciente sobre o CET e taxas do meu contrato."
                  />
                </FormGroup>
              </StepContentSyn>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  )
}

interface IPropsParcela {
  qtd: number
  valor: number
  taxa: string
  ativo: boolean
  handleClick: () => void
}

function BoxParcela({ ativo, qtd, valor, taxa, handleClick }: IPropsParcela) {
  return (
    <UBox onClick={() => handleClick()} className={`item ${ativo && 'ativo'}`}>
      {qtd}x de
      <br />
      <b>{formatarDinheiro(valor)}</b>
      <br />
      Taxa: {taxa}% a.m.
    </UBox>
  )
}

interface IPropsResumo {
  resumoFinanciamento: IResumoFinanciamento
}

function ResumoProposta({ resumoFinanciamento }: IPropsResumo) {
  return (
    <UBox render={resumoFinanciamento?.cpf}>
      <Typography variant="h6" color="primary">
        <b>Dados do Procedimento</b>
      </Typography>
      <Divider />
      {/* {JSON.stringify(command)} */}
      <Box mt={2}>
        <Typography color="primary">
          <b>Nome do procedimento:</b>
          <span style={{ marginLeft: '12px' }}>
            {resumoFinanciamento?.nomeProcedimento}
          </span>
        </Typography>
      </Box>
      {resumoFinanciamento?.localAtendimento && (
        <Typography color="primary">
          <b>Local de atendimento:</b>
          <span style={{ marginLeft: '12px' }}>
            {resumoFinanciamento?.localAtendimento}
          </span>
        </Typography>
      )}
      <Typography color="primary">
        <b>Paciente:</b>
        <span style={{ marginLeft: '12px' }}>{resumoFinanciamento?.nome}</span>
      </Typography>
      <Typography color="primary">
        <b>Documento:</b>
        <span style={{ marginLeft: '12px' }}>
          {formatarCPF(resumoFinanciamento?.cpf)}
        </span>
      </Typography>
      <Typography color="primary">
        <b>Idade:</b>
        <span style={{ marginLeft: '12px' }}>
          {resumoFinanciamento?.idadePaciente} anos
        </span>
      </Typography>
      <Typography color="primary">
        <b>Médico:</b>
        <span style={{ marginLeft: '12px' }}>
          {resumoFinanciamento?.nomeMedico}
        </span>
      </Typography>
      <Typography color="primary">
        <b>Valor total do procedimento:</b>
        <span style={{ marginLeft: '12px' }}>
          {formatarDinheiro(resumoFinanciamento?.valorTotal ?? 0)}
        </span>
      </Typography>
    </UBox>
  )
}

interface IResumoSimulacao {
  form: IFormFinanciamento
  parcelaSelecionada: ParcelaBV
  condicoes: SimulacaoResponse
}

function ResumoSimulacao({
  parcelaSelecionada,
  condicoes,
  form
}: IResumoSimulacao) {
  return (
    <UBox>
      {' '}
      <Typography variant="subtitle1" color="primary">
        <b>Resumo da simulação em {parcelaSelecionada?.quantidadeParcelas}x</b>
      </Typography>
      <UBox
        className="box-resumo"
        p={2}
        style={{ border: '1px solid #ddd', borderRadius: '8px' }}
      >
        <UBox mb={2}>
          <Grid container spacing={1}>
            {/* <Grid item xs={3}>
        <Typography variant="body2">
          <small>Valor total da proposta</small>
          <br />
          {formatarDinheiro(30000)}
        </Typography>
      </Grid> */}
            <Grid item xs={4}>
              <Typography variant="body2">
                <small>Valor solicitado</small>
                <br />
                {formatarDinheiro(parcelaSelecionada?.valorLiberado)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                <small>Valor da entrada</small>
                <br />
                {formatarDinheiro(0)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                <small>Valor total do crédito</small>
                <br />
                {formatarDinheiro(
                  parcelaSelecionada?.valorFinanciamentoSemSeguro +
                    parcelaSelecionada?.valorIof
                )}
              </Typography>
            </Grid>
          </Grid>
        </UBox>

        <UBox mb={2}>
          <UBox mb={2}>
            <Typography variant="body2">
              <b>Prazos e parcelas</b>
            </Typography>
            <Divider />
          </UBox>

          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body2">
                <small>Vencimento</small>
                <br />
                1ª parcela{' '}
                {moment(
                  condicoes?.dataMinimaCarenciaFinanciamento,
                  'YYYY-MM-DD'
                ).format('DD/MM/YYYY')}{' '}
                (daqui a 30 dias)
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                <small>Valor da parcela</small>
                <br />
                {formatarDinheiro(parcelaSelecionada?.valorParcelaSemSeguro)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                <small>Quantidade de parcelas</small>
                <br />
                {parcelaSelecionada?.quantidadeParcelas} vezes
              </Typography>
            </Grid>
          </Grid>
        </UBox>
        <UBox mb={2}>
          <UBox mb={2}>
            <Typography variant="body2">
              <b>Taxas e impostos</b>
            </Typography>
            <Divider />
          </UBox>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant="body2">
                <small>Tarifa de cadastro</small>
                <br />
                {parcelaSelecionada?.listaDadosCustoFinanciamento?.find(
                  c => c?.custoFinanciamento?.codigo === 6
                )?.somaCusto
                  ? formatarDinheiro(
                      parcelaSelecionada?.listaDadosCustoFinanciamento?.find(
                        c => c?.custoFinanciamento?.codigo === 6
                      )?.valorCusto
                    )
                  : 'Isento'}
                {/* Isento */}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                <small>Taxa de juros mensal</small>
                <br />
                {parcelaSelecionada?.taxaFinanciamentoMensal}% ao mês
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                <small>Taxa de juros anual</small>
                <br />
                {parcelaSelecionada?.taxaFinanciamentoAnual}% ao ano
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                <small>IOF</small>
                <br />
                {`${formatarDinheiro(parcelaSelecionada?.valorIof)} `}
                {/* (2.85%) */}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                <small>CET mensal</small>
                <br />
                {parcelaSelecionada?.valorPercentualCetMensal}% ao mês
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                <small>CET anual</small>
                <br />
                {parcelaSelecionada?.valorPercentualCetAnual}%
              </Typography>
            </Grid>
          </Grid>
        </UBox>
        <UBox mb={2}>
          <UBox mb={2}>
            <Divider />
          </UBox>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">
                <small>Total a pagar:</small>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
              <Typography variant="body1">
                <b>
                  {parcelaSelecionada?.quantidadeParcelas}x de{' '}
                  {formatarDinheiro(parcelaSelecionada?.valorParcelaSemSeguro)}
                </b>
                <br />
                <small>
                  {`${formatarDinheiro(
                    parcelaSelecionada?.valorParcelaSemSeguro *
                      parcelaSelecionada?.quantidadeParcelas
                  )} `}
                </small>
              </Typography>
            </Grid>
          </Grid>
        </UBox>
      </UBox>
    </UBox>
  )
}
