import { Box, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { RiArrowLeftLine } from 'react-icons/ri'
import { GoSignOut } from 'react-icons/go'

import { useHistory } from 'react-router-dom'
import Button from 'src/components/ui/Button'
import UBox from 'src/components/ui/Ubox'
import { SynPerfis, SynPerfisArray } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { AiTwotoneThunderbolt } from 'react-icons/ai'
import { BiNetworkChart } from 'react-icons/bi'
import { useQueryParams } from 'src/hooks/useQuery'

const useStyles = makeStyles(theme => ({
  container: {
    // background: '#fff !important'
  },
  paper: {
    height: '100vh !important',

    '& .container-perfil': {
      overflow: 'auto',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingBottom: '40px',
      flexDirection: 'column'
    },

    '& .box-perfil': {
      width: '220px',
      height: '120px',
      flexBasis: '220px',
      flexGrow: 0,
      flexShrink: 0,
      border: '2px solid #eee',
      background: theme.palette.grey[50],
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      textAlign: 'center',

      '& b': {
        paddingTop: '8px'
      },

      '& img,& .icon': {
        filter: 'grayscale(100%)'
      },

      '&:hover': {
        '& img,& .icon': {
          filter: 'grayscale(0)'
        }
      }
    }
  },
  [theme.breakpoints.up('md')]: {
    container: {
      minHeight: '100vh'
    },
    paper: {
      height: '100% !important',

      '& .container-perfil': {
        justifyContent: 'center',
        overflow: 'auto',
        width: '100%',
        flexDirection: 'row'
      }
    }
  }
}))

export default function SelecionarTipoOrcamento() {
  const classes = useStyles()
  const {
    signed,
    user,
    perfilAtual,
    signOut,
    setPerfilAtual,
    setSetorParceiro
  } = useAuth()
  const history = useHistory()
  const query = useQueryParams()

  useEffect(() => {}, [])

  function selecionar(url: string) {
    const medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    const doc = query.get('doc')

    if (!!medicoSolicitacaoId) {
      url = `${url}?medicoSolicitacaoId=${medicoSolicitacaoId}`
    }

    if (!!doc) {
      url = `${url}&doc=${doc}`
    }

    history.push(url)
  }

  return (
    <UBox className={classes.paper}>
      <Box my={5}>
        <Typography variant="h6" align="center">
          Olá, como deseja continuar a solicitação?
        </Typography>
      </Box>
      {/* <Typography variant="body1">
          Digite o CPF da sua conta para que possamos enviar um e-mail com as
          instruções para definir uma nova senha.
        </Typography> */}
      <UBox display="flex" gridGap={20} className="container-perfil">
        <UBox
          className="box-perfil"
          onClick={() => selecionar(SynRoutes.formOrcamentoInteligente)}
        >
          {/* <img src="image/perfil/comercial.png" /> */}
          <AiTwotoneThunderbolt
            className="icon"
            style={{ fontSize: '38px', color: '#ebb852' }}
          />
          <b>Orçamento instantâneo</b>
        </UBox>
        <UBox
          className="box-perfil"
          onClick={() => selecionar(SynRoutes.formOrcamento)}
        >
          <BiNetworkChart
            className="icon"
            style={{ fontSize: '38px', color: '#1cc3a5' }}
          />
          {/* <img src="image/perfil/comercial.png" /> */}
          <b>Orçamento cotação</b>
        </UBox>
      </UBox>
    </UBox>
  )
}
